// React
import React from "react";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { sendCreateRequest } from "../../api/users/UserAPI";

// Styling
import brand2 from "../../img/ArcadisClarity02.png";
import EditProfile from "../edit-profile/EditProfile";
import "./Welcome.css";

function Welcome(props) {
  const { user } = props.auth;

  return (
    <div className="welcome-page">
      <WelcomeImage />
      <div className="create-profile-container">
        <div className="create-profile-form-container">
          <EditProfile
            user={user}
            sendRequest={sendCreateRequest}
            isCreateProfile={true}
          />
        </div>
      </div>
    </div>
  );
}

function WelcomeImage() {
  return (
    <div className="welcome-image-container">
      <img className="welcome-image" src={brand2} alt="" />
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(withRouter(Welcome));
