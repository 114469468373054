import React, { useState } from "react";
import Table from "../table/Table";
import PropTypes from "prop-types";

import "./ProjectControlUserTable.css";

import { columns } from "./ProjectControlNotifyTable.functions";
import { tranStr } from "../../utils/translation";

ProjectControlNotifyTable.propTypes = {
  data: PropTypes.array,
  selectedUsers: PropTypes.array,
  setSelectedUsers: PropTypes.func,
};

export default function ProjectControlNotifyTable(props) {
  const { data } = props;
  const { selectedUsers, setSelectedUsers } = props;

  // Table Row Selection
  const [selectedRow, setSelectedRow] = useState({});
  const [modal, setModal] = useState(false);

  return (
    <Table
      title={tranStr("Cost Plan Table")}
      tableArray={data}
      columns={columns()}
      tableSize={8}
      isTitleDisabled={true}
      // Checked Selection
      checked={selectedUsers}
      setChecked={setSelectedUsers}
      // Row Selection
      selectedRow={selectedRow}
      setSelectedRow={setSelectedRow}
      // Modal Control
      modal={modal}
      setModal={setModal}
    />
  );
}
