// React
import React, { useState } from "react";

// Functions
import { clone } from "./AdminLocalisation.functions";

// Translation
import { translate } from "../../utils/translation";

// Components
import AdminLocalisationUpload from "./AdminLocalisationUpload";
import AdminLocalisationToggle from "./AdminLocalisationToggle";
import ExportLocalisation from "./ExportLocalisation";

// Styles
import "./AdminLocalisationCloning.css";

export default function AdminLocalisationControls(props) {
  // Language Data
  const { languageCode } = props;
  const { setLanguageCode } = props;
  const { language } = props;
  const { namespacesObject } = props;
  // Namespace Name
  const { selectedNamespace } = props;
  const { setSelectedNamespace } = props;

  // Namespace Data
  const [terminologySetName, setTerminologySetName] = useState("");

  const [error, setError] = useState({});

  const [inputMode, setInputMode] = useState("CLONE");

  let data = {
    setError: setError,
    terminologySetName: terminologySetName,
    language: language,
    languageCode: languageCode,
    namespacesObject: namespacesObject,
    setSelectedNamespace: setSelectedNamespace,
    setTerminologySetName: setTerminologySetName,
  };
  return (
    <div className="alc-container">
      {/* Toggle between import and cloning */}
      <AdminLocalisationToggle
        inputMode={inputMode}
        setInputMode={setInputMode}
        setError={setError}
      />

      <div className="alc-column">
        <div className="alc-row">
          <b></b>
        </div>
        {/* Export localisation module components*/}
        <ExportLocalisation
          inputMode={inputMode}
          language={language}
          selectedNamespace={selectedNamespace}
        />

        {inputMode === "CLONE" && (
          <div>
            <div className="alc-row">
              <b>Clone Terminology</b>
            </div>

            <div className="alc-row">
              You can make a copy of the dataset and save it into a new
              terminology collection which can be edited with new definitions.
            </div>

            <div className="alc-column">
              <div className="alc-row">
                <div className="alc-label">
                  <b>{translate("Language")}: </b>
                </div>
                <select
                  className="alc-input"
                  value={languageCode}
                  onChange={(e) => {
                    setLanguageCode(e.target.value);
                  }}
                >
                  <option value="en">English</option>
                  <option value="zh">Chinese</option>
                </select>
              </div>

              <div className="alc-row">
                <div className="alc-label">
                  <b>{translate("Name")}: </b>
                </div>
                <input
                  className="alc-input"
                  value={terminologySetName}
                  onChange={(e) => {
                    setTerminologySetName(e.target.value);
                  }}
                />
              </div>
              <div className="general-row-container">
                <DisplayError error={error} setError={setError} />
              </div>
              <div className="general-row-container">
                <button
                  className="general-upload-button"
                  onClick={async () => {
                    onSubmit(data);
                  }}
                >
                  Copy Set
                </button>
              </div>
            </div>
          </div>
        )}
        {inputMode === "IMPORT" && (
          <div>
            <div className="alc-row">
              <b>Import Terminology Set</b>
            </div>
            <div>
              <div className="alc-row">
                Select the language to be used and enter a name for the
                collection.
              </div>

              <div className="alc-row">
                The name should follow the convention of region-sector-contract
                ("au-building-lump-sum").
              </div>

              <div className="alc-row">
                <div className="alc-label">
                  <b>{translate("Language")}: </b>
                </div>
                <select
                  className="alc-input"
                  value={languageCode}
                  onChange={(e) => {
                    setLanguageCode(e.target.value);
                  }}
                >
                  <option value="en">English</option>
                  <option value="zh">Chinese</option>
                </select>
              </div>

              <div className="alc-row">
                <div className="alc-label">
                  <b>{translate("Name")}: </b>
                </div>
                <input
                  className="alc-input"
                  value={terminologySetName}
                  onChange={(e) => {
                    setTerminologySetName(e.target.value);
                  }}
                />
              </div>
              <AdminLocalisationUpload
                terminologySetName={terminologySetName}
                languageCode={languageCode}
                namespacesObject={namespacesObject}
                setSelectedNamespace={setSelectedNamespace}
                setTerminologySetName={setTerminologySetName}
              />
            </div>
          </div>
        )}

        {inputMode === "MASTER" && (
          <div className="alc-container">
            <div className="alc-row">
              <b>Export Master Keys</b>
            </div>
            <div className="alc-row">
              Export a master list of all placeholder terms in Cost Clarity that
              are used as keys for translation.
            </div>
            <div className="alc-column">
              <div className="general-row-container">
                <div className="alc-key-export">
                  <a
                    className="general-upload-button"
                    href={
                      "https://stcostclarityprd001.blob.core.windows.net/1-guides/Translation_Key_Master_List_1677132129.xlsx?" +
                      Date.now()
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    Export Master
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

//Called on click of Create Copy button
async function onSubmit(data) {
  if (data.terminologySetName === "" || data.terminologySetName === null) {
    return data.setError({
      text: "Please enter terminology set name",
      type: "feedback-error",
    });
  }
  data.setError({
    text: "Cloning...",
    type: "feedback-success",
  });
  let name = data.languageCode + "-" + data.terminologySetName;

  let response = await clone(data.language, name);
  if (response === "Success") {
    // Reset dropdown data to include newly added namespace
    data.namespacesObject.set(await data.namespacesObject.get());
    //Select new namespace in the dropdown
    data.setSelectedNamespace(name.toLowerCase());
    // Clear input box
    data.setTerminologySetName("");
    data.setError({
      text: "Created Successfully",
      type: "feedback-success",
    });
  } else {
    data.setError({
      text: response,
      type: "feedback-error",
    });
  }
}
function DisplayError(props) {
  const { error } = props;
  return (
    <div className="feedback-error-container">
      <div className={error.type}>
        <b>{error.text}</b>
      </div>
    </div>
  );
}
