import { authHeader } from "../../_helpers/auth-header";
import { contactUsApis } from "../../api/api-endpoints/FormDataEndpoints";
export async function submitForm(data) {
  const url = contactUsApis().create_demo_request;

  const config = {
    method: "POST",
    body: JSON.stringify(data.payload),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  data.setError({
    text: "Submitting...",
    type: "request-demo-success",
  });

  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
  } else {
    console.log(response);
    return data.setError({
      text: "Connection Error",
      type: "request-demo-error",
    });
  }

  data.setError({
    text: "Submitted Successfully",
    type: "request-demo-success",
  });
}
