import { useState, useEffect } from "react";
import { authHeader } from "../../_helpers/auth-header";
import { opAPIs } from "../../api/api-endpoints/OptioneeringEndpoints";

export function useLoadOptionsStages(projectID) {
  const [loading, setLoading] = useState(true);
  const [stages, setStages] = useState([]);

  useEffect(() => {
    if (projectID) {
      setLoading(true);
      getOptionsStages(projectID)
        .then(async (stage_results) => {
          setStages(stage_results);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [projectID]);

  return {
    stages: {
      data: stages,
      loading,
      set: setStages,
      get: getOptionsStages,
    },
  };
}

export async function getOptionsStages(projectID) {
  // Optioneering API
  const url = opAPIs().get_stages + "/" + projectID;

  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };
  let erMessage = "Get Stages error";
  let response = await fetch(url, config);

  if (response.ok) {
    try {
      // attempt to extract json from response
      response = await response.json();
    } catch (e) {
      // ignore error since above step is optional
    }
  } else {
    response = [];
    console.log(erMessage);
  }
  return response;
}

export function useGetOptions(projectID) {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState(null);

  useEffect(() => {
    if (projectID) {
      setLoading(true);
      getOptionsStages(projectID)
        .then(async (result) => {
          setOptions(result);
        })
        .catch((e) => {
          console.log(`error during useGetOptions: ${e}`);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [projectID]);

  return {
    options: {
      loading,
      data: options,
      get: getOptionsStages,
      set: setOptions,
    },
  };
}
