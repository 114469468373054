import React, { useState } from "react";

// Components
import PostContractSelectorDrawer from "./PostContractSelectorDrawer";
import CheckboxesVertical from "../checkboxes/CheckboxesVertical";
import { tranStr } from "../../utils/translation";

import "./PostContractSelectorOptions.css";

export default function PostContractSelectorOptions(props) {
  // Props
  const { setFilters, filters } = props;
  const { maxReports, setMaxReports } = props;
  const { enableFilter } = props;
  const { height } = props;

  // State
  const [show, setShow] = useState(false);

  return (
    <div className="post-contract-selector-options">
      <PostContractSelectorDrawer
        // Modal Props
        Component={SelectorOptions}
        show={show}
        setShow={setShow}
        enableFilter={enableFilter}
        height={height}
        // Component Props
        setFilters={setFilters}
        filters={filters}
        setMaxReports={setMaxReports}
        maxReports={maxReports}
      />
    </div>
  );
}

function SelectorOptions(props) {
  const { filters, setFilters } = props;
  const { maxReports, setMaxReports } = props;
  const { enableFilter } = props;

  return (
    <div>
      <MaxReports setMaxReports={setMaxReports} maxReports={maxReports} />
      {enableFilter && (
        <CheckboxesVertical
          array={options()}
          setFunction={setFilters}
          selection={filters}
        />
      )}
    </div>
  );

  function options() {
    return [
      { label: tranStr("Cost Reports"), value: "Cost Reports" },
      { label: tranStr("Progress Claims"), value: "Progress Claims" },
      { label: tranStr("Variation Updates"), value: "Variation Updates" },
    ];
  }
}

function MaxReports(props) {
  const { maxReports, setMaxReports } = props;

  return (
    <div className="pcso-max-container">
      <input
        className="pcso-max-input"
        value={maxReports}
        onChange={(e) => {
          setMaxReports(e.target.value);
        }}
      />
      <div className="pcso-max-label">Report Limit</div>
    </div>
  );
}
