import React from "react";

export function PCRAStatusBar(props) {
  const { from, status, to } = props;

  return (
    <div className="pcra-log-status-bar">
      <div className={`status-line ${from}`}></div>
      <div className={`status-dot ${status}`}></div>
      <div className={`status-line ${to}`}></div>
    </div>
  );
}
