import React, { useContext } from "react";
import { PCRAStatusFlowBar } from "./PCRAStatusFlowBar";
import { PCRALogEntry } from "./PCRALogEntry";

import PCRALogAPI from "../pcra/PCRALogAPI";
import PCRAUpdateInput from "../pcra/PCRAUpdateInput.class";

import { PCRALogRootContext } from "./PCRALogRoot";

import { PCRA_GLOSSARY } from "./PCRAGlossary.constants";
import { getPCRARequestingUserDisplayName } from "./PCRA.functions";

const { AUDIT_STATUS } = PCRA_GLOSSARY;
const { CURRENT, PENDING } = AUDIT_STATUS;

export function PCRAJourney(props) {
  const { logs } = props;

  const context = useContext(PCRALogRootContext);
  const { user, data, setData, onAction } = context;

  const { isLocked, requestedBy } = data;

  const handleStageAction = prepareHandleStageAction({
    user,
    data,
    setData,
    onAction,
  });

  return (
    <div className="pcra-journey">
      {logs.map((log) => {
        const {
          id,
          user: { email },
          timeUpdated,
          stageDisplay,
          statusDisplay,
          comments,
        } = log;

        const status =
          isLocked && statusDisplay === CURRENT ? PENDING : statusDisplay;

        const username = getPCRARequestingUserDisplayName(log.user);

        return (
          <div key={id} className="pcra-journey-entry">
            <PCRALogEntry
              stage={stageDisplay}
              status={status}
              comments={comments}
              username={username}
              email={email}
              timestamp={timeUpdated}
              currentUser={requestedBy}
              handleStageAction={handleStageAction}
            />
            <PCRAStatusFlowBar stage={stageDisplay} status={status} />
          </div>
        );
      })}
    </div>
  );
}

function prepareHandleStageAction({ user, data, setData, onAction }) {
  const setPcraData = setData;

  return async function handleStageAction(e, { stage, status, comments }) {
    const updateInput = new PCRAUpdateInput({
      id: data.id,
      user,
      stage,
      status,
      comments,
    });

    await PCRALogAPI.UpdateAuditLog({ updateInput, setPcraData });

    // trigger external actions
    if (onAction && typeof onAction === "function") {
      await onAction.call();
    }
  };
}
