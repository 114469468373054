import React, { useState } from "react";
import Modal from "../modal/Modal";
import "./BenchmarkingJustification.css";
import { tranStr } from "../../utils/translation";
import PropTypes from "prop-types";

import { columns } from "./BenchmarkingJustification.functions";

import Table from "../table/Table";

BenchmarkingJustification.propTypes = {
  benchmarks: PropTypes.array,
  isLocalRegionFactorEnabled:PropTypes.bool
};

export function BenchmarkingJustification(props) {
  const { benchmarks } = props;
  const {isLocalRegionFactorEnabled} =props;

  const [modal, setModal] = useState(false);

  return (
    <div>
      <Modal
        // Modal Props
        title={"Benchmark Escalation Breakdown"}
        Component={BenchmarkingEscalationTable}
        modal={modal}
        setModal={setModal}
        // Component Props
        benchmarks={benchmarks}
        isLocalRegionFactorEnabled={isLocalRegionFactorEnabled} 
      />
      <button
        className={"benchmarking-justification-button"}
        id={"benchmarking-justification-button"}
        onClick={() => {
          setModal(true);
        }}
      >
        <i className="fas fa-question-circle"></i>
      </button>
    </div>
  );
}

BenchmarkingEscalationTable.propTypes = {
  benchmarks: PropTypes.array,
  isLocalRegionFactorEnabled:PropTypes.bool
};

function BenchmarkingEscalationTable(props) {
  const { benchmarks } = props;
  const { isLocalRegionFactorEnabled } = props;
  const [selectedCompany, setSelectedCompany] = useState({});

  return (
    <div>
      <Table
        title={tranStr("Benchmark Justification")}
        tableArray={benchmarks}
        columns={columns(isLocalRegionFactorEnabled)}
        tableSize={benchmarks.length}
        isTitleDisabled={true}
        isTotalDisabled={true}
        // Row Selection
        selectedRow={selectedCompany}
        setSelectedRow={setSelectedCompany}
        // Modal Control
        setModal={() => null}
      />
    </div>
  );
}
