import React from "react";
import { comma } from "../../helpers/common.functions";

import "./CostPlanningComparisonTable.css";
import isEmpty from "../../validation/is-empty";

export default function CostPlanningDetailedComparisonTable(props) {
  const { e, project } = props;

  if (e) {
    return (
      <div className="comparison-table">
        <TableHead />
        <Table data={e.deltas} project={project} />
        <TableTotal
          project={project}
          totalDelta={e.totalDelta}
          total={e.calculated_total}
          comparisonTotal={e.comparison_calculated_total}
        />
      </div>
    );
  } else {
    return null;
  }
}

function TableHead() {
  let row = {
    description: "Description",
    previous: "Previous",
    current: "Current",
    delta: "Delta",
  };

  return (
    <div className="comparison-table-head-row">
      <div className="comparison-table-description-cell">
        <b>{row.description}</b>
      </div>
      <div className="comparison-table-previous-cell-head">
        <b>{row.previous}</b>
      </div>
      <div className="comparison-table-current-cell-head">
        <b>{row.current}</b>
      </div>
      <div className="comparison-table-subtotal-cell-head">
        <b>{row.delta}</b>
      </div>
    </div>
  );
}

function TableRow(props) {
  const { row, project } = props;
  const rowType = "comparison-table-row";
  const bold = false;
  const valueType =
    row.delta >= 0
      ? "comparison-table-value-positive"
      : "comparison-table-value-negative";

  return (
    <div className={rowType}>
      <div className="comparison-table-description-cell">
        {valueBolder(row.description, bold)}
      </div>
      <div className="comparison-table-previous-cell">
        <div>{valueBolder(project.currency_symbol, bold)}</div>
        <div>{valueBolder(comma(row.previousSubtotal), bold)}</div>
      </div>
      <div className="comparison-table-current-cell">
        <div>{valueBolder(project.currency_symbol, bold)}</div>
        <div>{valueBolder(comma(row.subtotal), bold)}</div>
      </div>
      <div className="comparison-table-subtotal-cell">
        <div className={valueType}>
          <div>{valueBolder(project.currency_symbol, bold)}</div>
        </div>
        <div className={valueType}>
          <b>
            {valueBolder(generateBrackets(comma(Math.round(row.delta))), bold)}
          </b>
        </div>
      </div>
    </div>
  );
}

function Table(props) {
  const { data, project } = props;

  if (isEmpty(data)) {
    return null;
  }

  return (
    <div>
      {data.map((row, i) => (
        <TableRow key={i} row={row} project={project} />
      ))}
    </div>
  );
}

function TableTotal(props) {
  const { comparisonTotal, total, totalDelta, project } = props;

  const valueType =
    totalDelta >= 0
      ? "comparison-table-value-positive"
      : "comparison-table-value-negative";

  return (
    <div className="comparison-table-total-row">
      <div className="comparison-table-description-cell">
        <b>Total</b>
      </div>
      <div className="comparison-table-previous-cell">
        <div>{valueBolder(project.currency_symbol, true)}</div>
        <b>{comma(Math.round(comparisonTotal))}</b>
      </div>
      <div className="comparison-table-current-cell">
        <div>{valueBolder(project.currency_symbol, true)}</div>
        <b>{comma(Math.round(total))}</b>
      </div>
      <div className="comparison-table-subtotal-cell">
        <div className={valueType}>
          <div>{valueBolder(project.currency_symbol, true)}</div>
        </div>
        <div className={valueType}>
          <b>{generateBrackets(comma(Math.round(totalDelta)))}</b>
        </div>
      </div>
      <div className="comparison-table-note-cell">
        <b></b>
      </div>
    </div>
  );
}

// Helper Functions
function valueBolder(value, isBold) {
  if (isBold) {
    return <b>{value}</b>;
  } else {
    return value;
  }
}

function generateBrackets(string) {
  if (string.toString().includes("-")) {
    return "(" + string.split("-")[1] + ")";
  } else {
    return "+" + string;
  }
}
