import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { translate } from "../../utils/translation";
import { connect } from "react-redux";
import HelpButton from "./HelpButton";
import browserDetector from "../browser/BrowserDetector";
import brand2 from "../../img/ArcadisClarity02.png";
import ActiveDirectory from "./ActiveDirectory";
import Spinner from "../common/Spinner";
import IdleLogout from "../browser/IdleLogout";
import Modal from "../modal/Modal";
import isEmpty from "../../validation/is-empty";
import "./Landing.css";

/// props validation | SQ(javascript:S6774)
Landing.propTypes = {
  auth: PropTypes.object,
  history: PropTypes.object,
};
///
function Landing(props) {
  const { auth, history } = props;

  const [idleLogout, setIdleLogout] = useState(false);
  useEffect(() => {
    //show the modal if the user has been logged out while idle
    if (!isEmpty(localStorage.idleLogout)) {
      if (localStorage.idleLogout) {
        setIdleLogout(true);
      }
    }
    if (auth.isAuthenticated) {
      history.push("/portfolio/all-projects/projects/");
    }

    if (browserDetector().unsupported) {
      history.push("/browser");
    }
  }, [auth.isAuthenticated, history]);

  return (
    <div className="landing-background">
      <div className="landing-content">
        <img className="landing-brand" width="700" src={brand2} alt="" />
        <Display />
      </div>

      <Modal
        title="Inactive Timeout"
        Component={IdleLogout}
        modal={idleLogout}
        setModal={setIdleLogout}
        // Component Props
        setShow={setIdleLogout}
      />
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
Display.propTypes = {};
///
function Display(props) {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({ error: false, message: "" });

  // Update loading based on existence of AD loading
  useEffect(() => {
    const ADLoading = localStorage.getItem("ADLoading");
    if (ADLoading) {
      setLoading(true);
    }
    if (!ADLoading) {
      setLoading(false);
    }
  }, [setLoading]);

  //Login in progress
  if (loading) {
    return (
      <div className="landing-box">
        <h1 className="display-5 text-center">{translate("Welcome")}</h1>
        <p className="landing-message">
          <b>{translate("Please wait while we redirect you")}</b>
        </p>
        <Spinner width={100} />
      </div>
    );
    //Error while logging in
  } else if (!loading && status.error) {
    return (
      <div className="landing-box">
        <h1 className="display-5 text-center">{translate("Welcome")}</h1>
        <p className="landing-message">
          <b>{status.message}</b>
        </p>
        <ActiveDirectory setLoading={setLoading} setStatus={setStatus} />
        <HelpButton />
      </div>
    );
    //Normal state
  } else {
    return (
      <div className="landing-box">
        <h1 className="display-5 text-center">{translate("Welcome")}</h1>
        <p className="landing-message">
          <b> {translate("Bring Clarity to your project")}</b>
        </p>
        <ActiveDirectory setLoading={setLoading} setStatus={setStatus} />
        <HelpButton />
      </div>
    );
  }
}

export default connect((state) => ({
  auth: state.auth,
}))(Landing);
