import React from "react";
import PropTypes from "prop-types";

import { Switch, Route, Redirect } from "react-router-dom";

import isEmpty from "../../validation/is-empty";
import "./PostContract.css";

import PostContractSection from "../post-contract-2.0/PostContractSection";

PostContract.propTypes = {
  project: PropTypes.object,
  user: PropTypes.object,
  selectedReport: PropTypes.object,
  selectedPackage: PropTypes.object,
  postContract: PropTypes.object,
  dashboardSettings: PropTypes.object,
  showCRPanel: PropTypes.bool,
  showPCPanel: PropTypes.bool,

  setShowCRPanel: PropTypes.func,
  setShowPCPanel: PropTypes.func,
};

export default function PostContract(props) {
  const { project } = props;
  const { user } = props;
  const { selectedReport } = props;
  const { selectedPackage } = props;
  const { postContract } = props;
  const { dashboardSettings } = props;
  const { showCRPanel, setShowCRPanel } = props;
  const { showPCPanel, setShowPCPanel } = props;

  if (isEmpty(project)) {
    return null;
  }

  return (
    <div className="project-background">
      <Switch>
        <Route
          exact
          path={"/project/:id/post-contract/"}
          render={() => (
            <Redirect
              replace
              to={`/project/${project.id}/post-contract/cost-report`}
            />
          )}
        />
        <Route
          path={`/project/:id/post-contract/:pagename`}
          render={() => (
            <PostContractSection
              // Project
              project={project}
              user={user}
              // Post Contract
              selectedReport={selectedReport}
              selectedPackage={selectedPackage}
              postContract={postContract}
              // Dashboard
              dashboardSettings={dashboardSettings}
              // Cost Report Dashboard
              showCRPanel={showCRPanel}
              setShowCRPanel={setShowCRPanel}
              // Progress Claim Dashboard
              showPCPanel={showPCPanel}
              setShowPCPanel={setShowPCPanel}
            />
          )}
        />
      </Switch>
    </div>
  );
}
