// React
import React from "react";

export default function WholelifeCost(props) {
  return (
    <div className="alc-column">
      <div>
        <div className="toggle-button-text-container">
          <p></p>
          <p></p>
          <p>
            As well as providing advice on the Capital cost of your project,
            Arcadis possess a wealth of expertise within the Whole Life Cost and
            Facilities Management arena, <br />
            underpinned by our Asset Optimisation offering, which covers-
          </p>
          <ul>
            <li>Corporate Real Estate and Portfolio Management</li>
            <li>Capital investment, Whole Life Cost, and Lifecycle planning</li>
            <li>Facilities Management</li>
            <li>Workplace</li>
            <li>Energy & Sustainability</li>
          </ul>
          <p>
            Our Whole Life Cost service offer delivers improved value for money
            by creating a solution that strikes an effective balance between the
            costs for construction, <br /> asset replacement, on-going
            maintenance and repair, soft FM services such as cleaning, security
            and reception, and energy costs incurred during the life of an
            asset. <br />
            We help our clients maximise the return on the money they spend on
            built assets, enriching business performance and quality of life by
            creating sustainable <br />
            solutions that improve the built and natural environment. <br />
          </p>
          <p>
            For more information please get in touch with our Asset Optimisation
            team -
          </p>
          <b>Nigel Herbert - Asset Optimisation Lead -</b>
          <a href="mailto:nigel.herbert@arcadis.com">
            {" nigel.herbert@arcadis.com"}
          </a>
          <br />
          <b>Janine Garvie-Cole - Whole Life Cost Lead -</b>
          <a href="mailto:janine.garvie.cole@arcadis.com">
            {" janine.garvie.cole@arcadis.com"}
          </a>
        </div>
      </div>
    </div>
  );
}
