import React from "react";
import { comma } from "../../helpers/common.functions";

import "./ReconciliationSubtotal.css";

export default function ReconciliationSubtotal(props) {
  const { estimates, estimateID, subtotal_below, subtotal_name } = props;
  const { project } = props;

  // Only return a subtotal if the estimate has a subtotal below it
  if (!subtotal_below) {
    return null;
  }

  let total = 0;
  let stop = false;

  // Look at each estimate to determine if a subtotal is required
  estimates.forEach((e) => {
    // Do not add subtotals below this subtotal line
    if (stop) {
      return;
    }
    // If e has its own subtotal and is NOT this estimate we reset the counter
    if (e.subtotal_below && e.id !== estimateID) {
      total = 0; // Reset
      return;
    }

    // Add total for e
    total += e.totalDelta;
    if (e.id === estimateID) {
      stop = true;
    }
  });

  let displayTotal = comma(Math.round(total));

  return (
    <div className="reconciliation-subtotal">
      <div className="reconciliation-subtotal-label">{subtotal_name}</div>
      <div className="reconciliation-subtotal-costs">
        <div className="reconciliation-subtotal-total">
          {generateBrackets(displayTotal, project.currency_symbol)}
        </div>
      </div>
    </div>
  );
}

function generateBrackets(string, currency_symbol) {
  string = string.toString();
  if (string.includes("-")) {
    return currency_symbol + "(" + string.split("-")[1] + ")";
  } else {
    return "+" + currency_symbol + string;
  }
}
