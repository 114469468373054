import React from "react";
import PropTypes from "prop-types";

import "./BenchmarkControlHeatmap.css";

import { columnsFromProjects } from "./BenchmarkingHeatMap.functions";
import isEmpty from "../../validation/is-empty";
import { tranStr } from "../../utils/translation";

const AVERAGE_COLUMN = 2;

/// props validation | SQ(javascript:S6774)
BenchmarkingCostplanComparisons.propTypes = {
  skipHighlight: PropTypes.bool,
  thisBenchmark: PropTypes.object,
  benchmarks: PropTypes.array,
  cbs: PropTypes.object,
  costTypeLabel: PropTypes.string,
  selectedCostType: PropTypes.string,
};
///
export default function BenchmarkingCostplanComparisons(props) {
  const { skipHighlight } = props;

  const { thisBenchmark, benchmarks } = props;
  const { cbs } = props;
  const { costTypeLabel } = props;
  const { selectedCostType } = props;

  let theBenchmarks = [thisBenchmark];
  const allBenchmarks = theBenchmarks.concat(benchmarks);
  // List the headings
  const headings = [
    { column_name: costTypeLabel + " " + tranStr("on Cost Elements") },
    { column_name: tranStr("Unit") },
  ];

  // Add each Project's name as a column heading
  const columns = columnsFromProjects(headings, allBenchmarks);
  delete columns[AVERAGE_COLUMN];

  if (isEmpty(cbs.data)) {
    return null;
  }

  const categoryGroup = cbs?.data?.categoryGroups?.find(
    (g) => g.categoryName === selectedCostType
  );

  const codes = [];

  categoryGroup?.categoryItems?.forEach((categoryItem) => {
    codes.push(...categoryItem.codes);
  });

  const allElements = cbs?.data?.elementCodes;
  const elements = allElements?.filter((e) => codes.includes(e.code));

  if (isEmpty(elements)) {
    return null;
  }

  const displayedCols = [];
  const displayedRows = [];

  let displayColKey = 0;
  columns.forEach((p, i) => {
    let headingType = "benchmark-element-heading";
    if (i === 1) {
      headingType = "benchmark-element-uom-heading";
    }
    if (i === 3 && !skipHighlight) {
      headingType = "benchmark-element-project-heading";
    }

    displayedCols.push(
      <th className={headingType} key={displayColKey}>
        {p.column_name}
      </th>
    );
    displayColKey++;
  });

  let rowKey = 0;
  // Create a row for Each element
  elements.forEach((element) => {
    const tableData = [];

    // Description Cell
    const tableData1 = (
      <td key={rowKey + 1} className="benchmark-element-cell-description">
        <div>{element.code + " - " + element.description}</div>
      </td>
    );

    tableData.push(tableData1);
    // Unit Cell
    const tableData2 = (
      <td key={rowKey + 2} className="benchmark-element-cell-unit">
        <div>{element.unit}</div>
      </td>
    );
    tableData.push(tableData2);

    // A cell for each benchmark
    allBenchmarks.forEach((benchmark, i) => {
      const row = benchmark.rows.find((r) => r.code === element.code);

      let percent = round((row?.total / benchmark.construction_cost) * 100, 2);

      if (isNaN(percent)) {
        percent = "";
      }

      const tableData3 = (
        <td key={rowKey + 3}>
          <div>{percent}</div>
        </td>
      );
      rowKey++;
      tableData.push(tableData3);
    });

    const tableRow = <tr key={rowKey}>{tableData}</tr>;

    displayedRows.push(tableRow);
  });

  return (
    <div className="benchmark-element-flex">
      <div className="benchmark-element-table-box">
        <div className="benchmark-element-table-container">
          <table className="benchmark-element-table">
            <thead className="benchmark-element-head">
              <tr>{displayedCols}</tr>
            </thead>
            <tbody className="benchmark-body">{displayedRows}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function round(value, precision) {
  let multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}
