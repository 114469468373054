import React, { useState, useEffect } from "react";
import { pc2APIs } from "../../api/api-endpoints/PostContract2.0Endpoints";
import PropTypes from "prop-types";

import isEmpty from "../../validation/is-empty";
import { authHeader } from "../../_helpers/auth-header";
import Commentary from "./Commentary";
import "./PostContractCommentary.css";
import { tranStr } from "../../utils/translation";

ProgressClaimCommentary.propTypes = {
  projectID: PropTypes.string,
  commentary: PropTypes.array,
  postContract: PropTypes.object,
  postContractId: PropTypes.string,
};

export default function ProgressClaimCommentary(props) {
  const { projectID } = props;
  const { commentary } = props;
  const { postContract } = props;
  const { postContractId } = props;

  const [error, setError] = useState({});

  const [fieldSet, setFieldSet] = useState([
    {
      comment:
        "The due date for this progress claim is YYYY and payment should be made to the Contractor by ZZZZ.",
      type: "progress_claim",
    },
    {
      comment:
        "The recommended payment includes for advance payments to the Contractor totaling XXXX.",
      type: "progress_claim",
    },
    {
      comment:
        "The recommended payment includes for payments to the Contractor in respect of materials off site totaling AAAA.  Evidence has been received that the title of these materials is vested in the Employer and that they are suitably insured.",
      type: "progress_claim",
    },
    {
      comment:
        "The recommended payment includes for contra charges against the Contractor totaling BBBB. ",
      type: "progress_claim",
    },
    {
      comment:
        "No adjustment has been included within the recommended payment for Liquidated Damages",
      type: "progress_claim",
    },
    {
      comment:
        "The amount of tax on the payment has been calculated for indicative purposes on a % basis of the total of work executed, at the rate advised by the client.",
      type: "progress_claim",
    },
    {
      comment:
        "No tax has been included within the payment figures associated with this progress claim. We recommend that the client seeks professional advice on tax issues. ",
      type: "progress_claim",
    },
  ]);

  const mainHeading = "Add Progress Commentary";
  const subHeading = "Progress Commentary";
  useEffect(() => {
    if (!isEmpty(commentary)) {
      if (!isEmpty(commentary.filter((x) => x.type === "progress_claim"))) {
        setFieldSet(
          commentary
            .filter((x) => x.type === "progress_claim")
            .map((c) => {
              return {
                comment: c.comment,
                type: c.type,
              };
            })
        );
      }
    }
  }, [commentary]);

  return (
    <div className="post-contract-clarifications">
      <Commentary
        fields={fieldSet}
        setFields={setFieldSet}
        mainHeading={mainHeading}
        subHeading={subHeading}
        crType="progress_claim"
      />
      <div className="general-row-container">
        <Notify error={error} setError={setError} />
      </div>
      <div className="general-button-container">
        <SaveButton />
      </div>
    </div>
  );

  function SaveButton() {
    return (
      <button className="general-upload-button" onClick={Save}>
        Save
      </button>
    );
  }

  //Save
  async function Save(e) {
    e.preventDefault();
    const comments = [];
    fieldSet.forEach((f) => {
      f.post_contract_id = postContractId;
      f.type = "progress_claim";
      if (!isEmpty(f.comment)) {
        comments.push(f);
      }
    });

    const payload = {
      comments: comments,
      postContractID: postContractId,
      projectID: projectID,
      type: "progress_claim",
    };
    const field = JSON.stringify(payload);
    const formData = new FormData();
    formData.append("field", field);

    // POST CONTRACT API
    const url = pc2APIs().save_commentary;

    const config = {
      method: "POST",
      body: formData,
      headers: authHeader({
        authJson: false,
        authForm: true,
        guestJson: false,
      }),
    };
    setError({
      text: "Saving...",
      type: "feedback-success",
    });
    let response = await fetch(url, config);

    if (response.ok) {
      // Response OK
      setError({
        text: "Saved Successfully",
        type: "feedback-success",
      });

      const URL = window.location.href;
      const sheetName = URL.substring(URL.lastIndexOf("/") + 1);
      postContract.set(
        await postContract.get(projectID, postContractId, sheetName)
      );
    } else {
      // Response Not OK
      console.log("Network Error");
    }
  }
}
export function Notify(props) {
  const { error } = props;

  return (
    <div className="pcu-error-container">
      <div className={error.type}>
        <b>{tranStr(error.text)}</b>
      </div>
    </div>
  );
}
