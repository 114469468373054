// React
import React, { useState, useEffect } from "react";

// Components
import CreateProjectSelectProjectCostManagers from "../project-create/CreateProjectSelectProjectCostManagers";
import { ModalMultiple, Submit, DisplayErrors } from "./Permissions.components";

// Functions
import isEmpty from "../../validation/is-empty";
import { translate } from "../../utils/translation";

// Styles
import "./ProjectPermissions.css";

export default function PortfolioPermissions(props) {
  // Props
  const { selectedPortfolio } = props;
  const { updateState } = props;
  const { portfolioUsers } = props;

  // Selection Details
  const [users, setUsers] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!isEmpty(portfolioUsers.data)) {
      setUsers(portfolioUsers.data || []);
    }
  }, [portfolioUsers.data]);

  const data = {
    selectedPortfolio: selectedPortfolio,
    updateState: updateState,
    setError: setErrors,
    project_leader: "",
    peer_reviewer: "",
    usersSelected: users.map((c) => c.id),
    existingUsers: portfolioUsers.data,
    mappingId: selectedPortfolio.id,
    mappingType: "Portfolio",
    companyId: null,
  };

  return (
    <div className="pciu">
      <div className="pciu-form">
        <div className="pciu-panel-container">
          <div className="pciu-panel">
            Please note that the user must also be allocated to the project for
            it to appear in the shared portfolio.
          </div>
        </div>

        <div className="pciu-panel-container">
          <div className="pciu-panel">
            <ModalMultiple
              Component={CreateProjectSelectProjectCostManagers}
              setFunction={setUsers}
              label={translate("Users")}
              selectedData={users}
              userGroup={"all"}
            />
          </div>
        </div>
        <div className="general-row-container">
          <DisplayErrors error={errors} />
        </div>
        <Submit data={data} />
      </div>
    </div>
  );
}
