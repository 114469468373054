import React from "react";
import { PCRAJourneyContinuity } from "./PCRAJourneyContinuity";
import { PCRAJourney } from "./PCRAJourney";

import { PCRAShowPastJourneysToggle } from "./PCRAShowPastJourneysToggle";

export function PCRAJourneys(props) {
  const { processType } = props;
  const { journeys } = props;
  const { enablePastJourneys, showPastJourneys, setShowPastJourneys } = props;

  const displayJourneys = [];

  for (let i = 0; i < journeys.length; i++) {
    const journey = journeys[i];
    const { id, logs } = journey;

    displayJourneys.push(<PCRAJourney key={id} logs={logs} />);

    if (!enablePastJourneys) {
      break;
    }

    if (journeys.length > 1 && i === 0) {
      displayJourneys.push(
        <PCRAShowPastJourneysToggle
          key={`${id}_showPastJourneysToggle`}
          showPastJourneys={showPastJourneys}
          setShowPastJourneys={setShowPastJourneys}
        />
      );
    }

    if (!showPastJourneys) {
      break;
    }

    const nextJourney = journeys[i + 1];
    const endStage = nextJourney?.endStage;
    if (endStage) {
      displayJourneys.push(
        <PCRAJourneyContinuity
          key={`${id}_endStage`}
          id={id}
          endStage={endStage}
          processType={processType}
        />
      );
    }
  }

  return <div className="pcra-journeys">{displayJourneys}</div>;
}
