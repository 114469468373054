import React from "react";

import ValueEngineering from "./ValueEngineering";
import isEmpty from "../../validation/is-empty";
import { translate } from "../../utils/translation";
import ValueEngineeringsTotal from "./ValueEngineeringsTotal";

import "./ValueEngineering.css";
import "../estimates/Estimates.css";
import ValueEngineeringDisclaimer from "./ValueEngineeringDisclaimer";

export default function ValueEngineerings(props) {
  const { project } = props;
  const { user } = props;
  const { CPs } = props;
  const { CP } = props;
  const { TPI } = props;
  const { accordionState } = props;

  // Loading Complete Cost Plan Empty
  if (isEmpty(CP) || isEmpty(CP.estimates)) {
    return (
      <div className="estimates-container">
        <div className="estimate-box">
          {translate(
            "Value Management is available after a Cost Plan has been uploaded."
          )}
        </div>
      </div>
    );
  }

  const display = [];

  // Estimates Included in the Total
  CP.estimates.forEach((estimate, i) => {
    if (estimate.is_included) {
      display.push(
        <ValueEngineering
          project={project}
          accordionState={accordionState}
          user={user}
          CPs={CPs}
          CP={CP}
          TPI={TPI}
          estimate={estimate}
          key={estimate.id}
        />
      );
    }
  });

  // The Total
  display.push(<ValueEngineeringsTotal project={project} CP={CP} key={0} />);

  // Estimates Excluded from the Total
  CP.estimates.forEach((estimate, i) => {
    if (!estimate.is_included) {
      display.push(
        <ValueEngineering
          project={project}
          accordionState={accordionState}
          user={user}
          CPs={CPs}
          CP={CP}
          TPI={TPI}
          estimate={estimate}
          key={estimate.id}
        />
      );
    }
  });

  // Loading Complete
  return (
    <div className="estimates-container">
      <div className="estimate-table-and-total-container">{display}</div>
      <ValueEngineeringDisclaimer estimateCount={CP.estimates.length} />
    </div>
  );
}
