import { tranStr } from "../../utils/translation";
import isEmpty from "../../validation/is-empty";
import { AVERAGE, TOTAL } from "../../actions/types";

export function benchmarkCategories(benchmarkArray, key, operation) {
  // Extract super elements for each benchmark project
  const masterCategories = [];
  const benchmarkData = [];
  const benchmarks = benchmarkArray;
  const length = benchmarkArray?.length;

  if (isEmpty(benchmarks)) {
    return benchmarkData;
  }

  // Create the Data Model
  benchmarks.forEach((benchmark) => {
    populateMasterCategories(masterCategories, benchmark, key);
  });

  if (isEmpty(masterCategories)) {
    return benchmarkData;
  }

  // Iterate through each master category
  // and sum the category values across each benchmark
  masterCategories.forEach((masterCategory) => {
    benchmarks.forEach((benchmark) => {
      sumMasterCategories(benchmark, masterCategory, key);
    });
  });

  // Push the objects of the field types to the field array
  let total = 0;
  masterCategories.forEach((field) => {
    total += field.value;
  });

  masterCategories.forEach((masterCategory, i) => {
    processBenchmarkData(
      masterCategory,
      benchmarkData,
      length,
      total,
      operation,
      i
    );
  });
  return benchmarkData;
}

// Populate the collection of categories
function populateMasterCategories(masterCategories, benchmark, key) {
  if (isEmpty(masterCategories)) {
    const categoryGroup = benchmark?.category_groups?.find(
      (g) => g.categoryName === key
    );

    categoryGroup?.categoryItems?.forEach((categoryItem) => {
      masterCategories.push({
        field: categoryItem.field,
        value: 0,
      });
    });
  }
}

// Sum the data of each category
function sumMasterCategories(benchmark, masterCategory, key) {
  const categoryGroup = benchmark?.category_groups?.find(
    (g) => g.categoryName === key
  );

  categoryGroup?.categoryItems?.forEach((categoryItem) => {
    if (masterCategory.field === categoryItem.field) {
      masterCategory.value += categoryItem.value;
    }
  });
}

// Create the data object to be used in the charts
function processBenchmarkData(
  masterCategory,
  benchmarkData,
  length,
  total,
  operation,
  i
) {
  if (!isEmpty(masterCategory)) {
    let value = 0;
    if (operation === AVERAGE) {
      value = masterCategory.value / length;
    }

    if (operation === TOTAL) {
      value = masterCategory.value;
    }

    benchmarkData.push({
      // Name OR Field are used by charts
      name: tranStr(masterCategory.field),
      field: tranStr(masterCategory.field),

      // Quantity Or Value are used by charts
      quantity: Math.round(value),
      value: Math.round(value),

      // Percentage
      percentage: ((masterCategory.value / total) * 100).toFixed(1),

      id: i + 1,
    });
  }
}

export function createEscalationCategory(estimates) {
  let escalation = 0;
  let total = 0;

  estimates.forEach((estimate) => {
    escalation += estimate.escalationAllowance;
    total += estimate.calculated_total;
  });

  const escalationObject = {
    // Name OR Field are used by charts
    name: tranStr("Tender Price Inflation"),
    field: tranStr("Tender Price Inflation"),

    // Quantity Or Value are used by charts
    quantity: Math.round(escalation),
    value: Math.round(escalation),

    // Percentage
    percentage: ((escalation / total) * 100).toFixed(1),

    id: 99, // It only needs to be at the end
  };

  return escalationObject;
}

export function createEscalationPercentages(data) {
  let total = 0;

  data.forEach((item) => {
    total += item.value;
  });

  data.forEach((item) => {
    item.percentage = ((item.value / total) * 100).toFixed(1);
  });
}
