import { useState, useEffect } from "react";
import { authHeader } from "../../_helpers/auth-header";
import { sharedAPIs } from "../api-endpoints/SharedServiceEndpoints";
import isEmpty from "../../validation/is-empty";
// import {currencyExchange} from "../../__tests__/data/currency-exchange.json"
// import {currencies} from "../../__tests__/data/currency-exchange.json"
// Get a list of Currencies
export async function getCurrencies() {
  const url = sharedAPIs().get_currencies;

  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let erMessage = "Get Currencies error";

  let response = await fetch(url, config);

  if (response.ok) {
    response = await response.json();
  } else {
    response = [];
    console.log(erMessage);
  }
  return response;
}


// GET CURRENCIES
export function useCurrencies(show) {
  const [loading, setLoading] = useState(true);
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    if (show) {
      setLoading(true);
      getCurrencies()
        .then((currenciesRes) => {
          const _currencies = currenciesRes;
          setCurrencies(_currencies);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [show]);

  return {
    currencies: {
      data: currencies,
      loading: loading,
      get: getCurrencies,
      set: setCurrencies,
    },
  };
}

// GET CURRENCY EXCHANGE
export function useCurrencyExchange() {
  const [loading, setLoading] = useState(true);
  const [currencyExchange, setCurrencyExchange] = useState([]);

  useEffect(() => {
    setLoading(true);
    getCurrencyExchange()
      .then((currencyExchangeRes) => {
        setCurrencyExchange(currencyExchangeRes);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  return {
    currencyExchange: {
      data: currencyExchange,
      loading: loading,
      get: getCurrencyExchange,
      set: setCurrencyExchange,
    },
  };
}

function addExchangeToCurrency(currencies, currencyExchange) {
  currencyExchange.forEach((e) => {
    let currency = currencies.find((x) => x.code === e.Code);
    if (!isEmpty(currency)) {
      currency.rate = e.Rate;
      currency.name = e.Name;
      currency.time = e.Time;
      currency.username = e.UserName;
    }
  });
  return currencies;
}

async function getCurrencyExchange() {
  const currencies = await getCurrencies();
  const url = sharedAPIs().get_currency_exchange;

  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);
  if (response.ok) {
    response = await response.json();

    //add currency with exchange rates
    return addExchangeToCurrency(currencies, response);
  } else {
    return [];
  }
}
