import { cpAPIs } from "../../api/api-endpoints/CostPlanningEndpoints";
import { authHeader } from "../../_helpers/auth-header";
import { projectAPIs } from "../../api/api-endpoints/ProjectEndpoints";
import { pc2APIs } from "../../api/api-endpoints/PostContract2.0Endpoints";

export async function deleteProject(data) {
  let result = window.confirm(
    `This will permanently delete the project. Confirm?`
  );

  if (!result) {
    return null;
  }

  const url =
    projectAPIs().delete_project + "/" + data.projectID + "?isSoftDelete=true";

  const config = {
    method: "DELETE",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);
  if (response.ok) {
    // Close first to prevent second get request
    data.setShow(false);
    data.projects.set(await data.projects.get());
  } else {
    console.log("Admin Project Delete Error");
  }
}

export async function deleteCostPlanning(data) {
  let result = window.confirm(
    `This will permanently clear Cost Planning for this project. Confirm?`
  );

  if (!result) {
    return null;
  }

  const url = cpAPIs().delete_cost_planning + "/" + data.projectID;

  const config = {
    method: "DELETE",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);
  if (response.ok) {
    // Close first to prevent second get request
    data.setShow(false);
    data.projects.set(await data.projects.get());
  } else {
    console.log("Admin Project Delete Error");
  }
}

export async function deletePostContract(data) {
  let result = window.confirm(
    `This will permanently clear Post Contract for this project. Confirm?`
  );

  if (!result) {
    return null;
  }

  const url = pc2APIs().delete_all_post_contract + "/" + data.projectID;

  const config = {
    method: "DELETE",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);
  if (response.ok) {
    // Close first to prevent second get request
    data.setShow(false);
    data.projects.set(await data.projects.get());
  } else {
    console.log("Admin Project Delete Error");
  }
}
