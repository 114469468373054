import React, { useState, useEffect, useCallback } from "react";

import isEmpty from "../../validation/is-empty";
import { deepClone } from "../../helpers/common.functions";

import Modal from "../modal/Modal";
import ValueEngineeringTableRowDetailModal from "./ValueEngineeringTableRowDetailModal";

import { VALUE_ENGINEERING } from "./ValueEngineering.strings";

import ValueEngineeringTable from "./ValueEngineeringTable";
import ValueEngineeringsMultipleTotals from "./ValueEngineeringsMultipleTotals";
import Accordion from "../accordion/Accordion";
import ValueEngineeringControl from "./ValueEngineeringControl";

export default function ValueEngineering(props) {
  const { user } = props;
  const { project } = props;
  const { CPs } = props;
  const { CP } = props;
  const { estimate } = props;
  const { accordionState } = props;

  if (isEmpty(CP)) {
    return (
      <div className="project-budget">
        {VALUE_ENGINEERING.WARNINGS.VM_NOT_AVAILABLE_UNTIL_CP_UPLOAD}
      </div>
    );
  }

  return (
    <EstimateAccordion
      user={user}
      project={project}
      CPs={CPs}
      CP={CP}
      estimate={estimate}
      accordionState={accordionState}
    />
  );
}

export function EstimateAccordion(props) {
  const { user } = props;
  const { project } = props;
  const { CPs } = props;
  const { CP } = props;
  const { estimate } = props;
  const { accordionState } = props;

  const [modal, setModal] = useState(false);
  const [isModalReadonly, setIsModalReadonly] = useState(true);

  let data = CP?.value_engineerings?.find(
    (ve) => ve?.estimate_id === estimate.id
  );

  const veItemsOriginal = deepClone(data?.value_engineering_items || []);
  const [veItems, setVeItems] = useState(veItemsOriginal);
  const [selectedVeItemId, setSelectedVeItemId] = useState(
    veItems[0]?.id || null
  );

  const resetVeItems = useCallback(
    function () {
      // use a fresh clone of data?.value_engineering_items to prevent an infinite loop of state changes
      const _veItems = deepClone(data?.value_engineering_items || []);
      setVeItems(_veItems);

      let id = _veItems[0]?.id || null;

      // check if selectedVeItemId is valid; if not, reset it
      const row = _veItems.find((item) => item.id === selectedVeItemId);
      if (isEmpty(row)) {
        setSelectedVeItemId(id);
      }

      return id;
    },
    [data, selectedVeItemId]
  );

  // If modal is closed, reset veItems (discard unsaved changes made in ValueEngineeringTableRowDetailModal)
  useEffect(() => {
    if (modal === false) {
      resetVeItems();
    }
  }, [modal, resetVeItems]);

  const { calculated_total, local_region_area } = estimate;

  let rate = Math.round(calculated_total / local_region_area);
  if (Number.isNaN(rate)) {
    rate = 0;
  }

  let total = Math.round(calculated_total);

  const isProjectPhaseCostPlanning = project.project_phase === "COST_PLANNING";

  return (
    <div className="estimate-wrapper">
      <div className="estimate-flex-box">
        <Accordion
          key={estimate.id}
          label={estimate.name}
          local_region_area={local_region_area}
          unit={project.unit_of_measure}
          rate={rate}
          total={total}
          // State
          accordionState={accordionState}
          accordionSection={"VALUEMANAGEMENT"}
          estimateID={estimate.id}
        >
          <div className="project-budget">
            <div className="project-budget-column">
              <ValueEngineeringTable
                data={data}
                project={project}
                user={user}
                CPs={CPs}
                veItemsOriginal={veItemsOriginal}
                veItems={veItems}
                setVeItems={setVeItems}
                setModal={setModal}
                setSelectedVeItemId={setSelectedVeItemId}
                setIsModalReadonly={setIsModalReadonly}
              />
            </div>
          </div>

          <div>
            <ValueEngineeringsMultipleTotals data={data} total={total} />
          </div>
        </Accordion>
        {isProjectPhaseCostPlanning && (
          <ValueEngineeringControl
            project={project}
            user={user}
            CP={CP}
            setModal={setModal}
            setIsModalReadonly={setIsModalReadonly}
          />
        )}
      </div>
      <Modal
        Component={ValueEngineeringTableRowDetailModal}
        title={VALUE_ENGINEERING.TITLE}
        modal={modal}
        setModal={setModal}
        // Component Props
        data={data}
        project={project}
        CP={CP}
        estimate={estimate}
        user={user}
        CPs={CPs}
        veItems={veItems}
        setVeItems={setVeItems}
        resetVeItems={resetVeItems}
        selectedVeItemId={selectedVeItemId}
        setSelectedVeItemId={setSelectedVeItemId}
        isReadonly={isModalReadonly}
        isProjectPhaseCostPlanning={isProjectPhaseCostPlanning}
      />
    </div>
  );
}
