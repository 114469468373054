export function roundWithPrecision(value, precision) {
  let multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

// Find the largest number in an array
export function maxNumber(array) {
  let tempTotal = 0;

  tempTotal = Math.max(...array);

  return tempTotal;
}

// Find the smallest number in an array
export function minNumber(array) {
  let tempTotal = 0;
  tempTotal = Math.min(...array);

  return tempTotal;
}
