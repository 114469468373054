import React, { useState, useEffect } from "react";

import isEmpty from "../../validation/is-empty";
import MetricContainer from "./ProjectControlEstimateMetricsContainer";

// Functions
import {
  onSaveMetrics,
  onAddMetrics,
  mergeMetrics,
} from "./ProjectControlEstimateMetrics.functions";

import "./ProjectControlEstimateMetrics.css";
import { translate } from "../../utils/translation";
import { DisplayError } from "../project-control/ProjectControl";

// Metrics are derived from the estimates and are saved to the Project
export default function ProjectControlEstimateMetrics(props) {
  const { project } = props;
  const { CP } = props;
  const { setModal } = props;

  const [metricContainers, setMetricContainers] = useState([]);
  const [error, setError] = useState({});

  useEffect(() => {
    if (!isEmpty(project.metrics)) {
      let filtered = project.metrics.filter(
        (m) => m.stage + "." + m.revision === CP.stage + "." + CP.version
      );
      setMetricContainers(filtered);
    }
  }, [project, CP]);

  if (isEmpty(CP) || isEmpty(CP.estimates)) {
    return (
      <div className="project-control-estimate-metrics">
        No cost plan has been uploaded for this project. Upload cost plan using
        Summary tab in the navigation bar
      </div>
    );
  }

  const estimates = CP.estimates;
  const allMetrics = mergeMetrics(estimates);
  const display = [];

  metricContainers.forEach((metricContainer, i) => {
    display.push(
      <MetricContainer
        key={i}
        // Data
        allMetrics={allMetrics}
        metricContainer={metricContainer}
        // State
        metricContainers={metricContainers}
        setMetricContainers={setMetricContainers}
      />
    );
  });

  return (
    <div className="project-control-estimate-metrics">
      <div className="project-control-estimate-metrics-container">
        {display}
      </div>
      <div className="general-row-container">
        <AddContainerButton
          project={project}
          metricContainers={metricContainers}
          setMetricContainers={setMetricContainers}
        />
      </div>
      <div className="general-row-container">
        <DisplayError error={error} setError={setError} />
      </div>
      <div className="general-button-container">
        <SaveMetricsButton
          metricContainers={metricContainers}
          project={project}
          CP={CP}
          setError={setError}
          setModal={setModal}
        />
      </div>
    </div>
  );
}

function AddContainerButton(props) {
  const { metricContainers, setMetricContainers } = props;
  const { project } = props;

  if (metricContainers.length >= 3) {
    return null;
  }

  return (
    <button
      className="general-modal-button"
      onClick={() => {
        onAddMetrics(metricContainers, setMetricContainers, project.id);
      }}
    >
      {translate("Add Metric")}
    </button>
  );
}

function SaveMetricsButton(props) {
  const { metricContainers } = props;
  const { project } = props;
  const { setError } = props;
  const { setModal } = props;
  const { CP } = props;

  return (
    <button
      className="general-upload-button"
      onClick={() => {
        onSaveMetrics(metricContainers, project, CP, setError, setModal);
      }}
    >
      {translate("Save")}
    </button>
  );
}
