import React, { useState } from "react";
import ProjectControlEditEstimateMetrics from "./ProjectControlEstimateMetricsEdit";
import Modal from "../modal/Modal";
import { translate } from "../../utils/translation";
import "./ProjectControlEstimateMetricsContainer.css";

export default function ProjectControlEstimateMetricsContainer(props) {
  // Data
  const { allMetrics } = props;
  const { metricContainer } = props;
  // State from Props
  const { metricContainers, setMetricContainers } = props;
  const [selectedMetrics, setSelectedMetrics] = useState([]);

  return (
    <div className="project-control-estimate-metric-wrapper">
      <MetricBox
        // Container State
        metric={metricContainer}
        setMetricContainers={setMetricContainers}
      />
      <EditMetricButton
        // Data
        allMetrics={allMetrics}
        // Selected Metrics
        selectedMetrics={selectedMetrics}
        setSelectedMetrics={setSelectedMetrics}
        // Container State
        metricContainer={metricContainer}
        setMetricContainers={setMetricContainers}
      />
      <RemoveMetricButton
        // Container State
        metricContainer={metricContainer}
        metricContainers={metricContainers}
        setMetricContainers={setMetricContainers}
      />
    </div>
  );
}

function MetricBox(props) {
  const { metric } = props;
  const { setMetricContainers } = props;

  return (
    <div className="project-control-estimate-metric-display">
      <input
        className="project-control-estimate-metric-display-input-code"
        placeholder={"Code"}
        value={metric.code}
        onChange={(e) => {
          let newValue = e.target.value;
          const ID = metric.id;

          setMetricContainers((metricContainers) => {
            let editableMetrics = [...metricContainers];

            editableMetrics.forEach((editableMetric) => {
              if (editableMetric.id === ID) {
                editableMetric.code = newValue;
              }
            });
            return editableMetrics;
          });
        }}
      />
      <input
        className="project-control-estimate-metric-display-input-value"
        placeholder={"Value"}
        value={metric.quantity}
        onChange={(e) => {
          let newValue = e.target.value;
          const ID = metric.id;

          setMetricContainers((metricContainers) => {
            let editableMetrics = [...metricContainers];

            editableMetrics.forEach((editableMetric) => {
              if (editableMetric.id === ID) {
                editableMetric.quantity = newValue;
              }
            });
            return editableMetrics;
          });
        }}
      />
      <input
        className="project-control-estimate-metric-display-input-unit"
        placeholder={"Unit"}
        value={metric.unit}
        onChange={(e) => {
          let newValue = e.target.value;
          const ID = metric.id;

          setMetricContainers((metricContainers) => {
            let editableMetrics = [...metricContainers];

            editableMetrics.forEach((editableMetric) => {
              if (editableMetric.id === ID) {
                editableMetric.unit = newValue;
              }
            });
            return editableMetrics;
          });
        }}
      />
      <input
        className="project-control-estimate-metric-display-input-metric"
        placeholder={"Description"}
        value={metric.metric}
        onChange={(e) => {
          let newValue = e.target.value;
          const ID = metric.id;

          setMetricContainers((metricContainers) => {
            let editableMetrics = [...metricContainers];

            editableMetrics.forEach((editableMetric) => {
              if (editableMetric.id === ID) {
                editableMetric.metric = newValue;
              }
            });
            return editableMetrics;
          });
        }}
      />
    </div>
  );
}

function EditMetricButton(props) {
  const { metricContainer } = props;
  const { allMetrics } = props;
  const { setMetricContainers } = props;
  const { selectedMetrics, setSelectedMetrics } = props;

  const [modal, setModal] = useState(false);

  return (
    <div>
      <button
        className="general-modal-button"
        onClick={() => {
          setModal(true);
        }}
      >
        {translate("Select Metrics")}
      </button>
      <Modal
        // Modal Props
        title={"Select Metrics"}
        Component={ProjectControlEditEstimateMetrics}
        modal={modal}
        setModal={setModal}
        // Data
        allMetrics={allMetrics}
        // Modal
        show={modal}
        setShow={setModal}
        // Selected Metrics
        setSelectedMetrics={setSelectedMetrics}
        selectedMetrics={selectedMetrics}
        // Containers
        metricContainer={metricContainer}
        setMetricContainers={setMetricContainers}
      />
    </div>
  );
}

function RemoveMetricButton(props) {
  const { setMetricContainers } = props;
  const { metricContainer } = props;

  return (
    <button
      className="project-control-estimate-metric-remove-button"
      onClick={() => {
        setMetricContainers((metricsContainers) =>
          metricsContainers.filter((c) => c.id !== metricContainer.id)
        );
      }}
    >
      <i className="far fa-trash-alt" />
    </button>
  );
}
