import React, { useState } from "react";
import { PCRALogBox } from "./PCRALogBox";
import { PCRALogAction } from "./PCRALogAction";

export function PCRALogEntry(props) {
  const { stage, status } = props;
  const { comments, username, email, timestamp } = props;
  const { currentUser } = props;
  const { handleStageAction } = props;

  const [currentComments, setCurrentComments] = useState(null);

  return (
    <div className={`pcra-log-entry ${status}`}>
      <PCRALogAction
        stage={stage}
        status={status}
        currentComments={currentComments}
        setCurrentComments={setCurrentComments}
        handleStageAction={handleStageAction}
      />
      <PCRALogBox
        status={status}
        comments={comments}
        username={username}
        email={email}
        timestamp={timestamp}
        currentUser={currentUser}
        currentComments={currentComments}
        setCurrentComments={setCurrentComments}
      />
    </div>
  );
}
