import { useState, useEffect, useCallback } from "react";
import { authHeader } from "../../_helpers/auth-header";
import { portAPIs } from "../api-endpoints/PortfolioEndpoints";
import {
  getProjectDetailByUser,
  getPortfolioProjects,
} from "../projects/ProjectAPI";
import { getUserList, updatePermissions } from "../permissions/PermissionsAPI";
import { addProjectCostPlanData } from "../projects/ProjectCostPlanAPI";

export const PORTFOLIO_TYPES = {
  PORTFOLIO: 0,
  BENCHMARK_ANALYSIS: 1,
};

export function useDefaultPortfolio() {
  const defaultPortfolio = {
    id: "all-projects",
    name: "all-projects",
    project_ids: "*",
  };

  // Portfolio State
  const [selectedPortfolio, setSelectedPortfolio] = useState(defaultPortfolio);

  return {
    selectedPortfolio,
    setSelectedPortfolio,
  };
}

export function usePortfolios() {
  const [loading, setLoading] = useState(true);
  const [portfolios, setPortfolios] = useState([]);
  useEffect(() => {
    setLoading(true);
    getPortfolios()
      .then((portfoliosRes) => {
        setPortfolios(portfoliosRes);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return {
    portfolios: {
      data: portfolios,
      loading: loading,
      get: getPortfolios,
      set: setPortfolios,
    },
  };
}

export async function getPortfolios(portfolioType = PORTFOLIO_TYPES.PORTFOLIO) {
  // PROJECT API
  const url =
    portAPIs().GetPortfolioDetailByUser + `?portfolioType=${portfolioType}`;

  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);
  if (response.ok) {
    response = await response.json();
  } else {
    response = [];
  }

  return response;
}

export async function addPortfolio(
  portfolioName,
  creatorID,
  projectIds,
  details,
  type = PORTFOLIO_TYPES.PORTFOLIO
) {
  // PROJECT API
  const url = portAPIs().Post;

  const payload = {
    name: portfolioName,
    type,
    projectIds,
    details,
  };

  const config = {
    method: "POST",
    body: JSON.stringify(payload),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);
  if (response.ok) {
    let portfolio = await response.json();

    // Add permission for creator user
    const permissionData = {
      payload: [
        {
          userGlobalId: creatorID,
          userRoleList: ["USER"],
          isRecordDeleted: false,
          companyId: null,
        },
      ],
      mappingId: portfolio.id,
      mappingType: "Portfolio",
      // Unused functions expected by updatePermissions
      setError: () => {},
      updateState: () => {},
    };
    await updatePermissions(permissionData);
    response = portfolio;
  } else {
    response = [];
  }

  return response;
}

export async function savePortfolioProjects(selectedPortfolio, projectIDs) {
  // PROJECT API
  const url = portAPIs().UpdateProjectList + "/" + selectedPortfolio.id;

  const config = {
    method: "PUT",
    body: JSON.stringify(projectIDs),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);
  if (response.ok) {
    response = await response.json();
  } else {
    response = [];
  }

  return response;
}

export async function savePortfolioData(selectedPortfolio) {
  // PROJECT API
  const url = portAPIs().Put;

  const config = {
    method: "PUT",
    body: JSON.stringify(selectedPortfolio),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);
  if (response.ok) {
    response = await response.json();
  } else {
    response = [];
  }

  return response;
}

export async function deletePortfolio(portfolioID) {
  // PROJECT API
  const url = portAPIs().Delete + "/" + portfolioID + "?isSoftDelete=false";

  const config = {
    method: "DELETE",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);
  if (response.ok) {
    response = await response.json();
  } else {
    response = [];
  }

  return response;
}

export function usePortfolioUsers(portfolioID) {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState({});
  useEffect(() => {
    setLoading(true);
    getUserList(portfolioID, "Portfolio", "all")
      .then((portfolioUsers) => {
        setUsers(portfolioUsers);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [portfolioID]);

  return {
    users: {
      data: users,
      loading: loading,
      get: getUserList,
      set: setUsers,
    },
  };
}

export function usePortfolioProjects(
  portfolioID,
  { includeCostPlanData = false } = {}
) {
  const [projectsLoading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);

  const loadProjects = useCallback(
    (projects, isCostPlanDataIncluded) => {
      if (includeCostPlanData && !isCostPlanDataIncluded) {
        addProjectCostPlanData(projects)
          .then((projectsWithCostPlanData) => {
            loadProjects(projectsWithCostPlanData, true);
          })
          .catch(handleProjectLoadError);
      } else {
        setProjects(projects);
        setLoading(false);
      }
    },
    [includeCostPlanData]
  );

  function handleProjectLoadError(e) {
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    if (portfolioID === "all-projects") {
      setLoading(true);
      getProjectDetailByUser()
        .then((projectsRes) => {
          loadProjects(projectsRes);
        })
        .catch(handleProjectLoadError);
    } else {
      getPortfolioProjects(portfolioID)
        .then((projectsRes) => {
          loadProjects(projectsRes);
        })
        .catch(handleProjectLoadError);
    }
  }, [portfolioID, loadProjects]);

  return {
    projects,
    setProjects,
    projectsLoading,
    error: null,
  };
}
