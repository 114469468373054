import React from "react";

import isEmpty from "../../validation/is-empty";

import "./PDCPAdviceHighlights.css";

export default function PDCPAdviceHighlights(props) {
  const { highlights } = props;

  if (isEmpty(highlights)) {
    return null;
  }

  return (
    <div className="pdcp-advice-highlights-display-area">
      <div className="pdcp-advice-highlights-highlight-table">
        {highlights.map((d, i) => {
          return (
            <div className="pdcp-advice-highlights-highlight-row" key={i}>
              <div className="pdcp-advice-highlights-bullet" />
              <div className="pdcp-advice-highlights-highlight">
                {d.highlight}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
