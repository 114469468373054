import PropTypes from "prop-types";

/// props validation | SQ(javascript:S6774)
useServiceSelectionHomeControls.propTypes = {
  user: PropTypes.object,
};
///
export function useServiceSelectionHomeControls({ user }) {
  const controls = [
    // placeholder for future expansion
    // see BenchmarkCreate.functions.js for example
    // controls are fed to MasterPage.js via ServiceSelectionHome.js
  ];

  return controls;
}
