// React
import React, { useState, useEffect } from "react";

// Components
import CreateProjectSelectClientCompany from "./CreateProjectSelectClientCompany";
import {
  RegionSelect,
  CountrySelect,
  CitySelect,
} from "../locations/LocationSelect";

// Geolocator
import {
  GenerateCoordinatesButton,
  CreateProjectMap,
} from "./CreateProjectMap";

// API
import { useTPI } from "../../api/tender-price-index/TenderPriceIndexingAPI";
import { useLocations } from "../../api/admin/RegionManagementAPI";

// Functions
import isEmpty from "../../validation/is-empty";

// Components
import {
  ErrorResponse,
  Submit,
  FieldLimited,
  Field,
  Select,
  SelectFilter,
  DisplayError,
  ModalCompany,
  Label,
  SelectLocalisation,
  FieldNumber,
} from "./CreateProject.components";

import {
  generateCities,
  // Lists
  phaseList,
  qualityList,
  currencyList,
  procurementList,
  typeList,
  sectorList,
  subsectorList,
  measurementSystemList,
} from "./CreateProject.functions";
import { translate, tranStr } from "../../utils/translation";

export default function CreateProject(props) {
  // Props
  const { history } = props;
  const { project } = props;
  const { urlEndpoint, urlMethod } = props;
  const { isProjectCreation } = props;
  const { user } = props;

  // API
  const { locations } = useLocations(true);

  // IDs
  const [projectID, setProjectID] = useState("");
  const [clientCompany, setClientCompany] = useState({});

  // Project Details
  const [jobNumber, setJobNumber] = useState("");
  const [title, setTitle] = useState("");
  const [projectPhase, setProjectPhase] = useState("COST_PLANNING");
  const [terminologySet, setTerminologySet] = useState("");

  // Project Categories
  const [sector, setSector] = useState("");
  const [subsector, setSubsector] = useState("");
  const [quality, setQuality] = useState("");
  const [type, setType] = useState("");
  const [currency, setCurrency] = useState("");
  const [procurement, setProcurement] = useState("");
  const [measurementSystem, setMeasurementSystem] = useState("");
  const [unitOfMeasure, setUnitOfMeasure] = useState("m²");
  const [searchTags, setSearchTags] = useState("");

  // Project Location
  const [region, setRegion] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [TPICity, setTPICity] = useState("");
  const [postCode, setPostCode] = useState("");
  const [lng, setLng] = useState(0);
  const [lat, setLat] = useState(0);

  // UI State
  const [disableSubmit, setDisableSubmit] = useState("");
  const [errors, setErrors] = useState({});

  // TPI API
  const { TPI } = useTPI(true, region, country, false);
  const citiesList = generateCities(TPI);

  useEffect(() => {
    if (!isEmpty(project)) {
      // IDs
      setProjectID(project.id);
      const comp = {
        id: project.company_id,
        logo: project.company_logo,
      };
      setClientCompany(comp || {});

      // Project Details
      setJobNumber(project.job_number);
      setTitle(project.title);
      setProjectPhase(project.project_phase);
      setTerminologySet(project.terminologySet);

      // Project Categories
      setSector(project.sector);
      setSubsector(project.sub_sector);
      setQuality(project.quality);
      setType(project.type);
      setCurrency(project.currency);
      setProcurement(project.procurement);
      setMeasurementSystem(project.measurement_system);
      setUnitOfMeasure(project.unit_of_measure);
      setSearchTags(project.search_tags);

      // Project Location
      setRegion(project.region);
      setCountry(project.country);
      setCity(project.city);
      setAddress(project.address);
      setTPICity(project.tpi_city);
      setPostCode(project.post_code);
      setLat(project.lat);
      setLng(project.lng);
    }
  }, [project]);

  // Compose the data payload
  const data = {
    urlMethod: urlMethod,
    URL: urlEndpoint,
    history: history,
    setDisableSubmit: setDisableSubmit,
    setErrors: setErrors,
    user: user,
    isProjectCreation: isProjectCreation,

    projectData: {
      // Project Details
      ProjectId: projectID,
      jobNumber: jobNumber,
      title: title,
      terminologySet: terminologySet,
      CompanyId: clientCompany.id ? clientCompany.id.toString() : "",

      // Project Categories
      sector: sector,
      subSector: subsector,
      quality: quality,
      projectType: type,
      currency: currency,
      currencySymbol: "",
      procurement: procurement,
      measurementSystem: measurementSystem,
      unitOfMeasure: unitOfMeasure,
      searchTags: searchTags,

      // Project Location
      region: region,
      country: country,
      city: city,
      address: address,
      tpiCity: TPICity,
      postCode: postCode,
      lat: lat,
      lng: lng,

      // Temp
      isDeleted: false,
      projectPhase: projectPhase,
    },
  };

  return (
    <div className="create-project">
      <div className="create-project-form">
        <div className="create-project-panel-container">
          <div className="create-project-panel">
            <h1
              className="display-4 text-center"
              style={{ fontSize: "25px", maginBottom: "0px" }}
            >
              {translate("Project Details")}
            </h1>
            <Field
              label={translate("Oracle Project Number")}
              placeholder={tranStr("e.g. 10016244")}
              value={jobNumber}
              onChange={setJobNumber}
              error={errors.JobNumber}
            />
            <FieldLimited
              label={translate("Project Title")}
              placeholder={tranStr("e.g. Brisbane Centre")}
              value={title}
              onChange={setTitle}
              error={errors.Title}
            />
            <br></br>
            The client budget is now configured in Cost Planning after a project
            is created, under the new section titled Project Budget.
            <br></br>
            <br></br>
            <ModalCompany
              Component={CreateProjectSelectClientCompany}
              setFunction={setClientCompany}
              label={translate("Select Client Company")}
              company={clientCompany}
              error={errors.CompanyId}
              title={"Client Company"}
              isProjectCreation={isProjectCreation}
            />
            <br></br>
            Please note that a client company cannot be changed after a project
            has been created.
            <br></br>
            <br></br>
            <Select
              label={translate("Project Phase")}
              placeholder={tranStr("Project Phase")}
              value={projectPhase}
              options={phaseList()}
              onChange={setProjectPhase}
              error={errors.project_phase}
            />
            <SelectLocalisation
              label={translate("Terminology Set (Optional)")}
              placeholder={tranStr("Terminology Set")}
              value={terminologySet}
              setValue={setTerminologySet}
              error={errors.terminologySet}
            />
          </div>
          <div className="create-project-panel">
            <h1
              className="display-4 text-center"
              style={{ fontSize: "25px", maginBottom: "0px" }}
            >
              {translate("Project Categories")}
            </h1>
            <Select
              label={translate("Project Sector")}
              placeholder={tranStr("Sector")}
              value={sector}
              options={sectorList()}
              onChange={setSector}
              error={errors.Sector}
            />
            <SelectFilter
              label={translate("Project Subsector")}
              placeholder={tranStr("Subsector")}
              value={subsector}
              options={subsectorList()}
              onChange={setSubsector}
              error={errors.SubSector}
              filter={sector}
            />
            <Select
              label={translate("Project Quality")}
              placeholder={tranStr("Quality")}
              value={quality}
              options={qualityList()}
              onChange={setQuality}
              error={errors.Quality}
            />
            <Select
              label={translate("Project Type")}
              placeholder={tranStr("Type")}
              value={type}
              options={typeList()}
              onChange={setType}
              error={errors.ProjectType}
            />

            <Select
              label={translate("Currency")}
              placeholder={tranStr("Currency")}
              value={currency}
              options={currencyList()}
              onChange={setCurrency}
              error={errors.Currency}
            />
            <Select
              label={translate("Procurement")}
              placeholder={tranStr("Procurement")}
              value={procurement}
              options={procurementList()}
              onChange={setProcurement}
              error={errors.Procurement}
            />
            <Select
              label={translate("System of Measurement")}
              placeholder={tranStr("Measurement System")}
              value={measurementSystem}
              options={measurementSystemList()}
              onChange={setMeasurementSystem}
              error={errors.MeasurementSystem}
            />
            <Field
              label={translate("Displayed Unit")}
              placeholder={tranStr("e.g. m2")}
              value={unitOfMeasure}
              onChange={setUnitOfMeasure}
              error={errors.UnitOfMeasure}
            />
            <Field
              label={translate("Search Tags (Optional)")}
              placeholder={tranStr("Search Tags")}
              value={searchTags}
              onChange={setSearchTags}
              error={errors.SearchTags}
            />
          </div>
          <div className="create-project-panel">
            <h1
              className="display-4 text-center"
              style={{ fontSize: "25px", maginBottom: "0px" }}
            >
              {translate("Project Location")}
            </h1>
            <div className="create-project-location-wrapper">
              <div className="create-project-input-row">
                <Label label={translate("Region")} />
                <RegionSelect
                  value={region}
                  set={setRegion}
                  options={locations.data}
                  classType={"create-project-location-select"}
                  setCountry={setCountry}
                  setCity={setCity}
                />
                <DisplayError error={errors.Region} />
              </div>
              <div className="create-project-input-row">
                <Label label={translate("Country")} />
                <CountrySelect
                  value={country}
                  set={setCountry}
                  options={locations.data}
                  classType={"create-project-location-select"}
                  region={region}
                  setCity={setCity}
                />
                <DisplayError error={errors.Country} />
              </div>
            </div>
            <div className="create-project-location-wrapper">
              <div className="create-project-input-row">
                <Label label={translate("City")} />
                <CitySelect
                  value={city}
                  set={setCity}
                  options={locations.data}
                  classType={"create-project-location-select"}
                  country={country}
                />
                <DisplayError error={errors.City} />
              </div>
              <Field
                label={translate("Address")}
                placeholder={tranStr("Address")}
                value={address}
                onChange={setAddress}
                error={errors.Address}
                classType={"create-project-location-input"}
              />
            </div>
            <div className="create-project-location-wrapper">
              <Field
                label={translate("Post Code")}
                placeholder={tranStr("Post Code")}
                value={postCode}
                onChange={setPostCode}
                error={errors.PostCode}
                classType={"create-project-location-input"}
              />
              <GenerateCoordinatesButton
                label={"Generate Coordinates"}
                address={address}
                postCode={postCode}
                country={country}
                setLat={setLat}
                setLng={setLng}
              />
            </div>

            <div className="create-project-location-wrapper">
              <div className="create-project-location-container">
                <FieldNumber
                  label={translate("Latitude")}
                  placeholder={tranStr("Latitude")}
                  value={lat}
                  onChange={setLat}
                  error={errors.Lat}
                />
                <FieldNumber
                  label={translate("Longitude")}
                  placeholder={tranStr("Longitude")}
                  value={lng}
                  onChange={setLng}
                  error={errors.Lng}
                />
              </div>
              <CreateProjectMap lat={lat} lng={lng} />
            </div>
            <Select
              label={translate("Select Location for TPI Purposes")}
              value={TPICity}
              options={citiesList}
              onChange={setTPICity}
              error={errors.TpiCity}
            />
          </div>
        </div>
        <div className="general-row-container">
          <ErrorResponse error={errors.Response} />
        </div>
        <Submit data={data} disableSubmit={disableSubmit} />
      </div>
    </div>
  );
}
