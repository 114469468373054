import React from "react";
import DoughnutChartBrite from "../charts/DoughnutChartBrite";
import isEmpty from "../../validation/is-empty";

export default function PDCPCostBreakdownDonut(props) {
  const { CP } = props;

  let show = false;

  if (!show) {
    return null;
  }

  if (isEmpty(CP) || isEmpty(CP.estimates)) {
    return null;
  }

  let data = [];

  let heritage = "rgba(228, 97, 15, 1)";
  let aqua = "rgba(50, 98, 149, 1)";
  let collaboration1 = "rgba(85, 87, 90, 1)";
  let heritage2 = "rgba(228, 97, 15, 0.7)";
  let aqua2 = "rgba(50, 98, 149, 0.7)";
  let collaboration12 = "rgba(85, 87, 90, 0.7)";

  let colorSchema = [
    collaboration1,
    heritage,
    aqua,
    collaboration12,
    aqua2,
    heritage2,
  ];

  let margin = {
    left: -40,
    right: 10,
    top: 5,
    bottom: 5,
  };

  return (
    <div className="project-dashboard-parts">
      <h1
        className="display-4"
        style={{
          fontSize: "20px",
          marginBottom: "20px",
          paddingTop: "10px",
          marginLeft: "10px",
        }}
      >
        Cost Breakdown
      </h1>
      <DoughnutChartBrite
        data={data}
        width={300}
        height={300}
        eRadius={90}
        iRadius={70}
        lWidth={290}
        lHeight={110}
        margin={margin}
        colorSchema={colorSchema}
        projectDashboard={true}
      />
    </div>
  );
}
