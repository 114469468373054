import React from "react";
import Spinner from "../common/Spinner";

export default function CorrespondenceNavIcon({ isProjectsLoading }) {
  if (isProjectsLoading) {
    return <Spinner width={"18px"} />;
  }

  return <i className="fas fa-comments" />;
}
