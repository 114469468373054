import React from "react";
import isEmpty from "../../validation/is-empty";
import { comma } from "../../helpers/common.functions";

import { VALUE_ENGINEERING } from "../value-engineering/ValueEngineering.strings";

import "./PDCPValueEngineeringStatusSummary.css";

export default function PDCPValueEngineeringStatusSummary(props) {
  const { CP } = props;

  if (isEmpty(CP) || isEmpty(CP.value_engineerings)) {
    return null;
  }

  const totals = computeTotals(CP.value_engineerings);

  return (
    <div id="pdcp-value-engineering-status-summary">
      <div className="ve-status-summary-table">
        {Object.values(totals).map(({ key, label, value }, index) => {
          return (
            <div key={key} className={`ve-status-summary-table-row ${key}`}>
              <div className="ve-status-summary-table-row-cell">
                <div className="bullet"></div>
              </div>
              <div className="ve-status-summary-table-row-cell">{label}</div>
              <div className="ve-status-summary-table-row-cell right">
                {comma(value)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function computeTotals(value_engineerings) {
  const totals = {};
  const TOTAL = {
    key: "TOTAL",
    label: "Total",
    value: 0,
  };

  for (const key in VALUE_ENGINEERING.STATUS) {
    const status = VALUE_ENGINEERING.STATUS[key];
    totals[status] = {
      key: key,
      label: status,
      value: 0,
    };
  }

  totals[TOTAL.label] = TOTAL;

  /*
   * Example of totals object
    {
        "Approved": {
            "key": "APPROVED",
            "label": "Approved",
            "value": 50000
        },
        "For Review": {
            "key": "FORREVIEW",
            "label": "For Review",
            "value": 35000
        },
        "Rejected": {
            "key": "REJECTED",
            "label": "Rejected",
            "value": 0
        },
        "Total": {
            "key": "TOTAL",
            "label": "Total",
            "value": 85000
        }
    }
   */

  for (const value_engineering of value_engineerings) {
    for (const value_engineering_item of value_engineering.value_engineering_items) {
      totals[value_engineering_item.status].value +=
        value_engineering_item.value;

      totals[TOTAL.label].value += value_engineering_item.value;
    }
  }

  return totals;
}
