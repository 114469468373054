import React, { useState } from "react";
import "./LocalityFactorOverride.css";
import { translate, tranStr } from "../../utils/translation";
import { cpAPIs } from "../../api/api-endpoints/CostPlanningEndpoints";
import { authHeader } from "../../_helpers/auth-header";
import { DisplayError } from "../project-control/ProjectControl";
import { AutoCloseModal } from "../modal/Modal";
import isEmpty from "../../validation/is-empty";

export default function LocalityFactorOverride(props) {
  const { projectID } = props;
  const { setModal } = props;
  const { CP } = props;

  const [error, setError] = useState({});
  const [isOverride, setOverride] = useState(CP.is_locality_factor_active);

  const [factor, setFactor] = useState(CP.locality_factor || "");

  if (isEmpty(CP)) {
    return (
      <div className="project-control-advice">
        {translate(
          "This section is available after Cost Plan stages have been set"
        )}
      </div>
    );
  }
  const data = {
    projectID: projectID,
    costPlanId: CP.cost_plan_id,
    setModal: setModal,
    setError: setError,
    isOverride: isOverride,
    factor: factor,
  };

  return (
    <div className="locality-factor-container">
      <div className="locality-factor-flex-container">
        <div className="locality-factor-label">Locality Factor</div>
        <input
          className="locality-factor-input"
          type="text"
          value={factor}
          onChange={(e) => {
            setFactor(e.target.value);
          }}
        ></input>
        <div></div>
        <div className="checkboxes-vertical">
          <label className="checkboxes-vertical-container">
            <div className="checkbox-label">
              {"Apply locality factor to this project"}
            </div>
            <input
              type="checkbox"
              name={"override"}
              checked={isOverride}
              onChange={(e) => {
                onOverride(e, setOverride);
              }}
            />
            <span className="checkmark"></span>
          </label>
        </div>
      </div>
      <div className="general-row-container">
        <DisplayError error={error} setError={setError} />
      </div>
      <button
        id="save-locality-button"
        className="save-locality-button"
        onClick={async () => {
          Save(data);
        }}
      >
        {translate("Save")}
      </button>
    </div>
  );
}

function onOverride(event, set) {
  const target = event.target;
  const value = target.type === "checkbox" ? target.checked : target.value;
  set(value);
}

async function Save(data) {
  const { setError, setModal } = data;

  const payload = {
    project_id: data.projectID,
    cost_plan_id: data.costPlanId,
    locality_factor: data.factor,
    is_locality_factor_active: data.isOverride,
  };

  const url = cpAPIs().save_locality_factor;

  const config = {
    method: "POST",
    body: JSON.stringify(payload),
    headers: authHeader({
      authJson: true,
      authForm: false,
      guestJson: false,
    }),
  };

  setError({
    text: tranStr("Saving..."),
    type: "project-stages-success",
  });

  const response = await fetch(url, config);
  if (response.ok) {
    setError({
      text: tranStr("Saved Successfully"),
      type: "project-stages-success",
    });

    AutoCloseModal(setModal);
  } else {
    setError({
      text: tranStr("Save Failed"),
      type: "project-stages-error",
    });
  }
}
