// React
import React, { useState, useEffect } from "react";
import isEmpty from "../../validation/is-empty";
// Map
import { MapContainer, TileLayer, Marker, useMap } from "react-leaflet";

// Generate the coordinates
export function GenerateCoordinatesButton(props) {
  const { label } = props;
  const { address, postCode, country } = props;
  const { setLat, setLng } = props;

  const [loading, setLoading] = useState(false);

  const locale = {
    address: address,
    postCode: postCode,
    country: country,
  };

  if (loading) {
    return (
      <div className="create-project-input-row">
        <div className="create-project-coordinates-button">Generating...</div>
      </div>
    );
  }

  return (
    <div className="create-project-input-row">
      <button
        id="create-project-coordinates-button"
        className="create-project-coordinates-button"
        onClick={async () => {
          let lat = 0;
          let lng = 0;

          setLoading(true);
          const coordinates = await generateCoordinates(locale);
          setLoading(false);

          // Fail gracefully
          if (!isEmpty(coordinates)) {
            if (!isEmpty(coordinates.results)) {
              if (!isEmpty(coordinates.results[0].locations)) {
                if (!isEmpty(coordinates.results[0].locations[0].latLng)) {
                  lat = coordinates.results[0].locations[0].latLng.lat;
                  lng = coordinates.results[0].locations[0].latLng.lng;
                }
              }
            }
          }

          setLat(lat);
          setLng(lng);
        }}
      >
        {label}
      </button>
    </div>
  );
}

async function generateCoordinates(locale) {
  let endpoint = "https://www.mapquestapi.com/geocoding/v1/address?";
  let key = "key=dmuUKzJ0DtWy5RXUz50MDTkl6aazH30y";
  let location = `&location=${locale.address}, ${locale.postCode}, ${locale.country}`;

  const url = endpoint + key + location;
  const config = {
    method: "GET",
  };

  let response = {};
  try {
    response = await fetch(url, config);
  } catch (e) {
    console.log("Failed to fetch coords");
  }

  if (response.ok) {
    // Response OK
    response = await response.json();
  } else {
    // Do nothing for now
  }
  return response;
}

export function CreateProjectMap(props) {
  const { lat, lng } = props;

  const [centerCoords, setCenterCoords] = useState(null);

  useEffect(() => {
    if (!isEmpty(lat) && !isEmpty(lng) && !isNaN(lat) && !isNaN(lng)) {
      setCenterCoords([lat, lng]);
    } else {
      setCenterCoords([0, 0]);
    }
  }, [lat, lng]);

  if (centerCoords === null) {
    return null;
  }

  return (
    <MapContainer
      className="create-project-map"
      center={centerCoords}
      zoom={12}
      scrollWheelZoom={true}
      attributionControl={false}
      zoomControl={false}
    >
      <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />

      <Marker position={centerCoords}></Marker>
      <ChangeView center={centerCoords} zoom={12} />
    </MapContainer>
  );

  function ChangeView({ center, zoom }) {
    const map = useMap();
    map.setView(center, zoom);
    return null;
  }
}
