import React, { useState } from "react";

import { translate, tranStr } from "../../utils/translation";

export default function CostPlanningUploader(props) {
  const { data } = props;
  const { uploadFunction } = props;
  const { isAreaSchedule } = props;

  const [file, setFile] = useState(null);
  const [error, setError] = useState({});

  data.file = file;
  data.setError = setError;
  data.error = error;

  return (
    <div className="pdu-container">
      <div className="general-row-container">
        <AreaScheduleLink isAreaSchedule={isAreaSchedule} />
      </div>
      <div className="general-row-container">
        <FileTray file={file} />
      </div>
      <div className="general-row-container">
        <UploadFile setFile={setFile} />
      </div>
      <div className="general-row-container">
        <UploadError error={error} setError={setError} />
      </div>
      <div className="general-button-container">
        <Upload data={data} uploadFunction={uploadFunction} />
      </div>
    </div>
  );
}

function UploadFile(props) {
  const { setFile } = props;

  return (
    <label className="general-modal-button">
      <input
        type="file"
        onChange={(e) => {
          if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0]);
          }
        }}
      />
      {translate("Attach File")}
    </label>
  );
}

function FileTray(props) {
  const { file } = props;
  let fileName = tranStr("No File Added");

  if (file !== null) {
    fileName = file.name;
  }

  return (
    <div className="pdu-file-display">
      <b>{translate("File")}:</b> {fileName}
    </div>
  );
}

function Upload(props) {
  const { data } = props;
  const { uploadFunction } = props;

  return (
    <button
      className="general-upload-button"
      onClick={async () => {
        uploadFunction(data);
      }}
    >
      {translate("Upload")}
    </button>
  );
}

function UploadError(props) {
  const { error } = props;
  return (
    <div className="pdu-error-container">
      <div className={error.type}>
        <b>{error.text}</b>
      </div>
    </div>
  );
}

function AreaScheduleLink(props) {
  const { isAreaSchedule } = props;

  if (!isAreaSchedule) {
    return null;
  }

  return (
    <div className="cost-planning-upload-control-row">
      <div className="cost-planning-upload-control-label">
        {translate("Area Schedule Template")}:
      </div>
      <a
        className="cost-planning-upload-document-button"
        href={
          "https://stcostclarityprd001.blob.core.windows.net/1-guides/Cost Clarity - Area Schedule Template.xlsx"
        }
        target="_blank"
        rel="noopener noreferrer"
        download
      >
        {translate("Download")}
      </a>
    </div>
  );
}
