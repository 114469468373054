import React, { useState } from "react";

import Modal from "../modal/Modal";

import { hasRoles } from "../../utils/roles";
import { tranStr } from "../../utils/translation";

import "../estimates/EstimatesEdit.css";
import isEmpty from "../../validation/is-empty";
import { CostAllocation } from "./CostAllocation";
import { cpAPIs } from "../../api/api-endpoints/CostPlanningEndpoints";

export default function AreaScheduleAllocationModal(props) {
  const { project } = props;
  const { estimate } = props;
  const { user } = props;
  const { CP, CPs } = props;

  const [modal, setModal] = useState(false);
  const data = {
    estimate_id: estimate.id,
    cost_plan_id: CP.cost_plan_id,
    projectID: project.id,
    project: project,
    CPs: CPs,
    setError: () => null, // gets overwritten inside CostAllocation to the correct function
    url: cpAPIs().allocate_area_schedules,
  };

  if (project.project_phase !== "COST_PLANNING") {
    return null;
  }

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }
  if (isEmpty(CP.areaschedules)) {
    return null;
  }
  let areaschedules = CP.areaschedules.filter(
    (x) => !isEmpty(x.description) && isEmpty(x.code)
  );

  return (
    <div className="estimate-edit-wrapper">
      <Modal
        // Modal Props
        Component={CostAllocation}
        title={tranStr("Allocate Area Schedule")}
        modal={modal}
        setModal={setModal}
        setShow={setModal}
        // Component Props
        estimate={estimate}
        data={data}
        allocationItems={areaschedules}
        allocationType={"Area Schedules"}
      />
      <button
        id={"button-area-schedule-allocation"}
        className="estimate-edit-button"
        onClick={() => {
          setModal(true);
        }}
        title="Area Schedule Allocation"
      >
        <i className="fas fa-ruler-combined"></i>
      </button>
    </div>
  );
}
