import React from "react";
import { translate } from "../../utils/translation";
import PropTypes from 'prop-types';

export function Toggle(props) {
  const { isElementalUnitRate, setIsElementalUnitRate, isLocalRegionFactorEnabled } = props;

  let local_region_area;
  let unit;
  if (isElementalUnitRate === false) {
    local_region_area = "bench-recon-comarison-toggle-button-in-left";
    unit = "bench-recon-comarison-toggle-button-out-right";
  } else {
    local_region_area = "bench-recon-comarison-toggle-button-out-left";
    unit = "bench-recon-comarison-toggle-button-in-right";
  }

  return (
    <div className="recon-comparison-toggle">
      <button
        id={"benchmark-project-toggle-button-area"}
        className={local_region_area}
        onClick={() => {
          setIsElementalUnitRate(false);
        }}
      >
        <h1 className="display-4">{isLocalRegionFactorEnabled? translate("Displayed Unit") : translate("GIA")}</h1>
      </button>
      <button
        id={"benchmark-project-toggle-button-unit"}
        className={unit}
        onClick={() => {
          setIsElementalUnitRate(true);
        }}
      >
        <h1 className="display-4">{translate("Elemental Unit")}</h1>
      </button>
    </div>
  );
}

Toggle.propTypes = {
  isElementalUnitRate: PropTypes.bool.isRequired,
  setIsElementalUnitRate: PropTypes.func.isRequired,
  isLocalRegionFactorEnabled: PropTypes.bool.isRequired,
};
