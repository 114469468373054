import React, { useState, useEffect } from "react";
import isEmpty from "../../validation/is-empty";

import { tranStr, translate } from "../../utils/translation";

import "./ProjectControlEditHighlights.css";
import { cpAPIs } from "../../api/api-endpoints/CostPlanningEndpoints";
import { DisplayError } from "./ProjectControl";

import {
  saveFunction,
  updateField,
  deleteField,
} from "./ProjectControlEdit.functions";

export default function ProjectControlEditRisksOpportunities(props) {
  const { projectID } = props;
  const { project } = props;
  const { CP, CPs } = props;
  const { setModal } = props;

  const [error, setError] = useState({});

  const [fields, setFields] = useState([
    {
      risk_opportunity: "",
      urgency: "",
    },
  ]);
  useEffect(() => {
    if (!isEmpty(CP)) {
      if (!isEmpty(CP.risks)) {
        setFields(CP.risks);
      }
    }
  }, [CP]);

  if (isEmpty(CP)) {
    return (
      <div className="project-control-advice">
        {translate(
          "Risks and Opportunities are available after a Cost Plan has been uploaded."
        )}
      </div>
    );
  }

  return (
    <div className="project-control-advice">
      <div className="advice-title">
        <div className="display-4" style={{ fontSize: "20px" }}>
          {translate(
            "Add up to 5 Risks / Opportunities below to be displayed on the Cost Planning Dashboard."
          )}
        </div>
      </div>
      <FieldHeadings />
      <DecisionFields fields={fields} setFields={setFields} />
      <div className="general-row-container">
        <AddField fields={fields} setFields={setFields} />
      </div>
      <div className="general-row-container">
        <DisplayError error={error} setError={setError} />
      </div>
      <div className="general-button-container">
        <button
          id={"advice-risks-save-button"}
          className="general-upload-button"
          onClick={async () => {
            fields.forEach((f) => {
              f.stage = CP.stage;
              f.revision = CP.version;
            });

            const payload = JSON.stringify({
              risks: fields,
              projectID: projectID,
              stage: CP.stage,
              revision: CP.version,
              cost_plan_id: CP.cost_plan_id,
            });
            const data = {
              projectID: projectID,
              project: project,
              CPs: CPs,
              setModal: setModal,
              setError: setError,
              fields: fields,
              CP: CP,
              url: cpAPIs().set_risks,
              payload: payload,
            };

            await saveFunction(data);
          }}
        >
          {translate("Save")}
        </button>
      </div>
    </div>
  );
}

function AddField(props) {
  const { fields } = props;
  const { setFields } = props;
  return (
    <button
      id={"advice-risks-add-button"}
      className="general-modal-button"
      onClick={() => {
        let temp = [...fields];

        if (temp.length >= 5) {
          return;
        }

        temp.push({
          risk_opportunity: "",
          urgency: "",
        });
        setFields(temp);
      }}
    >
      {translate("Add Field")}
    </button>
  );
}

function FieldHeadings() {
  return (
    <div className="advice-field-container">
      <div className="advice-decision">
        <b>{translate("Risk")}</b>
      </div>
      <div className="advice-required-by">
        <b>{translate("Severity")}</b>
      </div>
      <div className="advice-delete-spacer"></div>
    </div>
  );
}

function DecisionFields(props) {
  const { fields } = props;
  const { setFields } = props;

  return fields.map((f, i) => {
    return (
      <div key={i} className="advice-field-container">
        <input
          id={"advice-risks-input-" + i}
          className="advice-decision"
          value={f.risk_opportunity}
          maxLength={90}
          onChange={(e) => {
            updateField(
              "risk_opportunity",
              e.target.value,
              i,
              fields,
              setFields
            );
          }}
        />

        <select
          className="advice-required-by"
          value={f.urgency}
          maxLength={10}
          onChange={(e) => {
            updateField("urgency", e.target.value, i, fields, setFields);
          }}
        >
          {selectOptions(["", "Low", "Medium", "High"])}
        </select>

        <button
          id={"advice-risks-delete-field-" + i}
          className="advice-delete-button"
          onClick={() => deleteField(i, fields, setFields)}
        >
          <i className="far fa-trash-alt" />
        </button>
      </div>
    );
  });
}

function selectOptions(array) {
  return array.map(function (item, i) {
    return (
      <option key={i} value={item}>
        {tranStr(item)}
      </option>
    );
  });
}
