import React from "react";
import ReactQuill from "react-quill";

import "./TextViewer.css";

export default function TextViewer(props) {
  const { text } = props;

  const modules = {
    toolbar: false,
  };

  return (
    <div className="commentary-text-viewer">
      <ReactQuill value={text} modules={modules} readOnly></ReactQuill>
    </div>
  );
}
