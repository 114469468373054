import React from "react";
import "./HelpButton.css";

export default function HelpButton() {
  return (
    <a
      className="help-button"
      href={
        "https://stcostclarityprd001.blob.core.windows.net/1-guides/Cost Clarity Authentication Guide.pdf"
      }
      target="_blank"
      rel="noopener noreferrer"
      download
    >
      Login Instructions
    </a>
  );
}
