import React from "react";
import { Link } from "react-router-dom";

import "./SummaryTable.css";

export default function SummaryTable(props) {
  const { schema } = props;

  const { className } = schema.table;

  return (
    <div className={className}>
      <TableHead {...props} />
      <Table {...props} />
    </div>
  );
}

function TableHead(props) {
  const { schema } = props;
  const { columns } = schema.table;

  return (
    <div className={schema.table.headRow.className}>
      {Object.values(columns).map((cell, index) => {
        const { show, label, className } = cell;

        if (!show) {
          return <></>;
        }

        const key = `${index}_${label}`;
        return (
          <div key={key} className={className}>
            {label}
          </div>
        );
      })}
    </div>
  );
}

function Table(props) {
  const { data } = props;

  const { schema } = props;
  const { rows, columns } = schema.table;

  return (
    <div>
      {data.map((row, index) => {
        const key = `row_${index}`;
        let rowType = rows.className(row, index);

        return (
          <Row
            key={key}
            parentKey={key}
            rowType={rowType}
            row={row}
            columns={columns}
            link={rows.link}
          />
        );
      })}
    </div>
  );
}

function Row(props) {
  const { parentKey } = props;
  const { rowType } = props;
  const { row, columns } = props;
  const { link } = props;

  const fields = Object.values(columns).map((cell, index) => {
    const { label, className, value } = cell;
    const key = `${parentKey}_${index}_${label}`;
    return (
      <div key={key} className={className}>
        {value(row)}
      </div>
    );
  });

  if (link.show(row)) {
    const { className, path } = link;
    return (
      <Link to={path(row)} className={className}>
        {fields}
      </Link>
    );
  } else {
    return <div className={rowType}>{fields}</div>;
  }
}
