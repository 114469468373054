import React from "react";
import PropTypes from "prop-types";

import { comma } from "../../helpers/common.functions";
import { formatUOM } from "./BenchmarkingMetrics.functions";

import MetricsChart from "./MetricsChart";

/// props validation | SQ(javascript:S6774)
MetricsVerticalBarChart.propTypes = {
  skipHighlight: PropTypes.bool,
  benchmarkControl: PropTypes.bool,
  data: PropTypes.array,
  avgs: PropTypes.array,
  metric: PropTypes.string,
  currencySymbol: PropTypes.string,
};
///
export default function MetricsVerticalBarChart(props) {
  const { skipHighlight } = props;
  const { benchmarkControl } = props;
  const { data, avgs } = props;

  const { metric } = props;
  const { currencySymbol } = props;

  return (
    <MetricsChart
      // props to pick chart
      chartType={"VerticalBar"}
      labelTooltipCallback={(tooltipItem) =>
        currencySymbol + formatUOM(comma(tooltipItem.yLabel), metric, true)
      }
      yAxisTicksCallback={(tick, index, values) =>
        currencySymbol + formatUOM(comma(tick), metric, true)
      }
      // props to fill chart
      skipHighlight={skipHighlight}
      benchmarkControl={benchmarkControl}
      data={data}
      avgs={avgs}
    />
  );
}
