import React, { useState, useEffect } from "react";
import { getCost } from "./Cashflow.functions";
import Cashflow from "./Cashflow";
import CashflowSplit from "./CashflowSplit";

import CashflowToggle from "./CashflowToggle";
import isEmpty from "../../validation/is-empty";
import { translate } from "../../utils/translation";

export default function CashflowContainer(props) {
  const { project } = props;
  const { CP } = props;
  const { CPs } = props;
  const { accordionState } = props;

  const [cashflowBasis, setCashflowBasis] = useState(
    CP?.cashflow?.CashflowBasis
  );

  useEffect(() => {
    setCashflowBasis(CP?.cashflow?.CashflowBasis);
  }, [CP]);

  if (isEmpty(CP)) {
    return (
      <div>
        {translate(
          "Cost Plan Cashflow is available after a Cost Plan has been uploaded."
        )}
      </div>
    );
  }

  let cashflowType = <div></div>;

  if (cashflowBasis === "join") {
    const totalCost = getCost(CP.estimates);
    const cashflowData = CP.cashflow?.Cashflows.find(
      (c) => c.CashflowType === "join"
    );

    cashflowType = (
      <div className="estimate-cashflow">
        <Cashflow
          CP={CP}
          CPs={CPs}
          project={project}
          cashflowData={cashflowData}
          totalCost={totalCost}
          cashflowBasis={cashflowBasis}
        />
      </div>
    );
  }

  if (cashflowBasis === "split") {
    cashflowType = (
      <CashflowSplit
        CP={CP}
        CPs={CPs}
        project={project}
        accordionState={accordionState}
        cashflowBasis={cashflowBasis}
      />
    );
  }

  return (
    <div>
      <div className="cashflow-control-row">
        <div className="cashflow-label">{translate("Cashflow Cost Basis")}</div>
        <CashflowToggle
          cashflowBasis={cashflowBasis}
          setCashflowBasis={setCashflowBasis}
          CP={CP}
        />
      </div>
      {cashflowType}
    </div>
  );
}
