// React
import React from "react";

export default function PortfolioDashboardButton(props) {
  const { selectedPortfolio } = props;
  const { showDashboard, setShowDashboard } = props;

  if (selectedPortfolio.id === "all-projects") {
    return null;
  }

  if (!window.location.href.includes("dashboard")) {
    return null;
  }

  let buttonClass = "post-contract-control-button-dashboard";

  if (showDashboard) {
    buttonClass = "post-contract-control-button-clicked";
  }

  return (
    <button
      className={buttonClass}
      onClick={() => {
        setShowDashboard(!showDashboard);
      }}
    >
      Projects Dashboard
    </button>
  );
}
