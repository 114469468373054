import React from "react";

// Dashboards
import PDCP from "../project-dashboard-cost-planning/PDCP";

// Components
import PostContractDashboard from "../post-contract-2.0/PostContractDashboard.components";

// Styles
import "./Project.css";
import isEmpty from "../../validation/is-empty";

export default function Dashboard(props) {
  // Props
  const { project, projectLoading } = props;

  // Not Authenticated or doesn't exist
  if (!projectLoading && isEmpty(project)) {
    props.history.push("/not-found");
  }

  if (!projectLoading && !isEmpty(project)) {
    return (
      <div className="project-background">
        <PDCP {...props} />
        <PostContractDashboard {...props} />
      </div>
    );
  }

  // While loading
  return null;
}
