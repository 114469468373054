import React from "react";
import "./AdminLocalisationToggle.css";

export default function AdminLocalisationToggle(props) {
  const { inputMode, setInputMode, setError } = props;

  let cloneToggle;
  let importToggle;
  let exportToggle;
  let masterToggle;

  if (inputMode === "CLONE") {
    cloneToggle = "toggle-active-button";
    importToggle = "toggle-inactive-button";
    exportToggle = "toggle-inactive-button";
    masterToggle = "toggle-inactive-button";
  }

  if (inputMode === "EXPORT") {
    cloneToggle = "toggle-inactive-button";
    importToggle = "toggle-inactive-button";
    exportToggle = "toggle-active-button";
    masterToggle = "toggle-inactive-button";
  }

  if (inputMode === "IMPORT") {
    cloneToggle = "toggle-inactive-button";
    importToggle = "toggle-active-button";
    exportToggle = "toggle-inactive-button";
    masterToggle = "toggle-inactive-button";
  }

  if (inputMode === "MASTER") {
    cloneToggle = "toggle-inactive-button";
    importToggle = "toggle-inactive-button";
    exportToggle = "toggle-inactive-button";
    masterToggle = "toggle-active-button";
  }

  return (
    <div className="localisation-toggle">
      <button
        className={cloneToggle}
        onClick={async () => {
          setInputMode("CLONE");
          setError("");
        }}
      >
        Clone
      </button>
      <button
        className={exportToggle}
        onClick={async () => {
          setInputMode("EXPORT");
          setError("");
        }}
      >
        Export
      </button>
      <button
        className={importToggle}
        onClick={async () => {
          setInputMode("IMPORT");
          setError("");
        }}
      >
        Import
      </button>
      <button
        className={masterToggle}
        onClick={async () => {
          setInputMode("MASTER");
          setError("");
        }}
      >
        Master
      </button>
    </div>
  );
}
