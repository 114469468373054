import { useState, useEffect } from "react";
import { authHeader } from "../../_helpers/auth-header";
import { sharedAPIs } from "../api-endpoints/SharedServiceEndpoints";
import { getUsersByIDs, getAllEmployees } from "../users/UserAPI";
import { projectAPIs } from "../api-endpoints/ProjectEndpoints";
import isEmpty from "../../validation/is-empty";

// COMPANIES
export function useCompanies() {
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState({});
  useEffect(() => {
    setLoading(true);
    getCompanies()
      .then((companiesRes) => {
        setCompanies(companiesRes);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  return {
    companies: {
      data: companies,
      loading: loading,
      get: getCompanies,
      set: setCompanies,
    },
  };
}

export async function getCompanies() {
  const url = sharedAPIs().get_companies;
  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let erMessage = "Get Companies error";

  let response = [];
  try {
    response = await fetch(url, config);
  } catch (e) {
    return response;
  }

  if (response.ok) {
    let companies = await response.json();
    const companyIDs = companies.map((x) => x.id);
    const company_users = await getAllCompanyEmployees(companyIDs);
    const userIDs = company_users.map((x) => x.user_id);
    const users = await getUsersByIDs(userIDs);

    companies.forEach((company) => {
      const newUsers = [];
      company_users.forEach((company_user) => {
        users.forEach((user) => {
          if (
            company_user.user_id === user.id &&
            company_user.company_id === company.id
          ) {
            const newUser = {
              ...user,
              ...company_user,
            };
            newUsers.push(newUser);
          }
        });
      });
      company.users = newUsers;
    });

    response = companies;
  } else {
    response = [];
    console.log(erMessage);
  }

  return response;
}

// COMPANY PROJECTS
export function useCompanyProjects(company, show) {
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState({});
  useEffect(() => {
    setLoading(true);
    getCompanyProjects(company, show)
      .then((projectsRes) => {
        if (show) {
          setProjects(projectsRes);
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [company, show]);

  return {
    projects: {
      data: projects,
      loading: loading,
      get: getCompanyProjects,
      set: setProjects,
    },
  };
}

export async function getCompanyProjects(company, show) {
  if (!show) {
    return;
  }
  const url = projectAPIs().get_projects_by_company_id;

  const config = {
    method: "POST",
    body: JSON.stringify([company.id.toString()]),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let erMessage = "Get Company Projects error";

  let response = await fetch(url, config);

  if (response.ok) {
    response = await response.json();
  } else {
    response = [];
    console.log(erMessage);
  }
  return response;
}

// EMPLOYEES
export function useEmployees(company, show) {
  const [loading, setLoading] = useState(true);
  const [employees, setEmployees] = useState([]);
  useEffect(() => {
    if (show) {
      setLoading(true);
      getEmployees(company, show)
        .then((employeesRes) => {
          let employeeIDs = employeesRes.map((x) => {
            return { id: x.user_id };
          });
          setEmployees(employeeIDs);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [company, show]);

  return {
    employees: {
      data: employees,
      loading: loading,
      get: getEmployees,
      set: setEmployees,
    },
  };
}

export async function getEmployees(company, show) {
  if (!show) {
    return [];
  }

  const url = sharedAPIs().get_employees;
  const payload = {
    companyIDs: [company.id],
  };

  const config = {
    method: "POST",
    body: JSON.stringify(payload),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let erMessage = "Get Employees for company error";
  let response = [];
  try {
    response = await fetch(url, config);
  } catch (e) {
    return response;
  }
  if (response.ok) {
    response = await response.json();
  } else {
    response = [];
    console.log(erMessage);
  }

  return response;
}

export async function getAllCompanyEmployees(companyIDs) {
  const url = sharedAPIs().get_employees;

  const payload = {
    companyIDs: companyIDs,
  };

  const config = {
    method: "POST",
    body: JSON.stringify(payload),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let erMessage = "getAllCompanyEmployees error";

  let response = [];
  try {
    response = await fetch(url, config);
  } catch (e) {
    return response;
  }

  if (response.ok) {
    response = await response.json();
  } else {
    response = [];
    console.log(erMessage);
  }

  return response;
}

// EMPLOYEES
export function useAllEmployees(show) {
  const [loading, setLoading] = useState(true);
  const [allEmployees, setAllEmployees] = useState([]);
  useEffect(() => {
    if (show) {
      setLoading(true);
      getAllEmployees(show)
        .then((employees) => {
          setAllEmployees(employees);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [show]);

  return {
    allEmployees: {
      data: allEmployees,
      loading: loading,
      get: getAllEmployees,
      set: setAllEmployees,
    },
  };
}

export async function getCompaniesByIDs(companyIDs) {
  const url = sharedAPIs().get_companies_by_ids;

  // Create unique list and remove nulls or undefined values
  const IDs = Array.from(new Set(companyIDs.filter((id) => !isEmpty(id))));

  const payload = {
    companyIDs: IDs,
  };

  const config = {
    method: "POST",
    body: JSON.stringify(payload),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let erMessage = "Get Companies by IDs error";
  let response = [];
  if (!isEmpty(companyIDs)) {
    try {
      response = await fetch(url, config);
    } catch (e) {
      return response;
    }
  } else {
    return response;
  }

  if (response.ok) {
    response = await response.json();
  } else {
    response = [];
    console.log(erMessage);
  }

  return response;
}

export async function addSingleEmployee(companyID, id) {
  const url = sharedAPIs().add_single_employee;

  const payload = {
    companyID: companyID,
    userID: id,
  };

  const config = {
    method: "PUT",
    body: JSON.stringify(payload),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let erMessage = "Add single employee error";

  let response = [];
  try {
    response = await fetch(url, config);
  } catch (e) {
    return response;
  }

  if (response.ok) {
    response = true;
  } else {
    console.log(erMessage);
    response = false;
  }

  return response;
}

export async function getCompanyIDsByEmployeeIDs(userIDs) {
  const url = sharedAPIs().get_company_ids_by_employee_ids;
  const payload = {
    userIDs: userIDs,
  };

  const config = {
    method: "POST",
    body: JSON.stringify(payload),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let erMessage = "getCompanyIDsByEmployeeIDs error";

  let response = [];
  if (!isEmpty(userIDs)) {
    try {
      response = await fetch(url, config);
    } catch (e) {
      return response;
    }
  } else {
    return response;
  }

  if (response.ok) {
    response = await response.json();
  } else {
    console.log(erMessage);
    response = [];
  }

  return response;
}

export async function addCompanyIDs(users) {
  const userIDs = users.map((p) => p.id);
  const newUserData = await getCompanyIDsByEmployeeIDs(userIDs);
  const updatedUsers = [];
  users.forEach((user) => {
    let match = false;
    let newData = {};

    // Try and find a match for this user
    newUserData.forEach((data) => {
      if (user.id.toString() === data.user_id.toString()) {
        match = true;
        newData = data;
      }
    });

    // If there is a match combine the user with the data
    if (match) {
      const combinedUser = {
        ...user,
        ...newData,
      };
      updatedUsers.push(combinedUser);
    } else {
      // If there is no match simply return the plain user
      updatedUsers.push(user);
    }
  });
  return updatedUsers;
}

export async function addCompanyData(objects) {
  const companyIDs = objects.map((p) => p.company_id);
  const newCompanyData = await getCompaniesByIDs(companyIDs);
  if (isEmpty(newCompanyData)) {
    return objects;
  }
  const updatedObjects = [];
  objects.forEach((object) => {
    let match = false;
    let newData = {};
    // Try and find a match for this object
    newCompanyData.forEach((data) => {
      if (!isEmpty(object.company_id)) {
        if (object.company_id.toString() === data.id.toString()) {
          match = true;
          newData = data;
        }
      }
    });

    // If there is a match combine the object with the data
    if (match) {
      object.company_name = newData.name;
      object.company_logo = newData.logo;
      updatedObjects.push(object);
    } else {
      // If there is no match simply return the plain object
      updatedObjects.push(object);
    }
  });

  return updatedObjects;
}
