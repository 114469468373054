import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Dashboard from "./ProjectNavigationDashboard";
import CostPlanning from "./ProjectNavigationCostPlanning";
import Tender from "./ProjectNavigationTender";
import PostContractNavigation from "../post-contract-2.0/PostContractNavigation";
import Sustainability from "./ProjectNavigationSustainability";
import OptionsAnalysis from "./ProjectNavigationOptionsAnalysis";

import "./ProjectNavigation.css";

ProjectNavigation.propTypes = {
  project: PropTypes.object,
  projectID: PropTypes.object,
  postContractForm: PropTypes.object,
  projectLoading: PropTypes.bool,
  CP: PropTypes.object,
};

export default function ProjectNavigation(props) {
  const { projectID } = props;
  const { project } = props;
  const { postContractForm } = props;
  const { projectLoading } = props;
  const { CP } = props;

  const [costPlanningShow, setCostPlanningShow] = useState(false);
  const [postContractShow, setPostContractShow] = useState(false);
  const [sustainabilityShow, setSustainabilityShow] = useState(false);
  const [optioneeringShow, setoptioneeringShow] = useState(false);

  useEffect(() => {
    if (projectLoading && project.project_phase === "POST_CONTRACT") {
      setCostPlanningShow(false);
      setPostContractShow(true);
      setSustainabilityShow(false);
    } else if (projectLoading && project.project_phase === "COST_PLANNING") {
      setCostPlanningShow(true);
      setPostContractShow(false);
      setSustainabilityShow(false);
    }
  }, [projectLoading, project.project_phase]);
  if (projectID === undefined) {
    return null;
  }

  if (projectLoading) {
    return null;
  }
  return (
    <div className="project-navigation-tab-group">
      <Dashboard projectID={projectID} />
      <CostPlanning
        projectID={projectID}
        show={costPlanningShow}
        setShow={setCostPlanningShow}
        setPCShow={setPostContractShow}
        setSustainabilityShow={setSustainabilityShow}
        project={project}
        CP={CP}
      />
      <Tender />
      <PostContractNavigation
        projectID={projectID}
        postContractForm={postContractForm}
        show={postContractShow}
        setShow={setPostContractShow}
        setCPShow={setCostPlanningShow}
        setSustainabilityShow={setSustainabilityShow}
      />
      <Sustainability
        projectID={projectID}
        show={sustainabilityShow}
        setShow={setSustainabilityShow}
        setPCShow={setPostContractShow}
        setCPShow={setCostPlanningShow}
        project={project}
        CP={CP}
      />
      <OptionsAnalysis
        projectID={projectID}
        show={optioneeringShow}
        setShow={setoptioneeringShow}
        setPCShow={setPostContractShow}
        setCPShow={setCostPlanningShow}
        project={project}
        CP={CP}
      />
    </div>
  );
}
