import React, { useState, useEffect } from "react";
import isEmpty from "../../validation/is-empty";
import { comma, commaToFixed } from "../../helpers/common.functions";
import { translate } from "../../utils/translation";
import "./EstimatesTPI.css";

import Modal from "../modal/Modal";

import EstimatesTPISaveEscalation from "./EstimatesTPISaveEscalation";
import { SAVE_MESSAGES } from "./EstimatesTPISaveMessages";
import EstimatesTPIAnticipatedCost from "./EstimatesTPIAnticipatedCost";
import EstimatesTPILinkedInfoModal from "./EstimatesTPILinkedInfoModal";

export default function EstimatesTPI(props) {
  // Props
  const { estimate, project } = props;
  const { user } = props;
  const { TPI } = props;
  const { CPs } = props;
  const { isBreakdown } = props;
  const { selectedRow } = props;
  const { isClassic = true } = props;

  // State management from parent
  const { isLinkedTPI = false } = props;
  const { linkedTPISelectedYear } = props;
  const { linkedTPIPercentage } = props;

  // State
  const [futureYear, setFutureYear] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [message, setMessage] = useState(null);
  const [linkedTPIInfoModal, setLinkedTPIInfoModal] = useState(false);

  //////
  /// DATES
  //////

  let escalationDate = parseInt(estimate.escalation_date);

  // Get the base date of the estimate

  let currentYear = getYearFromDate(estimate.date);

  // If no future year is set, set the future year to current year
  let estimateFutureYear = isNaN(escalationDate)
    ? currentYear
    : getYearFromDate(escalationDate);

  // Set the percentage
  useEffect(() => {
    if (isLinkedTPI) {
      setPercentage(linkedTPIPercentage);
    } else {
      setPercentage(estimate.escalation_percentage);
    }
  }, [estimate.escalation_percentage, isLinkedTPI, linkedTPIPercentage]);

  // Display message if percentage changes to prompt user that it is not saved
  useEffect(() => {
    if (percentage !== estimate.escalation_percentage) {
      setMessage(SAVE_MESSAGES.NOT_SAVED);
    }
    if (percentage === estimate.escalation_percentage) {
      setMessage(null);
    }
  }, [estimate.escalation_percentage, percentage]);

  //If it is a linked tpi that is saved, then get the future year from the estimate
  useEffect(() => {
    if (!isEmpty(TPI)) {
      if (isLinkedTPI && linkedTPISelectedYear !== 0) {
        setFutureYear(linkedTPISelectedYear);
      } else {
        setFutureYear(estimateFutureYear);
      }
    }
  }, [TPI, isLinkedTPI, linkedTPISelectedYear, estimateFutureYear]);

  // Early exit
  if (isEmpty(TPI) || (isBreakdown && selectedRow !== "breakdown")) {
    return null;
  }

  // compute specifics of Estimate TPI
  const {
    estimateCost,
    inflation,
    containerType,
    descriptionType,
    calculatePercentage,
  } = computeEstimateTPISpecifics({
    estimate,
    project,
    TPI,
    isBreakdown,
    selectedRow,
    percentage,
    currentYear,
  });

  // this map is used to return differential values for isLinkedTPI state vs !isLinkedTPI state
  const map = {
    // isLinkedTPI = true, map[isLinkedTPI] will return the below object
    true: {
      iconClass: "linkedTPI",
      textClass: "",
      icon: <i className="fas fa-lock"></i>,
      estimateCost: commaToFixed(estimateCost),
      totalCost: comma(inflation),
    },
    // isLinkedTPI = false, map[isLinkedTPI] will return the below object
    false: {
      iconClass: "delinkedTPI",
      textClass: "disabled",
      icon: <i className="fas fa-lock-open"></i>,
      estimateCost: "",
      totalCost: "",
    },
  };

  return (
    <div className="estimates-TPI">
      <div className="estimates-TPI-row">
        <div className={containerType}>
          <div className={descriptionType}>
            {!isClassic && (
              <button
                className={`estimates-TPI-icon ${map[isLinkedTPI].iconClass}`}
                onClick={() => setLinkedTPIInfoModal(true)}
              >
                {map[isLinkedTPI].icon}
              </button>
            )}
            <div className="estimates-TPI-label">
              {translate("Tender Price Inflation")}
            </div>
            <div className="estimates-tpi-slider-container">
              <div className="estimates-TPI-label">{currentYear}</div>
              <input
                disabled={isLinkedTPI}
                className="estimates-tpi-slider"
                type="range"
                min={currentYear}
                max={TPI.years[TPI.years.length - 1]}
                value={futureYear}
                onChange={(e) => {
                  let selectedYear = parseInt(e.target.value);

                  let percentage = calculatePercentage(selectedYear);

                  setFutureYear(selectedYear);
                  setPercentage(percentage);
                  setMessage(null);
                }}
              />
              <div className="estimates-TPI-label">
                <b>{futureYear}</b>
              </div>
            </div>
          </div>
          <input
            readOnly={isLinkedTPI}
            disabled={isLinkedTPI}
            className="estimates-TPI-value"
            value={percentage.toString()}
            onChange={(e) => {
              if (!isNaN(e.target.value)) {
                setPercentage(e.target.value);
              }
            }}
          ></input>
          <div className="estimates-TPI-unit">%</div>
          <div className="estimates-TPI-construction-cost">
            {commaToFixed(estimateCost)}
          </div>

          <div className="estimates-TPI-total">{comma(inflation)}</div>
        </div>
        {isLinkedTPI ? (
          <></>
        ) : (
          <EstimatesTPISaveEscalation
            isLinkedTPI={isLinkedTPI}
            estimateIDs={[estimate.id]}
            futureYear={futureYear}
            percentage={percentage}
            user={user}
            message={message}
            setMessage={setMessage}
            CPs={CPs}
            project={project}
          />
        )}
      </div>
      <EstimatesTPIAnticipatedCost
        estimate={estimate}
        project={project}
        futureYear={futureYear}
        inflation={inflation}
        containerType={containerType}
        descriptionType={descriptionType}
        isBreakdown={isBreakdown}
      />
      <Modal
        //title={`Synchronized ${translate("Tender Price Inflation")}`}
        title={isLinkedTPI ? "Control locked" : "Control unlocked"}
        Component={EstimatesTPILinkedInfoModal}
        modal={linkedTPIInfoModal}
        setModal={setLinkedTPIInfoModal}
        // Component Props
        setShow={setLinkedTPIInfoModal}
        isLinkedTPI={isLinkedTPI}
      />
    </div>
  );
}

export function round(value, precision) {
  let multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

export function getYearFromDate(date) {
  return parseInt(
    new Date(date).toLocaleString("default", {
      year: "numeric",
    })
  );
}

export function computeEstimateTPISpecifics({
  estimate,
  project,
  TPI,
  isBreakdown,
  selectedRow,
  percentage,
  currentYear,
}) {
  // Generate Values to Display
  let estimateCost = estimate.construction_cost;
  let inflation = Math.round((estimateCost * percentage) / 100);

  let containerType = "estimates-TPI-container";
  let descriptionType = "estimates-TPI-description";

  if (isBreakdown) {
    containerType = "estimates-breakdown-TPI-container";
    descriptionType = "estimates-breakdown-TPI-description";
  }

  // Extract the Current index and Future index
  let TPICity = project.tpi_city;
  if (isEmpty(TPICity)) {
    TPICity = TPI.locations[1];
  }

  let currentIndex;
  TPI.raw.forEach((row) => {
    if (row.year === currentYear && row.location === TPICity) {
      currentIndex = row.value;
    }
  });

  function calculatePercentage(futureYear) {
    let futureIndex;
    TPI.raw.forEach((row) => {
      if (row.year === futureYear && row.location === TPICity) {
        futureIndex = row.value;
      }
    });
    let percent = round((futureIndex / currentIndex) * 100 - 100, 2);
    if (isNaN(percent)) {
      percent = 0;
    }

    return percent;
  }

  return {
    estimateCost,
    inflation,
    containerType,
    descriptionType,
    calculatePercentage,
  };
}
