import React from "react";
import { translate } from "../../../utils/translation";

import isEmpty from "../../../validation/is-empty";

import "../OptionsAnalysisTable.css";
import { COLUMNS } from "./OptionsAnalysisBreakdownItems.constants";

export default function OptionsSummaryBreakDownItems(props) {
  const { data } = props;

  if (isEmpty(data)) {
    return null;
  }

  return (
    <div className="options-analysis-table-container breakdown-container">
      <div className="options-analysis-table">
        <TableHeader />
        <TableRows rows={data} />
      </div>
    </div>
  );
}

function TableCell(props) {
  const { value } = props;
  const { className } = props;

  return <div className={className}>{translate(value)}</div>;
}

function TableHeader() {
  return (
    <div className="options-analysis-table-heading-row breakdown-heading-row">
      {COLUMNS.map((cell, index) => {
        const key = `${index}_${cell.title}`;
        return (
          cell.visible && (
            <TableCell
              key={key}
              value={cell.title}
              className={`${cell.className} heading-cell`}
            />
          )
        );
      })}
    </div>
  );
}

function TableRows(props) {
  const { rows } = props;

  if (isEmpty(rows)) {
    return null;
  }

  const fields = Object.keys(rows[0]);

  return rows.map((row) => {
    const key = row.id;
    return <TableRow key={key} parentKey={key} row={row} fields={fields} />;
  });
}

function TableRow(props) {
  const { parentKey } = props;
  const { row, fields } = props;
  const cells = [];

  fields.forEach((field, index) => {
    const column = COLUMNS.find((item) => item.field === field);
    const key = `${parentKey}_${index}`;

    if (isEmpty(column) || !column.visible) {
      cells.push(<div key={key}></div>);
    } else {
      const value = column.value(row);

      cells.push(
        <TableCell key={key} value={value} className={column.className} />
      );
    }
  });

  return (
    <div className="options-analysis-table-row-container">
      <div className={getClassName(row)}>{cells}</div>
    </div>
  );
}

function getClassName(row) {
  let className = "options-analysis-table-row breakdown-row";

  switch (row["code"]) {
    case "Head1":
      return `${className} breakdown-heading`;
    case "ST":
      return `${className} breakdown-subtotal`;
    case "T":
      return `${className} breakdown-total`;
    default:
      return className;
  }
}
