import React from "react";
import isEmpty from "../../validation/is-empty";

import { handleResponse, handleError } from "./ActiveDirectory.functions";

export function B2CLoginPopup(props) {
  const { myMSALObj } = props;
  const { setLoading } = props;
  const { setStatus } = props;
  const { loginClient } = props;

  return (
    <button
      className="landing-button"
      onClick={async () => {
        setLoading(true);
        if (!isEmpty(localStorage.idleLogout)) {
          localStorage.removeItem("idleLogout");
        }
        try {
          const tokenResponse = await myMSALObj.loginPopup();

          await handleResponse(
            tokenResponse,
            setLoading,
            setStatus,
            loginClient
          );
        } catch (error) {
          setLoading(false);
          setStatus(handleError(error));
        }
      }}
    >
      Login
    </button>
  );
}

export function B2CLoginRedirect(props) {
  const { myMSALObj } = props;
  const { setLoading } = props;
  const { setStatus } = props;
  const { loginClient } = props;

  // Register Callbacks for Redirect flow
  myMSALObj
    .handleRedirectPromise()
    .then(async (tokenResponse) => {
      await handleResponse(tokenResponse, setLoading, setStatus, loginClient);
    })
    .catch((error) => {
      setLoading(false);
      setStatus(handleError(error));
    });
  return (
    <button
      className="landing-button"
      onClick={() => {
        setLoading(true);
        //for every new login remove idle popup local storage item
        if (!isEmpty(localStorage.idleLogout)) {
          localStorage.removeItem("idleLogout");
        }
        localStorage.setItem("ADLoading", true);
        myMSALObj.loginRedirect();
      }}
    >
      Login
    </button>
  );
}
