import React from "react";
import { PCRA_GLOSSARY } from "./PCRAGlossary.constants";
import { PCRAStatusBar } from "./PCRAStatusBar";

const { AUDIT_STAGE, AUDIT_STATUS } = PCRA_GLOSSARY;
const { PRODUCE, CHECK, REVIEW, APPROVE } = AUDIT_STAGE();
const { PENDING, CURRENT, PASSED, FAILED } = AUDIT_STATUS;
const NONE = "NONE";

export function PCRAStatusFlowBar(props) {
  const { stage, status } = props;

  const flow = {
    [PRODUCE + PENDING]: [NONE, PENDING],
    [PRODUCE + CURRENT]: [NONE, PENDING],
    [PRODUCE + PASSED]: [NONE, PASSED],
    [PRODUCE + FAILED]: [NONE, NONE],
    [CHECK + PENDING]: [PENDING, PENDING],
    [CHECK + CURRENT]: [PASSED, PENDING],
    [CHECK + PASSED]: [PASSED, PASSED],
    [CHECK + FAILED]: [PASSED, NONE],
    [REVIEW + PENDING]: [PENDING, PENDING],
    [REVIEW + CURRENT]: [PASSED, PENDING],
    [REVIEW + PASSED]: [PASSED, PASSED],
    [REVIEW + FAILED]: [PASSED, NONE],
    [APPROVE + PENDING]: [PENDING, NONE],
    [APPROVE + CURRENT]: [PASSED, NONE],
    [APPROVE + PASSED]: [PASSED, NONE],
    [APPROVE + FAILED]: [PASSED, NONE],
  };

  const [from, to] = flow[stage + status];

  return <PCRAStatusBar from={from} status={status} to={to} />;
}
