import isEmpty from "../../validation/is-empty";
import { projectUpdate } from "../../api/projects/ProjectAPI";
import { authHeader } from "../../_helpers/auth-header";
import { tranStr } from "../../utils/translation";
import { cpAPIs } from "../../api/api-endpoints/CostPlanningEndpoints";
import { AutoCloseModal } from "../modal/Modal";

export async function submitStages(data) {
  // Check for duplicate Stage Numbers
  let values = data.newStages.map((item) => item.stage);
  let isDuplicate = values.some((item, idx) => values.indexOf(item) !== idx);

  if (isDuplicate) {
    return data.setError({
      text: tranStr("No duplicate stages"),
      type: "project-stages-error",
    });
  } else if (isInvalidStages(data.newStages)) {
    return data.setError({
      text: tranStr("Stages not valid"),
      type: "project-stages-error",
    });
  } else {
    data.setError({
      text: tranStr("Saving..."),
      type: "project-stages-success",
    });
  }

  const stages = data.newStages.map((s) => ({
    project_id: data.projectID,
    stage: parseInt(s.stage),
    stage_name: s.stage_name,
    stage_code: s.stage_code,
  }));

  const payload = JSON.stringify({
    project_id: data.projectID,
    stages: stages,
  });

  // COST PLAN API
  const url = cpAPIs().update_stages;
  const config = {
    method: "POST",
    body: payload,
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);
  if (response.ok) {
    response = await response.json();
    data.CPs.set(await data.CPs.get(data.projectID, data.project));
    projectUpdate(data.project);

    data.setError({
      text: tranStr("Saved Successfully"),
      type: "project-stages-success",
    });

    AutoCloseModal(data.setModal);
  } else {
    // Response OK
    return data.setError({
      text: tranStr("Save Failed"),
      type: "project-stages-error",
    });
  }

  return response;
}

function isInvalidStages(array) {
  let fail = false;
  array.forEach((s) => {
    if (isEmpty(s.stage) || isEmpty(s.stage_name) || isEmpty(s.stage_code)) {
      fail = true;
    }
  });

  return fail;
}
