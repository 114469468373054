import React, { useState, useEffect } from "react";
import { addElement, moveElement } from "../../api/cbs/CbsAPI";
import Modal from "../modal/Modal";
import { EditElementModal } from "./CbsElementsModal";
import isEmpty from "../../validation/is-empty";

import "./CbsElements.css";

export function CbsElements(props) {
  const { selectedCategory } = props;
  const { cbs } = props;
  const { selectedElements, setSelectedElements } = props;

  let table = [];
  cbs?.data?.elementCodes?.forEach((element) => {
    let colour = "";

    selectedCategory?.categoryItems?.forEach((catItem) => {
      if (catItem?.codes?.includes(element.code)) {
        colour = catItem.colour;
      }
    });

    table.push(
      <ElementRow
        key={element.id}
        element={element}
        colour={colour}
        cbs={cbs}
        selectedElements={selectedElements}
        setSelectedElements={setSelectedElements}
      />
    );
  });
  return (
    <div className="cbs-category">
      {table}
      <div className="cbs-elements-button-contaier">
        <AddElementButton cbs={cbs} />
      </div>
    </div>
  );
}

function AddElementButton(props) {
  const { cbs } = props;

  const [message, setMessage] = useState("");

  if (isEmpty(cbs?.data)) {
    return null;
  }

  if (cbs?.data?.isPublished) {
    return null;
  }

  return (
    <div>
      <button
        id={"cbs-add-element-button"}
        className="general-upload-button"
        onClick={async () => {
          const cbsId = cbs?.data?.id;
          const typeCode = cbs?.data?.typeCode;

          let result = await addElement(
            cbsId,
            Date.now().toString(),
            "New Element",
            ""
          );
          if (result.success) {
            cbs.set(await cbs.get(typeCode));
          } else {
            setMessage(result.message);
          }
        }}
      >
        Add New Element
      </button>
      <div>{message}</div>
    </div>
  );
}

function ElementRow(props) {
  const { element } = props;
  const { colour } = props;
  const { cbs } = props;
  const { selectedElements, setSelectedElements } = props;

  const [modal, setModal] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (selectedElements.includes(element.code)) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [selectedElements, element.code]);

  let style = { backgroundColor: "rgba(248, 249, 250, 1)", color: "black" };
  if (colour) {
    style = { backgroundColor: colour, color: "white" };
  }

  let detailsStyle = "cbs-element-details";
  if (checked) {
    detailsStyle = "cbs-element-details-checked";
  }

  let elementMoveButton = "cbs-element-move-button";
  if (cbs?.data?.isPublished) {
    elementMoveButton = "cbs-element-move-button-disabled";
  }

  return (
    <div className="cbs-element-row" key={element.id}>
      <div className={detailsStyle}>
        <button
          id={"cbs-element-row-selector"}
          className="cbs-element-code"
          style={style}
          onClick={() => {
            if (cbs?.data?.isPublished) {
              return;
            }
            const isChecked = checked;

            const tempSelectedElements = [...selectedElements];
            if (isChecked) {
              const index = tempSelectedElements.indexOf(element.code);
              tempSelectedElements.splice(index, 1);
            }

            if (!isChecked) {
              tempSelectedElements.push(element.code);
            }

            setSelectedElements(tempSelectedElements);
            setChecked(isChecked);
          }}
        >
          {element.code}
        </button>
        <div className="cbs-element-move-container">
          <button
            className={elementMoveButton}
            onClick={async () => {
              const cbsId = cbs?.data?.id;
              const isPublished = cbs?.data?.isPublished;
              const typeCode = cbs?.data?.typeCode;
              const elementId = element.id;

              if (isPublished) {
                return;
              }

              let result = await moveElement(cbsId, elementId, -1);
              if (result.success) {
                cbs.set(await cbs.get(typeCode));
              }
            }}
          >
            <i className="fa fa-caret-up" />
          </button>
          <button
            className={elementMoveButton}
            onClick={async () => {
              const cbsId = cbs?.data?.id;
              const isPublished = cbs?.data?.isPublished;
              const typeCode = cbs?.data?.typeCode;
              const elementId = element.id;

              if (isPublished) {
                return;
              }

              let result = await moveElement(cbsId, elementId, +1);
              if (result.success) {
                cbs.set(await cbs.get(typeCode));
              }
            }}
          >
            <i className="fa fa-caret-down" />
          </button>
        </div>
        <button
          id={"cbs-element-row-selector-modal-button"}
          className="cbs-element-description"
          onClick={() => {
            setModal(true);
          }}
        >
          {element.description}
        </button>
        <button
          id={"cbs-element-row-selector-modal-button"}
          className="cbs-element-unit"
          onClick={() => {
            setModal(true);
          }}
        >
          {element.unit}
        </button>
      </div>
      <Modal
        // Modal Props
        title={"Element"}
        Component={EditElementModal}
        modal={modal}
        setModal={setModal}
        // Component Props
        element={element}
        cbs={cbs}
      />
    </div>
  );
}
