//Translation
import { tranStr } from "../../utils/translation";
import { ImageDisplay } from "../images/ImageDisplay";

export function columns() {
  return [
    {
      type: "CHECK",
      key: "id",
      width: 50,
      isModal: false,
    },
    {
      heading: tranStr(""),
      key: "image",
      type: "IMAGE",
      width: 42,
      customComponent: (value) => {
        return (
          <ImageDisplay
            image={value}
            imageWidth={"30px"}
            imageHeight={"20px"}
            isBezierDisabled={true}
          />
        );
      },
    },
    {
      heading: tranStr("Project ID"),
      key: "id",
      type: "NUMBER",

      width: 100,
    },
    {
      heading: tranStr("Job No."),
      key: "job_number",
      type: "DESCRIPTION",
      width: 100,
    },
    {
      heading: tranStr("Project Name"),
      key: "title",
      type: "DESCRIPTION",
      isFiltered: true,

      width: 200,
    },
    {
      heading: tranStr("No. of Cost Reports"),
      key: "total_cost_reports",
      type: "DESCRIPTION",

      width: 200,
    },
    {
      heading: tranStr("Project Region"),
      key: "region",
      type: "DESCRIPTION",
      isFiltered: true,

      width: 200,
    },
    {
      heading: tranStr("Project Country"),
      key: "country",
      type: "DESCRIPTION",
      isFiltered: true,

      width: 200,
    },
    {
      heading: tranStr("Project City"),
      key: "city",
      type: "DESCRIPTION",
      isFiltered: true,

      width: 200,
    },
    {
      heading: tranStr("Project Sector"),
      key: "sector",
      type: "DESCRIPTION",
      isFiltered: true,

      width: 200,
    },
    {
      heading: tranStr("Project Sub Sector"),
      key: "sub_sector",
      type: "DESCRIPTION",
      isFiltered: true,

      width: 200,
    },
  ];
}
