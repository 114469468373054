// React
import React, { useState, useEffect } from "react";
import { translate } from "../../utils/translation";

// API
import { useLocations } from "../../api/admin/RegionManagementAPI";

// Components
import { RegionSelect } from "../locations/LocationSelect";
import CreateProjectSelectCostManager from "../project-create/CreateProjectSelectCostManager";
import { ModalSingle } from "./CompanyCreate";
import {
  ExistingFile,
  FileTray,
  UploadFile,
  DisplayError,
  onConsent,
  Upload,
} from "./CompanyCreate.components";

// Functions
import { uploadFile } from "./CompanyEdit.functions";
import isEmpty from "../../validation/is-empty";

// Styles
import "./CompanyCreate.css";
import "./CompanyEdit.css";
import { getUsersByIDs } from "../../api/users/UserAPI";

export default function CompanyEdit(props) {
  // Props
  const { show } = props;
  const { setShow } = props;
  const { company } = props;
  const { companies } = props;
  const { setParentModalShow } = props;
  const { locations } = useLocations(show);

  // State
  const [file, setFile] = useState(null);
  const [error, setError] = useState({});
  const [companyName, setCompanyName] = useState("");
  const [companyEmailDomain, setCompanyEmailDomain] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [region, setRegion] = useState("");
  const [isGlobal, setIsGlobal] = useState(0);
  const [accountLead, setAccountLead] = useState({});

  const [existingLogo, setExistingLogo] = useState("");

  useEffect(() => {
    if (!isEmpty(company)) {
      setCompanyName(company.name);
      setCompanyEmailDomain(company.email_domain);
      setCompanyAddress(company.address);
      setRegion(company.region);
      setIsGlobal(company.is_global);
      setExistingLogo(company.logo);
    }
  }, [company]);

  //set account lead
  useEffect(() => {
    if (!isEmpty(company)) {
      async function getUser() {
        const user = await getUsersByIDs([company.account_lead]);
        if (!isEmpty(user)) {
          setAccountLead(user[0]);
        }
      }
      getUser();
    }
  }, [company]);

  let data = {};

  if (!isEmpty(company)) {
    data = {
      file: file,
      field: {
        companyID: company.id,
        name: companyName,
        email_domain: companyEmailDomain,
        address: companyAddress,
        region: region,
        is_global: isGlobal,
        account_lead: accountLead.id,
      },
      setError: setError,
      setShow: setShow,
      setParentModalShow: setParentModalShow,
      companies: companies,
    };
  }

  return (
    <div className="company-create-container">
      <div className="company-create-inputs">
        <div className="company-create-field">
          <div className="company-create-label">
            {translate("Company Name")}
          </div>
          <input
            id="company-name-input"
            className="company-create-input"
            value={companyName}
            onChange={(e) => {
              setCompanyName(e.target.value);
            }}
          ></input>
        </div>
        <div className="company-create-field">
          <div className="company-create-label">
            {translate("Email Domain(s) (CSVs)")}
          </div>
          <input
            id="company-domain-input"
            className="company-create-input"
            value={companyEmailDomain}
            onChange={(e) => {
              setCompanyEmailDomain(e.target.value);
            }}
          ></input>
        </div>
        <div className="company-create-field">
          <div className="company-create-label">
            {translate("Head Office Address")}
          </div>
          <input
            id="company-address-input"
            className="company-create-input"
            value={companyAddress}
            onChange={(e) => {
              setCompanyAddress(e.target.value);
            }}
          ></input>
        </div>
        <div className="company-create-field">
          <div className="company-create-label">
            {translate("Head Office Region")}
          </div>
          <RegionSelect
            value={region}
            set={setRegion}
            options={locations.data}
            classType={"company-create-input"}
          />
        </div>
        <label className="company-edit-checkbox">
          <div className="company-edit-checkbox-text">
            {translate("Global (2+ Regions)")}
          </div>

          <input
            id="company-global-input"
            name={"consent"}
            type="checkbox"
            checked={isGlobal}
            onChange={(e) => {
              onConsent(e, setIsGlobal);
            }}
          />
          <span className="company-edit-checkbox-checkmark"></span>
        </label>
        <ModalSingle
          Component={CreateProjectSelectCostManager}
          setFunction={setAccountLead}
          label={translate("Arcadis Account Lead")}
          user={accountLead}
        />
      </div>
      <ExistingFile existingLogo={existingLogo} />
      <div className="general-row-container">
        <FileTray file={file} />
      </div>
      <div className="general-row-container">
        <UploadFile setFile={setFile} setExistingLogo={setExistingLogo} />
      </div>
      <div className="general-row-container">
        <DisplayError error={error} setError={setError} />
      </div>
      <div className="general-button-container">
        <Upload data={data} uploadFile={uploadFile} />
      </div>
    </div>
  );
}
