import isEmpty from "../../validation/is-empty";

export function arrangeProjectsByMetric(
  metrics,
  thisBenchmark,
  benchmarkingForBenchmark
) {
  let projectsByMetrics = [];

  metrics.forEach((t) => {
    let benchmarks = [];

    thisBenchmark.metrics.forEach((m) => {
      if (t.code === m.code) {
        benchmarks.push({
          project_name: formatName(thisBenchmark),
          quantity: m.quantity,
        });
      }
    });

    benchmarkingForBenchmark.forEach((b) => {
      b.metrics.forEach((m) => {
        if (t.code === m.code) {
          benchmarks.push({
            project_name: formatName(b),
            quantity: m.quantity,
          });
        }
      });
    });
    if (benchmarks.length > 1) {
      projectsByMetrics.push({
        metric: t.metric,
        benchmarks: benchmarks,
        code: t.code,
        unit: t.unit,
      });
    }
  });

  return projectsByMetrics;
}

export function combineBuiltAreaMetricsByPercentage(metrics) {
  let metric = {
    metric: "Proportion of Built Areas By Percentage",
    code: "D2.9, D2.11",
    unit: "%",
    benchmarks: [], // Not used
    cellular: [],
    breakout: [],
  };

  metrics.forEach((m) => {
    if (m.code === "D2.9") {
      metric.cellular = m;
    }

    if (m.code === "D2.11") {
      metric.breakout = m;
    }
  });

  if (!isEmpty(metric.breakout) || !isEmpty(metric.cellular)) {
    metrics.push(metric);
  }

  return metrics;
}

export function combineBuiltAreaMetricsByArea(metrics) {
  let metric = {
    metric: "Proportion of Built Areas By Area",
    code: "D2.8, D2.10",
    unit: "m²",
    benchmarks: [], // Not used
    cellular: [],
    breakout: [],
  };

  metrics.forEach((m) => {
    if (m.code === "D2.8") {
      metric.cellular = m;
    }

    if (m.code === "D2.10") {
      metric.breakout = m;
    }
  });

  if (!isEmpty(metric.breakout) || !isEmpty(metric.cellular)) {
    metrics.push(metric);
  }

  return metrics;
}

export function organiseMetrics(metrics) {
  let organisedMetrics = {
    designMetrics: [],
    costMetrics: [],
  };

  metrics.forEach((m) => {
    if (m.code && m.code[0] === "C") {
      organisedMetrics.costMetrics.push(m);
    } else {
      organisedMetrics.designMetrics.push(m);
    }
  });

  return organisedMetrics;
}

export function formatName(b) {
  let name = "";

  if (isEmpty(b.project_name_short)) {
    if (!isEmpty(b.project_name)) {
      if (b.project_name.length > 12) {
        name = b.project_name.slice(0, 9) + "...";
      } else {
        name = b.project_name;
      }
    }
  } else {
    name = b.project_name_short;
  }
  return name;
}

export function isMetricHidden(metricCode) {
  let hide = false;
  const hiddenMetricCodes = [
    "D1.1", // Gross Internal Area (GIA)*
    // Hide individual metrics that are combined into one graph
    "D2.8",
    "D2.9",
    "D2.10",
    "D2.11",
  ];

  hiddenMetricCodes.forEach((code) => {
    if (metricCode === code) {
      hide = true;
    }
  });

  return hide;
}
