/*
CSS:

:root {
    --heritage: rgb(228, 97, 15);
    --heritage2: rgba(228, 97, 15, 0.7);
    --confident: rgb(29, 29, 29);
    --know-how: rgb(179, 179, 179);
    --integrity: rgb(255, 255, 255);
    --natural1: rgb(13, 166, 66);
    --natural2: rgb(113, 202, 142);
    --natural3: rgb(184, 228, 198);
    --natural4: rgb(231, 246, 236);
    --collaboration1: rgb(85, 87, 90);
    --collaboration2: rgb(145, 143, 146);
    --collaboration3: rgb(196, 194, 196);
    --collaboration4: rgb(235, 234, 235);
    --built1: rgb(248, 218, 64);
    --built2: rgb(252, 232, 154);
    --built3: rgb(254, 244, 208);
    --built4: rgb(255, 251, 240);
    --growth1: rgb(195, 210, 0);
    --growth2: rgb(221, 227, 131);
    --growth3: rgb(238, 241, 198);
    --growth4: rgb(250, 251, 237);
    --fluidity1: rgb(50, 98, 149);
    --fluidity2: rgb(101, 137, 176);
    --fluidity3: rgb(153, 171, 202);
    --fluidity4: rgb(204, 216, 229);
    --strength1: rgb(228, 31, 19);
    --strength2: rgb(241, 135, 100);
    --strength3: rgb(249, 198, 176);
    --strength4: rgb(254, 237, 229);
}
*/

export function getCSSColorPalette() {
  const colorNames = [
    "--heritage",
    "--heritage2",
    "--confident",
    "--know-how",
    "--integrity",
    "--natural1",
    "--natural2",
    "--natural3",
    "--natural4",
    "--collaboration1",
    "--collaboration2",
    "--collaboration3",
    "--collaboration4",
    "--built1",
    "--built2",
    "--built3",
    "--built4",
    "--growth1",
    "--growth2",
    "--growth3",
    "--growth4",
    "--fluidity1",
    "--fluidity2",
    "--fluidity3",
    "--fluidity4",
    "--strength1",
    "--strength2",
    "--strength3",
    "--strength4",
  ];

  const cssColors = {};

  colorNames.forEach((colorName) => {
    const colorValue = getComputedStyle(
      document.documentElement
    ).getPropertyValue(colorName);

    cssColors[colorName] = colorValue;
  });

  return cssColors;
}
