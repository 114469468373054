import {
  processRisks,
  processDecisions,
  processBenchmarks,
  processCostCategories,
  processTotals,
  processDurations,
  processAreas,
  processMetrics,
} from "./PortfolioDashboard.functions";

import {
  processBudgetChart,
  processTotalsChart,
  processCostCategoriesChart,
  processBenchmarksChart,
  processProgressChart,
  processAreaChart,
  processServicesMetricsChart,
} from "./PortfolioDashboard.charts";

import processPortfolioModel from "./portfolioDashboardModel";

export function processPortfolioDashboard(projects, TPI) {
  const portfolioDashboard = processPortfolioModel();

  // Collection of data points
  projects.forEach((p) => {
    processTotals(p, portfolioDashboard);
    processRisks(p, portfolioDashboard);
    processDecisions(p, portfolioDashboard);
    processCostCategories(p, portfolioDashboard);
    processBenchmarks(p, portfolioDashboard, TPI);
    processDurations(p, portfolioDashboard);
    processAreas(p, portfolioDashboard);
    processMetrics(p, portfolioDashboard);
  });

  // Processing of data points for visualisation
  processBudgetChart(portfolioDashboard);
  processTotalsChart(portfolioDashboard);
  processCostCategoriesChart(portfolioDashboard);
  processBenchmarksChart(portfolioDashboard);
  processProgressChart(portfolioDashboard);
  processAreaChart(portfolioDashboard);
  processServicesMetricsChart(portfolioDashboard);

  return portfolioDashboard;
}
