import React from "react";
import PropTypes from "prop-types";

import "./ChartsToggle.css";
import { hasRoles } from "../../utils/roles";

/// props validation | SQ(javascript:S6774)
ChartsToggle.propTypes = {
  off: PropTypes.bool,
  setOff: PropTypes.func,
  setting: PropTypes.string,
  user: PropTypes.object,
  onSettingsToggle: PropTypes.func,
};
///
export default function ChartsToggle(props) {
  const { off, setOff, setting, user } = props;
  const { onSettingsToggle } = props;

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  if (typeof onSettingsToggle !== "function") {
    return <></>;
  }

  const visible = off ? "Hidden" : "Visible";

  return (
    <label className="switch">
      <input
        type="checkbox"
        checked={!off}
        onChange={() => {
          setOff(!off);
          onSettingsToggle({ setting, state: !off });
        }}
      />
      <span className="slider round" />
      <span className="tooltiptext">{"Client " + visible}</span>
    </label>
  );
}
