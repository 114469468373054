// React
import React from "react";

// Map
import { MapContainer, TileLayer, Marker, useMap } from "react-leaflet";

// Style
import "./Map.css";
import isEmpty from "../../validation/is-empty";

export default function CreateProjectMap(props) {
  const { lat, lng } = props;

  if (!isEmpty(lat) && !isEmpty(lng) && !isNaN(lat) && !isNaN(lng)) {
    return (
      <MapContainer
        className="project-dashboard-map"
        center={[lat, lng]}
        zoom={12}
        scrollWheelZoom={true}
        attributionControl={false}
        zoomControl={false}
      >
        <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />

        <Marker position={[lat, lng]}></Marker>
        <ChangeView center={[lat, lng]} zoom={12} />
      </MapContainer>
    );
  } else {
    return null;
  }

  function ChangeView({ center, zoom }) {
    const map = useMap();
    map.setView(center, zoom);
    return null;
  }
}
