import React from "react";
import PropTypes from "prop-types";

import { Bar } from "react-chartjs-2";
import { comma } from "../../helpers/common.functions";
import { tranStr } from "../../utils/translation";

import { getCSSColorPalette } from "../../actions/cssColors";

/// props validation | SQ(javascript:S6774)
BenchmarkCostM2.propTypes = {
  skipHighlight: PropTypes.bool,
  benchmarkControl: PropTypes.bool,
  dash: PropTypes.bool,
  field: PropTypes.array,
  value: PropTypes.array,
  avgs: PropTypes.array,
  unitOfMeasure: PropTypes.string,
};
///
export default function BenchmarkCostM2(props) {
  const { skipHighlight } = props;

  const { benchmarkControl, dash } = props;
  const { field, value, avgs } = props;
  const { unitOfMeasure } = props;

  const CSS_COLORS = getCSSColorPalette();
  const highlightColor = CSS_COLORS["--heritage"];
  const baseColor = CSS_COLORS["--fluidity2"];

  const colorArray = value.map((v, i) =>
    (i === 0 && !skipHighlight) || benchmarkControl ? highlightColor : baseColor
  );

  let unit = " / " + unitOfMeasure;

  let barThickness;

  if (dash) {
    barThickness = 15;
  }

  // Create the graph object
  let graph = {
    labels: field,
    datasets: [
      {
        label: tranStr("Average of Benchmarks (Line)"),
        data: avgs,
        backgroundColor: "gray",
        borderColor: "gray",
        borderWidth: 2,
        type: "line",
        fill: false,
      },
      {
        label: "Construction Cost" + unit + " " + tranStr("Escalated"),
        data: value,
        barThickness: barThickness || 15,
        backgroundColor: colorArray,
        borderColor: colorArray,
        borderWidth: 1,
      },
    ],
  };

  // Create the options object
  let options = {
    maintainAspectRatio: false,
    plugins: { datalabels: { display: false } },
    tooltips: {
      enabled: true,
      xPadding: 10,
      yPadding: 10,
      callbacks: {
        label: function (tooltipItem, test) {
          if (tooltipItem.datasetIndex === 0) {
            return (
              tranStr("Average of Benchmarks (Line)") +
              " " +
              //project.currency_symbol +
              comma(Math.round(tooltipItem.yLabel)) +
              unit
            );
          } else {
            return (
              //project.currency_symbol +
              comma(Math.round(tooltipItem.yLabel)) + unit
            );
          }
        },
      },
    },

    scales: {
      yAxes: [
        {
          ticks: {
            callback: function (tick, index, values) {
              return (
                // project.currency_symbol +
                comma(tick) + unit
              );
            },
            stepSize: 2000,
            beginAtZero: true,
          },
        },
      ],
    },
    legend: {
      display: true,
      position: "bottom",
      reverse: true,
      labels: {
        usePointStyle: false,
      },
    },
  };
  return <Bar data={graph} options={options} />;
}
