import isEmpty from "../../validation/is-empty";

export function postContractTableAdaptor(data, config) {
  const columns = [];
  const rows = [];

  if (isEmpty(data)) {
    return {
      columns,
      rows,
    };
  }

  // COLUMNS
  data.Rows.forEach((row) => {
    if (row.RowType === "METADATA") {
      row?.Cells?.forEach((column, i) => {
        let type = column.Value; //Keeping default as currency to make sure there is a defult width to every column
        let width = 50;

        if (column.Value === "SUBHEADING") {
          type = "STATUS";
        }

        if (!isEmpty(config)) {
          width = config[type];
        }

        columns.push({
          heading: "",
          key: "key" + i, //keeping a default key so that tables without title row will have keys
          type: type,
          width: width,
          isFiltered: false,
        });
      });
    }
  });

  // HEADINGS
  data.Rows.forEach((row) => {
    if (row.RowType === "TITLE") {
      row?.Cells?.forEach((col, j) => {
        columns.forEach((column, i) => {
          if (j === i) {
            column.heading = col.Value;
            // Set the column key to the legacy format, Construction Cost => construction_cost
            const columnName = col.Value.toLowerCase().replaceAll(" ", "_");
            column.key = columnName;
          }
        });
      });
    }
  });

  // ROWS
  data.Rows.forEach((row, i) => {
    // Normal Cells
    if (row.RowType === "REGULAR") {
      let tableRow = { id: row.Id };
      row?.Cells?.forEach((column, i) => {
        // Add the key name to match the legacy key name, e.g. construction_cost
        tableRow[columns[i]?.key] = column.Value;
      });
      rows.push(tableRow);
    }

    // Subheadings
    if (row.RowType === "SUBHEADING") {
      let tableRow = { id: row.Id };
      tableRow.tableRowType = "SUBHEADING";
      row?.Cells?.forEach((column, i) => {
        tableRow[columns[i]?.key] = column.Value;
      });
      rows.push(tableRow);
    }

    // Subtotals
    if (row.RowType === "SUBTOTAL") {
      let tableRow = { id: row.Id };
      tableRow.tableRowType = "SUBTOTAL";
      row?.Cells?.forEach((column, i) => {
        tableRow[columns[i]?.key] = column.Value;
      });
      rows.push(tableRow);
    }

    // Totals
    if (row.RowType === "TOTAL") {
      let tableRow = { id: row.Id };
      tableRow.tableRowType = "TOTAL";
      row?.Cells?.forEach((column, i) => {
        tableRow[columns[i]?.key] = column.Value;
      });
      rows.push(tableRow);
    }

    if (row.RowType === "TAXTOTAL") {
      let tableRow = { id: row.Id };
      tableRow.tableRowType = "TOTAL";
      row?.Cells?.forEach((column, i) => {
        tableRow[columns[i]?.key] = column.Value;
      });
      rows.push(tableRow);
    }
  });

  return {
    columns,
    rows,
  };
}

export function postContractChartAdaptor(component) {
  // Create the graph object
  let data = {
    labels: component.Fields,
    datasets: component.Values.map((v, i) => {
      return {
        label: v.Label,
        data: v.Data,
        barThickness: 10,
        backgroundColor: colourArray(i, 1),
      };
    }),
    text: component.Fields,
  };

  return {
    data,
  };
}

export function postContractLineChartAdaptor(component, yaxisIdMapping) {
  // Create the graph object
  let data = {
    labels: component.Fields,
    datasets: component.Values.map((v, i) => {
      return {
        yAxisID: yaxisIdMapping[v.Label],
        label: v.Label,
        data: v.Data,
        barThickness: 10,
        borderColor: colourArray(i, 1),
        backgroundColor: colourArray(i, 1),
        lineTension: 0,
        fill: false,
      };
    }),
    text: component.Fields,
  };

  return {
    data,
  };
}

function colourArray(i, shade) {
  let heritage = `rgba(228, 97, 15, ${1 * shade})`;
  let aqua = `rgba(50, 98, 149, ${1 * shade})`;
  let collaboration1 = `rgba(85, 87, 90, ${1 * shade})`;
  let heritage2 = `rgba(228, 97, 15, ${0.3 * shade})`;
  let fluidity2 = `rgb(101, 137, 176)`;
  let collaboration2 = `rgba(85, 87, 90, ${0.7 * shade})`;
  let collaboration3 = `rgba(85, 87, 90, ${0.3 * shade})`;
  let fluidity4 = `rgb(204, 216, 229)`;

  let array = [
    heritage,
    aqua,
    collaboration1,
    fluidity2,
    heritage2,
    collaboration2,
    collaboration3,
    fluidity4,
  ];

  let index = i % array.length;

  return array[index];
}
