// API
import { updateUserMappings } from "../../api/permissions/PermissionsAPI";

export function DisplayErrors(prop) {
  const { errors } = prop;
  return (
    <div className="company-create-error-container">
      <div className={errors.type}>
        <b>{errors.text}</b>
      </div>
    </div>
  );
}

export function Save(prop) {
  const { accountsProjects } = prop;
  const { data } = prop;
  const { account } = prop;

  return (
    <div className="admin-modal-cancel-button-container">
      <button
        className="create-project-save-cost-managers-button"
        onClick={async () => {
          // Update Permissions
          await updateUserMappings(data);

          // Reload Account Data
          accountsProjects.set(await accountsProjects.get(account.id, true));
        }}
      >
        Save
      </button>
    </div>
  );
}
