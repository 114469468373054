import React from "react";
import { Link } from "react-router-dom";
import { translate } from "../../utils/translation";
import isEmpty from "../../validation/is-empty";
import { CommentNotification as ProjectCommentNotification } from "../portfolio-projects/ProjectItem";

export default function ProjectNavigationCostPlanning(props) {
  const {
    projectID,
    show,
    setShow,
    setPCShow,
    setSustainabilityShow,
    project,
  } = props;
  const { CP } = props;
  let section = window.location.href.includes("cost-planning");

  let name = "project-navigation-section";
  if (section) {
    name = "project-navigation-section-selected";
  }

  const tabs = [
    "Summary",
    "Commentary",
    "Key Documents",
    "Breakdown",
    "Reconciliation",
    "Benchmarking",
    "Cashflow",
    "Project Budget",
    "Area Schedule",
    "Value Management",
  ];

  return (
    <div className="project-navigation-tab-item-group">
      <button
        className="project-navigation-tab-link"
        onClick={() => {
          setShow((state) => !state);
          setPCShow(() => false);
          setSustainabilityShow(() => false);
        }}
      >
        <div className={name}>
          <div className="project-navigation-label">
            <div className="front-icon">
              <div className="icon-container">
                <i
                  className="fas fa-ruler-combined"
                  style={{ marginRight: "10px" }}
                ></i>
              </div>
              {translate("Cost Planning")}
            </div>
            {show && (
              <div className="icon">
                <i className="far fa-minus-square" />
              </div>
            )}
            {!show && (
              <div className="icon">
                <i className="far fa-plus-square" />
              </div>
            )}
          </div>
        </div>
      </button>

      {tabs.map((t) => {
        if (show) {
          return (
            <Tab
              key={t}
              projectID={projectID}
              CP={CP}
              project={project}
              t={t}
              section={section}
            />
          );
        } else {
          return null;
        }
      })}
    </div>
  );
}

function Tab(props) {
  const { project, projectID, t } = props;
  const { CP } = props;
  const { section } = props;

  if (t === "Key Documents" && !project.enable_estimate_drawings) {
    return null;
  }

  if (t === "Reconciliation" && !project.enable_estimate_reconciliation) {
    return null;
  }

  if (t === "Benchmarking" && !project.enable_estimate_benchmarking) {
    return null;
  }

  if (t === "Cashflow" && !project.enable_estimate_cashflow) {
    return null;
  }
  if (t === "Area Schedule" && !project.enable_cp_area_schedule) {
    return null;
  }
  if (t === "Value Management" && !project.enable_cp_value_management) {
    return null;
  }

  let type = "cost-planning-tab-item";
  if (
    section &&
    window.location.href.includes(t.toLowerCase().replace(/\s+/g, "-"))
  ) {
    type = "cost-planning-tab-item-selected";
  }

  return (
    <Link
      key={t}
      to={`/project/${projectID}/cost-planning/${t
        .toLowerCase()
        .replace(/\s+/g, "-")}`}
      className="cost-planning-tab-link"
    >
      <div className={type}>
        <div className="cost-planning-tab-link-text">{translate(`${t}`)}</div>
        <CommentNotification CP={CP} t={t} />
      </div>
    </Link>
  );
}

function CommentNotification(props) {
  const { CP } = props;
  const { t } = props;

  if (isEmpty(CP)) {
    return null;
  }

  if (t !== "Summary" && t !== "Breakdown") {
    return null;
  }

  if (CP.notification_count < 1) {
    return null;
  }

  return <ProjectCommentNotification notification={CP.notification_count} />;
}
