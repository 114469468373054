import store from "../../store";
// MSAL
import * as Msal from "@azure/msal-browser";
import { msalConfig } from "../../active-directory/authConfig";
// Auth
import { setCurrentUser, logoutUser } from "../../actions/authActions";
import isEmpty from "../../validation/is-empty";

function loginUser() {
  const user = JSON.parse(localStorage.user);
  const myMSALObj = new Msal.PublicClientApplication(msalConfig());
  const account = myMSALObj.getAllAccounts()[0];

  if (myMSALObj.getAllAccounts().length > 1) {
    console.warn("Multiple accounts detected.");
    console.log(myMSALObj.getAllAccounts());
  }

  const accessTokenRequest = {
    account: account,
  };
  // Use the same publicClientApplication instance provided to MsalProvider
  myMSALObj
    .acquireTokenSilent(accessTokenRequest)
    .then((res) => {
      // Acquire token silent success
      const { idToken } = res;
      const { exp } = res.idTokenClaims;

      // Set user data to local storage
      user.exp = exp;
      localStorage.setItem("idToken", idToken);
      localStorage.setItem("user", JSON.stringify(user));
      // Set current user in Redux
      store.dispatch(setCurrentUser(user));
    })
    .catch(function (error) {
      //Acquire token silent failure
      console.log(error);
      store.dispatch(logoutUser());
      // Redirect to login
      window.location.href = "/";
    });
}
function logOutUser() {
  try {
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = "/";
  } catch (e) {
    console.log("error ", e.message);
  }
}
async function ADLogout() {
  try {
    //if spinner is activated by any chance, remove the local storage item
    if (!isEmpty(localStorage.ADLoading)) {
      localStorage.removeItem("ADLoading");
    }
    const myMSALObj = new Msal.PublicClientApplication(msalConfig());
    await myMSALObj.logoutPopup();
  } catch (e) {
    console.log("error ", e.message);
  }
}
export { logOutUser, loginUser, ADLogout };
