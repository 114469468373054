import { detectDeployment } from "./Utilities";

export function reportAPIs() {
  const config = {
    serviceAPI: true,
    localService: "https://dev.cost-clarity.com/apis/reportservice/v1.0",
    deployedService: "/apis/reportservice/v1.0",
  };

  // Local dev https://localhost:44315/v1.0
  // Deployed dev https://dev.cost-clarity.com/apis/reportservice/v1.0

  const D = detectDeployment(config);

  return {
    report_by_report_id: `${D}/ReportService/GetReportsByReportID`,
    report_import_start: `${D}/ReportService/ImportStart`,
    report_import_status: `${D}/ReportService/ImportStatus`,
  };
}

//Fetch the report ID based on environment change
export function fetchReportID(project_phase) {
  // Current Host
  const host = window.location.hostname;

  // Report IDs
  const local = {
    COST_PLANNING: "253509f7-4297-44b1-8181-a732a7b52bcc",
    POST_CONTRACT: "f662eefb-fccf-46b0-9c7c-31d2361d93fe",
  };

  const dev = {
    COST_PLANNING: "253509f7-4297-44b1-8181-a732a7b52bcc",
    POST_CONTRACT: "f662eefb-fccf-46b0-9c7c-31d2361d93fe",
  };

  const tst = {
    COST_PLANNING: "9d560d46-60ab-4eee-b670-63f13b5c7947",
    POST_CONTRACT: "354c6b28-18f4-449a-a9dc-0b13316d4e80",
  };

  const prd = {
    COST_PLANNING: "87fc3007-6eba-4f88-a9aa-cc0521c50023",
    POST_CONTRACT: "9cb1986a-e9dd-4010-9084-db6a7b88ec97",
  };

  // Local
  if (host.includes("localhost")) {
    return local[project_phase];
  }

  // Dev Deployment
  if (host.includes("dev")) {
    return dev[project_phase];
  }

  // Test Deployment
  if (host.includes("test")) {
    return tst[project_phase];
  }

  // Production Deployment
  if (host.includes("www")) {
    return prd[project_phase];
  }
}
