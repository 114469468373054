import { useState, useEffect } from "react";

// Components
import isEmpty from "../../validation/is-empty";

export default function CostPlanningAccordionState(CP) {
  // Accordion State
  const [accordionState, setAccordionState] = useState({});

  // The set of all estimate IDs available
  useEffect(() => {
    // Set defaults only when there are none (first load)
    if (!isEmpty(CP)) {
      if (isEmpty(accordionState)) {
        const estimateIDs = CP.estimate_ids;
        const stateObject = {};
        const cpSections = sections();

        cpSections.forEach((section) => {
          stateObject[section] = {};
          estimateIDs.forEach((id) => {
            if (CP.multiple_parts) {
              stateObject[section][id] = false;
            } else {
              stateObject[section][id] = true;
            }
          });
        });

        setAccordionState(stateObject);
      }
    }
  }, [CP, accordionState]);

  return {
    accordionState: {
      state: accordionState,
      set: setAccordionState,
    },
  };
}

function sections() {
  return [
    "SUMMARY",
    "BREAKDOWN",
    "RECONCILIATION",
    "BENCHMARKING",
    "CASHFLOW",
    "VALUEMANAGEMENT",
  ];
}
