import React from "react";

import { hasRoles } from "../../utils/roles";

import PCRALogModalTrigger from "../pcra/PCRALogModalTrigger";
import CostPlanningPCRALogModal from "./CostPlanningPCRALogModal";

import { PCRA_MARKUP } from "../pcra/PCRAGlossary.constants";

export default function CostPlanningPCRALogModalTrigger(props) {
  const { user } = props;
  const { project } = props;
  const { CPs } = props;
  const { CP } = props;

  // Hide if not Summary
  let subLocation = determineSubLocation();
  if (subLocation !== "summary") {
    return null;
  }

  const isUserCostManager = hasRoles(user.roles, ["CostManager"]);
  const isUserClient = hasRoles(user.roles, ["Client"]);

  if (!(isUserCostManager || isUserClient)) {
    return null;
  }

  const isActionable =
    project.project_phase === "COST_PLANNING" && isUserCostManager;

  const isLocked = !isActionable;
  const lockedReason = determineLockedReason({
    isLocked,
    isUserClient,
    project,
  });

  return (
    <PCRALogModalTrigger
      modalComponent={CostPlanningPCRALogModal}
      user={user}
      project={project}
      CPs={CPs}
      CP={CP}
      isLocked={isLocked}
      lockedReason={lockedReason}
    />
  );
}

function determineLockedReason({ isLocked, isUserClient, project }) {
  if (isUserClient) {
    return PCRA_MARKUP.LOCKED_REASONS.QA_LOG_LOCKED_FOR_CLIENT;
  }

  if (project.project_phase === "COST_PLANNING") {
    return PCRA_MARKUP.LOCKED_REASONS
      .PREV_COST_PLAN_RELEASE_LOCKED_SINCE_NEWER_RELEASE_UPLOADED;
  } else {
    return PCRA_MARKUP.LOCKED_REASONS
      .COST_PLANNING_QA_LOG_LOCKED_WHEN_PROJECT_PHASE_NOT_IN_COST_PLANNING;
  }
}

function determineSubLocation() {
  const URL = window.location.href;

  return URL.substring(URL.lastIndexOf("/") + 1);
}
