import { tranStr } from "../../utils/translation";

export function columns() {
  return [
    {
      type: "CHECK",
      key: "id",
      width: 50,
      isModal: false,
    },
    {
      heading: tranStr("Name"),
      key: "project_name",
      type: "DESCRIPTION",
      width: 150,
      isFiltered: true,
    },
    {
      heading: tranStr("CBS"),
      key: "standard",
      type: "STATUS",
      width: 50,
      isFiltered: true,
    },
    {
      heading: tranStr("Client"),
      key: "client",
      type: "STATUS",
      width: 150,
      isFiltered: true,
    },
    {
      heading: tranStr("Base Date"),
      key: "base_date",
      type: "DATE",
      width: 100,
    },
    {
      heading: tranStr("City"),
      key: "city",
      type: "STATUS",
      width: 90,
      isFiltered: true,
    },
    {
      heading: tranStr("TPI Location"),
      key: "tpi_city",
      type: "STATUS",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Local Region Area"),
      key: "local_region_area",
      type: "QUANTITY",
      width: 80,
    },
    {
      heading: tranStr("Construction Cost"),
      key: "construction_cost",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Construction Cost / Local Region Area"),
      key: "construction_cost_rate",
      type: "CURRENCY",
      width: 100,
    },
    {
      heading: tranStr("Project Quality"),
      key: "quality",
      type: "STATUS",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Procurement Method"),
      key: "procurement_method",
      type: "DESCRIPTION",
      width: 100,
      isFiltered: true,
    },
  ];
}
