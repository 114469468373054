import React from "react";

import "./TenderPriceIndexAdminDashboard.css";
import TenderPriceIndexAdminChart from "./TenderPriceIndexAdminChart";

import Checkboxes from "../checkboxes/Checkboxes";

export default function TenderPriceIndexAdminDashboard(props) {
  const { TPI } = props;
  const { maxYear, setMaxYear } = props;
  const { minYear, setMinYear } = props;
  const { selectedLocations, setLocations } = props;

  return (
    <div>
      <div className="tpi-admin-dashboard-container">
        <div className="tpi-admin-dashboard-controls">
          <div className="tpi-admin-dashboard-checkboxes">
            <Checkboxes
              array={TPI.locations}
              setFunction={setLocations}
              selection={selectedLocations}
            />
          </div>
          <MultiSlider
            TPI={TPI}
            maxYear={maxYear}
            setMaxYear={setMaxYear}
            minYear={minYear}
            setMinYear={setMinYear}
          />
        </div>
        <TenderPriceIndexAdminChart
          TPI={TPI}
          selectedLocations={selectedLocations}
          maxYear={maxYear}
          minYear={minYear}
        />
      </div>
    </div>
  );
}

function MultiSlider(props) {
  const { TPI } = props;
  const { minYear, setMinYear } = props;
  const { maxYear, setMaxYear } = props;

  return (
    <div className="tpi-admin-dashboard-slider-container">
      <div className="tpi-admin-dashboard-slider-row">
        <input
          className="tpi-admin-dashboard-slider"
          type="range"
          min={TPI.years[0]}
          max={TPI.years[TPI.years.length - 1]}
          value={minYear}
          onChange={(e) => {
            setMinYear(parseInt(e.target.value));
          }}
        />
        <div className="tpi-admin-dashboard-slider-label">
          <b>{minYear}</b>
        </div>
      </div>

      <div className="tpi-admin-dashboard-slider-row">
        <input
          className="tpi-admin-dashboard-slider"
          type="range"
          min={TPI.years[0]}
          max={TPI.years[TPI.years.length - 1]}
          value={maxYear}
          onChange={(e) => {
            setMaxYear(parseInt(e.target.value));
          }}
        />
        <div className="tpi-admin-dashboard-slider-label">
          <b>{maxYear}</b>
        </div>
      </div>
    </div>
  );
}
