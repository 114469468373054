import React, { useState, useEffect } from "react";
import { authHeader } from "../../_helpers/auth-header";
import isEmpty from "../../validation/is-empty";
import "./PostContractCommentary.css";
import PropTypes from "prop-types";

import Commentary from "./Commentary";
import { pc2APIs } from "../../api/api-endpoints/PostContract2.0Endpoints";
import { Notify } from "./ProgressClaimCommentary.components";

PostContractCommentary.propTypes = {
  projectID: PropTypes.string,
  commentary: PropTypes.array,
  postContract: PropTypes.object,
  postContractId: PropTypes.string,
};

export default function PostContractCommentary(props) {
  const { projectID } = props;
  const { commentary } = props;
  const { postContract } = props;
  const { postContractId } = props;

  const [error, setError] = useState({});

  const [fieldSet, setFieldSet] = useState([
    {
      comment: "4. The following heads of cost are excluded from this report:",
      type: "cost_report_exclusions",
    },
    {
      comment: "4.1. Demolition / Enabling Works ",
      type: "cost_report_exclusions",
    },
    {
      comment:
        "4.2. Professional Fees other than Novated Fees included within the Contract Sum and Contractor's directly appointed design consultants",
      type: "cost_report_exclusions",
    },
    {
      comment: "4.3. Development Management Fees",
      type: "cost_report_exclusions",
    },
    {
      comment: "4.4. Section 106, CIL and Crossrail Contribution",
      type: "cost_report_exclusions",
    },
    {
      comment: "4.5. Rights of Light fees and payments to adjoining owners",
      type: "cost_report_exclusions",
    },
    {
      comment: "4.6. Fees / Payments for other neighbourly matters",
      type: "cost_report_exclusions",
    },
    {
      comment: "4.7. Surveys / Monitoring",
      type: "cost_report_exclusions",
    },
    {
      comment: "4.8. Project Insurances procured directly by the Employer",
      type: "cost_report_exclusions",
    },
    {
      comment:
        "4.9. Cat A Contributions. Please note that no allowance has been included within either the construction costs or contributions for blinds",
      type: "cost_report_exclusions",
    },
    {
      comment: "4.10. Artwork / loose furniture",
      type: "cost_report_exclusions",
    },
    {
      comment: "4.11. Letting Agents and Letting Legal fees",
      type: "cost_report_exclusions",
    },
    {
      comment: "4.12. Developer's Contingency",
      type: "cost_report_exclusions",
    },
    {
      comment: "4.13. Loss and/or expense claims",
      type: "cost_report_exclusions",
    },
    {
      comment: "4.14. Liquidated Damages",
      type: "cost_report_exclusions",
    },
  ]);
  const mainHeading =
    "Add Exclusions below to be displayed on the Post Contract Dashboard.";
  const subHeading = "Post Contract Exclusions";

  useEffect(() => {
    //if there is data in db
    if (!isEmpty(commentary)) {
      //if there are any cost report commentary
      const exclusions = commentary.filter(
        (x) => x.type === "cost_report_exclusions"
      );

      if (!isEmpty(exclusions)) {
        //load only cost report commentary data from db
        setFieldSet(
          exclusions.map((c) => {
            return {
              comment: c.comment,
              type: c.type,
            };
          })
        );
      }
    }
  }, [commentary]);

  return (
    <div>
      <div className="post-contract-clarifications">
        <Commentary
          fields={fieldSet}
          setFields={setFieldSet}
          mainHeading={mainHeading}
          crType={"cost_report_exclusions"}
          subHeading={subHeading}
          commentary={commentary}
        />
      </div>
      <div className="general-row-container">
        <Notify error={error} setError={setError} />
      </div>
      <div className="general-button-container">
        <SaveButton />
      </div>
    </div>
  );
  function SaveButton() {
    return (
      <button className="general-upload-button" onClick={Save}>
        Save
      </button>
    );
  }

  //Save
  async function Save(e) {
    e.preventDefault();

    const comments = [];

    // Add the changed Exclusions
    fieldSet.forEach((f) => {
      f.post_contract_id = postContractId;
      if (!isEmpty(f.comment)) {
        comments.push(f);
      }
    });

    if (!isEmpty(commentary)) {
      // We need to add clarifications in as well since they share 1 endpoint
      // Otherwise they will be deleted when exclusions are updated
      commentary.forEach((x) => {
        if (x.type === "cost_report_clarifications") {
          comments.push(x);
        }
      });
    }

    const payload = {
      comments: comments,
      postContractID: postContractId,
      projectID: projectID,
      type: "cost_report",
    };
    const field = JSON.stringify(payload);
    const formData = new FormData();
    formData.append("field", field);

    // POST CONTRACT API
    const url = pc2APIs().save_commentary;

    setError({
      text: "Saving...",
      type: "feedback-success",
    });

    const config = {
      method: "POST",
      body: formData,
      headers: authHeader({
        authJson: false,
        authForm: true,
        guestJson: false,
      }),
    };
    //console.log(config)
    let response = await fetch(url, config);

    if (response.ok) {
      setError({
        text: "Saved Successfully",
        type: "feedback-success",
      });

      const URL = window.location.href;
      const sheetName = URL.substring(URL.lastIndexOf("/") + 1);
      postContract.set(
        await postContract.get(projectID, postContractId, sheetName)
      );
    } else {
      // Response Not OK
      console.log("Network Error");
    }
  }
}
