import React from "react";
import isEmpty from "../../validation/is-empty";
import EstimateBreakdownComments from "./EstimateBreakdownComments";

export default function CorrespondenceComments(props) {
  const { project, user, CPs, CP } = props;
  const { notificationsOnly } = props;

  if (isEmpty(CP)) {
    return <></>;
  }

  const estimates = CP.estimates;

  return (
    <>
      {estimates.map((estimate) => (
        <div
          key={`correspondence_${estimate.id}`}
          className="correspondence-estimate-container"
        >
          <div className="correspondence-estimate-title">
            {`Estimate Part ${estimate.part} - ${estimate.name}`}
          </div>

          <EstimateBreakdownComments
            user={user}
            project={project}
            CPs={CPs}
            estimate={estimate}
            notificationsOnly={notificationsOnly}
          />
        </div>
      ))}
    </>
  );
}
