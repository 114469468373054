import { authHeader } from "../../_helpers/auth-header";
import { pcraAPIs } from "../../api/api-endpoints/PCRAEndpoints";

const METHOD = {
  GET: "GET",
  POST: "POST",
};

function prepareConfig({ method, payload }) {
  const config = {
    method: method,
    body: JSON.stringify(payload),
    headers: authHeader({
      authJson: true,
      authForm: false,
      guestJson: false,
    }),
  };

  return config;
}

async function processResponse({ response, onSuccess }) {
  if (response.ok) {
    try {
      const result = await response.json();
      const { success, message, data } = result;

      if (success) {
        await onSuccess(data);
      } else {
        console.log(message);
      }
    } catch (e) {
      console.log(`An error occurred when processing audit log: ${e}`);
    }
  } else {
    console.log(`An error occurred when processing audit log.`);
  }
}

async function ReadAuditLog({ readInput, setPcraData }) {
  const url = pcraAPIs().read_audit_log;

  const payload = {
    ...readInput,
    timeRequested: new Date().toISOString(),
  };

  const config = prepareConfig({ method: METHOD.POST, payload });

  try {
    let response = await fetch(url, config);

    processResponse({
      response,
      onSuccess: async function (data) {
        data.loading = false;

        const id = readInput.ids[0];
        const pcraData = data.bundle[id];
        pcraData && (pcraData.isLocked ||= readInput.isLocked);
        await setPcraData(pcraData);
      },
    });
  } catch (e) {
    console.log(`An error occurred when reading audit log: ${e}`);
  }
}

async function UpdateAuditLog({ updateInput, setPcraData }) {
  const url = pcraAPIs().update_audit_log;

  const payload = {
    ...updateInput,
    timeRequested: new Date().toISOString(),
  };

  const config = prepareConfig({ method: METHOD.POST, payload });

  try {
    let response = await fetch(url, config);

    processResponse({
      response,
      onSuccess: async function (data) {
        data.loading = false;
        await setPcraData(data);
      },
    });
  } catch (e) {
    console.log(`An error occurred when updating audit log: ${e}`);
  }
}

const PCRALogAPI = {
  ReadAuditLog,
  UpdateAuditLog,
};

export default PCRALogAPI;
