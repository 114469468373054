// React
import React from "react";
import PropTypes from "prop-types";

import { TableFilterCell } from "./TableFiltersCells";

import "./TableFilters.css";

TableFilter.propTypes = {
  columns: PropTypes.array,
  filters: PropTypes.array,
  setFilters: PropTypes.func,
  tableArray: PropTypes.array,
  isFilterDisabled: PropTypes.bool,
};

export default function TableFilter(props) {
  const { columns } = props;
  const { filters, setFilters } = props;
  const { tableArray } = props;
  const { isFilterDisabled } = props;

  if (isFilterDisabled) {
    return null;
  }

  let headings = [];

  const data = {
    filters: filters,
    setFilters: setFilters,
    tableArray: tableArray,
  };

  columns.forEach((cell) => {
    headings.push(
      <TableFilterCell
        key={cell.key}
        cell={cell}
        data={data}
        width={cell.width}
      />
    );
  });

  return <div className="pct-filter-row">{headings}</div>;
}
