import React from "react";
import isEmpty from "../../validation/is-empty";
import { comma } from "../../helpers/common.functions";
import "./EstimatesTotal.css";

import Accordion from "../accordion/Accordion";

// Needs Refactoring
export default function EstimatesTotalAccordion(props) {
  const { project, CP } = props;
  const { isBreakdown } = props;
  const { selectedRow } = props;

  const { accordionState = false } = props;
  const { children } = props;

  if (isEmpty(CP) || isEmpty(CP.estimates)) {
    return null;
  }

  if (CP.estimates.length < 2) {
    return null;
  }

  if (isBreakdown) {
    if (selectedRow !== "breakdown") {
      return null;
    }
  }

  let label = CP.calculated_total_name,
    local_region_area = comma(CP.calculated_local_region_area_total),
    unit = project.unit_of_measure,
    rate = CP.calculated_total_rate,
    total = CP.calculated_total;

  return (
    <div
      className={`estimate-wrapper ${isBreakdown ? "breakdown" : "summary"}`}
    >
      <div className="estimate-flex-box">
        <Accordion
          estimateID={-1} // implies total row
          key={-1}
          label={label}
          local_region_area={local_region_area}
          unit={unit}
          rate={rate}
          total={total}
          // State
          accordionState={accordionState}
          accordionSection={"BREAKDOWN"}
        >
          <div className="estimate-breakdown-table-and-TPI-container">
            {children}
          </div>
        </Accordion>
      </div>
    </div>
  );
}
