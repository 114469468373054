import React, { useState, useEffect } from "react";
import Spinner from "../common/Spinner";
import PropTypes from "prop-types";

import {
  usePostContractForm,
  updatePostContractForm,
  updateFieldErrors,
} from "../../api/post-contract/PostContractFormAPI";

import "./PostContractForm.css";

import { postContractFormTable } from "./PostContractForm.functions";
import isEmpty from "../../validation/is-empty";

PostContractForm.propTypes = {
  projectId: PropTypes.string,
  packageId: PropTypes.string,

  urlEndpoint: PropTypes.string,
  postContractFormType: PropTypes.string,
  setModal: PropTypes.func,
  stateUpdateFunction: PropTypes.func,
};

export function PostContractForm(props) {
  const { projectId } = props;
  const { packageId } = props;

  const { urlEndpoint } = props;
  const { postContractFormType } = props;
  const { setModal } = props;
  const { stateUpdateFunction } = props;

  const { postContractForm } = usePostContractForm(
    postContractFormType,
    projectId,
    packageId
  );

  const [fields, setFields] = useState([]);
  const [errors, setErrors] = useState({});
  const [fieldErrors, setFieldErrors] = useState([]);

  useEffect(() => {
    const fields = [];
    postContractForm?.data?.[postContractFormType]?.forEach((field) => {
      // Set default dropdown value
      if (field.dataType === "dropdown" && field.value === "") {
        field.value = isEmpty(field.options[0]) ? "" : field.options[0].value;
      }

      fields.push(field);
    });

    setFields(fields);
  }, [postContractForm.data, postContractFormType]);

  if (postContractForm.loading) {
    return <Spinner />;
  }

  const display = [];

  fields.forEach((field) => {
    let textValue = fields.find((f) => f.id === field.id).value;

    if (textValue === null) {
      textValue = "";
    }

    if (field.dataType === "text") {
      display.push(
        <div className="general-row-container" key={field.id}>
          <div className="pcf-element">
            <div>
              <div className="pcf-element-item">{field.name}</div>
              <ValidationMessage field={field} fieldErrors={fieldErrors} />
            </div>

            <input
              id={"pcf-text-" + field.id}
              className="pcf-element-item"
              value={textValue}
              placeholder={field.placeholderText}
              onChange={(e) => {
                let temp = [...fields];
                temp.find((f) => f.id === field.id).value = e.target.value;
                setFields(temp);
              }}
            ></input>
          </div>
        </div>
      );
    }
    if (field.dataType === "date") {
      display.push(
        <div className="general-row-container" key={field.id}>
          <div className="pcf-element">
            <div>
              <div className="pcf-element-item">{field.name}</div>
              <ValidationMessage field={field} fieldErrors={fieldErrors} />
            </div>

            <input
              id={"pcf-date-" + field.id}
              type="date"
              className="pcf-date-item"
              value={textValue}
              placeholder={field.placeholderText}
              onChange={(e) => {
                let temp = [...fields];
                temp.find((f) => f.id === field.id).value = e.target.value;
                setFields(temp);
              }}
              required
            ></input>
          </div>
        </div>
      );
    }

    if (field.dataType === "dropdown") {
      display.push(
        <div className="general-row-container" key={field.id}>
          <div className="pcf-element">
            <div>
              <div className="pcf-element-item">{field.name}</div>
              <ValidationMessage field={field} fieldErrors={fieldErrors} />
            </div>
            <select
              id={"pcf-dropdown-" + field.id}
              className="pcf-element-item"
              value={fields.find((f) => f.id === field.id).value}
              onChange={(e) => {
                let temp = [...fields];
                temp.find((f) => f.id === field.id).value = e.target.value;
                setFields(temp);
              }}
            >
              {field?.options?.map((option) => {
                return (
                  <option key={option.id} value={option.value}>
                    {option.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      );
    }

    if (field.dataType === "file") {
      display.push(
        <div key={field.id}>
          <div className="general-row-container">
            <div className="pcu-file-display">
              <b>File:</b>{" "}
              {fields.find((f) => f.id === field.id).value?.name ||
                "No File Added"}
            </div>
          </div>
          <div>
            <ValidationMessage field={field} fieldErrors={fieldErrors} />
          </div>
          <div className="general-row-container">
            <label className="general-modal-button">
              <div>
                <input
                  type="file"
                  onChange={(e) => {
                    if (e.target.files && e.target.files.length > 0) {
                      let temp = [...fields];
                      temp.find((f) => f.id === field.id).value =
                        e.target.files[0];
                      setFields(temp);
                    }
                  }}
                />
              </div>
              Attach File
            </label>
          </div>
        </div>
      );
    }

    if (field.dataType === "checkbox") {
      let checkboxValue = false;
      if (
        field.value === "true" ||
        field.value === "True" ||
        field.value === true
      ) {
        checkboxValue = true;
      }
      if (
        field.value === "false" ||
        field.value === "False" ||
        field.value === false
      ) {
        checkboxValue = false;
      }
      display.push(
        <label key={field.id} className="checkboxes-vertical-container">
          <div className="checkbox-label">{field.name}</div>
          <input
            id={"pcf-checkbox-" + field.id}
            type="checkbox"
            name={field.name}
            value={field.name}
            checked={checkboxValue}
            onChange={(e) => {
              const checked = e.target.checked;

              let temp = [...fields];
              temp.find((f) => f.id === field.id).value = checked;
              setFields(temp);
            }}
          />
          <span className="checkmark"></span>
        </label>
      );
    }

    if (field.dataType === "table") {
      postContractFormTable(field, fields, setFields, display);
    }
  });

  return (
    <div className="pcf-container">
      {display}
      <div className="general-row-container">
        <div className="pcu-error-container">
          <div className={errors.type}>
            <b>{errors.text}</b>
          </div>
        </div>
      </div>

      <div className="general-row-container">
        <button
          id={"pcf-table-save-button"}
          className="general-upload-button"
          onClick={() => {
            updateFieldErrors(fields, setFieldErrors);

            const data = {
              fields,
              setErrors,
              urlEndpoint,
              setModal,
              stateUpdateFunction,
              setFieldErrors,
            };

            updatePostContractForm(data);
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
}

ValidationMessage.propTypes = {
  field: PropTypes.object,
  fieldErrors: PropTypes.array,
};
function ValidationMessage(props) {
  const { field, fieldErrors } = props;
  if (field.isMandatory === true && fieldErrors.includes(field.name)) {
    return (
      <div className="pcf-validation-message">{field.name} is Required</div>
    );
  } else {
    return null;
  }
}
