import isEmpty from "../validation/is-empty";

// Escalate Costs based on the locality factors
export function escalateTfi(escalationData, benchmark, rate) {
  const { localityData } = escalationData;
  const { LFI } = localityData;
  const { projectCity } = localityData;
  const { is_locality_factor_active } = localityData; // Cost Plan Locality Factor Override Toggle
  const { locality_factor } = localityData; // Cost Plan Locality Factor Override Value
  const { isGlobalBenchmarking } = localityData;

  if (isEmpty(LFI)) {
    return rate;
  }

  let benchmarkLocation = LFI.filter((x) => x.city === benchmark.city)[0];
  let projectLocation = LFI.filter((x) => x.city === projectCity)[0];

  let pFactor;
  let bFactor;
  let text;

  // Cost Plan Benchmarking uses Local Factors Only
  if (!isGlobalBenchmarking) {
    pFactor = projectLocation?.factor;
    // Cost Plan Locality Factor Override
    if (is_locality_factor_active) {
      pFactor = locality_factor;
    }
    bFactor = benchmarkLocation?.factor;
    text = "Locality factor cannot be found for this project.";
  }

  // Global Benchmarking uses Global Factor
  if (isGlobalBenchmarking) {
    pFactor = projectLocation?.globalfactor;
    bFactor = benchmarkLocation?.globalfactor;
    text = "Global factor cannot be found for this project's city.";
  }

  return escalateLocality(rate, benchmark, pFactor, bFactor, text);
}

function escalateLocality(rate, bm, projFactor, benchFactor, text) {
  let escalatedRate = rate;
  let escalatedPercent;

  if (isEmpty(benchFactor) || isEmpty(projFactor)) {
    bm.locationWarning = true;
    bm.locationWarningText = text;

    return { lfiRate: rate, lfiPercent: 0 };
  }

  if (!isEmpty(benchFactor) && !isEmpty(projFactor) && benchFactor !== 0) {
    escalatedRate = Math.round((projFactor / benchFactor) * rate);
    escalatedPercent = (projFactor / benchFactor) * 100 - 100;
    bm.locationWarning = false;
    bm.locationWarningText = "";
  }

  if (isNaN(escalatedRate)) {
    return { lfiRate: rate, lfiPercent: 0 };
  }

  return { lfiRate: escalatedRate, lfiPercent: escalatedPercent };
}
