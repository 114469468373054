import { tranStr } from "../../utils/translation";
import PropTypes from "prop-types";

export function columns(isLocalRegionFactorEnabled) {
  return [
    {
      heading: tranStr("Project Name"),
      key: "project_name_short",
      type: "DESCRIPTION",
      width: 200,
      isFiltered: true,
    },
    {
      heading:  getColumnTitles(isLocalRegionFactorEnabled),
      key: "calculated_construction_cost_rate",
      type: "NUMBER",
      width: 100,
    },
    {
      heading: tranStr("Currency %"),
      key: "percent_fer_escalated_calculated_construction_cost_rate",
      type: "IMAGE",
      width: 100,
      customComponent: (value) => {
        return <CostUpDownIndicators value={value} />;
      },
    },
    {
      heading: tranStr("Converted Currency Cost"),
      key: "exchanged_calculated_construction_cost_rate",
      type: "NUMBER",
      width: 100,
    },
    {
      heading: tranStr("Locality %"),
      key: "percent_lfi_escalated_calculated_construction_cost_rate",
      type: "IMAGE",
      width: 100,
      customComponent: (value) => {
        return <CostUpDownIndicators value={value} />;
      },
    },
    {
      heading: tranStr("Refactored Local Cost"),
      key: "locality_escalated_calculated_construction_cost_rate",
      type: "NUMBER",
      width: 100,
    },
    {
      heading: tranStr("Inflation %"),
      key: "percent_tpi_escalated_calculated_construction_cost_rate",
      type: "IMAGE",
      width: 100,
      customComponent: (value) => {
        return <CostUpDownIndicators value={value} />;
      },
    },
    {
      heading: tranStr("Escalated Cost"),
      key: "escalated_calculated_construction_cost_rate",
      type: "NUMBER",
      width: 100,
    },
  ];
}

function getColumnTitles(isLocalRegionFactorEnabled) {
  return isLocalRegionFactorEnabled
    ? tranStr("Cost/Local Region Area ") + " @ " + tranStr("Estimate Date")
    : tranStr("Construction Cost") + " @ " + tranStr("Estimate Date");
}
CostUpDownIndicators.propTypes = {
  value: PropTypes.number,
 
};
function CostUpDownIndicators(props) {
  const { value } = props;
  let arrowClass = "";
  if (value > 0) arrowClass = "fas fa-caret-up";
  if (value < 0) arrowClass = "fas fa-caret-down";
  return (
    <div>
      <i className={arrowClass}></i>
      {parseFloat(value).toFixed(2)}%
    </div>
  );
}
