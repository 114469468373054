import { cpAPIs } from "../api-endpoints/CostPlanningEndpoints";
import { tranStr } from "../../utils/translation";
import isEmpty from "../../validation/is-empty";
import { getDmsToken } from "../../active-directory/getDmsToken";
import { authHeader } from "../../_helpers/auth-header";
import { AutoCloseModal } from "../../components/modal/Modal";

export async function deleteProjectDrawing(
  drawing_id,
  project_id,
  cost_plan_id
) {
  // COST PLAN API
  const url = cpAPIs().delete_project_drawing;
  const payload = JSON.stringify({
    project_id: project_id,
    drawing_id: drawing_id,
    cost_plan_id: cost_plan_id,
  });

  const dmsToken = await getDmsToken();
  let dmsBearer = "";
  if (!isEmpty(dmsToken)) {
    dmsBearer = dmsToken.accessToken;
  }

  const config = {
    method: "POST",
    body: payload,
    headers: {
      ...authHeader({ authJson: true, authForm: false, guestJson: false }),
      dmsBearer: `Bearer ${dmsBearer}`,
    },
  };

  let erMessage = "Delete Project Drawing Error";
  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
  } else {
    console.log(erMessage);
  }
}

export async function uploadDrawing(data) {
  if (data.file === null) {
    return data.setError({
      text: tranStr("Please attach file"),
      type: "feedback-error",
    });
  }

  data.setError({
    text: tranStr("Creating..."),
    type: "feedback-success",
  });

  let field = JSON.stringify({
    projectID: data.projectID,
    cost_plan_id: data.CP.cost_plan_id,
    stage: data.stage,
    revision: data.revision,
  });

  const formData = new FormData();
  formData.append("file", data.file);
  formData.append("field", field);

  // COST PLAN API
  const url = cpAPIs().upload_project_drawings;

  const dmsToken = await getDmsToken();
  let dmsBearer = "";
  if (!isEmpty(dmsToken)) {
    dmsBearer = dmsToken.accessToken;
  }

  const config = {
    method: "POST",
    body: formData,
    headers: {
      ...authHeader({ authJson: false, authForm: true, guestJson: false }),
      dmsBearer: `Bearer ${dmsBearer}`,
    },
  };

  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
    data.CPs.set(await data.CPs.get(data.projectID, data.project));

    data.setError({
      text: tranStr("Created Successfully"),
      type: "feedback-success",
    });

    // close modal after timeout
    AutoCloseModal(data.setShow);
  } else {
    return data.setError({
      text: tranStr("Connection Error"),
      type: "feedback-error",
    });
  }
}
