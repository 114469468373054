export function TableHeader(props) {
  const { TPI } = props;
  const { selectedLocations } = props;

  if (selectedLocations.length < 1) {
    return null;
  }

  let array = [...TPI.locations];
  array.unshift("");
  let display = [];
  let count = 0;
  array.forEach((location, i) => {
    if (i === 0) {
      display.push(
        <div key={count} className="tpi-admin-table-cell1-header" />
      );
    } else {
      if (selectedLocations.some((x) => x === location)) {
        display.push(
          <div key={count} className="tpi-admin-table-header-cell">
            <b>{location}</b>
          </div>
        );
      } else {
        display.push(null);
      }
    }
    count++;
  });
  return display;
}

export function TableSubHeader(props) {
  const { TPI } = props;
  const { selectedLocations } = props;

  if (selectedLocations.length < 1) {
    return null;
  }

  let count = 0;
  let display = [];
  TPI.subHeadings.forEach((e, i) => {
    if (selectedLocations.some((x) => x === e.location) || e.location === "*") {
      display.push(
        <div key={count} className="tpi-admin-table-cell1">
          <b>{e.value}</b>
        </div>
      );
    } else {
      display.push(null);
    }
    count++;
  });
  return display;
}

export function Table(props) {
  const { TPI } = props;
  const { maxYear } = props;
  const { minYear } = props;
  const { selectedLocations } = props;

  return TPI.fullTable.map((row, j) => {
    // Determine Row Type
    let rowType = "tpi-admin-table-row1";
    if (j % 2 === 0) {
      rowType = "tpi-admin-table-row2";
    }

    // Only return selected years
    if (checkYear(row, minYear, maxYear)) {
      return null;
    }

    return rowItem(selectedLocations, row, rowType, j);
  });
}

function rowItem(selectedLocations, row, rowType, j) {
  let cellNumber = 1;
  return (
    <div key={j} className={rowType}>
      {row.map((item, i) => {
        // Match Value to the location
        if (checkLocation(selectedLocations, item)) {
          // Determine Cell Type
          if (cellNumber === 4) {
            cellNumber = 1;
          }

          let cellType = determineCellType(i);

          if (cellNumber === 2 || cellNumber === 3) {
            if (i !== 0) {
              cellNumber++;
            }
            return cell(i, cellType, item, false);
          }

          if (cellNumber === 1) {
            if (i !== 0) {
              cellNumber++;
            }
            return cell(i, cellType, item, true);
          }
          return null;
        } else {
          return null;
        }
      })}
    </div>
  );
}

function determineCellType(i) {
  let cellType = "tpi-admin-table-cell1";
  if (i === 0) {
    cellType = "tpi-admin-table-cell2";
  }

  return cellType;
}

function cell(i, cellType, item, bold) {
  if (bold) {
    return (
      <div key={i} className={cellType}>
        <b>{item.value}</b>
      </div>
    );
  } else {
    return (
      <div key={i} className={cellType}>
        {item.value}
      </div>
    );
  }
}

function checkLocation(selectedLocations, item) {
  return selectedLocations.some(
    (x) => x === item.location || item.location === "*"
  );
}

function checkYear(row, minYear, maxYear) {
  return row[0].value < minYear || row[0].value > maxYear;
}
