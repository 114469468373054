import React from "react";
import generateXLSX from "../../utils/spreadsheets";
// API
export default function ExportSystemDashboard(props) {
  const { projects } = props;
  const { inputMode } = props;
  const headings = [];
  headings.push("Project ID");
  headings.push("Job No.");
  headings.push("Project Name");
  if (inputMode === "COSTPLAN") {
    headings.push("No. of Cost Plans");
  }
  if (inputMode === "COSTREPORT") {
    headings.push("No. of Cost Reports");
  }
  headings.push("Project Region");
  headings.push("Project Country");
  headings.push("Project City");
  headings.push("Project Sector");
  headings.push("Project Sub Sector");

  const data = [];

  projects.forEach((r) => {
    const proj = [];
    proj.push(r.id);
    proj.push(r.job_number);
    proj.push(r.title);
    if (inputMode === "COSTPLAN") {
      proj.push(r.CostPlanNumbers);
    }
    if (inputMode === "COSTREPORT") {
      proj.push(r.total_cost_reports);
    }
    proj.push(r.region);
    proj.push(r.country);
    proj.push(r.city);
    proj.push(r.sector);
    proj.push(r.sub_sector);
    data.push(proj);
  });
  data.unshift(headings);

  return (
    <div className="alc-container">
      <div className="alc-column">
        <div className="general-row-container"></div>
        <div className="general-row-container">
          <button
            className="general-upload-button"
            onClick={() => {
              if (inputMode === "COSTPLAN") {
                generateXLSX("Project Cost Plan Details", data);
              }
              if (inputMode === "COSTREPORT") {
                generateXLSX("Project Cost Report Details", data);
              }
            }}
          >
            Export
          </button>
        </div>
      </div>
    </div>
  );
}
