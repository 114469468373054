import React from "react";

import isEmpty from "../../validation/is-empty";
import { translate } from "../../utils/translation";
import "./RisksAdvice.css";

export default function RisksAdvice(props) {
  const { risks } = props;
  const { isPortfolio } = props;

  if (isEmpty(risks)) {
    return null;
  }

  let displayAreaType = "risks-advice-display-area";
  let adviceLabel = "risks-advice-label";
  if (isPortfolio) {
    displayAreaType = "risks-advice-display-area-portfolio";
    adviceLabel = "risks-advice-label-portfolio";
  }

  return (
    <div className="risks-advice-container">
      <div className={displayAreaType}>
        <div className="risks-advice-table">
          {risks.map((r, i) => {
            let urgencyType = "risks-advice-urgency-low";

            if (r.urgency === "High") {
              urgencyType = "risks-advice-urgency-high";
            }

            if (r.urgency === "Medium") {
              urgencyType = "risks-advice-urgency-medium";
            }

            if (r.urgency === "Low") {
              urgencyType = "risks-advice-urgency-low";
            }

            if (r.projectTitle) {
              return (
                <div className="risks-advice-row" key={i}>
                  <div className="risks-advice-bullet-blank" />
                  <div className="risks-advice-label-project-portfolio">
                    <b>{r.risk_opportunity}</b>
                  </div>
                  {r.urgency === "" ? null : (
                    <div className="risks-advice-level"></div>
                  )}
                </div>
              );
            }

            return (
              <div className="risks-advice-row" key={i}>
                <div className="risks-advice-bullet" />
                <div className={adviceLabel}>{r.risk_opportunity}</div>
                {r.urgency === "" ? null : (
                  <div className="risks-advice-level">
                    <div className={urgencyType}>{translate(r.urgency)}</div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
