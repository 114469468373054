import React from "react";
import Pagination from "react-js-pagination";

import "./TranslationsPagination.css";

const TranslationsPagination = (props) => {
  const { activePage } = props;
  const { setActivePage } = props;
  const { tempLanguage } = props;

  //get total number of items after filter
  const totalItems = Object.keys(tempLanguage).length;

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
  }

  return (
    <div className="portfolio-page-control">
      <Pagination
        activePage={activePage}
        itemsCountPerPage={15}
        totalItemsCount={totalItems}
        pageRangeDisplayed={3}
        onChange={handlePageChange}
        firstPageText={<i className="fas fa-angle-double-left"></i>}
        prevPageText={<i className="fas fa-chevron-left"></i>}
        nextPageText={<i className="fas fa-chevron-right"></i>}
        lastPageText={<i className="fas fa-angle-double-right"></i>}
        itemClassFirst="tab-arrow-left"
        itemClassPrev="tab-arrow-left"
        itemClassNext="tab-arrow-right"
        itemClassLast="tab-arrow-right"
        itemClass="tab"
        activeClass="tab-selected"
        disabledClass="tab-arrow-inactive"
      />
    </div>
  );
};

TranslationsPagination.propTypes = {};

export default TranslationsPagination;
