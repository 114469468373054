export function Heading(props) {
  const { text } = props;
  const { fontSize } = props;
  return (
    <h1 className="display-4" style={{ fontSize: fontSize }}>
      {text}
    </h1>
  );
}

export function DisplayError(props) {
  const { error } = props;
  return (
    <div className="feedback-error-container">
      <div className={error.type}>
        <b>{error.text}</b>
      </div>
    </div>
  );
}
