import React from "react";
import { Radar } from "react-chartjs-2";
import ChartCard from "./ChartCard";
import { getCSSColorPalette } from "../../../actions/cssColors";

export default function OptioneeringRadarChart(props) {
  const { optionsAnalysisItems } = props;

  const labels = [
    "Cost Impact",
    "Programme Impact",
    "Environmental Impact",
    "Operational Cost Impact",
  ];

  const CSS_COLORS = getCSSColorPalette();

  // Function to map the dataset index to a specific color
  const getColorForDataset = (index) => {
    const colors = [
      CSS_COLORS["--heritage"],
      CSS_COLORS["--natural1"],
      CSS_COLORS["--built1"],
      CSS_COLORS["--fluidity1"],
      CSS_COLORS["--strength1"],
      CSS_COLORS["--growth1"],
      CSS_COLORS["--collaboration1"],
    ];
    return colors[index % colors.length];
  };

  const datasets = optionsAnalysisItems.map((item, index) => ({
    label: item.summary, // dataset label
    data: [
      parseFloat(item.costImpactRating),
      parseFloat(item.programmeImpactRating),
      parseFloat(item.environmentalImpact),
      parseFloat(item.operationalCostImpactRating),
    ],
    borderColor: getColorForDataset(index),
    borderWidth: 2,
  }));

  const data = {
    labels: labels,
    datasets: datasets,
  };

  const size = 401;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    width: size,
    height: size,
    scale: {
      ticks: { beginAtZero: true },
      pointLabels: {
        fontSize: 10,
      },
    },
    legend: {
      display: true,
      position: "right",
    },
  };

  return (
    <ChartCard labelClassName="lower-label">
      <div>
        <div style={{ width: `${750}px`, height: `${size}px` }}>
          <RadarChart data={data} options={options} />
        </div>
      </div>
    </ChartCard>
  );
}

function RadarChart(props) {
  const { data } = props;
  const { options } = props;

  return <Radar data={data} options={options} />;
}
