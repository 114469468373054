import { tranStr } from "../../../utils/translation";
import { authHeader } from "../../../_helpers/auth-header";
import { sharedAPIs } from "../../../api/api-endpoints/SharedServiceEndpoints";

export async function saveCurrencyExchanges(data) {
  const { currencyExchange } = data;
  if (data.fields === null) {
    return data.setError({
      text: tranStr("Please enter currency exchange rate"),
      type: "feedback-error",
    });
  }

  data.setError({
    text: tranStr("Saving..."),
    type: "feedback-success",
  });

  const payload = JSON.stringify({ currency_exchange: data.fields });

  const url = sharedAPIs().save_currency_exchange;
  const config = {
    method: "POST",
    body: payload,
    headers: authHeader({
      authJson: true,
      authForm: false,
      guestJson: false,
    }),
  };

  let response = await fetch(url, config);
  if (response.ok) {
    currencyExchange.set(await currencyExchange.get());
    // Response OK
    return data.setError({
      text: "Saved Successfully",
      type: "feedback-success",
    });
  } else {
    // Response Not OK
    return data.setError({
      text: "Save Failed",
      type: "feedback-error",
    });
  }
}
