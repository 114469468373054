import React from "react";

import "./CategoryProfile.css";
import isEmpty from "../../validation/is-empty";

export default function CategoryProfile(props) {
  const { selectedTopic } = props;

  let subLocation = determineSubLocation();

  if (subLocation !== "articles") {
    return null;
  }

  if (isEmpty(selectedTopic)) {
    return null;
  }

  return (
    <div className="category-profile">
      <div
        className="article-profile-image"
        style={{
          backgroundImage: `url(${selectedTopic.image})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="article-profile-image-banner">
          <h1 className="display-4" style={{ fontSize: "20px" }}>
            {selectedTopic.title}
          </h1>
        </div>
      </div>
    </div>
  );
}

function determineSubLocation() {
  const URL = window.location.href;

  return URL.substring(URL.lastIndexOf("/") + 1);
}
