import { cpAPIs } from "../../api/api-endpoints/CostPlanningEndpoints";
import { authHeader } from "../../_helpers/auth-header";

export async function saveCashflowSettings(payload, setError) {
  let fields = {
    // IDs
    projectID: payload.projectID,
    costPlanID: payload.costPlanId,

    // Cashflow Data
    cashflowBasis: payload.cashflowBasis,
    estimateID: payload.estimateID,
    selectedMonth: payload.selectedMonth,
    selectedYear: payload.selectedYear,
    standardDeviation: payload.standardDeviation,
    program: payload.program,
  };

  const config = {
    method: "POST",
    body: JSON.stringify(fields),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  setError({
    text: "Saving...",
    type: "cashflow-feedback-success",
  });

  const url = cpAPIs().save_cashflow;
  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
  } else {
    setError({
      text: "Save Failed",
      type: "cashflow-feedback-error",
    });
    return console.log("Network response was not ok.");
  }
  setError({
    text: "Saved Successfully",
    type: "cashflow-feedback-success",
  });

  payload.CPs.set(await payload.CPs.get(payload.projectID, payload.project));
}

export function getCost(estimates) {
  // Determine Cost
  let totalCost = 0;

  estimates.forEach((e) => {
    if (e.is_included) {
      totalCost += e.construction_cost + e.escalationAllowance;
    }
  });

  return totalCost;
}
