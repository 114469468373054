import { useEffect } from "react";
import isEmpty from "../../validation/is-empty";

export function useSelector(
  CPs,
  CP,
  setCP,
  setComparedCP,
  setComparedCPR,
  activeStage,
  setActiveStage
) {
  // On first load set the default CP
  useEffect(() => {
    // Only set a default if none active
    if (isEmpty(CP)) {
      if (!isEmpty(CPs.data)) {
        CPs.data.forEach((CPStage, i) => {
          if (!isEmpty(CPStage.versions)) {
            setActiveStage(CPStage.stage);
            if (i > 0) {
              // Set comparison to previous cost plan first revision
              setComparedCP(CPs.data[i - 1].versions[0]);
            }
          }
        });
      }
    }
  }, [CP, CPs.data, setCP, setComparedCP, setActiveStage]);

  // On a stage update set the selected CP
  useEffect(() => {
    let selectCP = {};
    let previousCPR = {};
    if (!isEmpty(CPs.data)) {
      CPs.data.forEach((CPStage) => {
        if (CPStage.stage === activeStage) {
          // Only set the most recent default if it exists!
          if (CPStage.versions[0]) {
            selectCP = CPStage.versions[0];
            previousCPR = CPStage.versions[1];
          }
        }
      });
    }

    setCP(selectCP);
    setComparedCPR(previousCPR);
  }, [CPs.data, setCP, setComparedCPR, activeStage]);
}
