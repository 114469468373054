import isEmpty from "../../validation/is-empty";
import { useState, useEffect } from "react";
import {
  estimateBreakupsByStages,
  estimateBreakupsByRevisions,
} from "../charts/CostPlanComparisonChart.functions";
import {
  designDevelopmentProgressionByStages,
  designDevelopmentProgressionByRevisions,
} from "../charts/DesignDevelopmentProgression.functions";

import { tranStr } from "../../utils/translation";

export function useReconciliationSwitch(CPs, CP, comparedCP, comparedCPR) {
  const [reconciliationType, setReconciliationType] = useState("STAGE");
  const [comparisonCP, setComparisonCP] = useState({});
  const [error, setError] = useState("");
  const [costPlanComparison, setCostPlanComparison] = useState({});
  const [designDevelopmentProgress, setDesignDevelopmentProgress] = useState(
    {}
  );

  useEffect(() => {
    if (reconciliationType === "STAGE") {
      setComparisonCP(comparedCP);
      setError(
        tranStr(
          "Stage comparison is available when there is at least one previous Cost Plan Stage."
        )
      );

      setCostPlanComparison(estimateBreakupsByStages(CPs, CP));
      setDesignDevelopmentProgress(
        designDevelopmentProgressionByStages(CP, CPs)
      );
    }

    if (reconciliationType === "RELEASE") {
      setComparisonCP(comparedCPR);
      setError(
        tranStr(
          "Release comparison is available when there is at least one previous Cost Plan Release."
        )
      );

      setCostPlanComparison(estimateBreakupsByRevisions(CPs, CP));
      setDesignDevelopmentProgress(
        designDevelopmentProgressionByRevisions(CP, CPs)
      );
    }
  }, [CP, CPs, reconciliationType, comparedCP, comparedCPR]);

  let estimateDeltas = [];
  if (!isEmpty(comparisonCP)) {
    // Calculate the total deltas between both Cost Plans
    CP.calculated_total_delta =
      CP.calculated_total - comparisonCP.calculated_total;

    // Calculate the deltas between the two selections
    estimateDeltas = generateDeltas(CP.estimates, comparisonCP.estimates);
  }

  return {
    reconciliationData: {
      error: error,
      comparisonCP: comparisonCP,
      setComparisonCP: setComparisonCP,
      reconciliationType: reconciliationType,
      setReconciliationType: setReconciliationType,
      costPlanComparison: costPlanComparison,
      estimateDeltas: estimateDeltas,
      designDevelopmentProgress: designDevelopmentProgress,
    },
  };
}

export function generateDeltas(CP, comparedCP) {
  const estimateDeltas = [];

  // Each estimate
  CP.forEach((e, i) => {
    // The comparison Estimate is taken from the same iterable i
    const CE = comparedCP[i];
    let CERows = [];
    let CEEscalationAllowance = 0;

    // If there is a matching Estimate Part
    if (!isEmpty(CE)) {
      // Get compared estimate data
      CERows = comparedCP[i].rows;
      CEEscalationAllowance = CE.escalationAllowance;
    }

    // Remove subtotals and totals
    const filteredRows = filterRows(e.rows.concat(CERows));
    // Extract a unique list of row codes between the two parts
    const uniqueRows = uniqueValuesFromObjectsArray(filteredRows);
    const rowDeltas = [];

    // Compose the ROW deltas
    processRow(uniqueRows, e, CERows, rowDeltas, CEEscalationAllowance);

    // Compose the ESTIMATE deltas
    processEstimate(estimateDeltas, e, CE, rowDeltas);
  });

  return estimateDeltas;
}

function processEstimate(estimateDeltas, estimate, comparison, rowDeltas) {
  const e = estimate;
  const CE = comparison;

  const totalDelta = calculateTotalDelta(e, CE);

  // Cost Change (Z) is the difference between totals (Y) between A and B = Y
  // Minus the delta in contingency (X) between A and B = X
  // Y - X = Z
  const currentDesignContingency = e.design_contingency;
  const comparisonDesignContingency = CE ? CE.design_contingency : 0;
  const contingencyDelta =
    currentDesignContingency - comparisonDesignContingency;
  const costChange = totalDelta - contingencyDelta;
  // Compose the ESTIMATE
  estimateDeltas.push({
    id: e.id,
    project_id: e.project_id,
    gfa: e.local_region_area,
    local_region_area: e.local_region_area,
    subtotal_below: e.subtotal_below,
    subtotal_name: e.subtotal_name,
    subtotal_gfa_override: e.subtotal_gfa_override,
    escalationAllowance: e.escalationAllowance,
    project_cost: e.project_cost,
    construction_cost: e.construction_cost,
    calculated_total: e.calculated_total,
    stage: e.stage,
    revision: e.revision,
    stage_name: e.stage_name,
    stage_code: e.stage_code,
    name: e.name,
    part: e.part,
    is_included: e.is_included,
    changes: e.changes,
    designContingency: e.design_contingency,
    deltas: rowDeltas,

    // Comparison Estimate set to 0 if undefined and it will update when set
    comparison_name: CE ? CE.name : "",
    comparison_stage_name: CE ? CE.stage_name : "",
    comparison_stage: CE ? CE.stage : "",
    comparison_revision: CE ? CE.revision : "",
    comparison_design_contingency: CE ? CE.design_contingency : 0,
    comparisonEscalationAllowance: CE ? CE.escalationAllowance : 0,
    comparison_construction_cost: CE ? CE.construction_cost : 0,
    comparison_project_cost: CE ? CE.project_cost : 0,
    comparison_calculated_total: CE ? CE.calculated_total : 0,

    totalDelta: totalDelta,
    costChange: costChange,
  });
}

function processCurrentRow(currentRow) {
  const id = currentRow ? currentRow.id : null;
  const code = currentRow ? currentRow.code : null;
  const subtotal = currentRow ? Math.round(currentRow.subtotal) : 0;
  const cost_category = currentRow ? currentRow.cost_category : null;
  const currentSubtotal = currentRow ? currentRow.subtotal : 0;

  return {
    id,
    code,
    subtotal,
    cost_category,
    currentSubtotal,
  };
}

function processPreviousRow(previousRow) {
  // Previous Row
  const comparison_id = previousRow ? previousRow.id : null;
  const previousSubtotal = previousRow ? Math.round(previousRow.subtotal) : 0;

  return {
    comparison_id,
    previousSubtotal,
  };
}

function processBothRows(
  currentRow,
  previousRow,
  currentSubtotal,
  previousSubtotal
) {
  const description = currentRow
    ? currentRow.description
    : previousRow.description;
  const delta = Math.round(currentSubtotal - previousSubtotal);

  return {
    description,
    delta,
  };
}

function processRow(uniqueRows, e, CERows, rowDeltas, CEEscalationAllowance) {
  uniqueRows.forEach((row) => {
    // Find the rows to compare
    const currentRow = e.rows.find((r) => r.description === row.description);
    const previousRow = CERows.find((r) => r.description === row.description);

    // Create Comparison

    // Current Row
    const { id, code, subtotal, cost_category, currentSubtotal } =
      processCurrentRow(currentRow);

    // Previous Row
    const { comparison_id, previousSubtotal } = processPreviousRow(previousRow);

    // Both Rows
    const { description, delta } = processBothRows(
      currentRow,
      previousRow,
      currentSubtotal,
      previousSubtotal
    );

    const rowDelta = {
      id: id,
      estimate_id: e.id,
      project_id: e.project_id,

      code: code,
      comparison_id: comparison_id,
      description: description,
      cost_category: cost_category,
      subtotal: subtotal,
      previousSubtotal: previousSubtotal,
      delta: delta,
    };

    // Only return values that have changed
    if (subtotal !== 0 || previousSubtotal !== 0) {
      rowDeltas.push(rowDelta);
    }
  });

  // Add a row for escalation
  if (e.escalationAllowance > 0 || CEEscalationAllowance > 0) {
    rowDeltas.push({
      id: "Escalation" + e.id,
      code: "Escalation",
      comparison_id: "Escalation" + e.id,
      description: "Tender Price Inflation",
      subtotal: Math.round(e.escalationAllowance),
      cost_category: "Escalation",
      delta: Math.round(e.escalationAllowance - CEEscalationAllowance),
      previousSubtotal: Math.round(CEEscalationAllowance),
      estimate_id: e.id,
      project_id: e.project_id,
    });
  }
}

function calculateTotalDelta(thisEstimate, comparisonEstimate) {
  let delta = 0;
  let total = thisEstimate.calculated_total;
  let comparisonTotal = comparisonEstimate
    ? comparisonEstimate.calculated_total
    : 0;
  delta = total - comparisonTotal;
  return delta;
}

function filterRows(array) {
  return array.filter((s) =>
    s.code !== "st" && s.code !== "cc" && s.code !== "pc" && s.code !== "pct"
      ? s
      : null
  );
}

function uniqueValuesFromObjectsArray(array) {
  return Array.from(new Set(array.map((s) => s.description))).map(
    (description) => {
      return {
        description: array.find((s) => s.description === description)
          .description,
      };
    }
  );
}
