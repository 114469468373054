import isEmpty from "../../validation/is-empty";
import { comma, commaToFixed } from "../../helpers/common.functions";
import { prepareElementalAnalysisTable } from "./BenchmarkElementAnalysisView";
import { prepareProjectInfoTable } from "./BenchmarkProjectInfoView";
import { prepareBenchmarkMetricsTable } from "./BenchmarkMetricsView";

export function filterBenchmarks(benchmarking, filter) {
  if (isEmpty(benchmarking)) {
    return [];
  }

  return benchmarking.filter((b) => {
    return benchmarkFilterCondition(b, filter);
  });
}

export function benchmarkFilterCondition(b, filter) {
  // Project Fields
  let number = "";
  if (!isEmpty(b.project_number)) {
    number = b.project_number.toString().toLowerCase();
  }
  let name = b.project_name ? b.project_name.toLowerCase() : "";
  let city = b.city ? b.city.toLowerCase() : "";
  let region = b.region ? b.region.toLowerCase() : "";
  let sector = b.sector ? b.sector.toLowerCase() : "";
  let date = Date.parse(b.base_date);

  // If there is an error with the date, set an arbitrary date so it will filter
  if (isNaN(date)) {
    date = Date.parse("1 Jan 2000");
  }

  let rate = b.construction_cost_rate ? b.construction_cost_rate : "";
  let cCost = b.construction_cost ? b.construction_cost : "";
  let pCost = b.project_cost ? b.project_cost : "";
  let metrics = b.metrics || [{ metric: "" }];

  // The Search Filter
  if (
    number.includes(filter.number) &&
    name.includes(filter.name) &&
    city.includes(filter.city) &&
    region.includes(filter.region) &&
    sector.includes(filter.sectorValue) &&
    date >= filter.minDate &&
    date <= filter.maxDate &&
    rate >= filter.minRateCost &&
    rate <= filter.maxRateCost &&
    cCost >= filter.minConstructionCost &&
    cCost <= filter.maxConstructionCost &&
    pCost >= filter.minProjectCost &&
    pCost <= filter.maxProjectCost &&
    metrics.find((m) => m.metric.includes(filter.metric))
  ) {
    return b;
  } else {
    return null;
  }
}

export function findDifference(array1, array2) {
  return array1.filter(
    ({ id: id1 }) => !array2.some(({ id: id2 }) => id2 === id1)
  );
}

export function formatValue(value) {
  if (typeof value === "object" || Array.isArray(value)) {
    return null; // Skip showing arrays
  }

  if (typeof value === "number") {
    value = comma(value);
  }

  return value;
}

export function formatDate(value) {
  const dateObject = new Date(value);
  const formattedDate = `${dateObject.getDate()} ${getMonthAbbreviation(
    dateObject
  )} ${dateObject.getFullYear()}`;

  return formattedDate;
}

function getMonthAbbreviation(date) {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return monthNames[date.getMonth()];
}

export function formatKey(key) {
  let formattedKey = key.replace(/_/g, " ");
  switch (formattedKey) {
    case "project name":
      formattedKey = "Name";
      break;
    case "standard":
      formattedKey = "CBS";
      break;
    case "construction cost rate":
      formattedKey = "Construction Cost/ Local Region";
      break;
    case "quality":
      formattedKey = "Project Quality";
      break;
    case "contactEmail":
      formattedKey = "Contact Email";
      break;

    default:
      formattedKey = formattedKey
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      break;
  }

  return formattedKey;
}

export function removePropeties(dataResponse) {
  let data = [];
  // Removing properties not required in export from the object.
  dataResponse.forEach((element) => {
    delete element["id"];
    delete element["companyId"];
    delete element["source"];
    delete element["type"];
    delete element["project_name_short"];
    delete element["project_number"];
    delete element["exclusiveProjectId"];
    data.push(element);
  });
  return data;
}

export function deepCopy(object) {
  return JSON.parse(JSON.stringify(object));
}

export const featureEnumerator = Object.freeze({
  SENSITIVITY: 1,
  COMPANY: 2,
  COORDINATES: 3,
});

export const removeExportProperties = [
  "project_name_short",
  "project_stage",
  "contactEmail",
  "source",
  "id",
  "companyId",
  "type",
  "project_number",
  "exclusiveProjectId",
  "benchmarkId",
];

export function prepareObjectForExcelUpload(benchmarksWithRows) {
  let TotalSheets = [];
  let TableRecords = [];
  let sheetNames = [];
  let MultiTableRecords = [];

  //FOR PROJECT INFORMATION TAB IN BENCHMARK INFO MODAL DIAGLOG - FOLLOWING METHOD WILL GENERATE ARRAY
  //FROM HTML CONTENT GENERATED FOR PROJECT INFORMATION TAB
  benchmarksWithRows.forEach((element) => {
    let combinedRows = prepareProjectInfoTable(element);
    let combRowsEleAnalysis = [];
    let combRowsEleAnaHead = [];
    let cells = [];
    TableRecords = [];
    MultiTableRecords = [];

    sheetNames.push(element.project_name);

    //PROJECT INFORMATION
    TableRecords.push(["PROJECT INFORMATION", "", ""]);
    TableRecords.push(["Item Code", "Item", "Response"]);
    combinedRows.forEach((row) => {
      cells = [];
      if (!isEmpty(row.props.subHeading)) {
        cells.push(row.props.subHeading);
      } else {
        Object.keys(row.props?.itemData).forEach((cell, index) => {
          if (index !== 1) {
            cells.push(row.props?.itemData[cell]);
          }
        });
      }
      if (!removeExportProperties.includes(row.props?.itemData?.col1))
        TableRecords.push(cells);
    });
    MultiTableRecords.push(TableRecords);

    //ELEMENTAL ANALYSIS-PUSH ROW BY ROW CONTENT TO CELLS ARRAY .. PUSH CELLS ARRAY TO TABLE ARRAY... PUSH TABLE ARRAY TO MULTITABLE ARRAY
    TableRecords = [];
    prepareElementalAnalysisTable(
      element,
      combRowsEleAnaHead,
      combRowsEleAnalysis
    );
    if (isEmpty(combRowsEleAnaHead)) {
      TableRecords.push(["No Elemental Data Available", "", ""]);
    } else {
      TableRecords.push(["ELEMENTAL ANALYSIS", "", ""]);
      cells = [];
      //PUSH HEADING TO CELLS ARRAY
      combRowsEleAnaHead[0].props.Cells[0].forEach((header) => {
        if (header.props.isReporting) cells.push(header.props.content);
      });
      TableRecords.push(cells);

      combRowsEleAnalysis.forEach((row) => {
        cells = [];
        extractContentFromHTML(row, cells);
        TableRecords.push(cells);
      });
    }
    //PUSH ELEMENTABLE TABLE RECRODS
    MultiTableRecords.push(TableRecords);

    //METRICS DATA
    TableRecords = [];
    let metricsTableData = prepareBenchmarkMetricsTable(element.metrics);
    if (metricsTableData.length===1) {
      TableRecords.push(["No Metrics Data Available", "", "", ""]);
    } else {
      TableRecords.push(["BENCHMARKS", "", "", ""]);
      TableRecords.push([
        "Benchmark Code",
        "Benchmark Description",
        "Benchmark Qty / Rate",
        "Benchmark Unit",
      ]);
      metricsTableData.forEach((row) => {
        cells = [];
        if (row.props.hasOwnProperty("subHeading")) {
          cells.push(row.props.subHeading);
        } else {
          extractDataForBenchmarks(row, cells);
        }
        TableRecords.push(cells);
      });
    }

    //PUSH METRICS TABLE RECRODS
    MultiTableRecords.push(TableRecords);
    //PUSH PROJECT INFORMATION ,ELEMENTAL AND BENCHMARK METRICS TABLE DATA TO TOTALSHEETS
    TotalSheets.push(MultiTableRecords);
  });
  return { TotalSheets, sheetNames };

  function extractDataForBenchmarks(row, cells) {
    Object.keys(row.props?.itemData).forEach((cell) => {
      if (!removeExportProperties.includes(cell)) {
        cells.push(
          cell === "quantity"
            ? parseFloat(row.props?.itemData[cell])
            : row.props?.itemData[cell]
        );
      }
    });
  }

  function extractContentFromHTML(row, cells) {
    row.props.Cells.forEach((cellitem) => {
      Array.isArray(cellitem)
        ? cellitem?.forEach((item) => {
            setContentBasedOnType(item, cells);
          })
        : setContentBasedOnType(cellitem, cells);
    });
  }
}

function setContentBasedOnType(cellitem, cells) {
  if (cellitem.props.isReporting) {
    cells.push(
      ["currency", "number"].some((v) =>
        cellitem.props.cellSpecificClass.includes(v)
      ) && !isEmpty(cellitem.props.content)
        ? parseFloat(cellitem.props.content.replaceAll(",", ""))
        : cellitem.props.content
    );
  }
}

export function formatNumber(type, cellContent) {
  switch (type) {
    case "currency":
      return commaToFixed(cellContent);
    case "number":
      return comma(cellContent);
    default:
      return cellContent;
  }
}
