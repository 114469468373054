export function checkLength(name, n) {
  if (name.length > n) {
    let temp = name.substring(0, n - 1);
    let space = temp.lastIndexOf(" ");
    //There is a space before 30th character
    if (space !== -1) {
      name = temp.substring(0, space + 2);
    } else {
      return temp;
    }
  }
  return name;
}
