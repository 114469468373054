// React
import React from "react";
import PropTypes from "prop-types";

// Functions
import { TableHeadingsCell } from "./TableHeadingsCells";

TableHeadings.propTypes = {
  columns: PropTypes.array,
};

export function TableHeadings(props) {
  const { columns } = props;

  let headings = [];
  columns.forEach((cell) => {
    headings.push(
      <TableHeadingsCell
        key={cell.key}
        cell={cell}
        value={cell.heading}
        width={cell.width}
      />
    );
  });

  return <div className="pct-headings-row">{headings}</div>;
}
