// React
import React from "react";

// Components
import PDCPLayout from "./PDCPLayout";

// Styles
import "./PDCP.css";
import Spinner from "../common/Spinner";

export default function PDCP(props) {
  // Project
  const { project } = props;

  // Dashboard
  const { dashboardType } = props;
  const { showDashboardDrawer, setShowDashboardDrawer } = props;
  const { dashboardSettings } = props;

  // Cost Planning
  const { CPs } = props;
  const { CP } = props;
  const { TPI, TPILoading } = props; // TPI for Escalating Benchmarks to the Estimate Date

  // Check project Phase
  if (dashboardType !== "COST_PLANNING") {
    return null;
  }

  // Loading
  if (TPILoading) {
    return <Spinner marginTop={"280px"} marginLeft={"700px"} />;
  }

  // Loaded
  return (
    <div className="project-dashboard">
      <div className="project-dashboard-area">
        <PDCPLayout
          // Project
          project={project}
          // Cost Plan
          CP={CP}
          CPs={CPs}
          // TPI
          TPI={TPI}
          // Dashboard Drawer
          showDashboard={showDashboardDrawer}
          setShowDashboard={setShowDashboardDrawer}
          // Dashboard Settings
          dashboardSettings={dashboardSettings}
        />
      </div>
    </div>
  );
}
