import isEmpty from "../../validation/is-empty";

function validateField(value) {
  return value ? value.toLowerCase() : "";
}

function filterProjects(projects, filter) {
  if (isEmpty(projects)) {
    return [];
  }

  return projects.filter((p) => {
    // Get Project Details
    const title = validateField(p.title);
    const clientCompany = validateField(p.company_name);
    const sector = validateField(p.sector);
    const subsector = validateField(p.sub_sector);
    const projectType = validateField(p.type);
    const projectQuality = validateField(p.quality);
    const procurementMethod = validateField(p.procurement);
    const location = validateField(p.city + p.country + p.region);
    const searchTags = validateField(p.search_tags);

    // Get Project Costs
    // const GFA = parseInt(p.local_region_area) || 0;
    // const constructionCost = parseInt(p.construction_cost) || 0;
    // const projectCost = parseInt(p.project_cost) || 0;
    // const projectRate = parseInt(p.construction_cost_rate) || 0;

    // Check Details
    const titleCheck = title.includes(filter.title.toLowerCase());
    const clientCompanyCheck = clientCompany.includes(
      filter.clientCompany.toLowerCase()
    );
    const sectorCheck = sector.includes(filter.sector.toLowerCase());
    const subsectorCheck = subsector.includes(filter.subsector.toLowerCase());

    const projectTypeCheck = projectType.includes(
      filter.projectType.toLowerCase()
    );
    const projectQualityCheck = projectQuality.includes(
      filter.projectQuality.toLowerCase()
    );
    const procurementCheck = procurementMethod.includes(
      filter.procurementMethod.toLowerCase()
    );
    const locationCheck = location.includes(filter.location.toLowerCase());

    const searchTagCheck = searchTags.includes(filter.searchTags.toLowerCase());

    // Check Cost
    // const minGFACheck = GFA >= filter.minGFA;
    // const maxGFACheck = GFA <= filter.maxGFA;
    // const minConstructionCheck = constructionCost >= filter.minConstructionCost;
    // const maxConstructionCheck = constructionCost <= filter.maxConstructionCost;
    // const minProjectCheck = projectCost >= filter.minProjectCost;
    // const maxProjectCheck = projectCost <= filter.maxProjectCost;
    // const minConstructionRateCheck = projectRate >= filter.minConstructionRate;
    // const maxConstructionRateCheck = projectRate <= filter.maxConstructionRate;

    // The Search Filter
    if (
      // Filter Details
      titleCheck &&
      clientCompanyCheck &&
      sectorCheck &&
      subsectorCheck &&
      projectTypeCheck &&
      projectQualityCheck &&
      procurementCheck &&
      locationCheck &&
      searchTagCheck // &&
      // Filter Cost
      // minGFACheck &&
      // maxGFACheck &&
      // minConstructionCheck &&
      // maxConstructionCheck &&
      // minProjectCheck &&
      // maxProjectCheck &&
      // minConstructionRateCheck &&
      // maxConstructionRateCheck
    ) {
      return p;
    } else {
      return null;
    }
  });
}

function procurementList() {
  return [
    { label: "Select Procurement", value: "" },
    {
      label: "Lump Sum - Construct Only",
      value: "Lump Sum - Construct Only",
    },
    {
      label: "Design and Construct",
      value: "Design and Construct",
    },
    {
      label: "Construction Management",
      value: "Construction Management",
    },
    {
      label: "Managing Contractor",
      value: "Managing Contractor",
    },
    {
      label: "GMP",
      value: "GMP",
    },
  ];
}

function typeList() {
  return [
    { label: "Select Type", value: "" },
    {
      label: "New Build",
      value: "New Build",
    },
    {
      label: "Fit-Out",
      value: "Fit-Out",
    },
    {
      label: "Refurbishment",
      value: "Refurbishment",
    },
    {
      label: "Extension",
      value: "Extension",
    },
  ];
}

function sectorList() {
  return [
    { label: "Select Sector", value: "" },
    {
      label: "Commercial",
      value: "Commercial",
    },
    {
      label: "Commercial Retail",
      value: "Commercial Retail",
    },
    {
      label: "Education, Scientific, Information",
      value: "Education, Scientific, Information",
    },
    {
      label: "Health",
      value: "Health",
    },
    {
      label: "Industrial",
      value: "Industrial",
    },
    {
      label: "Protective Facilities",
      value: "Protective Facilities",
    },
    {
      label: "Recreational",
      value: "Recreational",
    },

    { label: "Recreational Sport", value: "Recreational Sport" },
    {
      label: "Religious",
      value: "Religious",
    },
    {
      label: "Residential",
      value: "Residential",
    },
    {
      label: "Short Stay",
      value: "Short Stay",
    },
    {
      label: "Transport",
      value: "Transport",
    },
  ];
}

function subsectorList() {
  return [
    { label: "Select Subsector", value: "", type: "" },
    {
      label: "Offices",
      value: "Offices",
      type: "Commercial",
    },
    {
      label: "Workplace / Fit-Out",
      value: "Workplace / Fit-Out",
      type: "Commercial",
    },
    {
      label: "Department Stores",
      value: "Commercial",
      type: "Commercial Retail",
    },
    {
      label: "Shoppping Centres",
      value: "Commercial",
      type: "Commercial Retail",
    },
    {
      label: "Speciality Shops / Malls",
      value: "Commercial",
      type: "Commercial Retail",
    },
    {
      label: "College Buildings",
      value: "College Buildings",
      type: "Education, Scientific, Information",
    },
    {
      label: "Libraries / Municipal Buildings",
      value: "Libraries / Municipal Buildings",
      type: "Education, Scientific, Information",
    },
    {
      label: "Primary School Buildings",
      value: "Primary School Buildings",
      type: "Education, Scientific, Information",
    },
    {
      label: "Research Institutions / Laboratories",
      value: "Research Institutions / Laboratories",
      type: "Education, Scientific, Information",
    },
    {
      label: "Secondary School Buildings",
      value: "Secondary School Buildings",
      type: "Education, Scientific, Information",
    },
    {
      label: "Student Accommodation",
      value: "Student Accommodation",
      type: "Education, Scientific, Information",
    },
    {
      label: "University Buildings",
      value: "University Buildings",
      type: "Education, Scientific, Information",
    },
    {
      label: "Aged Care Facilities",
      value: "Aged Care Facilities",
      type: "Health",
    },
    {
      label: "Health Centres / Medical Suites",
      value: "Health Centres / Medical Suites",
      type: "Health",
    },
    {
      label: "Hospitals",
      value: "Hospitals",
      type: "Health",
    },
    {
      label: "Vetinary Hospitals and Animal Clinics",
      value: "Vetinary Hospitals and Animal Clinics",
      type: "Health",
    },
    {
      label: "Data Centres",
      value: "Data Centres",
      type: "Industrial",
    },
    {
      label: "Factories",
      value: "Factories",
      type: "Industrial",
    },
    {
      label: "Logistics Park",
      value: "Logistics Park",
      type: "Industrial",
    },
    {
      label: "Warehouse Units / Stores",
      value: "Warehouse Units / Stores",
      type: "Industrial",
    },
    {
      label: "Fire Stations / Ambulance Stations / Police Stations",
      value: "Fire Stations / Ambulance Stations / Police Stations",
      type: "Protective Facilities",
    },
    {
      label: "Law Courts",
      value: "Law Courts",
      type: "Protective Facilities",
    },
    {
      label: "Prisons",
      value: "Prisons",
      type: "Protective Facilities",
    },
    {
      label:
        "Convention Centres / Cultural Centres / Auditoriums / Art Galleries",
      value:
        "Convention Centres / Cultural Centres / Auditoriums / Art Galleries",
      type: "Recreational",
    },
    {
      label: "Performing Arts Entities / Theatres / Cinemas",
      value: "Performing Arts Entities / Theatres / Cinemas",
      type: "Recreational",
    },
    {
      label: "Restaurants / Pubs / Clubs",
      value: "Restaurants / Pubs / Clubs",
      type: "Recreational",
    },
    {
      label: "Theme Parks and Resorts",
      value: "Theme Parks and Resorts",
      type: "Recreational",
    },
    {
      label: "Sports Centre",
      value: "Sports Centre",
      type: "Recreational Sport",
    },
    {
      label: "Sports Fields",
      value: "Sports Fields",
      type: "Recreational Sport",
    },
    {
      label: "Stadia",
      value: "Stadia",
      type: "Recreational Sport",
    },
    {
      label: "Swimming Pools",
      value: "Swimming Pools",
      type: "Recreational Sport",
    },
    {
      label: "Places of Worship",
      value: "Places of Worship",
      type: "Religious",
    },
    {
      label: "Places of Worship - Other",
      value: "Places of Worship - Other",
      type: "Religious",
    },
    {
      label: "Apartments",
      value: "Apartments",
      type: "Residential",
    },
    {
      label: "Single and Double Storey Dwellings",
      value: "Single and Double Storey Dwellings",
      type: "Residential",
    },
    {
      label: "Townhouses",
      value: "Townhouses",
      type: "Residential",
    },
    {
      label: "Hotels - 3-Star",
      value: "Hotels - 3-Star",
      type: "Short Stay",
    },
    {
      label: "Hotels - 4-Star",
      value: "Hotels - 4-Star",
      type: "Short Stay",
    },
    {
      label: "Hotels - 5+ Star",
      value: "Hotels - 5+ Star",
      type: "Short Stay",
    },
    {
      label: "Aviation - Airport Terminal",
      value: "Aviation - Airport Terminal",
      type: "Transport",
    },
    {
      label: "Car Parking",
      value: "Car Parking",
      type: "Transport",
    },
    {
      label: "Rail - Train Stations",
      value: "Rail - Train Stations",
      type: "Transport",
    },
  ];
}

function qualityList() {
  return [
    { label: "Select Quality", value: "" },
    {
      label: "Premium",
      value: "Premium",
    },
    {
      label: "High",
      value: "High",
    },
    {
      label: "Medium",
      value: "Medium",
    },
    {
      label: "Basic",
      value: "Basic",
    },
  ];
}

export {
  filterProjects,
  sectorList,
  subsectorList,
  procurementList,
  typeList,
  qualityList,
};
