import React from "react";

import isEmpty from "../../validation/is-empty";
import { comma } from "../../helpers/common.functions";
import { VALUE_ENGINEERING } from "./ValueEngineering.strings";
import { BoxSet as ValueEngineeringStatusControl } from "./ValueEngineeringStatusControl";

export default function ValueEngineeringMiniTable(props) {
  const { veItems } = props;

  if (isEmpty(veItems)) {
    return <></>;
  }

  return <ValueEngineeringTableBody veItems={veItems} />;
}

function ValueEngineeringTableBody(props) {
  const { veItems } = props;

  let tableClass = `ve-table no-hover ebt`;

  return (
    <div className={tableClass}>
      <div className="ve-head-row-container">
        <EstimateBudgetTableHead />
      </div>
      <EstimateBudgetTableRows veItems={veItems} />
    </div>
  );
}

function EstimateBudgetTableRows(props) {
  const { veItems } = props;

  const display = [];
  let rowType = "value-engineering-row";

  let total = 0;

  veItems.forEach((group, index) => {
    const key = `group_${index}`;
    display.push(
      <EstimateBudgetGroupRow
        key={key}
        rowType={rowType}
        groupName={group.name}
      />
    );

    group.veItems?.forEach((row, index) => {
      display.push(
        <EstimateBudgetRow
          key={row.id}
          id={row.id}
          rowType={rowType}
          row={row}
          veItems={group.veItems}
        />
      );

      total += row.value;
    });
  });

  display.push(
    <EstimateBudgetTotalRow
      key={"ve-total-row"}
      rowType={rowType}
      total={total}
    />
  );

  return <div>{display}</div>;
}

function EstimateBudgetTableHead() {
  return (
    <div className="value-engineering-head-row">
      <div className="budget-description-cell ve-description-cell">
        <b>{VALUE_ENGINEERING.FIELDS.TITLE}</b>
      </div>
      <div className="budget-cell-head">
        <b>{VALUE_ENGINEERING.FIELDS.VALUE}</b>
      </div>
      <div className="budget-cell-head ve-status-cell-head">
        <b>{VALUE_ENGINEERING.FIELDS.STATUS}</b>
      </div>
    </div>
  );
}

function getStatus({ id, veItems }) {
  const _veItem = veItems.find((row) => row.id === id);
  return _veItem.status;
}

function EstimateBudgetGroupRow(props) {
  const { groupName, rowType } = props;

  return (
    <div className="estimate-breakdown-row-wrapper">
      <div className={`${rowType} group-row`}>
        <div>{groupName}</div>
      </div>
    </div>
  );
}

function EstimateBudgetRow(props) {
  const { row, rowType } = props;
  const { id, veItems } = props;

  const status = getStatus({ id, veItems });

  return (
    <div className="estimate-breakdown-row-wrapper">
      <div className={rowType}>
        <div className="budget-description-cell ve-description-cell">
          {row.title}
        </div>
        <div className="budget-cell">
          {row.value ? comma(Math.round(row.value)) : 0}
        </div>
        <div className="status-cell ve-status-cell">
          <ValueEngineeringStatusControl
            status={status}
            setStatus={() => null}
            readonly={true}
          />
        </div>
      </div>
    </div>
  );
}

function EstimateBudgetTotalRow(props) {
  const { rowType } = props;
  const { total } = props;

  return (
    <div className="estimate-breakdown-row-wrapper">
      <div className={rowType + " totalrow"}>
        <div className="budget-description-cell ve-description-cell">
          <b>Total</b>
        </div>
        <div className="budget-cell">{comma(Math.round(total))}</div>
        <div className="status-cell ve-status-cell"></div>
      </div>
    </div>
  );
}
