import React from "react";

import BreakdownSummaryRow from "./EstimateBreakdownSummaryRow";
import BreakdownRows from "./EstimateBreakdownRows";

export default function EstimateBreakdownTableRows(props) {
  const { data } = props;
  const { CPs } = props;
  const { selectedRow } = props;
  const { projectID } = props;
  const { search } = props;
  const { project } = props;
  const { enableLineRefs } = props;

  const { lineCounter } = props;
  const { headCounter } = props;
  let { filtered, notificationsOnly = false } = props;

  const display = [];

  data.forEach((row) => {
    let { bold, rowType } = rowFormatter(row);

    let displayParent = true;
    if (search.length > 0) {
      displayParent = row.breakdown.some((value) =>
        value.description.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (selectedRow === row.id.toString() || selectedRow === "breakdown") {
      if (!displayParent) {
        return null;
      }

      let sectionClass = "";
      if (filtered !== undefined) sectionClass = "ebtr-section-filter";
      else sectionClass = "ebtr-section";

      display.push(
        <div className={sectionClass} key={row.id}>
          {filtered === undefined && (
            <BreakdownSummaryRow
              row={row}
              rowType={rowType}
              bold={bold}
              enableLineRefs={enableLineRefs}
              lineCounter={lineCounter}
              headCounter={headCounter}
            />
          )}
          <BreakdownRows
            row={row}
            rowType={rowType}
            search={search}
            project={project}
            projectID={projectID}
            CPs={CPs}
            enableLineRefs={enableLineRefs}
            lineCounter={lineCounter}
            headCounter={headCounter}
            filtered={filtered}
            notificationsOnly={notificationsOnly}
          />
        </div>
      );
    }
  });

  return <div>{display}</div>;
}

function rowFormatter(row) {
  // Subtotal styling for other rows
  let rowType = "ebt-subhead-row";
  let bold = true;
  if (row.breakdown.length === 0 && row.code === "sh") {
    // This is the event that of a single line SH code
    // Do nothing - treat as a heading or:
    rowType = "ebt-row";
    // Do Nothing - treat as a heading or:
    bold = false;
  }

  if (
    row.code === "st" ||
    row.code === "cc" ||
    row.code === "pc" ||
    row.code === "pct"
  ) {
    rowType = "ebt-head-row";
    bold = true;
  }

  if (row.code === "pc" || row.code === "pct") {
    rowType = "ebt-total-row";
    bold = true;
  }

  return { rowType, bold };
}
