export default function browserDetector() {
  const isIE = !!document.documentMode || false;
  const isEdge = !isIE && !!window.StyleMedia;
  const isFirefox = typeof InstallTrigger !== "undefined";
  const isOpera = operaCheck();
  const isChrome =
    !isEdge &&
    !isOpera &&
    !!window.chrome &&
    (!!window.chrome.webstore ||
      navigator.vendor.toLowerCase().indexOf("google inc.") !== -1);
  const isSafari =
    !isChrome && navigator.userAgent.toLowerCase().indexOf("safari") !== -1;
  const isBlink = (isChrome || isOpera) && !!window.CSS;

  let type;
  let unsupported;

  if (isIE) {
    type = "ie";
    unsupported = true;
  } else if (isEdge) {
    type = "edge";
    unsupported = true;
  } else if (isFirefox) {
    type = "firefox";
    unsupported = false;
  } else if (isOpera) {
    type = "opera";
    unsupported = true;
  } else if (isChrome) {
    type = "chrome";
    unsupported = false;
  } else if (isSafari) {
    type = "safari";
    unsupported = true;
  } else if (isBlink) {
    type = "blink";
    unsupported = true;
  } else {
    type = "unknown";
    unsupported = true;
  }
  return { type, unsupported };
}

function operaCheck() {
  return (
    (!!window.opr && !!window.opr.addons) ||
    !!window.opera ||
    navigator.userAgent.indexOf(" OPR/") >= 0
  );
}
