// React
import React, { useState } from "react";
import PropTypes from "prop-types";

// Components
import Table from "../table/Table";
import BenchmarkView from "./BenchmarkView";
import Modal from "../modal/Modal";
import Spinner from "../common/Spinner";

// Functions
import { tranStr } from "../../utils/translation";
import isEmpty from "../../validation/is-empty";
import {
  getDecoratedData,
  getRoleBasedColumns,
} from "./BenchmarkControlTable.functions";
// Style
import "./BenchmarkControlTable.css";
import TableMenuOptions from "../table/TableMenuOptions";
import { columns } from "../benchmarking-project/BenchmarkingTable.functions";

BenchmarkControlTable.propTypes = {
  selectedBenchmarks: PropTypes.array,
  setSelected: PropTypes.func,
  data: PropTypes.array,
  user: PropTypes.object,
  sensitivityLevelCodes: PropTypes.object,
  configureSettings: PropTypes.object,
};

export default function BenchmarkControlTable(props) {
  const { selectedBenchmarks, setSelected, user, sensitivityLevelCodes } =
    props;
  const { data } = props;
  const { configureSettings } = props;

  const [selectedRow, setSelectedRow] = useState({});
  const [modal, setModal] = useState(false);
  const [tableMenuModal, setTableMenuModal] = useState(false);

  const settings = configureSettings;
  const [selectedColumns, setSelectedColumns] = useState(
    settings.configureSettings?.data[0]?.configSettings?.table[0]?.Columns
  );
  const roleBasedColumns = getRoleBasedColumns(user, columns(null));

  if (isEmpty(data) || sensitivityLevelCodes.loading || settings.loading) {
    return (
      <div className="benchmark-control-table-container">
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <Table
        title={tranStr("Benchmarking")}
        tableArray={getDecoratedData(data, sensitivityLevelCodes)}
        columns={getRoleBasedColumns(user, columns(selectedColumns))}
        tableSize={15}
        isTitleDisabled={true}
        isTotalDisabled={true}
        // Checked Selection
        checked={selectedBenchmarks}
        setChecked={setSelected}
        // Row Selection
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        // Modal Control
        setModal={setModal}
        setTableMenuModal={setTableMenuModal}
      />
      <Modal
        // Modal Props
        title={tranStr("Benchmarking") + " Column Options"}
        Component={TableMenuOptions}
        modal={tableMenuModal}
        setModal={setTableMenuModal}
        // Component Props
        columns={roleBasedColumns}
        selectedColumns={getRoleBasedColumns(
          user,
          columns(selectedColumns)
        ).map((col) => col.key)}
        setSelectedColumns={setSelectedColumns}
        configureSettings={configureSettings}
        tableDiscriminator={"Benchmarking View Table"}
        discriminator="MASTER_BENCHMARKING"
        moduleId={user.id}
      />
      <Modal
        // Modal Props
        title={"Selected Item"}
        Component={BenchmarkView}
        modal={modal}
        setModal={setModal}
        // Component Props
        selectedRow={selectedRow}
      />
    </div>
  );
}
