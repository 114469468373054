import {
  getReportsByReportId,
  getReportImportStart,
} from "../../api/reports/GenerateReportAPI.js";
import Spinner from "../common/Spinner";
import isEmpty from "../../validation/is-empty";

export const ReportImportButton = (props) => {
  const { reportStatus } = props;
  const { reportParams } = props;

  let disabled = false;

  // The report service is down
  if (reportStatus.data.down) {
    disabled = true;
  }

  if (disabled) {
    return (
      <div className={"general-modal-button-disabled"}>Create New Report</div>
    );
  }

  return (
    <button
      className="report-export-button"
      onClick={async () => {
        reportStatus.set({
          started: true,
          completed: false,
          timestamp: null,
        });

        await getReportImportStart(reportParams);
        reportStatus.set(await reportStatus.get(reportParams));
      }}
    >
      Create New Report
    </button>
  );
};

export const ReportGenerateButton = (props) => {
  const { setReportLoading, setReportDetails } = props;
  const { reportParams } = props;
  const { reportStatus } = props;

  let disabled = false;

  // The report service is down
  if (reportStatus.data.down) {
    disabled = true;
  }

  // It is disabled when import hasn't started and hasn't completed
  if (!reportStatus.data.started && !reportStatus.data.completed) {
    disabled = true;
  }

  // It is disabled if there is an error
  if (reportStatus.data.error) {
    disabled = true;
  }

  if (disabled) {
    return <div className={"general-modal-button-disabled"}>Open Report</div>;
  }

  if (reportStatus.data.started && !reportStatus.data.completed) {
    return (
      <div className="report-export-exporting">
        <Spinner width={30} />
        <div className="report-export-exporting-text">
          Creating New Report...
        </div>
      </div>
    );
  }

  return (
    <button
      className={"report-view-button"}
      onClick={async () => {
        setReportLoading(true);
        setReportDetails({});
        let response = await getReportsByReportId(reportParams);
        setReportDetails(response);
        setReportLoading(false);
      }}
    >
      Open Report
    </button>
  );
};

export const ReportImportStatus = (props) => {
  const { reportStatus } = props;

  let date = "";

  if (isEmpty(reportStatus.data)) {
    date = "No Report Found";
  } else if (isEmpty(reportStatus.data.timestamp)) {
    date = "No Report Found";
  } else if (reportStatus.data.error) {
    date = "Report Error";
  } else {
    date = reportStatus.data.timestamp;
    date = new Date(date);
    date = date.toLocaleString("default", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });
  }

  return (
    <div className="export-report-date-container">
      <div className="export-report-date-container-row1">Report Timestamp</div>
      <div className="export-report-date-container-row2">{date}</div>
    </div>
  );
};
