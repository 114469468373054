export function mapOrder(array, order, dashboardID) {
  // Order based on order array
  array.sort(function (a, b) {
    let A = a.props[dashboardID];
    let B = b.props[dashboardID];

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    } else {
      return -1;
    }
  });

  // Filter out elements not in the order array
  return array.filter((item) => order.includes(item.props[dashboardID]));
}

// Find the column the DRAGGED item is in
export function findDraggedColumn(columns, dragID) {
  let remove;
  if (columns.column1.some((e) => e === dragID.current)) {
    remove = columns.setColumn1;
  } else if (columns.column2.some((e) => e === dragID.current)) {
    remove = columns.setColumn2;
  } else if (columns.column3.some((e) => e === dragID.current)) {
    remove = columns.setColumn3;
  } else {
    // Not Currently in dashboard and is from Dashboard Editor
    remove = (e) => {
      return e;
    };
  }
  return remove;
}

export function updateColumns(add, remove, dragID, top, dashboardID) {
  // Remove must be first
  remove((column) => {
    return removeColumn(column, dragID);
  });

  // Add after remove
  add((column) => {
    let newColumn = [...column];
    // Add the item to the array after the selected
    let pos;
    if (top) {
      pos = 0;
    } else {
      // Get the index to insert the item after
      let dropIndex = column.indexOf(dashboardID);
      pos = dropIndex + 1;
    }
    newColumn.splice(pos, 0, dragID.current);

    return newColumn;
  });
}

export function updateColumnsDashboardEditor(remove, dragID) {
  // Remove must be first
  remove((column) => {
    return removeColumn(column, dragID);
  });
}

function removeColumn(column, dragID) {
  let newColumn = [...column];
  let dragIndex = column.indexOf(dragID.current);
  newColumn.splice(dragIndex, 1);
  return newColumn;
}
