export const IMPACT_RATING_SCALE = {
  UNDEFINED: {
    display: "N/A",
    value: "0",
  },
  VERY_LOW: {
    display: "Very Low",
    value: "1",
  },
  LOW: {
    display: "Low",
    value: "2",
  },
  MODERATELY_LOW: {
    display: "Moderately Low",
    value: "3",
  },
  MODERATE: {
    display: "Moderate",
    value: "4",
  },
  MODERATELY_HIGH: {
    display: "Moderately High",
    value: "5",
  },
  HIGH: {
    display: "High",
    value: "6",
  },
  VERY_HIGH: {
    display: "Very High",
    value: "7",
  },
};
