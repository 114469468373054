import { saveLocations } from "../../api/admin/RegionManagementAPI";
import isEmpty from "../../validation/is-empty";

export function updateCity(value, i, comps, setLocations) {
  let tempLocations = comps;
  tempLocations[i].city = value;
  setLocations(tempLocations.map((s) => s));
}

export function updateRegion(value, i, comps, setLocations) {
  let tempLocations = comps;
  tempLocations[i].region = value;
  setLocations(tempLocations.map((s) => s));
}

export function updateCountry(value, i, comps, setLocations) {
  let tempLocations = comps;
  tempLocations[i].country = value;
  setLocations(tempLocations.map((s) => s));
}

export function deletelocation(id, comps, setLocations) {
  let temp = comps;
  temp.splice(comps.map((x) => x.id).indexOf(id), 1);
  setLocations(temp.map((x) => x));
}

export function insertlocation(i, displayLocations, setLocations) {
  let temp = displayLocations;

  // Add new item at the next field
  temp.splice(i + 1, 0, {
    id: Date.now().toString(),
    city: "",
    region: "",
    country: "",
  });

  setLocations(temp.map((x) => x));
}

function checkArray(array) {
  let fail = false;
  array.forEach((s) => {
    if (isEmpty(s.city)) {
      fail = true;
    }
    if (isEmpty(s.region)) {
      fail = true;
    }
    if (isEmpty(s.country)) {
      fail = true;
    }
  });

  return fail;
}

export async function submitLocations(
  e,
  displayLocations,
  setSubmitted,
  setLoading,
  setError,
  setLocations
) {
  e.preventDefault();

  if (displayLocations.length < 1) {
    setSubmitted(false);
    setLoading(false);
    setError(false);
  } else if (checkArray(displayLocations)) {
    setSubmitted(false);
    setLoading(false);
    setError(true);
  } else {
    setSubmitted(false);
    setLoading(true);
    setError(false);
    saveLocations(
      displayLocations,
      setLocations,
      setLoading,
      setSubmitted,
      setError
    );
  }
}
