import React, { useEffect } from "react";
import Spinner from "../common/Spinner";
import "./CreateBenchmarkForm.css";

export default function CreateBenchmarkFormRows(props) {
  const { rows, setRows } = props;
  const { standard } = props;
  const { cbs } = props;
  const { benchmarkRows, area } = props;
  const { formIsEnabled } = props;
  const { unitOfMeasure } = props;

  // Generate the fields
  useEffect(() => {
    let tempRows = [];

    const categoryGroup = cbs?.data?.categoryGroups?.find(
      (g) => g.categoryName === "Element Categories"
    );

    // Within the collection of CBS data
    categoryGroup?.categoryItems?.forEach((c) => {
      // We only want to match items that are the selected standard (CBS type)
      let tempRow = {
        code: c.codes[0],
        description: c.description,
        unit: "",
        local_region_rate: 0,
        total: 0,
      };

      tempRows.push(tempRow);
      setRows(tempRows);
    });
  }, [cbs?.data, standard, setRows, benchmarkRows, unitOfMeasure, area]);

  if (!formIsEnabled) {
    return null;
  }

  if (cbs?.loading) {
    return (
      <div className="create-benchmark-form-container-column-rows">
        <div className="create-project-panel">
          <h1>{"Elemental Summary"}</h1>
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <div className="create-benchmark-form-container-column-rows">
      <div className="create-project-panel">
        <h1>{"Elemental Summary"}</h1>
        <div className="benchmarks-row">
          <div className="benchmarks-row-code">
            <div>Code</div>
          </div>
          <div className="benchmarks-row-elemental-analysis">
            <div>Description</div>
          </div>
          <div className="benchmarks-row-value">
            <div>Total</div>
          </div>
          <div className="benchmarks-row-rate">
            <div>Rate</div>
          </div>
        </div>
        <Table
          rows={rows}
          setRows={setRows}
          area={area}
          unitOfMeasure={unitOfMeasure}
        />
      </div>
    </div>
  );
}

function Table(props) {
  const { rows, setRows } = props;
  const { area, unitOfMeasure } = props;

  let displayTable = [];
  let displayTotal = 0;
  let keyIndex = 0;

  rows.forEach((rowData, i) => {
    displayTable.push(
      <TableRow
        key={keyIndex}
        i={i}
        rows={rows}
        setRows={setRows}
        rowData={rowData}
        area={area}
        unitOfMeasure={unitOfMeasure}
      />
    );
    keyIndex++;
    let rowTotal = parseInt(rowData.total);
    if (!isNaN(rowTotal)) {
      displayTotal += rowTotal;
    }
  });

  displayTable.push(
    <div
      id={"benchmarks-row-table-total"}
      key={keyIndex}
      className="benchmarks-row"
    >
      <div className="benchmarks-row-code">
        <b>Total</b>
      </div>
      <div className="benchmarks-row-elemental-analysis">
        <b>Construction Cost</b>
      </div>
      <div className="benchmarks-row-value">
        <b>{Math.round(displayTotal)}</b>
      </div>
      <div className="benchmarks-row-rate">
        <b>{Math.round(displayTotal / area) + "/" + unitOfMeasure}</b>
      </div>
    </div>
  );

  return displayTable;
}

function updateField(field, value, i, array, setRows) {
  let temp = [...array];
  temp[i][field] = value;
  setRows(temp);
}

function TableRow(props) {
  const { rowData } = props;
  const { area } = props;
  const { i } = props;
  const { rows, setRows } = props;
  const { unitOfMeasure } = props;

  return (
    <div key={i} className="benchmarks-row">
      <div className="benchmarks-row-code">{rowData.code}</div>
      <div className="benchmarks-row-elemental-analysis">
        {rowData.description}
      </div>
      <input
        id={rowData.description + "-benchmarks-row-input"}
        className="benchmarks-row-value"
        value={rowData.total}
        onChange={(e) => {
          updateField("total", e.target.value, i, rows, setRows);
        }}
      ></input>
      <div className="benchmarks-row-rate">
        {Math.round(rowData.total / area) + "/" + unitOfMeasure}
      </div>
    </div>
  );
}
