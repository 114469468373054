import React, { useState } from "react";

import { uploadValueEngineering } from "./ValueEngineering.functions";
import ValueEngineeringUploader from "./ValueEngineeringUploader";
import Modal from "../modal/Modal";
import { hasRoles } from "../../utils/roles";
import { translate } from "../../utils/translation";

import "./ValueEngineeringUploadModal.css";
import isEmpty from "../../validation/is-empty";

export default function ValueEngineeringUploadModal(props) {
  const { user, project, CPs, CP } = props;

  const [modal, setModal] = useState(false);

  // Hide if not value-management
  const subLocation = getSubLocation();

  if (
    subLocation !== "value-management" ||
    project.project_phase !== "COST_PLANNING" ||
    !hasRoles(user.roles, ["CostManager"]) ||
    isEmpty(CPs.data)
  ) {
    return null;
  }

  const data = {
    projectID: project.id,
    project: project,
    CP: CP,
    CPs: CPs,
    setShow: setModal,
    estimate: null,
  };

  return (
    <div>
      <button
        className="value-engineering-upload-modal-button"
        onClick={() => {
          setModal(true);
        }}
      >
        {translate("Upload VE Items")}
      </button>
      <Modal
        title={translate("Upload Value Engineering Items")}
        Component={ValueEngineeringUploader}
        modal={modal}
        setModal={setModal}
        // Component Props
        data={data}
        uploadFunction={uploadValueEngineering}
        isValueEngineering={true}
      />
    </div>
  );
}

function getSubLocation() {
  const URL = window.location.href;

  return URL.substring(URL.lastIndexOf("/") + 1);
}
