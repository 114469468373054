import React from "react";
import { Link } from "react-router-dom";

export default function TenderPriceNavigation() {
  return (
    <div className="project-navigation-tab-group">
      {categoriesLink("categories", "fas fa-layer-group")}
      {tpiLink("tender-price-index", "fas fa-chart-line")}
      {tpiLink("location-factors", "far fa-map")}
      {tpiLink("currency-exchange", "fas fa-dollar-sign")}
    </div>
  );
}

function categoriesLink(label, icon) {
  const URL = window.location.href;

  let name = "project-navigation-section";
  if (URL.includes("categories") || URL.includes("category")) {
    name = "project-navigation-section-selected";
  }

  return (
    <Link to={`/insights/${label}`} className="tab-link">
      <div className={name}>
        <div className="project-navigation-label">
          <div className="front-icon">
            <div className="icon-container">
              <i className={icon} style={{ marginRight: "10px" }}></i>
            </div>
            {"Categories"}
          </div>
        </div>
      </div>
    </Link>
  );
}

function tpiLink(label, icon) {
  const URL = window.location.href;

  let name = "project-navigation-section";
  if (URL.includes(label)) {
    name = "project-navigation-section-selected";
  }

  return (
    <Link to={`/insights/${label}`} className="tab-link">
      <div className={name}>
        <div className="project-navigation-label">
          <div className="front-icon">
            <div className="icon-container">
              <i className={icon} style={{ marginRight: "10px" }}></i>
            </div>
            {humanize(label)}
          </div>
        </div>
      </div>
    </Link>
  );
}

function humanize(str) {
  let frags = str.split("-");
  for (let i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(" ");
}
