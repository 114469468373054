import isEmpty from "../../validation/is-empty";
import { getBenchmarksWithRows } from "../benchmarking/BenchmarkingAPI";
import { getProjectsByProjectIDs } from "./ProjectAPI";

export function benchmarkIDsFromPortfolio(projects) {
  let benchmarkIDs = [];

  // Project
  projects.forEach((project) => {
    if (!isEmpty(project.latest_cost_plan)) {
      if (!isEmpty(project.latest_cost_plan.estimates)) {
        // Estimate
        project.latest_cost_plan.estimates.forEach((estimate) => {
          // Benchmark
          benchmarkIDs.push({ benchmark_id: estimate.id });
        });
      }
    }
  });

  return benchmarkIDs;
}

export async function addBenchmarksPortfolio(projects, abortSignal) {
  const benchmarkIDs = benchmarkIDsFromPortfolio(projects);
  const benchmarks = await getBenchmarksWithRows(benchmarkIDs, abortSignal);

  // Project
  projects.forEach((project) => {
    const costPlan = project.latest_cost_plan;
    if (!isEmpty(costPlan) && !isEmpty(costPlan.estimates)) {
      // Estimate
      costPlan.estimates.forEach((estimate) => {
        const benchmark = benchmarks.find((b) => b.id === estimate.id);

        estimate.benchmark = benchmark;
      });
    }
  });
}

export async function addProjectData(projects) {
  const projectIDs = projects.map((p) => p.project_id.toString());
  const newProjectData = await getProjectsByProjectIDs(projectIDs);
  const updatedProjects = [];
  projects.forEach((project) => {
    let match = false;
    let newData = {};

    // Try and find a match for this project
    newProjectData.forEach((data) => {
      if (project.project_id.toString() === data.id.toString()) {
        match = true;
        newData = data;
      }
    });

    // If there is a match combine the project with the data
    if (match) {
      const combinedProject = {
        ...project,
        ...newData,
      };
      updatedProjects.push(combinedProject);
    }
  });
  return updatedProjects;
}
