import React from "react";

import ProjectItem from "./ProjectItem";

import isEmpty from "../../validation/is-empty";
import { translate } from "../../utils/translation";
import Spinner from "../common/Spinner";
import PortfolioFiltersWarning from "./PortfolioFilterWarning";
import PortfolioPagination from "./PortfolioPagination";

// Styles
import "./Projects.css";

export default function Projects(props) {
  const { projects, projectsLoading } = props;
  const { pageSize } = props;
  const { dragID } = props;
  const { portfolioFilters } = props;
  const { setPortfolioFilters } = props;
  const { setIsDragEnabled } = props;
  const { selectedProjects } = props;
  const { filteredProjects } = props;
  const { activePage, setActivePage } = props;

  const { projectsCostPlanningLoading, projectsPostContractLoading } = props;

  return (
    <div className="portfolio-projects-column">
      <PortfolioFiltersWarning
        portfolioFilters={portfolioFilters}
        setPortfolioFilters={setPortfolioFilters}
      />
      <div className="portfolio-box">
        <PortfolioProjects
          projects={selectedProjects}
          availableProjects={projects}
          projectsLoading={projectsLoading}
          dragID={dragID}
          setIsDragEnabled={setIsDragEnabled}
          projectsCostPlanningLoading={projectsCostPlanningLoading}
          projectsPostContractLoading={projectsPostContractLoading}
        />
      </div>
      <div className="pagination-box">
        <PortfolioPagination
          activePage={activePage}
          setActivePage={setActivePage}
          filteredProjects={filteredProjects}
          pageSize={pageSize}
        />
      </div>
    </div>
  );
}

function PortfolioProjects(props) {
  const { projects, availableProjects, projectsLoading } = props;
  const { dragID } = props;
  const { setIsDragEnabled } = props;

  const { projectsCostPlanningLoading, projectsPostContractLoading } = props;

  if (projectsLoading) {
    return (
      <div className="portfolio-loading">
        <Spinner />
      </div>
    );
  }

  if (isEmpty(availableProjects) && !projectsLoading) {
    return (
      <div>
        {translate(
          "No Projects found. You may not be allocated to any or you may need to refresh the page."
        )}
      </div>
    );
  }

  return projects.map((project, i) => {
    if (project === null) {
      return null;
    }
    return (
      <ProjectItem
        key={i}
        dragID={dragID}
        project={project}
        setIsDragEnabled={setIsDragEnabled}
        projectsCostPlanningLoading={projectsCostPlanningLoading}
        projectsPostContractLoading={projectsPostContractLoading}
        isPortfolio
        isDraggable
      />
    );
  });
}
