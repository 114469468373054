import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import isEmpty from "../../validation/is-empty";
import { tranStr } from "../../utils/translation";
import {
  comma,
  commaToFixed,
  toSnakeCase,
} from "../../helpers/common.functions";

import Spinner from "../common/Spinner";

import { getBenchmarksWithRows } from "../../api/benchmarking/BenchmarkingAPI";

import "./BenchmarkMiniTable.css";

/// props validation | SQ(javascript:S6774)
BenchmarkMiniTable.propTypes = {
  selectedBenchmarkIds: PropTypes.array,
  benchmarkSelection: PropTypes.array,
};
///
export default function BenchmarkMiniTable(props) {
  const { selectedBenchmarkIds, benchmarkSelection } = props;

  // isMounted tracking: see https://stackoverflow.com/a/63719707
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const { loading, benchmarks } = useBenchmarks(isMounted, {
    selectedBenchmarkIds,
    benchmarkSelection,
  });

  return (
    <BenchmarkTable
      loading={loading}
      selectedBenchmarkIds={selectedBenchmarkIds}
      benchmarks={benchmarks}
    />
  );
}

export function useBenchmarks(
  isMounted,
  { selectedBenchmarkIds, benchmarkSelection }
) {
  const [loading, setLoading] = useState(false);
  const [benchmarks, setBenchmarks] = useState([]);

  useEffect(() => {
    const benchmarkIds = selectedBenchmarkIds.map((id) => ({
      benchmark_id: `${id}`,
    }));

    (async function fetchBenchmarks() {
      let benchmarks = [];
      if (!isEmpty(benchmarkSelection)) {
        benchmarks = benchmarkSelection;
      } else if (!isEmpty(benchmarkIds) && isMounted.current) {
        setLoading(true);
        benchmarks = await getBenchmarksWithRows(benchmarkIds);
      }
      setBenchmarks(benchmarks);
      setLoading(false);
    })();
  }, [isMounted, selectedBenchmarkIds, benchmarkSelection]);

  return {
    loading,
    benchmarks,
  };
}

/// props validation | SQ(javascript:S6774)
BenchmarkTable.propTypes = {
  loading: PropTypes.bool,
  benchmarks: PropTypes.array,
};
///
function BenchmarkTable(props) {
  const { loading } = props;
  const { benchmarks } = props;

  if (loading) {
    return <BenchmarkTableLoading />;
  }

  return (
    <div className="benchmark-mini-table grid">
      <BenchmarkTableHeaderRow />
      <BenchmarkTableRows benchmarks={benchmarks} />
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
BenchmarkTableLoading.propTypes = {};
///
function BenchmarkTableLoading(props) {
  return (
    <div className="benchmark-mini-table grid">
      <div className="grid-item full-width spinner">
        <Spinner width={"105px"} />
      </div>
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
BenchmarkTableHeaderRow.propTypes = {};
///
function BenchmarkTableHeaderRow(props) {
  // when adding columns here, also update BenchmarkMiniTable.css > .benchmark-mini-table > grid-template-columns
  // columns count should match BenchmarkTableRow fields count
  const columns = [
    tranStr("#"),
    tranStr("Name"),
    tranStr("CBS"),
    tranStr("Client"),
    tranStr("City"),
    tranStr("Local Region Area"),
    tranStr("Construction Cost"),
  ];

  return (
    <>
      {columns.map((column) => (
        <div key={toSnakeCase(column)} className="grid-item">
          <b>{column}</b>
        </div>
      ))}
    </>
  );
}

/// props validation | SQ(javascript:S6774)
BenchmarkTableRows.propTypes = {
  benchmarks: PropTypes.array,
};
///
function BenchmarkTableRows(props) {
  const { benchmarks } = props;

  return (
    <>
      {benchmarks.map((benchmark, index) => (
        <BenchmarkTableRow
          key={benchmark.id}
          benchmark={benchmark}
          index={index}
        />
      ))}
    </>
  );
}

/// props validation | SQ(javascript:S6774)
BenchmarkTableRow.propTypes = {
  benchmark: PropTypes.object,
  index: PropTypes.number,
};
///
function BenchmarkTableRow(props) {
  const { benchmark, index } = props;

  const {
    project_name,
    standard,
    client,
    city,
    local_region_area,
    construction_cost_rate,
  } = benchmark;

  let serial = index + 1;
  serial = (serial < 10 && "0") + serial;

  // fields count should match BenchmarkTableHeaderRow columns count
  const fields = {
    serial,
    project_name,
    standard,
    client,
    city,
    local_region_area: comma(local_region_area),
    construction_cost_rate: commaToFixed(construction_cost_rate),
  };

  return (
    <>
      {Object.entries(fields).map(([key, value]) => (
        <div key={key} className="grid-item">
          {value}
        </div>
      ))}
    </>
  );
}
