import React from "react";
import "./DuplicateNamespaceModal.css";

// Functions
import { importLocalisation } from "./AdminLocalisation.functions";

export default function DuplicateNamespaceModal(props) {
  const { setShow, setReplace, data } = props;
  return (
    <div className="ald">
      A namespace with same name already exists. Do you want to replace the
      existing set of terminologies?
      <div className="general-button-container aldb">
        <button
          className="general-upload-button aldb"
          onClick={() => {
            setReplace(true);
            replaceTranslations();
            setShow(false);
          }}
        >
          Replace
        </button>

        <button
          className="general-modal-button aldb"
          onClick={() => {
            setReplace(false);
            setShow(false);
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );

  async function replaceTranslations() {
    let name = data.languageCode + "-" + data.terminologySetName;
    let response = await importLocalisation(name, data.file);

    if (response === "Success") {
      data.namespacesObject.set(await data.namespacesObject.get());
      await data.setSelectedNamespace(name.toLowerCase());
      data.setTerminologySetName("");
      data.setError({
        text: "Created Successfully",
        type: "feedback-success",
      });
      data.setFile(null);
    } else {
      data.setError({
        text: response,
        type: "feedback-error",
      });
    }
  }
}
