import React from "react";
import { tranStr } from "../../utils/translation";
import isEmpty from "../../validation/is-empty";
import { comma, commaToFixed } from "../../helpers/common.functions";
import { CommentNotification as ProjectCommentNotification } from "../portfolio-projects/ProjectItem";

// Refer "SummaryTable.css" for styles
import SummaryTable from "../common/summary-and-breakdown/SummaryTable";

export default function EstimateSummaryTable(props) {
  const { data, estimateId } = props;
  const { project } = props;

  const enableLineRefs = project.enable_cp_line_references;

  const TABLE_SCHEMA = {
    table: {
      className: "summary-table",
      headRow: {
        className: "summary-table-head-row",
      },
      rows: {
        className: (row, index) => getRowType(data, row, index),
        link: {
          show: (row) => !isEmpty(row.breakdown),
          className: "summary-table-row-link",
          path: (row) =>
            `/project/${project.id}/cost-planning/breakdown/${row.id}`,
        },
      },
      columns: {
        ref: {
          show: enableLineRefs,
          label: tranStr("Line"),
          className: "summary-table-section-cell",
          value: (row) => getSectionNumber(row),
        },
        description: {
          show: true,
          label: tranStr("Description"),
          className: enableLineRefs
            ? "summary-table-description-cell-ref"
            : "summary-table-description-cell",
          value: (row) => Description({ enableLineRefs, row }),
        },
        quantity: {
          show: true,
          label: tranStr("Quantity"),
          className: "summary-table-quantity-cell",
          value: (row) => comma(row.quantity),
        },
        unit_of_measure: {
          show: true,
          label: tranStr("Unit"),
          className: "summary-table-unit-cell",
          value: (row) => row.unit_of_measure,
        },
        rate: {
          show: true,
          label: tranStr("Rate"),
          className: "summary-table-rate-cell",
          value: (row) => (row.rate ? commaToFixed(row.rate) : null),
        },
        subtotal: {
          show: true,
          label: tranStr("Total"),
          className: "summary-table-subtotal-cell",
          value: (row) => comma(Math.round(row.subtotal)),
        },
      },
    },
  };

  return (
    <SummaryTable
      /* props for SummaryTable */
      schema={TABLE_SCHEMA}
      /* props for sub component */
      data={data}
      estimateId={estimateId}
      projectId={project.id}
      project={project}
      enableLineRefs={project.enable_cp_line_references}
    />
  );
}

function Description(props) {
  const { enableLineRefs } = props;
  const { row } = props;

  return (
    <>
      {row.description}
      {enableLineRefs && (
        <DisplayActions notifyCount={row.notification_count} />
      )}
    </>
  );
}

function DisplayActions(props) {
  const { notifyCount } = props;
  if (isEmpty(notifyCount)) {
    return null;
  }

  if (notifyCount < 1) {
    return null;
  }

  return <ProjectCommentNotification notification={notifyCount} />;
}

function getSectionNumber(row) {
  if (
    row.code === "st" ||
    row.code === "cc" ||
    row.code === "pc" ||
    row.code === "pct"
  ) {
    return "";
  } else {
    return row.section;
  }
}

function getRowType(data, row, index) {
  // Subtotal styling for other rows
  let rowType = "summary-table-row";
  if (
    row.code === "st" ||
    row.code === "cc" ||
    row.code === "pc" ||
    row.code === "pct"
  ) {
    rowType = "summary-table-head-row";
  }

  // CC is the total row if it is also the final row
  if (row.code === "cc" && index === data.length - 1) {
    rowType = "summary-table-total-row";
  }

  // PC or PCT is always a total row
  if (row.code === "pc" || row.code === "pct") {
    rowType = "summary-table-total-row";
  }

  return rowType;
}
