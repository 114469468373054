import React, { useEffect } from "react";
import "./CreateBenchmarkForm.css";
import { translate } from "../../utils/translation";
import isEmpty from "../../validation/is-empty";

export default function CreateBenchmarkFormMetrics(props) {
  const { metrics, setMetrics } = props;
  const { formIsEnabled } = props;
  const { benchmarkMetrics } = props;

  useEffect(() => {
    if (!isEmpty(benchmarkMetrics)) {
      setMetrics(benchmarkMetrics);
    } else {
      // Default Metric
      setMetrics([{ code: "", metric: "", quantity: "" }]);
    }
  }, [benchmarkMetrics, setMetrics]);

  if (!formIsEnabled) {
    return null;
  }

  return (
    <div className="create-benchmark-form-container-column-metrics">
      <div className="create-project-panel">
        <h1>{"Benchmark Metrics"}</h1>
        <div className="benchmarks-row">
          <div className="benchmarks-metric-code">
            <div>Code</div>
          </div>
          <div className="benchmarks-metric-metric">
            <div>Metric</div>
          </div>
          <div className="benchmarks-metric-quantity">
            <div>Value</div>
          </div>
        </div>
        <Table metrics={metrics} setMetrics={setMetrics} />
        <div className="benchmarks-row">
          <AddField fields={metrics} setFields={setMetrics} />
        </div>
      </div>
    </div>
  );
}

function Table(props) {
  const { metrics, setMetrics } = props;

  let displayMetrics = [];
  let keyIndex = 0;

  metrics.forEach((rowData, i) => {
    displayMetrics.push(
      <div key={keyIndex} className="benchmarks-row">
        <MetricInput
          id={"create-benchmark-form-metrics-code-" + keyIndex}
          className={"benchmarks-metric-code"}
          value={rowData.code}
          index={i}
          fields={metrics}
          setFields={setMetrics}
          keyName={"code"}
        />
        <MetricInput
          id={"create-benchmark-form-metrics-metric-" + keyIndex}
          className={"benchmarks-metric-metric"}
          value={rowData.metric}
          index={i}
          fields={metrics}
          setFields={setMetrics}
          keyName={"metric"}
        />
        <MetricInput
          id={"create-benchmark-form-metrics-quantity-" + keyIndex}
          className={"benchmarks-metric-quantity"}
          value={rowData.quantity}
          index={i}
          fields={metrics}
          setFields={setMetrics}
          keyName={"quantity"}
        />
        <button
          id="create-benchmark-form-metrics-delete-button"
          className="advice-delete-button"
          onClick={() => {
            deleteField(i, metrics, setMetrics);
          }}
        >
          <i className="far fa-trash-alt" />
        </button>
      </div>
    );
    keyIndex++;
  });

  return displayMetrics;
}

function MetricInput(props) {
  const { id, className, value } = props;
  const { keyName, index } = props;
  const { fields, setFields } = props;

  return (
    <input
      id={id}
      className={className}
      value={value}
      onChange={(e) => {
        updateField(keyName, e.target.value, index, fields, setFields);
      }}
    ></input>
  );
}

function updateField(field, value, i, fields, setFields) {
  let temp = [...fields];
  temp[i][field] = value;
  setFields(temp);
}

function deleteField(i, fields, setFields) {
  let temp = [...fields];

  temp.splice(i, 1);
  setFields(temp);
}

function addField(fields, setFields) {
  let temp = [...fields];

  temp.push({
    code: "",
    metric: "",
    quantity: "",
  });
  setFields(temp);
}

function AddField(props) {
  const { fields, setFields } = props;

  return (
    <button
      id="create-benchmark-form-metrics-add-button"
      className="general-modal-button"
      onClick={() => {
        addField(fields, setFields);
      }}
    >
      {translate("Add Metric")}
    </button>
  );
}
