import { useState, useEffect } from "react";
import { authHeader } from "../../_helpers/auth-header";
import { cpAPIs } from "../api-endpoints/CostPlanningEndpoints";
////////////////////////////////////////////////////////////////////
// Reconciliation - Detailed Comparison Comments
////////////////////////////////////////////////////////////////////

export function useComparisonComments(estimateID, projectID) {
  const [commentsLoading, setLoading] = useState(false);
  const [comparisonComments, setComparisonComments] = useState([]);
  useEffect(() => {
    setLoading(true);
    getComparisonComments(estimateID, projectID)
      .then((comments) => {
        setComparisonComments(comments);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [estimateID, projectID]);

  return {
    commentsLoading,
    comparisonComments,
    setComparisonComments,
    error: null,
  };
}

export async function getComparisonComments(estimateID, projectID) {
  // COST PLAN API
  const url = cpAPIs().comparison_commentary_get;

  // Compose the project into a payload
  const payload = {
    estimateID: estimateID,
    projectID: projectID,
  };
  const config = {
    method: "POST",
    body: JSON.stringify(payload),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };
  let erMessage = "Get Comparison Comments Error";
  let response = await fetch(url, config);

  if (response.ok) {
    response = await response.json();
  } else {
    response = [];
    console.log(erMessage);
  }

  return response;
}
