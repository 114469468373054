import React, { useState } from "react";
import isEmpty from "../../validation/is-empty";
import PropTypes from "prop-types";

import Table from "../table/Table";
import { columns } from "./SystemDashboardCostPlanTable.functions";
import { tranStr } from "../../utils/translation";

import "./SystemDashboardTable.css";

SystemDashboardCostPlanTable.propTypes = {
  data: PropTypes.array,
  dataLoading: PropTypes.bool,
};

export default function SystemDashboardCostPlanTable(props) {
  const { data, dataLoading } = props;

  // Table Row Selection
  const [selectedChecked, setSelectedChecked] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [modal, setModal] = useState(false);

  if (isEmpty(data) && dataLoading) {
    return null;
  }

  return (
    <div>
      <Table
        title={tranStr("Cost Plan Table")}
        tableArray={data}
        columns={columns()}
        tableSize={15}
        isTitleDisabled={true}
        isTotalDisabled={true}
        // Checked Selection
        checked={selectedChecked}
        setChecked={setSelectedChecked}
        // Row Selection
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        // Modal Control
        modal={modal}
        setModal={setModal}
      />
    </div>
  );
}
