import React from "react";
import isEmpty from "../../validation/is-empty";
//To trim long names
import { checkLength } from "./profile.functions";
import "./ProfileCard.css";
import { displayUserImage } from "../../validation/image-clean";
import { ImageDisplay } from "../images/ImageDisplay";

export default function ProfileCard(props) {
  const { user, show, setShow, setSelectedUser } = props;

  if (isEmpty(user)) {
    return null;
  }

  return (
    <button
      className="project-team-profile-card"
      onClick={() => showModal(show, setShow, user, setSelectedUser)}
    >
      <ProfileImage user={user} />
      <ProfileNameTitle user={user} />
      <ProfileCompany user={user} />
      <ProfileEmail user={user} />
      <ProfileNumberLocation user={user} />
    </button>
  );
}

function ProfileNameTitle(props) {
  const { user } = props;

  return (
    <div className="project-team-name-title">
      <div className="project-team-name">
        {checkLength(user.name + " " + user.surname, 30)}
      </div>
      <div className="project-team-job-title">{user.jobTitle}</div>
    </div>
  );
}

function ProfileNumberLocation(props) {
  const { user } = props;

  return (
    <div className="project-team-number-location">
      <div className="project-team-location">{user.city}</div>
      <div className="project-team-phone">{user.phone_number}</div>
    </div>
  );
}

function ProfileCompany(props) {
  const { user } = props;
  return <div className="project-team-company">{user.company_name}</div>;
}

function ProfileEmail(props) {
  const { user } = props;

  return <div className="project-team-email">{user.email}</div>;
}

function ProfileImage(props) {
  const { user } = props;
  return (
    <ImageDisplay
      image={displayUserImage(user)}
      imageWidth={"70px"}
      imageHeight={"70px"}
      borderRadius={"35px"}
      isBezierDisabled={true}
    />
  );
}
function showModal(show, setShow, user, setSelectedUser) {
  if (show) {
    setShow(false);
    setSelectedUser(user);
    setShow(false);
  } else {
    setSelectedUser(user);
    setShow(true);
  }
}
