import React from "react";
import isEmpty from "../../validation/is-empty";
import { comma, commaToFixed } from "../../helpers/common.functions";

import "./EstimatesSubtotal.css";

export default function EstimatesSubtotal(props) {
  const {
    estimates,
    estimateID,
    subtotal_below,
    subtotal_name,
    subtotal_gfa_override,
    project,
  } = props;

  const { isBreakdown } = props;
  const { selectedRow } = props;

  // Only return a subtotal if the estimate has a subtotal below it
  if (!subtotal_below) {
    return null;
  }

  let total = 0;
  let gfa = 0;
  let unit = project.unit_of_measure;
  let stop = false;
  // The last included Cost Plan before a Cost Plan Total
  let lastIncluded = true;

  // Look at each estimate to determine if a subtotal is required
  estimates.forEach((e) => {
    // Check if the estimate comes before the Cost Plan Total, triggering a reset
    // for the excluded Cost Plans below the Cost Plan Total
    if (e.is_included) {
      lastIncluded = true;
    } else {
      if (lastIncluded) {
        total = 0; // Reset
        gfa = 0; // Reset
      }
      lastIncluded = false;
    }

    // Do not add subtotals below this subtotal line
    if (stop) {
      return;
    }
    // If e has its own subtotal and is NOT this estimate we reset the counter
    if (e.subtotal_below && e.id !== estimateID) {
      total = 0; // Reset
      gfa = 0; // Reset
      return;
    }

    // Add total for e
    total += e.calculated_total;
    // Where an area override is saved use it
    if (subtotal_gfa_override) {
      gfa = subtotal_gfa_override;
      // Or add the local region area
    } else {
      gfa += e.local_region_area;
    }

    if (e.id === estimateID) {
      stop = true;
    }
  });

  let rate = Math.round(total / gfa);
  let displayTotal = comma(Math.round(total));

  if (isBreakdown) {
    if (selectedRow !== "breakdown") {
      return null;
    }
  }

  return (
    <div className="estimates-subtotal">
      <div className="estimates-subtotal-label">{subtotal_name}</div>
      <div className="estimates-subtotal-costs">
        <Detail />
        <div className="estimates-subtotal-total">{displayTotal}</div>
      </div>
    </div>
  );

  function Detail() {
    if (isEmpty(gfa) || isEmpty(unit) || isEmpty(rate) || gfa === 0) {
      return null;
    }

    return (
      <div className="estimates-subtotal-details">
        <div className="estimates-subtotal-gfa">{comma(gfa)}</div>
        <div className="estimates-subtotal-unit">{unit}</div>
        <div className="estimates-subtotal-rate">{commaToFixed(rate)}</div>
      </div>
    );
  }
}
