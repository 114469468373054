// For reducers to be seen they need to be imported into this
// root reducer file

import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import responseReducer from "./responseReducer";
import projectReducer from "./projectReducer";
import settingsReducer from "./settingsReducer";

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  response: responseReducer,
  project: projectReducer,
  settings: settingsReducer
});
