import React, { useState, useEffect } from "react";
import Spinner from "../common/Spinner";
import { useEstimates } from "../../api/estimates/EstimateAPI";
import CorrespondenceComments from "./CorrespondenceComments";
import CorrespondenceCommentsHeader from "./CorrespondenceCommentsHeader";

export default function ProjectComments(props) {
  const { user, project, selectedProjectId } = props;
  const { notificationsOnly, setCommentsLoading } = props;

  const { CPs } = useEstimates(selectedProjectId, project);
  const [CP, setCP] = useState({});

  useEffect(() => {
    setCommentsLoading(CPs.loading);
  }, [CPs.loading, setCommentsLoading]);

  // CPs loading
  if (CPs.loading) {
    return (
      <div className="correspondence-project-comments-loading">
        <Spinner />
      </div>
    );
  }

  // CPs loaded
  return (
    <div className="correspondence-project-comments">
      <CorrespondenceCommentsHeader
        project={project}
        CPs={CPs}
        CP={CP}
        setCP={setCP}
      />
      <CorrespondenceComments
        project={project}
        user={user}
        CPs={CPs}
        CP={CP}
        notificationsOnly={notificationsOnly}
      />
    </div>
  );
}
