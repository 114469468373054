import React, { useState } from "react";
import PropTypes from "prop-types";

import Table from "../table/Table";
import Modal from "../modal/Modal";

import ProjectProfile from "./ProjectProfile";
import Spinner from "../common/Spinner";

import { projectColumns } from "./ProjectManagementTable.functions";
import { tranStr } from "../../utils/translation";
import { displayProjectImage } from "../../validation/image-clean";

ProjectManagement.propTypes = {
  projects: PropTypes.object,
  user: PropTypes.object,
};

export default function ProjectManagement(props) {
  const { projects } = props;
  const { user } = props;

  // Clicked Row
  const [selectedProject, setSelectedProject] = useState({});
  const [modal, setModal] = useState(false);

  if (projects.loading) {
    return (
      <div className="project-management-content">
        <Spinner marginLeft={"625px"} marginTop={"200px"} />
      </div>
    );
  }

  const projectsArray = projects.data.map((u) => {
    u.image = displayProjectImage(u);
    return u;
  });

  return (
    <div className="project-management-content">
      <Table
        title={tranStr("Projects")}
        tableArray={projectsArray}
        columns={projectColumns()}
        tableSize={15}
        isTitleDisabled={true}
        isTotalDisabled={true}
        // Row Selection
        selectedRow={selectedProject}
        setSelectedRow={setSelectedProject}
        // Modal Control
        setModal={setModal}
      />
      <Modal
        // Modal Props
        title={"Project Profile"}
        Component={ProjectProfile}
        modal={modal}
        setModal={setModal}
        // Component Props
        show={modal}
        setShow={setModal}
        selectedProject={selectedProject}
        projects={projects}
        user={user}
      />
    </div>
  );
}
