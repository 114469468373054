import React, { useState } from "react";
import CorrespondenceModalRightPane from "./CorrespondenceModalRightPane";
import CorrespondenceModalLeftPane from "./CorrespondenceModalLeftPane";

export default function CorrespondenceModal(props) {
  const { user, projects } = props;

  const [selectedProjectId, setSelectedProjectId] = useState(0);

  const [notificationsOnly, setNotificationsOnly] = useState(true);
  const [commentsLoading, setCommentsLoading] = useState(false);

  return (
    <div className="correspondence-modal-wrapper">
      <div className="correspondence-left-nav">
        <CorrespondenceModalLeftPane
          projects={projects}
          selectedProjectId={selectedProjectId}
          setSelectedProjectId={setSelectedProjectId}
          notificationsOnly={notificationsOnly}
          setNotificationsOnly={setNotificationsOnly}
          commentsLoading={commentsLoading}
        />
      </div>
      <div className="correspondence-right-pane">
        <CorrespondenceModalRightPane
          user={user}
          selectedProjectId={selectedProjectId}
          notificationsOnly={notificationsOnly}
          setCommentsLoading={setCommentsLoading}
        />
      </div>
    </div>
  );
}
