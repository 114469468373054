import { VALUE_ENGINEERING } from "./ValueEngineering.strings";

export default class ValueEngineeringItem {
  true; // bypass SonarLint bug 'Unexpected class with only a constuctor'

  constructor({
    title = "",
    description = "",
    work_package = "",
    location = "",
    value = "",
    status = "",
    wbs_code = "",
    action = "",
    action_owner = "",
    programme_impact = "",
    environmental_impact = "",
    last_updated_by = "",
    last_updated_date = "",
  } = {}) {
    this.id = Date.now().toString();
    this.title = title || "";
    this.description = description || "";
    this.work_package = work_package || "";
    this.location = location || "";
    this.value = value;
    this.status = status || VALUE_ENGINEERING.STATUS.FORREVIEW;
    this.wbs_code = wbs_code;
    this.action = action;
    this.action_owner = action_owner;
    this.programme_impact = programme_impact;
    this.environmental_impact = environmental_impact;
    this.last_updated_by = last_updated_by;
    this.last_updated_date = last_updated_date;
  }
}
