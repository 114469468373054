import React, { useState } from "react";
import { CurrencyExchangeModal } from "./CurrencyExchangeModal";
import Modal from "../../modal/Modal";
import { hasRoles } from "../../../utils/roles";
import PropTypes from "prop-types";

/// props validation | SQ(javascript:S6774)
CurrencyExchangeUpdate.propTypes = {
  currencies: PropTypes.array,
  user: PropTypes.object,
  currencyExchange: PropTypes.object,
};
export default function CurrencyExchangeUpdate(props) {
  const { currencies, user } = props;

  const { currencyExchange } = props;

  const [modal, setModal] = useState(false);

  if (!window.location.href.includes("currency-exchange")) {
    return null;
  }

  if (!hasRoles(user.roles, ["Admin"])) {
    return null;
  }
  return (
    <div>
      <Modal
        // Modal Props
        title={"Assign Currency Exchange"}
        Component={CurrencyExchangeModal}
        modal={modal}
        setModal={setModal}
        // Component Props
        currencyExchange={currencyExchange}
        currencies={currencies}
      />
      <input
        type="button"
        id={"button-location-factor"}
        className="location-factor-set-button"
        value="Update Currency Exchange"
        onClick={() => {
          setModal(true);
        }}
      ></input>
    </div>
  );
}
