import React from "react";
import { tranStr } from "../../utils/translation";

import Accordion from "../accordion/Accordion";
import EstimateBreakdownTable from "../estimate-breakdown-table/EstimateBreakdownTable";
import EstimatesSubtotal from "../estimates/EstimatesSubtotal";
import EstimatesTPI from "../estimates/EstimatesTPI";
import { ExportEstimatedBreakDown } from "../export/ExportEstimate";

// Style
import "./Breakdown.css";
import { FilteredEstimatedBreakDownModal } from "./EstimateBreakdownCommentModal";

export default function Breakdown(props) {
  const { estimate } = props;
  const { selectedRow } = props;

  const isBreakdown = selectedRow === "breakdown";

  const isEstimateContainingSelectedRow = () =>
    estimate.rows.some((row) => row.id.toString() === selectedRow.toString());

  // isBreakdown = true --> Cost Planning > Breakdown was selected
  // isBreakdown = false ---> Cost Planning > Summary > specific row item was selected

  if (isBreakdown || isEstimateContainingSelectedRow()) {
    return <EstimatesBreakdown {...{ ...props, isBreakdown }} />;
  }

  return null; // return nothing failing all above conditions
}

function EstimatesBreakdown(props) {
  const { user } = props;
  const { project } = props;
  const { CP } = props;
  const { CPs } = props;
  const { estimate } = props;
  const { TPI } = props;
  const { accordionState } = props;
  const { selectedRow, isBreakdown } = props;

  // State management from parent
  const { isLinkedTPI, setIsLinkedTPI } = props;
  const { linkedTPISelectedYear, setLinkedTPISelectedYear } = props;
  const { linkedTPIPercentage, setLinkedTPIPercentage } = props;

  const uom = project.unit_of_measure;
  const total = estimate.calculated_total;
  const rate = Math.round(total / estimate.local_region_area);

  return (
    <div className="estimate-wrapper">
      <div className="estimate-flex-box">
        <Accordion
          key={estimate.id}
          label={estimate.name + " - " + tranStr("Breakdown")}
          local_region_area={estimate.local_region_area}
          unit={uom}
          rate={rate}
          total={Math.round(total)}
          isOpen={!isBreakdown}
          // State
          accordionState={accordionState}
          accordionSection={"BREAKDOWN"}
          estimateID={estimate.id}
        >
          <div className="estimate-breakdown-table-and-TPI-container">
            <EstimateBreakdownTable
              estimateID={estimate.id}
              data={estimate.rows}
              CPs={CPs}
              project={project}
              selectedRow={selectedRow}
            />
            <EstimatesTPI
              estimate={estimate}
              project={project}
              user={user}
              TPI={TPI}
              CPs={CPs}
              isBreakdown={true}
              selectedRow={selectedRow}
              isClassic={CP.estimates.length < 2}
              isLinkedTPI={isLinkedTPI}
              setIsLinkedTPI={setIsLinkedTPI}
              linkedTPISelectedYear={linkedTPISelectedYear}
              setLinkedTPISelectedYear={setLinkedTPISelectedYear}
              linkedTPIPercentage={linkedTPIPercentage}
              setLinkedTPIPercentage={setLinkedTPIPercentage}
            />
          </div>
        </Accordion>
        <FilteredEstimatedBreakDownModal
          estimateID={estimate.id}
          data={estimate.rows}
          CPs={CPs}
          project={project}
          selectedRow={selectedRow}
        />
        {isBreakdown && <ExportEstimatedBreakDown estimate={estimate} />}
      </div>
      <EstimatesSubtotal
        project={project}
        estimates={CP.estimates}
        estimateID={estimate.id}
        subtotal_below={estimate.subtotal_below}
        subtotal_name={estimate.subtotal_name}
        subtotal_gfa_override={estimate.subtotal_gfa_override}
        isBreakdown={true}
        selectedRow={selectedRow}
      />
    </div>
  );
}
