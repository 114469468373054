import React from "react";
import { Route, Redirect } from "react-router-dom";
import Welcome from "../welcome/Welcome";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// MSAL
import userSession from "../../active-directory/userSession";

// Block access to components if user is not authenticated
function PrivateRoute({ component: Component, auth, ...rest }) {
  const URL = window.location.href;
  const subLocation = URL.substring(URL.lastIndexOf("/") + 1);

  // Handle user session
  userSession({ privateRoute: true });

  // If user is authenticated but has no profile
  if (auth.isAuthenticated === true && !auth.user.hasProfile) {
    return (
      <Route {...rest} render={(props) => <Welcome {...rest} {...props} />} />
    );
  }

  // Prevent user from navigating to welcome page
  if (subLocation === "welcome" && auth.user.hasProfile) {
    return <Route {...rest} render={() => <Redirect to="/" />} />;
  }

  // If user is authenticated load the component
  if (auth.isAuthenticated === true) {
    return (
      <Route {...rest} render={(props) => <Component {...rest} {...props} />} />
    );
  }

  // All other instances redirect to root
  return <Route {...rest} render={() => <Redirect to="/" />} />;
}

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
