import PropTypes from "prop-types";

import BenchmarkCreateAnalysisConfiguration from "./BenchmarkCreateAnalysisConfiguration.class";

import { MASTER_BENCHMARKING } from "./BenchmarkCreate.strings";

export default class BenchmarkCreateAnalysisPersistence {
  true; // bypass SonarLint bug 'Unexpected class with only a constuctor'

  constructor({
    user,
    id,
    title,
    configuration,
    selectedBenchmarkIds,
    benchmarkSelection,
    isConfigurationComplete,
    isBenchmarkSelectionComplete,
    isGenerateReady,
  } = {}) {
    this.user = user;
    this.id = id;
    this.title = title || MASTER_BENCHMARKING.TITLE_PLACEHOLDER;
    this.configuration = configuration || {};
    this.selectedBenchmarkIds = selectedBenchmarkIds || [];
    this.benchmarkSelection = benchmarkSelection || [];

    this.isConfigurationComplete = isConfigurationComplete || false;
    this.isBenchmarkSelectionComplete = isBenchmarkSelectionComplete || false;
    this.isGenerateReady = isGenerateReady || false;
  }
}

/// props validation | SQ(javascript:S6774)
BenchmarkCreateAnalysisPersistence.propTypes = {
  user: PropTypes.object,
  id: PropTypes.string,
  title: PropTypes.string,
  configuration: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.instanceOf(BenchmarkCreateAnalysisConfiguration),
  ]),
  selectedBenchmarkIds: PropTypes.array,
  benchmarkSelection: PropTypes.array,
  isConfigurationComplete: PropTypes.bool,
  isBenchmarkSelectionComplete: PropTypes.bool,
  isGenerateReady: PropTypes.bool,
};
///
