import React from "react";
import { Link } from "react-router-dom";

import "./Path.css";

export default function AdminPath(props) {
  let subLocation = determineSubLocation();

  return (
    <div className="admin-path">
      <Link to="/portfolio" className="path-link">
        {"Portfolio"}
      </Link>
      <div className="path-divider">{" > "}</div>
      <Link to={"/admin"} className="path-link-current">
        {"Admin"}
      </Link>
      <div className="path-divider">{" > "}</div>
      <Link to={`/admin/${subLocation}`} className="path-link-current">
        {humanize(subLocation)}
      </Link>
    </div>
  );
}

function determineSubLocation() {
  const URL = window.location.href;

  return URL.substring(URL.lastIndexOf("/") + 1);
}

function humanize(str) {
  let frags = str.split("-");
  for (let i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(" ");
}
