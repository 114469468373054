import { tranStr } from "../../utils/translation";

export function columns() {
  return [
    {
      type: "CHECK",
      key: "id",
      width: 50,
      isModal: false,
    },
    {
      heading: tranStr("Name"),
      key: "authorName",
      type: "DESCRIPTION",
      width: 100,
    },
    {
      heading: tranStr("Surname"),
      key: "authorSurname",
      type: "DESCRIPTION",
      width: 100,
    },
    {
      heading: tranStr("Email"),
      key: "authorEmail",
      type: "DESCRIPTION",
      width: 230,
    },
    {
      heading: tranStr("Type"),
      key: "type",
      type: "DESCRIPTION",
      width: 150,
    },
    {
      heading: tranStr("Comment"),
      key: "text",
      type: "DESCRIPTION",
      width: 650,
    },
    {
      heading: tranStr("Note"),
      key: "note",
      type: "DESCRIPTION",
      width: 150,
    },
    {
      heading: tranStr("Actioned"),
      key: "actioned",
      type: "DESCRIPTION",
      width: 60,
      // Cell: (p) => (
      //   <span>
      //     {p.value ? (
      //       <i style={{ color: "green" }} className="fas fa-check" />
      //     ) : (
      //       <i style={{ color: "red" }} className="fas fa-times" />
      //     )}
      //   </span>
      // ),
    },
    {
      heading: tranStr("Date"),
      key: "date",
      type: "DATE",
      width: 160,
    },
  ];
}
