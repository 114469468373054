import React from "react";

import "./ProjectDashboardImage.css";

import { displayProjectImage } from "../../validation/image-clean";
import { ImageDisplay } from "../images/ImageDisplay";

export default function ProjectDashboardImage(props) {
  const { project } = props;

  return (
    <ImageDisplay
      image={displayProjectImage(project)}
      imageWidth={"500px"}
      imageHeight={"270px"}
      isBezierDisabled={true}
    />
  );
}
