import { tranStr } from "../../utils/translation";

export const VALUE_ENGINEERING = {
  TITLE: tranStr("Value Management"),
  TITLE_PLACEHOLDER: tranStr("<untitled>"),
  STATUS: {
    APPROVED: tranStr("Approved"),
    FORREVIEW: tranStr("For Review"),
    REJECTED: tranStr("Rejected"),
  },
  WARNINGS: {
    VM_NOT_AVAILABLE_UNTIL_CP_UPLOAD: tranStr(
      "Value Management is available after a Cost Plan has been uploaded."
    ),
    VM_NOT_AVAILABLE_WHEN_NOT_IN_COST_PLANNING: tranStr(
      "Value Management items cannot be edited as the project is not in the Cost Planning phase."
    ),
    VM_NOT_YET_ADDED: (
      <span>
        {tranStr("Value Management has not been added yet.")}
        <br />
        {tranStr(
          "Please click Add VM Item to start adding Value Management items."
        )}
      </span>
    ),
    VM_ITEM_DELETED: (
      <span>
        {tranStr("The selected item has been deleted.")}
        <br />
        {tranStr("To restore, click Revert.")}
        <br />
        <br />
        {tranStr("To save changes, click Save.")}
      </span>
    ),
    VM_ITEMS_SAVE_VALIDATION: tranStr(
      "Please enter all title and value fields"
    ),
  },
  DISCLAIMER: {
    LINE_1_FOR_SINGLE_TABLE: tranStr(
      "The solutions included in this table provide an indication of potential impacts only.  Value studies in this section are primarily comparative - they are intended to identify the best value-adding solution from among those being considered."
    ),
    LINE_1_FOR_MULTIPLE_TABLES: tranStr(
      "The solutions included in these tables provide an indication of potential impacts only.  Value studies in this section are primarily comparative - they are intended to identify the best value-adding solution from among those being considered."
    ),
    LINE_2: tranStr(
      "The updated totals shown should not be considered as confirmed until the solutions have been incorporated into an updated design, and an updated estimate has been produced."
    ),
  },
  FIELDS: {
    REF: tranStr("Ref"),
    TITLE: tranStr("Title"),
    DESCRIPTION: tranStr("Description"),
    WORK_PACKAGE: tranStr("Package"),
    LOCATION: tranStr("Location"),
    VALUE: tranStr("Cost Impact"),
    APPROVED_COST: tranStr("Approved Cost"),
    FORREVIEW_COST: tranStr("For Review Cost"),
    REJECTED_COST: tranStr("Rejected Cost"),
    STATUS: tranStr("Status"),
    WBS_CODE: tranStr("Enter the Element Code from this Estimate's CBS"),
    ACTION: tranStr("Action"),
    ACTION_OWNER: tranStr("Action Owner"),
    PROGRAMME_IMPACT: tranStr("Programme Impact"),
    ENVIRONMENTAL_IMPACT: tranStr("Environmental Impact"),
    LAST_UPDATED_BY: tranStr("Last Updated by"),
    LAST_UPDATED_DATE: tranStr("Last Updated date"),
  },
  FIELD_DEFAULTS: {
    WBS_CODE: "1.0",
  },
  ACTIONS: {
    ADD_VM_ITEM: {
      TOOLTIP: "",
      LABEL: tranStr("Add VM Item"),
    },
    DELETE_VM_ITEM: {
      TOOLTIP: "",
      LABEL: "",
    },
    DISCARD_CHANGES: {
      TOOLTIP: "",
      LABEL: tranStr("Revert"),
    },
    SAVE_VM_ITEMS: {
      TOOLTIP: "",
      LABEL: tranStr("Save"),
    },
  },
};
