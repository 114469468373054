import ProjectBudgetDetailedSummary from "../project-budget/ProjectBudgetDetailedSummary";
import BudgetSummaryChart from "../charts/BudgetSummaryChart";
import isEmpty from "../../validation/is-empty";

import "./ProjectBudgetCharts.css";

export default function ProjectBudgetCharts(props) {
  const { data } = props;

  if (isEmpty(data)) {
    return null;
  }

  let total = 0;
  let approvedTotal = 0;

  data.forEach((row) => {
    total += parseFloat(row.budget);
    if (row.is_approved) {
      approvedTotal += parseFloat(row.budget);
    }
  });

  return (
    <div className="project-budget-charts">
      <div className="project-budget-chart-box">
        <div className="doughnut-chart-container">
          <div className="costplanning-chart-box-cp-dashboard2">
            <h1 className="display-4" style={{ fontSize: "20px" }}>
              {"Budget Detailed Summary"}
            </h1>
          </div>
          <ProjectBudgetDetailedSummary data={data} totalBudget={total} />
        </div>
      </div>
      <BudgetSummaryChart budgetTotals={[total, approvedTotal]} />
    </div>
  );
}
