import { useState, useEffect } from "react";
import { authHeader } from "../../_helpers/auth-header";
import { sharedAPIs } from "../api-endpoints/SharedServiceEndpoints";
import { getDmsToken } from "../../active-directory/getDmsToken";
import isEmpty from "../../validation/is-empty";

// Get a list of locations
export async function getInsights() {
  const dmsToken = await getDmsToken();

  let dmsBearer = "";
  if (!isEmpty(dmsToken)) {
    dmsBearer = dmsToken.accessToken;
  }
  const url = sharedAPIs().get_insights;

  let response = {};

  try {
    response = await fetch(url, {
      method: "GET",
      headers: {
        ...authHeader({ authJson: true, authForm: false, guestJson: false }),
        dmsBearer: `Bearer ${dmsBearer}`,
      },
    });

    if (response.ok) {
      return response.json();
    } else {
      return [];
    }
  } catch (e) {
    console.log("getInsights failed");
    console.log(e.message);
    return [];
  }
}

export function useInsights() {
  const [insightsLoading, setLoading] = useState(true);
  const [insights, setInsights] = useState([]);
  useEffect(() => {
    setLoading(true);
    getInsights()
      .then((insightsRes) => {
        setInsights(insightsRes);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  return {
    insightsLoading,
    insights,
    setInsights,
    error: null,
  };
}

export function useInsightsArticleUserRead(userId, region) {
  const [articleReadLoading, setLoading] = useState(true);
  const [articlesRead, setArticlesRead] = useState([]);
  useEffect(() => {
    if (userId) {
      setLoading(true);
      getArticlesRead(userId, region)
        .then((articlessRes) => {
          setArticlesRead(articlessRes);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [userId, region]);

  return {
    articleReadLoading,
    articlesRead,
    setArticlesRead,
    error: null,
  };
}

export async function getArticlesRead(userId, region) {
  const data = {
    userId: userId,
    region: region,
  };
  const config = {
    method: "POST",
    body: JSON.stringify(data),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  const url = sharedAPIs().get_insights_read;
  let erMessage = "getArticlesRead Error";
  let response = await fetch(url, config);
  if (response.ok) {
    response = await response.json();
  } else {
    response = [];
    console.log(erMessage);
  }
  return response;
}
