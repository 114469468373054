// React
import React from "react";
import isEmpty from "../../validation/is-empty";

// Components
import PortfolioControlRow from "./PortfolioControlRow";

export default function PortfolioList(props) {
  const { portfolioId } = props;
  const { portfolios } = props;

  const { setIsDropEnabled } = props;
  const { setIsEditEnabled } = props;

  const display = [];

  display.push(
    <PortfolioControlRow
      portfolioId={portfolioId}
      key={"all-projects"}
      portfolio={{ id: "all-projects", name: "All Projects", project_ids: "*" }}
      setIsDropEnabled={setIsDropEnabled}
      setIsEditEnabled={setIsEditEnabled}
    />
  );

  if (!isEmpty(portfolios.data)) {
    portfolios.data.forEach((portfolio, i) => {
      display.push(
        <PortfolioControlRow
          portfolioId={portfolioId}
          key={i}
          portfolio={portfolio}
          setIsDropEnabled={setIsDropEnabled}
          setIsEditEnabled={setIsEditEnabled}
        />
      );
    });
  }

  return <div className="portfolio-control-selection">{display}</div>;
}
