import React from "react";
import { GENERATED_CASHFLOW, TEMPLATE_CASHFLOW } from "../../actions/types";
import { translate } from "../../utils/translation";
import "./CashflowTable.css";
import PropTypes from "prop-types";

const PostContractCashflowToggle = (props) => {
  const { cashflowBasis, setCashflowBasis } = props;

  let templateToggle, generatedToggle;

  if (cashflowBasis === TEMPLATE_CASHFLOW) {
    templateToggle = "cashflow-toggle-button-in-left";
    generatedToggle = "cashflow-toggle-button-out-right";
  } else {
    templateToggle = "cashflow-toggle-button-out-left";
    generatedToggle = "cashflow-toggle-button-in-right";
  }

  return (
    <div className="cashflow-toggle">
      <button
        className={templateToggle}
        onClick={() => {
          setCashflowBasis(TEMPLATE_CASHFLOW);
        }}
      >
        {translate("Template")}
      </button>
      <button
        className={generatedToggle}
        onClick={() => {
          setCashflowBasis(GENERATED_CASHFLOW);
        }}
      >
        {translate("Generated")}
      </button>
    </div>
  );
};
PostContractCashflowToggle.propTypes = {
  cashflowBasis: PropTypes.string,
  setCashflowBasis: PropTypes.func,
};
export default PostContractCashflowToggle;
