// React
import React, { useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { translate } from "../../utils/translation";

// Paths
import AdminPath from "../path/AdminPath";
import AdminNavigation from "./AdminNavigation";
import RegionManagement from "../admin-locations/LocationManagement";
import FeedbackManagement from "../admin-feedback/FeedbackManagement";

// Management Modules
import AccountManagement from "../admin-accounts/AccountManagement";
import ProjectManagement from "../admin-projects/ProjectManagement";
import CompanyManagement from "../admin-companies/CompanyManagement";
import DemoManagement from "../admin-demo/DemoManagement";
import AdminLocalisation from "../admin-localisation/AdminLocalisation";
import SystemDashboard from "../admin-system-dashboard/SystemDashboard";
import Migration from "../admin-migration/Migration";
import CbsAdmin from "../cbs/CbsAdmin";

// Filters
import AccountFilter from "../admin-accounts/AccountFilter";
import ProjectFilter from "../admin-projects/ProjectFilter";
import CompanyFilter from "../admin-companies/CompanyFilter";
import { accountFilter } from "../admin-accounts/AccountFilter.functions";
import { projectFilter } from "../admin-projects/ProjectFilter.functions";
import { companyFilter } from "../admin-companies/CompanyFilter.functions";

// Components
import CompanyControlPanel from "../admin-companies/CompanyManagementComponents";

// Functions
import { attachCompanies } from "./Admin.functions";

// API
import { useAdminCompanies } from "../../api/admin/AdminCompanyAPI";
import { useAccounts } from "../../api/users/UserAPI";
import { useProjects } from "../../api/admin/ProjectAPI";

import "./Admin.css";

function Admin(props) {
  const { user } = props.auth;

  const { accounts } = useAccounts();
  const { projects } = useProjects();
  const { companies } = useAdminCompanies();

  attachCompanies(accounts.data, projects.data, companies.data);

  const [accountFilters, setAccountFilters] = useState({});
  const [projectFilters, setProjectFilters] = useState({});
  const [companyFilters, setCompanyFilters] = useState({});

  accounts.data = accountFilter(accounts.data, accountFilters);
  projects.data = projectFilter(projects.data, projectFilters);
  companies.data = companyFilter(companies.data, companyFilters);

  return (
    <div className="admin-background">
      <div className="admin-control-column-backer">
        <div className="admin-control-column">
          <div className="admin-navigation-container">
            <div className="admin-navigation-title">
              <h1 className="display-4" style={{ fontSize: "30px" }}>
                {translate("Admin")}
              </h1>
            </div>
            <AdminNavigation user={user} />
          </div>
          <CompanyControlPanel companies={companies} />
          <AccountFilter setFilters={setAccountFilters} accounts={accounts} />
          <ProjectFilter setFilters={setProjectFilters} />
          <CompanyFilter setFilters={setCompanyFilters} />
        </div>
      </div>

      <div className="admin-top"></div>
      <div>
        <div className="admin-container">
          <AdminPath />

          <div className="control-container">
            <Switch>
              <Route
                exact
                path={"/admin"}
                render={() => (
                  <Redirect replace to={`/admin/account-control`} />
                )}
              />
              <Route
                path={"/admin/account-control"}
                render={() => (
                  <AccountManagement accounts={accounts} projects={projects} />
                )}
              />
              <Route
                path={"/admin/project-control"}
                render={() => (
                  <ProjectManagement projects={projects} user={user} />
                )}
              />
              <Route
                path={"/admin/company-control"}
                render={() => <CompanyManagement companies={companies} />}
              />
              <Route
                path={"/admin/location-control"}
                render={() => <RegionManagement />}
              />
              <Route
                path={"/admin/feedback-control"}
                render={() => <FeedbackManagement />}
              />
              <Route
                path={"/admin/demo-control"}
                render={() => <DemoManagement />}
              />
              <Route
                path={"/admin/localisation-control"}
                render={() => <AdminLocalisation user={user} />}
              />
              <Route
                path={"/admin/system-dashboard-control"}
                render={() => <SystemDashboard user={user} />}
              />
              <Route
                path={"/admin/migration"}
                render={() => <Migration user={user} />}
              />
              <Route
                path={"/admin/cbs"}
                render={() => <CbsAdmin user={user} />}
              />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect((state) => ({
  auth: state.auth,
}))(Admin);
