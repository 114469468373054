import { useState, useEffect } from "react";
import { authHeader } from "../../_helpers/auth-header";
import { sharedAPIs } from "../api-endpoints/SharedServiceEndpoints";

// Get a list of companies by user email
export function useCompaniesByUser(user) {
  const [companiesLoading, setLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [companiesError, setError] = useState("");
  useEffect(() => {
    if (user) {
      setLoading(true);
      getCompaniesByUser(user)
        .then((companiesRes) => {
          setCompanies(companiesRes.companies);
          setLoading(false);
          if (companiesRes.error) {
            setError(companiesRes.message);
          }
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [user]);

  return {
    companiesLoading,
    companies,
    setCompanies,
    companiesError,
  };
}

export async function getCompaniesByUser(user) {
  const url = sharedAPIs().get_companies_by_email_domain;
  const payload = {
    user: {
      email: user.email,
    },
  };

  const config = {
    method: "POST",
    body: JSON.stringify(payload),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let erMessage = "Get getCompaniesByUser error";

  let response = [];
  try {
    response = await fetch(url, config);
  } catch (e) {
    return response;
  }

  if (response.ok) {
    response = await response.json();
  } else {
    response = [];
    console.log(erMessage);
  }

  return response;
}
