import React, { useState, useEffect } from "react";

import "./TenderPriceIndexingManagement.css";

import TenderPriceIndexAdminDashboard from "./TenderPriceIndexAdminDashboard";
import isEmpty from "../../validation/is-empty";

import ExportTPI from "../export/ExportTPI";

import {
  TableHeader,
  Table,
  TableSubHeader,
} from "./TenderPriceIndexingManagement.components";

export default function TenderPriceIndexingManagement(props) {
  const { TPI } = props;
  const { selectedLocations } = props;
  const { setLocations } = props;

  const [maxYear, setMaxYear] = useState("");
  const [minYear, setMinYear] = useState("");

  useEffect(() => {
    if (!isEmpty(TPI) && !isEmpty(TPI.years)) {
      setMinYear(TPI.years[0]);
      setMaxYear(TPI.years[TPI.years.length - 1]);
    }
  }, [TPI]);

  if (isEmpty(TPI.raw)) {
    return <div>There are no TPI uploaded for this country</div>;
  }

  return (
    <div className="tpi-admin-container">
      <TenderPriceIndexAdminDashboard
        TPI={TPI}
        maxYear={maxYear}
        setMaxYear={setMaxYear}
        minYear={minYear}
        setMinYear={setMinYear}
        selectedLocations={selectedLocations}
        setLocations={setLocations}
      />
      <div className="tpi-admin-table-container">
        <div className="tpi-admin-table">
          <div className="tpi-admin-table-header">
            <TableHeader TPI={TPI} selectedLocations={selectedLocations} />
          </div>
          <div className="tpi-admin-table-header">
            <TableSubHeader TPI={TPI} selectedLocations={selectedLocations} />
          </div>

          <Table
            TPI={TPI}
            maxYear={maxYear}
            minYear={minYear}
            selectedLocations={selectedLocations}
          />
        </div>
        <ExportTPI TPI={TPI} selectedLocations={selectedLocations} />
      </div>
    </div>
  );
}
