import { SET_CURRENT_USER } from "../actions/types";

import isEmpty from "../validation/is-empty";

const initialState = {
  isAuthenticated: false,
  user: {},
};

function authReducer(state, action) {
  if (isEmpty(state)) {
    state = initialState;
  }

  if (action.type === SET_CURRENT_USER) {
    return {
      ...state,
      // Authentication will depend on the payload being empty.
      // The user will be the payload. When we log out we can pass in
      // an empty payload and the user will go back to being an
      // empty object and no longer be authenticated.
      isAuthenticated: !isEmpty(action.payload),
      user: action.payload,
    };
  } else {
    return state;
  }
}

export default authReducer;
