import React from "react";
import "./BenchmarkPage.css";
import isEmpty from "../../validation/is-empty";
import { comma } from "../../helpers/common.functions";

export default function BenchmarkPage(props) {
  const { benchmark } = props;
  const { cbs } = props;

  return (
    <div className="benchmark-page">
      <div className="benchmark-page-details"></div>
      <BenchmarkElements
        benchmark={benchmark}
        cbs={cbs}
        elements={benchmark.rows}
      />
      <div className="benchmark-page-metrics"></div>
    </div>
  );
}

function BenchmarkElements(props) {
  const { benchmark } = props;
  const { elements } = props;
  const { cbs } = props;

  const cbsCodes = cbs?.data?.elementCodes;

  const benchmarkElements = [];

  cbsCodes.forEach((cbsCode) => {
    const elementRow = elements.find((e) => e.code === cbsCode.code);
    if (!isEmpty(elementRow)) {
      benchmarkElements.push(elementRow);
    } else {
      benchmarkElements.push(cbsCode);
    }
  });

  const displayBenchmarkElements = [
    <div className="benchmark-page-element" key="1">
      <div className="benchmark-page-code">Code</div>
      <div className="benchmark-page-description">Description</div>
      <div className="benchmark-page-unit">Unit</div>
      <div className="benchmark-page-quantity">Elemental Quantity</div>
      <div className="benchmark-page-rate">Elemental Rate</div>
      <div className="benchmark-page-rate">Local Region Area</div>
      <div className="benchmark-page-rate">Local Region Rate</div>
      <div className="benchmark-page-rate">Gia</div>
      <div className="benchmark-page-rate">Gia Rate</div>
      <div className="benchmark-page-total">Total</div>
    </div>,
  ];

  benchmarkElements.forEach((element) => {
    displayBenchmarkElements.push(
      <div className="benchmark-page-element" key={element.id}>
        <div className="benchmark-page-code">{element.code}</div>
        <div className="benchmark-page-description">{element.description}</div>
        <div className="benchmark-page-unit">{element.unit}</div>
        <div className="benchmark-page-quantity">{comma(element.quantity)}</div>
        <div className="benchmark-page-rate">{comma(element.rate)}</div>
        <div className="benchmark-page-rate">
          {comma(benchmark.local_region_area)}
        </div>
        <div className="benchmark-page-rate">
          {comma(element.local_region_rate)}
        </div>
        <div className="benchmark-page-rate">{comma(benchmark.gia)}</div>
        <div className="benchmark-page-rate">{comma(element.gia_rate)}</div>
        <div className="benchmark-page-total">{comma(element.total)}</div>
      </div>
    );
  });

  return (
    <div className="benchmark-page-elements">{displayBenchmarkElements}</div>
  );
}
