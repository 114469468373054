import React from "react";
import Pagination from "react-js-pagination";

import "./PortfolioPagination.css";

const PortfolioPagination = (props) => {
  const { activePage } = props;
  const { setActivePage } = props;
  const { filteredProjects } = props;
  const { pageSize } = props;

  //get total number of items after filter
  const totalItems = filteredProjects.length;

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
  }

  return (
    <div className="portfolio-page-control">
      <Pagination
        activePage={activePage}
        itemsCountPerPage={pageSize}
        totalItemsCount={totalItems}
        pageRangeDisplayed={5}
        onChange={handlePageChange}
        firstPageText={<i className="fas fa-angle-double-left"></i>}
        prevPageText={<i className="fas fa-chevron-left"></i>}
        nextPageText={<i className="fas fa-chevron-right"></i>}
        lastPageText={<i className="fas fa-angle-double-right"></i>}
        itemClassFirst="tab-arrow-left"
        itemClassPrev="tab-arrow-left"
        itemClassNext="tab-arrow-right"
        itemClassLast="tab-arrow-right"
        itemClass="tab"
        activeClass="tab-selected"
        disabledClass="tab-arrow-inactive"
      />
    </div>
  );
};

PortfolioPagination.propTypes = {};

export default PortfolioPagination;
