import React, { useState } from "react";

import { uploadInsight } from "../tender-price-indexing/MarketInsights.functions";

import {
  Heading,
  DisplayError,
} from "../tender-price-indexing/MarketInsights.components";

import { ImageUpload } from "../images/ImageUpload";

export function MarketInsightsUploadModal(props) {
  const { setShow } = props;
  const { setInsights } = props;

  const [title, setTitle] = useState("");

  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const [error, setError] = useState({});

  return (
    <div className="category-edit">
      <Heading title={title} />
      <div className="general-row-container">
        <input
          id="insight-upload-title-input"
          value={title}
          placeholder={"Title"}
          onChange={(e) => {
            setTitle(e.target.value);
            setError({});
          }}
        />
      </div>
      <div className="general-row-container">
        <ImageUpload
          imagePreview={imagePreview}
          setImage={setImage}
          setImagePreview={setImagePreview}
          imageWidth={"460px"}
          imageHeight={"300px"}
        />
      </div>

      <div className="general-row-container">
        <DisplayError error={error} />
      </div>

      <div className="general-button-container">
        <button
          id="insight-upload-button"
          className="general-upload-button"
          onClick={() => {
            uploadInsight(image, title, setError, setShow, setInsights);
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );
}
