import React, { useState } from "react";

import { useSelector } from "./CostPlanStageSelector.functions";
import { translate } from "../../utils/translation";

// Components
import isEmpty from "../../validation/is-empty";

import "./CostPlanStageSelector.css";

export default function CostPlanStageSelector(props) {
  const { CPs } = props;
  const { CP, setCP } = props;
  const { setComparedCP, setComparedCPR } = props;
  const { dashboardType } = props;

  // Active Stage
  const [activeStage, setActiveStage] = useState(null);

  useSelector(
    CPs,
    CP,
    setCP,
    setComparedCP,
    setComparedCPR,
    activeStage,
    setActiveStage
  );

  // Dashboard Page
  if (
    window.location.href.includes("dashboard") &&
    dashboardType !== "COST_PLANNING"
  ) {
    return null;
  }

  // Cost Planning Page
  if (
    !window.location.href.includes("dashboard") &&
    !window.location.href.includes("cost-planning") &&
    dashboardType !== "CORRESPONDENCE"
  ) {
    return null;
  }

  const displayStages = [];

  // List the stages
  CPs.data.forEach((CPStage, i) => {
    let prevCP = {};
    if (i > 0) {
      prevCP = CPs.data[i - 1].versions[0];
    }

    displayStages.push(
      <Box
        key={CPStage.stage}
        CPStage={CPStage}
        CP={CP}
        setCP={setCP}
        comparedCP={prevCP}
        setComparedCP={setComparedCP}
        setActiveStage={setActiveStage}
      />
    );
  });

  if (isEmpty(displayStages)) {
    return null;
  }

  return (
    <div className="cost-plan-stage-selector">
      <div className="cost-plan-stage-selector-bar">
        <div className="cost-plan-stage-selector-label">
          {translate("Cost Plan")}
        </div>
        {displayStages}
      </div>
    </div>
  );
}

function Box(props) {
  const { CPStage } = props;
  const { CP, setCP } = props;
  const { comparedCP, setComparedCP } = props;
  const { setActiveStage } = props;

  if (CPStage.versions.length === 0) {
    return (
      <div className="cost-plan-stage-selector-box-inactive">
        {CPStage.stage_code}
      </div>
    );
  }

  if (CPStage.versions.length > 0) {
    let boxType = "cost-plan-stage-selector-box";
    if (!isEmpty(CP) && CPStage.stage === CP.stage) {
      boxType = "cost-plan-stage-selector-current-stage-box";
    }

    return (
      <button
        className={boxType}
        onClick={() => {
          setCP(CPStage.versions[0]);
          setComparedCP(comparedCP);
          setActiveStage(CPStage.versions[0].stage);
        }}
      >
        {CPStage.stage_code}
      </button>
    );
  }
}
