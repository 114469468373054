import React, { useState } from "react";
import Table from "../table/Table";
import { tranStr } from "../../utils/translation";

export default function PortfolioTable(props) {
  const { data } = props;
  const { columns } = props;

  // Table Row Selection
  const [selectedRow, setSelectedRow] = useState({});
  const [checked, setChecked] = useState([]);
  const [modal, setModal] = useState(false);

  return (
    <Table
      title={tranStr("Cost Plan Table")}
      tableArray={data}
      columns={columns()}
      tableSize={8}
      isTitleDisabled={true}
      isTotalDisabled={true}
      // Checked Selection
      checked={checked}
      setChecked={setChecked}
      // Row Selection
      selectedRow={selectedRow}
      setSelectedRow={setSelectedRow}
      // Modal Control
      modal={modal}
      setModal={setModal}
    />
  );
}
