// i18next
import i18n from "i18next";
import { UseProjectLocalisations } from "../../api/admin/LocalisationsAPI";
import { UseLanguage } from "../../api/admin/LanguageAPI";

// Functions
import isEmpty from "../../validation/is-empty";

export async function projectLocalize(project) {
  // Terminology may not exist for every project so we set to blank and try to prove otherwise
  let terminology = "";
  // Set the localization for this project if it exists once loaded
  if (project.terminologySet) {
    terminology = project.terminologySet;
  }

  // Get language selected, used to find the language namespace and the localization namespace
  const currentLanguage = i18n.language;

  // API to load the default namespace for the currently selected language
  const { languageObject } = UseLanguage(currentLanguage);
  // API for the localization saved to this project
  const { localizationObject } = UseProjectLocalisations(
    currentLanguage,
    terminology
  );

  // Language Data
  const lang = languageObject.data;

  // Translation Data
  const data = localizationObject.data;

  // Config
  const namespace = "common";
  const deep = true;
  const overwrite = true;

  if (currentLanguage && isEmpty(terminology) && !isEmpty(lang)) {
    // This project has no localization so remove any resource bundles added by other projects
    i18n.removeResourceBundle(currentLanguage, "common");
    // Add the default resource bundle for the language
    i18n.addResourceBundle(currentLanguage, namespace, lang, deep, overwrite);
  }
  if (!isEmpty(data)) {
    // This project has localization terms so we merge them
    i18n.addResourceBundle(currentLanguage, namespace, data, deep, overwrite);
  }
}
