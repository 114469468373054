// React
import React from "react";

// Components
import { RegionSelect, CountrySelect } from "../locations/LocationSelect";

// Style
import "./TenderPriceIndexLocationSelect.css";

export default function TenderPriceIndexLocationSelect(props) {
  const { region, setRegion } = props;
  const { country, setCountry } = props;
  const { locations } = props;
  let display = [];
  // Decide to show upload button
  if (
    window.location.href.includes("tender-price-index") ||
    window.location.href.includes("location-factors") ||
    window.location.href.includes("articles")
  ) {
    display.push(
      <div key={"region"} className="tpi-location-select-row">
        <Label label="Region" />
        <RegionSelect
          value={region}
          set={setRegion}
          options={locations.data}
          classType={"tpi-location-select"}
          setCountry={setCountry}
        />
      </div>
    );
  }
  if (
    window.location.href.includes("tender-price-index") ||
    window.location.href.includes("location-factors")
  ) {
    display.push(
      <div key={"country"} className="tpi-location-select-row">
        <Label label="Country" />
        <CountrySelect
          value={country}
          set={setCountry}
          options={locations.data}
          classType={"tpi-location-select"}
          region={region}
        />
      </div>
    );
  }
  return <div>{display}</div>;
}

function Label(props) {
  const { label } = props;
  return (
    <div className="tpi-location-label">
      <div className="display-4" style={{ fontSize: "16px" }}>
        {label}
      </div>
    </div>
  );
}
