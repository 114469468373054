import React from "react";
import isEmpty from "../../validation/is-empty";
import PropTypes from "prop-types";

import { Line } from "react-chartjs-2";
import { postContractLineChartAdaptor } from "./PostContractAdaptor.functions";

PostContractLineChart.propTypes = {
  component: PropTypes.object,
  config: PropTypes.object,
};

export default function PostContractLineChart(props) {
  const { component, config } = props;

  if (isEmpty(component)) {
    return null;
  }

  const { data } = postContractLineChartAdaptor(
    component,
    config.YaxisIdMapping
  );

  return (
    <div>
      <Line data={data} options={config?.OPTIONS()} height={250} />
    </div>
  );
}
