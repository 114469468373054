import React from "react";
import "./OptioneeringControlDrawer.css";
import { hasRoles } from "../../utils/roles";

export default function OptioneeringControlDrawer(props) {
  const { Component } = props;
  const { user } = props;
  const { label } = props;
  const { show1, setShow1 } = props;

  const { dashboardSelect, dashboardType } = props;

  if (dashboardSelect !== "OPTIONEERING") {
    if (dashboardSelect !== dashboardType) {
      return null;
    }
  }

  let button = "drawer-button";
  if (show1) {
    button = "drawer-button-clicked";
  }

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  return (
    <div className="project-control-drawer">
      <button
        className={button}
        onClick={() => {
          setShow1(!show1);
        }}
      >
        {label}
      </button>

      {show1 && (
        <div className="project-control-drawer-content">
          <Component {...props} />
        </div>
      )}
    </div>
  );
}
