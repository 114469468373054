import React from "react";
import { translate } from "../../utils/translation";

export default function ProjectNavigationTender(props) {
  const { show } = props;

  let name = "project-navigation-section";
  if (window.location.href.includes("project-tender")) {
    name = "project-navigation-section-selected";
  }

  return (
    <div className="project-navigation-tab-link">
      <div className={name}>
        <div className="project-navigation-label">
          <div className="front-icon">
            <div className="icon-container">
              <i
                className="fas fa-balance-scale"
                style={{ marginRight: "10px" }}
              ></i>
            </div>
            {translate("Tender")}
          </div>
          {!show && (
            <div className="icon">
              <i className="far fa-plus-square" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
