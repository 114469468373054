import calculateBudget from "../../validation/calculate-budget";
import isEmpty from "../../validation/is-empty";
import {
  benchmarkCategories,
  createEscalationCategory,
  createEscalationPercentages,
} from "../estimates/EstimatesBenchmarkDonutComponent.functions";
import { TOTAL } from "../../actions/types";
export function estimateBreakupsByStages(CPs, CP) {
  const field = [];
  const budget = [];
  const cpCostCategories = [];
  const projectCostCategories = [];

  // First get the cost plan versions
  CPs.data.forEach((CPStage) => {
    if (CPStage.stage <= CP.stage) {
      let estimates = [];
      let revision = "";

      // If it's a previous stage, only get the latest revision
      if (CPStage.stage < CP.stage) {
        if (CPStage.versions[0]) {
          estimates = CPStage.versions[0].estimates;
          revision = ", r. " + CPStage.versions[0].version;
        }
      }

      // If its the currently viewed stage, get the selected revision
      if (CPStage.stage === CP.stage) {
        if (CP.estimates) {
          estimates = CP.estimates;
          revision = ", r. " + CP.version;
        }
      }

      field.push(CPStage.stage_name + revision);

      // Budget
      const currentBudget = calculateBudget(CPStage.versions[0]);
      budget.push(currentBudget);

      // Get only the included estimates
      const includedEstimates = estimates.filter((e) => e.is_included);

      // Get the Benchmarks from the included estimates
      const benchmarks = includedEstimates.map((e) => e.benchmark);

      // Get the Cost Categories for this set of Benchmarks
      let costCategories = benchmarkCategories(
        benchmarks,
        "Cost Categories",
        TOTAL
      );

      // Cost Escalation needs to be calculated and added if it exists
      const escalationCategory = createEscalationCategory(estimates);
        costCategories.push(escalationCategory);

        // Recalculate the percentages to account for cost escalation
      createEscalationPercentages(costCategories);

      cpCostCategories.push(costCategories);
    }
  });

  // Create datasets for each Cost Category type
  cpCostCategories.forEach((CC) => {
    CC.forEach((m) => {
      let s = projectCostCategories.find((x) => x.field === m.field);
      if (isEmpty(s)) {
        projectCostCategories.push({
          field: m.field,
          value: [m.value],
        });
      } else {
        s.value.push(m.value);
      }
    });
  });

  let estimateBreakups = projectCostCategories;

  return { estimateBreakups, field, budget };
}

export function estimateBreakupsByRevisions(CPs, CP) {
  const field = [];
  const budget = [];
  const cpCostCategories = [];
  const projectCostCategories = [];

  // First get the cost plan versions
  CPs.data.forEach((CPStage) => {
    if (CPStage.stage === CP.stage) {
      if (!isEmpty(CPStage.versions)) {
        const versions = [...CPStage.versions].reverse();

        versions.forEach((version) => {
          if (version.version <= CP.version) {
            const estimates = version.estimates;
            const revision = ", r. " + version.version;

            field.push(CPStage.stage_name + revision);

            // Budget
            const currentBudget = calculateBudget(version);
            budget.push(currentBudget);

            // Get only the included estimates
            const includedEstimates = estimates.filter((e) => e.is_included);

            // Get the Benchmarks from the included estimates
            const benchmarks = includedEstimates.map((e) => e.benchmark);

            // Get the Cost Categories for this set of Benchmarks
            let costCategories = benchmarkCategories(
              benchmarks,
              "Cost Categories",
              TOTAL
            );

            // Cost Escalation needs to be calculated and added if it exists
            const escalationCategory = createEscalationCategory(estimates);
              costCategories.push(escalationCategory);

            // Recalculate the percentages to account for cost escalation
            createEscalationPercentages(costCategories);

            cpCostCategories.push(costCategories);
          }
        });
      }
    }
  });

  // Create datasets for each Cost Category type
  cpCostCategories.forEach((CC) => {
    CC.forEach((m) => {
      let s = projectCostCategories.find((x) => x.field === m.field);
      if (isEmpty(s)) {
        projectCostCategories.push({
          field: m.field,
          value: [m.value],
        });
      } else {
        s.value.push(m.value);
      }
    });
  });

  let estimateBreakups = projectCostCategories;

  return { estimateBreakups, field, budget };
}
