import PropTypes from "prop-types";
import Modal from "../modal/Modal";

import { tranStr } from "../../utils/translation";

import {
  Label,
  Select,
  SelectFilter,
} from "../project-create/CreateProject.components";

import {
  RegionSelect,
  CountrySelect,
  CitySelect,
} from "../locations/LocationSelect";

import { CreateProjectMap } from "../project-create/CreateProjectMap";

import { getDisplayValueForForm } from "./BenchmarkCreateLoadOrSaveModal.functions";

const FormControlComponents = {
  Label,
  Text,
  Select,
  SelectFilter,
  RegionSelect,
  CountrySelect,
  CitySelect,
  Input,
  CreateProjectMapWithCoordinateInputs,
  Toggle,
};

const ExcludeLabelsForComponents = [
  Text,
  Select,
  SelectFilter,
  CreateProjectMapWithCoordinateInputs,
  Toggle,
];

/// props validation | SQ(javascript:S6774)
Input.propTypes = {
  value: PropTypes.string.isRequired,
  set: PropTypes.func.isRequired,
  validationType: PropTypes.string,
  attributes: PropTypes.object,
};
///
function Input(props) {
  const { value, set } = props;
  const { validationType } = props;
  const { attributes } = props;

  return (
    <input
      className={"create-project-input"}
      defaultValue={value}
      onChange={(e) => set(e.target.value)}
      onKeyDown={(e) =>
        validationType && validateInputKeySequence(e, validationType)
      }
      {...attributes}
    />
  );
}

/// props validation | SQ(javascript:S6774)
Text.propTypes = {
  value: PropTypes.element.isRequired,
};
///
function Text(props) {
  const { value } = props;
  return <div className="text">{value}</div>;
}

// Components such as Select, SelectFilter already include <Label ... /> within
// Components such as Text require no label
// Components other than these require to be wrapped within <LabelComponent />
export const ComponentNeedsLabel = (Component) =>
  !ExcludeLabelsForComponents.includes(Component);

/// props validation | SQ(javascript:S6774)
LabelComponent.propTypes = {
  label: PropTypes.object.isRequired,
  Component: PropTypes.func.isRequired,
};
///
function LabelComponent(props) {
  const { label } = props;
  const { Component } = props;

  return (
    <div className="create-project-input-row">
      <Label label={label} />
      <Component {...props} />
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
Form.propTypes = {};
///
export function Form(props) {
  return (
    <>
      <FormHeader {...props} />
      <FormBodyEditable {...props} />
    </>
  );
}

/// props validation | SQ(javascript:S6774)
FormHeader.propTypes = {
  icon: PropTypes.string,
  isHeadless: PropTypes.bool,
  label: PropTypes.any,
};
///
export function FormHeader(props) {
  const { icon, isHeadless, label } = props;

  return (
    <>
      {!isHeadless && (
        <h1 className="display-4 form-title">
          <i className={icon} />
          <span>{label}</span>
        </h1>
      )}
    </>
  );
}

/// props validation | SQ(javascript:S6774)
FormBodyEditable.propTypes = {
  formControls: PropTypes.array.isRequired,
};
///
export function FormBodyEditable(props) {
  const { formControls } = props;

  return (
    <>
      {formControls.map((control) => {
        const Component = FormControlComponents[control.component];

        return ComponentNeedsLabel(Component) ? (
          <LabelComponent
            key={control.key}
            Component={Component}
            {...control}
          />
        ) : (
          <Component key={control.key} {...control} />
        );
      })}
    </>
  );
}

/// props validation | SQ(javascript:S6774)
FormBodyReadonly.propTypes = {
  formControls: PropTypes.array,
  className: PropTypes.string,
};
///
export function FormBodyReadonly(props) {
  const { formControls, className } = props;

  return (
    <>
      {formControls.map((control) => {
        const { key, label, value, component } = control;

        const displayValue = getDisplayValueForForm({ value, component });

        return (
          <div className={className} key={key}>
            {label && <div>{label}</div>}
            <div>{displayValue}</div>
          </div>
        );
      })}
    </>
  );
}

/// props validation | SQ(javascript:S6774)
FormError.propTypes = {
  error: PropTypes.any,
};
///
export function FormError(props) {
  const { error } = props;

  return <div className="modal-error">{error}</div>;
}

/// props validation | SQ(javascript:S6774)
SaveForm.propTypes = {
  onSave: PropTypes.func,
};
///
export function SaveForm(props) {
  const { onSave } = props;

  return (
    <ActionForm
      {...props}
      buttonClassName="modal-save-button"
      label={"Save"}
      onAction={onSave}
    />
  );
}

/// props validation | SQ(javascript:S6774)
CloseForm.propTypes = {
  onClose: PropTypes.func,
};
///
export function CloseForm(props) {
  const { onClose } = props;

  return (
    <ActionForm
      {...props}
      buttonClassName="modal-close-button"
      label={"Close"}
      onAction={onClose}
    />
  );
}

/// props validation | SQ(javascript:S6774)
AcceptForm.propTypes = {
  isAcceptReady: PropTypes.bool,
  onAccept: PropTypes.func,
};
///
export function AcceptForm(props) {
  const { isAcceptReady } = props;
  const { onAccept } = props;

  return (
    <ActionForm
      {...props}
      buttonClassName={
        isAcceptReady ? "modal-save-button" : "modal-close-button"
      }
      label={"Accept"}
      onAction={onAccept}
    />
  );
}

/// props validation | SQ(javascript:S6774)
ActionForm.propTypes = {
  isActionButtonHidden: PropTypes.bool,
  buttonClassName: PropTypes.string,
  label: PropTypes.string,
  setModal: PropTypes.func,
  forms: PropTypes.array,
  onAction: PropTypes.func,
};
///
export function ActionForm(props) {
  const { isActionButtonHidden } = props;

  if (isActionButtonHidden) {
    return <></>;
  }

  const { buttonClassName, label } = props;
  const { setModal } = props;
  const { forms } = props;
  const { onAction } = props;

  return (
    <button
      className={buttonClassName}
      onClick={async (e) => {
        let success = true;
        if (typeof onAction === "function") {
          success = await onAction(e, forms);
        }
        success && setModal(false);
      }}
    >
      {label}
    </button>
  );
}

function validateInputKeySequence(e, type) {
  const key = parseInt(e.keyCode);

  /*
  8 = Backspace
  46 = Delete
  48..57 = Numbers Row 0..9
  96..105 = Numpad 0..9
  189 = Minus (-)
  */

  const isNumeric =
    key === 8 ||
    key === 46 ||
    (key >= 48 && key <= 57) ||
    (key >= 96 && key <= 105);

  const isMinus = key === 189;

  switch (type) {
    case "numberonly":
      if (!isNumeric) {
        e.preventDefault();
      }
      break;

    case "positive-and-negative-numbers-only":
      if (!(isNumeric || isMinus)) {
        e.preventDefault();
      }
      break;

    case "text":
    case "telNo":
    default:
      return;
  }
}

/// props validation | SQ(javascript:S6774)
ButtonWithModal.propTypes = {
  buttonLabel: PropTypes.any,
  buttonClassName: PropTypes.string,
  onClick: PropTypes.func,
  ModalComponent: PropTypes.func,
  label: PropTypes.any,
  show: PropTypes.bool,
  setShow: PropTypes.func,
};
///
export function ButtonWithModal(props) {
  const { buttonLabel, buttonClassName, onClick } = props;
  const { label, ModalComponent, show, setShow } = props;

  return (
    <>
      <button className={buttonClassName} onClick={onClick}>
        {buttonLabel}
      </button>
      <Modal
        Component={ModalComponent}
        modal={show}
        setModal={setShow}
        title={label}
        {...props}
      />
    </>
  );
}

/// props validation | SQ(javascript:S6774)
CreateProjectMapWithCoordinateInputs.propTypes = {
  value: PropTypes.array,
  set: PropTypes.array,
};
///
export function CreateProjectMapWithCoordinateInputs(props) {
  const { value, set } = props;

  const [latitude, longitude] = value || [];
  const [setLatitude, setLongitude] = set;

  const validationType = "positive-and-negative-numbers-only";

  return (
    <div className="benchmark-map">
      <div className="coordinate-inputs">
        <Label label={tranStr("Latitude")} />
        <Input
          value={latitude}
          set={setLatitude}
          validationType={validationType}
        />
        <Label label={tranStr("Longitude")} />
        <Input
          value={longitude}
          set={setLongitude}
          validationType={validationType}
        />
      </div>
      <CreateProjectMap lat={latitude} lng={longitude} />
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
Toggle.propTypes = {
  value: PropTypes.bool,
  set: PropTypes.func,
  icon: PropTypes.string,
  iconToolTip: PropTypes.string,
  label: PropTypes.any,
};
///
function Toggle(props) {
  const { value, set, icon, iconToolTip, label } = props;

  return (
    <div className="toggle">
      {label} <i className={`${icon} icon`} title={tranStr(iconToolTip)}></i>
      <label className="switch">
        <input
          type="checkbox"
          checked={value}
          onChange={() => {
            set(!value);
          }}
        />
        <span className="slider round" />
        {``}
      </label>
    </div>
  );
}
