import { useState, useEffect } from "react";
import isEmpty from "../../validation/is-empty";
import { authHeader } from "../../_helpers/auth-header";
import { cpAPIs } from "../api-endpoints/CostPlanningEndpoints";
import { pc2APIs } from "../api-endpoints/PostContract2.0Endpoints";

export async function getDashboardSettings(project) {
  let url = "";

  if (project.project_phase === "COST_PLANNING") {
    // COST PLAN API
    url = cpAPIs().get_layout + "/" + project.id;
  }

  if (project.project_phase === "POST_CONTRACT") {
    // POST CONTRACT API
    url = pc2APIs().get_layout + "/" + project.id;
  }

  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);
  if (response.ok) {
    response = await response.json();
  } else {
    response = [];
    console.log("Network Error");
  }
  return response;
}

export function useDashboardSettings(project) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (!isEmpty(project)) {
      setLoading(true);
      getDashboardSettings(project)
        .then((dataRes) => {
          setData(dataRes);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [project]);

  return {
    dashboardSettings: {
      data: data,
      loading: loading,
      get: getDashboardSettings,
      set: setData,
      save: saveProjectDashboardSettings,
    },
  };
}

export async function saveProjectDashboardSettings(
  project,
  dashboardType,
  layout,
  setError
) {
  let url = "";
  let payload = "";
  let config = "";
  const field = JSON.stringify({
    projectID: project.id,
    dashboardType: dashboardType,
    layout: layout,
  });
  if (project.project_phase === "COST_PLANNING") {
    // COST PLAN API
    url = cpAPIs().save_layout;
    payload = field;
    config = {
      method: "POST",
      body: payload,
      headers: authHeader({
        authJson: true,
        authForm: false,
        guestJson: false,
      }),
    };
  }

  if (project.project_phase === "POST_CONTRACT") {
    // POST CONTRACT API
    url = pc2APIs().save_layout;
    payload = new FormData();
    payload.append("field", field);
    config = {
      method: "POST",
      body: payload,
      headers: authHeader({
        authJson: false,
        authForm: true,
        guestJson: false,
      }),
    };
  }

  let response = await fetch(url, config);
  if (response.ok) {
    setError({
      text: "Saved",
      type: "dashboard-editor-success",
    });
    response = await response.json();
  } else {
    setError({
      text: "Not Saved",
      type: "dashboard-editor-error",
    });
    response = [];
    console.log("Network Error");
  }

  return response;
}
