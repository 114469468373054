import React, { useState } from "react";
import ProjectControlNotifyTable from "../project-control/ProjectControlNotifyTable";
import { displayUserImage } from "../../validation/image-clean";

import "./ProjectControlNotify.css";

export default function ProjectControlNotify(props) {
  const { projectID, projectTitle } = props;
  const { project } = props;

  const [selectedUsers, setSelectedUsers] = useState([]);

  const users = project.clients.concat(project.cost_managers);

  const usersArray = users.map((u) => {
    u.image = displayUserImage(u);
    return u;
  });

  return (
    <div className="project-control-notify">
      <h1 className="display-4" style={{ fontSize: "20px" }}>
        Select team members to notify below.
      </h1>
      <ProjectControlNotifyTable
        data={usersArray}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
      />
      <div className="notify-team-container">
        <a
          className="notify-team-button"
          href={
            "mailto:" +
            generateEmails(selectedUsers) +
            "?subject=" +
            (projectTitle || "") +
            " Update on Cost Clarity" +
            ` - ${window.location.host}/project/` +
            projectID +
            "&body=" +
            `Please see latest updates for ${projectTitle} at ${window.location.host}/project/${projectID}`
          }
        >
          Compose Email
        </a>
      </div>
    </div>
  );
}

function generateEmails(selectedUsers) {
  let addresses = "";

  selectedUsers.forEach((u) => (addresses += u.email + "; "));

  return addresses;
}
