import React, { Component } from "react";
import browserDetector from "../browser/BrowserDetector";
import brand2 from "../../img/ArcadisClarity02.png";
import privacyStatement from "../../files/ArcadisPrivacyStatement.pdf";

import "./Privacy.css";

// Add redux:
// PropTypes - we are mapping our state to props ( meaning
// we are going to have a property) and if you have properties
// in your components you should map them to PropTypes
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

class Privacy extends Component {
  // Lifecycle Methods
  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/home/");
    }

    if (browserDetector().unsupported) {
      this.props.history.push("/browser");
    }
  }

  render() {
    return (
      <div className="privacy-background">
        <div className="privacy-content">
          <img className="privacy-brand" src={brand2} width="500" alt="" />

          <div className="privacy-container">
            <PrivacyText />
          </div>
        </div>
      </div>
    );
  }
}

function PrivacyText() {
  return (
    <div className="privacy-box">
      <h1 className="display-5 text-center">Arcadis Privacy Statement</h1>

      <div className="privacy-text-container">
        Please download a copy of the Arcadis Privacy Statement below.
      </div>
      <PrivacyButton />
    </div>
  );
}

function PrivacyButton() {
  return (
    <a href={privacyStatement} download className="privacy-button">
      Download Privacy Statement
    </a>
  );
}

Privacy.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Privacy);
