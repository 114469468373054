import isEmpty from "../../validation/is-empty";
import { authHeader } from "../../_helpers/auth-header";
import { tranStr } from "../../utils/translation";
import { opAPIs } from "../../api/api-endpoints/OptioneeringEndpoints";
import { AutoCloseModal } from "../modal/Modal";

export async function submitStages(data) {
  const { project } = data;
  const { getOptions, setOptions } = data;

  // Validate that name, code, and number are set with a value in each stage
  if (hasInvalidStages(data.newStages)) {
    return data.setError({
      text: tranStr("Please enter data in all fields"),
      type: "project-stages-error",
    });
  }

  let values = data.newStages.map((item) => item.number);
  let isDuplicate = values.some((item, idx) => values.indexOf(item) !== idx);

  if (isDuplicate) {
    return data.setError({
      text: tranStr("No duplicate stages"),
      type: "project-stages-error",
    });
  } else if (isInvalidStages(data.newStages)) {
    return data.setError({
      text: tranStr("Stages not valid"),
      type: "project-stages-error",
    });
  } else {
    data.setError({
      text: tranStr("Saving..."),
      type: "project-stages-success",
    });
  }

  const stages = data.newStages?.map((s) => ({
    name: s.name,
    number: s.number,
    code: s.code,
    id: s.id,
    optionsAnalysisItems: s.optionsAnalysisItems?.map((item) => ({
      projectId: project.id,
      stage: s.name,
      items: [
        {
          summary: item.summary,
          costImpact: item.costImpact,
          costImpactRating: `${item.costImpactRating}`,
          programmeImpact: item.programmeImpact,
          programmeImpactRating: `${item.programmeImpactRating}`,
          environmentalImpact: `${item.environmentalImpact}`,
          operationalCostImpact: item.operationalCostImpact,
          operationalCostImpactRating: `${item.operationalCostImpactRating}`,
          otherConsiderations: item.otherConsiderations,
          id: item.id,
        },
      ],
    })),
  }));

  const payload = JSON.stringify({
    projectId: project.id,
    isDeleted: false,
    timeCreated: new Date(),
    OptionsAnalysisStages: stages,
  });

  const config = {
    method: "POST",
    body: payload,
    headers: authHeader({
      authJson: true,
      authForm: false,
      guestJson: false,
    }),
  };

  const url = opAPIs().create_stages;

  let response = await fetch(url, config);
  if (response.ok) {
    try {
      // attempt to extract json from response
      response = await response.json();
    } catch (e) {
      // ignore error since above step is optional
    }

    // refresh options
    await setOptions(await getOptions(project.id));

    data.setError({
      text: tranStr("Saved Successfully"),
      type: "project-stages-success",
    });

    AutoCloseModal(data.setModal);
  } else {
    return data.setError({
      text: tranStr("Connection Error"),
      type: "project-stages-error",
    });
  }

  return response;
}

function hasInvalidStages(array) {
  return array.some(
    (s) => isEmpty(s.name) || isEmpty(s.number) || isEmpty(s.code)
  );
}

function isInvalidStages(array) {
  let fail = false;
  array.forEach((s) => {
    if (isEmpty(s.number) || isEmpty(s.name)) {
      fail = true;
    }
  });

  return fail;
}
