import PropTypes from "prop-types";

TableFilterCell.propTypes = {
  cell: PropTypes.object,
};

export function TableFilterCell(props) {
  const { cell } = props;
  const { type } = cell;

  if (type === "NUMBER") {
    return <NumberFilter {...props} />;
  }
  if (type === "DESCRIPTION") {
    return <DescriptionFilter {...props} />;
  }
  if (type === "STATUS") {
    return <TextAndStatusFilter {...props} />;
  }
  if (type === "CURRENCY") {
    return <CurrencyAndQuantityFilter {...props} />;
  }
  if (type === "QUANTITY") {
    return <CurrencyAndQuantityFilter {...props} />;
  }
  if (type === "PERCENT") {
    return <PercentFilter {...props} />;
  }
  if (type === "DATE") {
    return <DateFilter {...props} />;
  }
  if (type === "TEXT") {
    return <TextAndStatusFilter {...props} />;
  }
  if (type === "CHECK") {
    return <TextAndStatusFilter {...props} />;
  }
  if (type === "IMAGE") {
    return <TextAndStatusFilter {...props} />;
  }
  if (type === "REASONS") {
    return <TextAndStatusFilter {...props} />;
  }
  if (type === "COMMENTS") {
    return <TextAndStatusFilter {...props} />;
  }
  if (type === "LINK") {
    return <TextAndStatusFilter {...props} />;
  }
  if (type === "CRITICAL") {
    return <TextAndStatusFilter {...props} />;
  }
}

DescriptionFilter.propTypes = {
  cell: PropTypes.object,
  data: PropTypes.object,
  width: PropTypes.number,
};

function DescriptionFilter(props) {
  const { cell } = props;
  const { data } = props;
  const { width } = props;

  return (
    <div className="pct-heading-description" style={{ width: width }}>
      {cell.isFiltered && (
        <input
          id="pct-filter-cell-description"
          className="pct-filter-cell-description"
          placeholder="Search"
          onChange={(e) => {
            updateFilters(cell.key, e.target.value, data);
          }}
        />
      )}
    </div>
  );
}

NumberFilter.propTypes = {
  cell: PropTypes.object,
  width: PropTypes.number,
};

function NumberFilter(props) {
  const { cell } = props;
  const { width } = props;

  return (
    <div className="pct-heading-number" style={{ width: width }}>
      {cell.isFiltered && (
        <input
          id="pct-filter-cell-number"
          className="pct-filter-cell"
          placeholder="Search"
          onChange={(e) => {
            // Do Nothing
          }}
        />
      )}
    </div>
  );
}

TextAndStatusFilter.propTypes = {
  cell: PropTypes.object,
  data: PropTypes.object,
  width: PropTypes.number,
};

function TextAndStatusFilter(props) {
  const { cell } = props;
  const { data } = props;
  const { width } = props;

  const statusTypes = getSelectList(cell.key, data);

  return (
    <div className="pct-heading" style={{ width: width }}>
      {cell.isFiltered && (
        <select
          id="pct-filter-cell-text-and-status"
          className="pct-filter-cell-drop-down"
          onChange={(e) => {
            updateFilters(cell.key, e.target.value, data);
          }}
        >
          {statusTypes.map((itm, i) => {
            return (
              <option key={itm.name} value={itm.value} className="option">
                {itm.name}
              </option>
            );
          })}
        </select>
      )}
    </div>
  );
}

CurrencyAndQuantityFilter.propTypes = {
  cell: PropTypes.object,
  data: PropTypes.object,
  width: PropTypes.number,
};

function CurrencyAndQuantityFilter(props) {
  const { cell } = props;
  const { data } = props;
  const { width } = props;

  return (
    <div className="pct-heading" style={{ width: width }}>
      {cell.isFiltered && (
        <div>
          <input
            id="pct-filter-cell-currency-min"
            className="pct-filter-cell-min-max"
            placeholder="Min"
            onChange={(e) => {
              updateMinMaxFilters(cell.key, e.target.value, "min", data);
            }}
          />
          <input
            id="pct-filter-cell-currency-max"
            className="pct-filter-cell-min-max"
            placeholder="Max"
            onChange={(e) => {
              updateMinMaxFilters(cell.key, e.target.value, "max", data);
            }}
          />
        </div>
      )}
    </div>
  );
}

PercentFilter.propTypes = {
  cell: PropTypes.object,
  data: PropTypes.object,
  width: PropTypes.number,
};

function PercentFilter(props) {
  const { cell } = props;
  const { data } = props;
  const { width } = props;

  return (
    <div className="pct-heading-percent" style={{ width: width }}>
      {cell.isFiltered && (
        <div className="pct-min-max">
          <input
            id="pct-filter-cell-percent-min"
            className="pct-filter-cell-min-max"
            placeholder="Min"
            onChange={(e) => {
              updateMinMaxFilters(cell.key, e.target.value, "min", data);
            }}
          />
          <input
            id="pct-filter-cell-percent-max"
            className="pct-filter-cell-min-max"
            placeholder="Max"
            onChange={(e) => {
              updateMinMaxFilters(cell.key, e.target.value, "max", data);
            }}
          />
        </div>
      )}
    </div>
  );
}

DateFilter.propTypes = {
  cell: PropTypes.object,
  data: PropTypes.object,
  width: PropTypes.number,
};

function DateFilter(props) {
  const { cell } = props;
  const { data } = props;
  const { width } = props;

  return (
    <div className="pct-heading-percent" style={{ width: width }}>
      {cell.isFiltered && (
        <input
          id="pct-filter-cell-date"
          className="pct-filter-cell"
          placeholder="Search"
          onChange={(e) => {
            updateFilters(cell.key, e.target.value, data);
          }}
        />
      )}
    </div>
  );
}

// Get dropdown list cells based on column name
function getSelectList(key, data) {
  const statusTypes = [];
  statusTypes.push({ name: "All", value: "" });
  Array.from(new Set(data.tableArray.map((v) => v[key])))
    .filter((f) => f !== "")
    .forEach((e) => {
      statusTypes.push({ name: e, value: e });
    });
  return statusTypes;
}

function updateFilters(column, value, data) {
  // Remove any occurance of this column
  const r = [...data.filters].filter((s) => s.column !== column);
  // Push column to filter only if entered data is not null
  if (value !== "") {
    r.push({ column: column, text: value });
  }
  data.setFilters(r);
}

function updateMinMaxFilters(column, value, minOrMax, data) {
  // Remove any occurance of this column
  const temp = [...data.filters].filter(
    (s) => s.column !== column || s.minOrMax !== minOrMax
  );
  // Push column to filter only if entered data is not null
  if (value !== "") {
    temp.push({ column: column, text: value, minOrMax: minOrMax });
  }
  data.setFilters(temp);
}
