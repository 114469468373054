import ProjectsListItem from "./ProjectsListItem";

export default function ProjectsListSection(props) {
  const { projects } = props;
  const { heading, type } = props;
  const { selectedProjectId, setSelectedProjectId } = props;

  return (
    <div className={`correspondence-projects-list-section ${type}`}>
      <span className="list-section-heading">
        {heading} ({projects.length})
      </span>
      {projects.map((project) => (
        <ProjectsListItem
          key={`correspondence-projects-list-item-${project.id}`}
          project={project}
          setSelectedProjectId={setSelectedProjectId}
          isSelected={selectedProjectId === project.id}
        />
      ))}
    </div>
  );
}
