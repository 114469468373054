import React from "react";
import Accordion from "../accordion/Accordion";
import isEmpty from "../../validation/is-empty";

import ReconciliationScopeDesignChanges from "./ReconciliationScopeDesignChanges";
import DetailedComparison from "./DetailedComparison";
import ModalChangesUploader from "./ModalChangesUploader";
import ScopeDesignChangesDeleteButton from "./ReconciliationScopeDesignChangesDeleteButton";

import ReconciliationDisplayTotal from "./ReconciliationDisplayTotal";
import ReconciliationOverallReconciliationSummary from "./ReconciliationOverallReconciliationSummary";
import DesignDevelopmentProgression from "../charts/DesignDevelopmentProgression";
import CostPlanComparisonChart from "../charts/CostPlanComparisonChart";
import ReconciliationSubtotal from "./ReconciliationSubtotal";
import { commaBrackets } from "../../helpers/common.functions";
import { translate, tranStr } from "../../utils/translation";

export default function ReconciliationContent(props) {
  const { user } = props;
  const { project } = props;
  const { CPs } = props;
  const { CP } = props;
  const { comparedCP } = props;
  const { accordionState } = props;
  const { reconciliationData } = props;

  if (isEmpty(comparedCP)) {
    return (
      <div className="estimate-reconciliation">{reconciliationData.error}</div>
    );
  }

  const { estimateDeltas } = reconciliationData;

  const display = [];

  estimateDeltas.forEach((e, i) => {
    if (e.is_included) {
      display.push(
        <ReconciliationPart
          key={e.id}
          e={e}
          project={project}
          user={user}
          CP={CP}
          CPs={CPs}
          estimateDeltas={estimateDeltas}
          i={i}
          accordionState={accordionState}
          reconciliationData={reconciliationData}
        />
      );
    }
  });

  // The Total
  display.push(
    <ReconciliationDisplayTotal
      key={-1}
      project={project}
      estimateDeltas={estimateDeltas}
      CP={CP}
    />
  );

  estimateDeltas.forEach((e, i) => {
    if (!e.is_included) {
      display.push(
        <ReconciliationPart
          e={e}
          key={e.id}
          project={project}
          user={user}
          CP={CP}
          CPs={CPs}
          estimateDeltas={estimateDeltas}
          i={i}
          accordionState={accordionState}
          reconciliationData={reconciliationData}
        />
      );
    }
  });

  return (
    <div className="estimate-reconciliation">
      <div className="recon-estimate-dashboard">
        <div className="recon-estimate-dashboard-highlights-chart-container">
          <ReconciliationOverallReconciliationSummary
            project={project}
            CP={CP}
            comparedCP={comparedCP}
          />
          <div className="reconciliation-cost-plan-comparison-chart">
            <div className="recon-chart-title">
              <h1
                className="display-4"
                style={{ fontSize: "20px", margin: "10px" }}
              >
                {translate("Cost Plan Comparison")}
              </h1>
            </div>
            <CostPlanComparisonChart
              project={project}
              estimateBreakups={
                reconciliationData.costPlanComparison.estimateBreakups
              }
              field={reconciliationData.costPlanComparison.field}
              budget={reconciliationData.costPlanComparison.budget}
              height={100}
            />
          </div>
        </div>
        <div className="recon-estimate-chart-container-1">
          <div>
            <h1 className="display-4" style={{ fontSize: "20px" }}>
              {translate("Design Development Progression")}
            </h1>
            <DesignDevelopmentProgression
              project={project}
              CP={CP}
              CPs={CPs}
              estimateDeltas={estimateDeltas}
              type={reconciliationData.reconciliationType}
              height={400}
              width={700}
              DCs={
                reconciliationData.designDevelopmentProgress.designContingencies
              }
              CCs={reconciliationData.designDevelopmentProgress.costChanges}
              fields={reconciliationData.designDevelopmentProgress.field}
            />
          </div>
        </div>
      </div>
      {display}
    </div>
  );
}

function ReconciliationPart(props) {
  const { e } = props;
  const { project } = props;
  const { user } = props;
  const { CP } = props;
  const { CPs } = props;
  const { estimateDeltas } = props;
  const { i } = props;
  const { accordionState } = props;
  const { reconciliationData } = props;

  const total = commaBrackets(Math.round(e.totalDelta));

  return (
    <div key={i}>
      <div className="recon-estimate-row">
        <Accordion
          key={e.id}
          label={e.name + " - " + tranStr("Variance")}
          total={total}
          // State
          accordionState={accordionState}
          accordionSection={"RECONCILIATION"}
          estimateID={e.id}
        >
          <div className="recon-estimate-section">
            <DetailedComparison
              e={e}
              i={i}
              project={project}
              reconciliationData={reconciliationData}
            />
            <ReconciliationScopeDesignChanges
              user={user}
              e={e}
              project={project}
              CP={CP}
              CPs={CPs}
              reconciliationData={reconciliationData}
            />
          </div>
        </Accordion>
        {
        
        project.project_phase === "COST_PLANNING" && 
        <>
        <ModalChangesUploader
          project_id={project.id}
          project={project}
          estimate_id={e.id}
          CPs={CPs}
          user={user}
          reconciliationData={reconciliationData}
        />
        <ScopeDesignChangesDeleteButton
          estimateID={e.id}
          projectID={project.id}
          project={project}
          CPs={CPs}
          user={user}
          reconciliationData={reconciliationData}
        /></>
        }
      </div>
      <ReconciliationSubtotal
        project={project}
        estimates={estimateDeltas}
        estimateID={e.id}
        subtotal_below={e.subtotal_below}
        subtotal_name={e.subtotal_name}
        subtotal_gfa_override={e.subtotal_gfa_override}
      />
    </div>
  );
}
