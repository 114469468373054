import { authHeader } from "../../_helpers/auth-header";
import { opAPIs } from "../../api/api-endpoints/OptioneeringEndpoints";
import { AutoCloseModal } from "../modal/Modal";
import { reloadData } from "./OptionsAnalysis.functions";

export async function saveBaselineCP(data) {
  data.setError({
    text: "Saving...",
    type: "feedback-success",
  });

  const payload = {
    CostplanId: data.costplan_id,
    ProjectId: data.project?.id,
    ActiveStageId: data.active_stage_id,
  };

  // Optioneering API
  const url = opAPIs().save_baseline_costplan;

  const config = {
    method: "POST",
    body: JSON.stringify(payload),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);

  if (response.ok) {
    // Response OK
    await reloadData(data);

    data.setError({
      text: "Saved Successfully",
      type: "feedback-success",
    });

    AutoCloseModal(data.setModal);
  } else {
    // Response Not OK
    return data.setError({
      text: "Save Failed",
      type: "feedback-error",
    });
  }
}
