import React, { useEffect, useState } from "react";
import PCRALogAPI from "../pcra/PCRALogAPI";
import PCRAReadInput from "../pcra/PCRAReadInput.class";

import PCRALogRoot from "../pcra/PCRALogRoot";

import { PROCESS_TYPE } from "../pcra/PCRAGlossary.constants";

export default function CostPlanningPCRALogModal(props) {
  const { user } = props;
  const { project } = props;
  const { CPs } = props;
  const { CP } = props;
  const { isLocked, lockedReason } = props;

  const [pcraData, setPcraData] = useState({ loading: true });

  const onAction = prepareonAction({ project, CPs });

  useEffect(() => {
    const readInput = new PCRAReadInput({
      ids: [CP?.cost_plan_id],
      user,
      processType: PROCESS_TYPE.PCRA,
      isLocked,
    });
    (async () => {
      await PCRALogAPI.ReadAuditLog({ readInput, setPcraData });
    })();
  }, [CP, user, isLocked]);

  return (
    <PCRALogRoot
      user={user}
      data={pcraData}
      setData={setPcraData}
      onAction={onAction}
      isLocked={isLocked}
      lockedReason={lockedReason}
    />
  );
}

function prepareonAction({ project, CPs }) {
  return async function onAction() {
    // refresh CPs
    CPs.set(await CPs.get(project.id, project));
  };
}
