import React, { useState, useEffect } from "react";
import { translate } from "../../utils/translation";

export default function CompanyFilter(props) {
  const { setFilters } = props;

  // Advanced Search Inputs
  const [name, setName] = useState("");
  const [region, setRegion] = useState("");

  useEffect(() => {
    setFilters({
      name: name,
      region: region,
    });
  }, [setFilters, name, region]);

  // Display on Account Control Only
  if (!window.location.href.includes("company-control")) {
    return null;
  }

  return (
    <div className="account-filters-container">
      <h1 className="display-4" style={{ fontSize: "20px" }}>
        {translate("Company Filters")}
      </h1>
      <PortfolioInputSingle
        placeholder={translate("Company Name")}
        value={name}
        onChange={setName}
        icon={<i className="fas fa-pen-fancy"></i>}
      />
      <PortfolioInputSingle
        placeholder={translate("Region")}
        value={region}
        onChange={setRegion}
        icon={<i className="fas fa-globe-asia"></i>}
      />
    </div>
  );
}

export function PortfolioInputSingle(props) {
  const { value, onChange, placeholder, icon } = props;
  const [focus, setFocus] = useState(false);
  let iconType = "account-filter-input-icon";
  if (focus || value !== "") iconType = "account-filter-input-icon-focus";

  return (
    <div className="account-filter-input-container">
      <div className={iconType}>{icon}</div>
      <div className="account-filter-input-col">
        {placeholder}
        <div className="account-filter-input-row">
          <input
            className="account-filter-search-input"
            type="text"
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            autoComplete="off"
          ></input>
        </div>
      </div>
    </div>
  );
}
