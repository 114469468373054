import React from "react";

import "./CommentaryTableViewer.css";

export default function CommentaryTableViewer(props) {
  const { table } = props;

  let displayTable = [];

  table.rows.forEach((row, index) => {
    const key = `commentary-row-${index}`;
    if (row.row === 1) {
      displayTable.push(<HeadRow key={key} row={row} table={table} />);
    } else {
      displayTable.push(<Row key={key} row={row} table={table} />);
    }
  });

  return <div className="ctv-table">{displayTable}</div>;
}

function HeadRow(props) {
  const { table, row } = props;

  return (
    <div className="ctv-row">
      <HeadCell cell={"cell1"} table={table} row={row} />
      <HeadCell cell={"cell2"} table={table} row={row} />
      <HeadCell cell={"cell3"} table={table} row={row} />
      <HeadCell cell={"cell4"} table={table} row={row} />
      <HeadCell cell={"cell5"} table={table} row={row} />
      <HeadCell cell={"cell6"} table={table} row={row} />
      <HeadCell cell={"cell7"} table={table} row={row} />
      <HeadCell cell={"cell8"} table={table} row={row} />
    </div>
  );
}

function Row(props) {
  const { table, row } = props;

  return (
    <div className="ctv-row">
      <Cell cell={"cell1"} table={table} row={row} />
      <Cell cell={"cell2"} table={table} row={row} />
      <Cell cell={"cell3"} table={table} row={row} />
      <Cell cell={"cell4"} table={table} row={row} />
      <Cell cell={"cell5"} table={table} row={row} />
      <Cell cell={"cell6"} table={table} row={row} />
      <Cell cell={"cell7"} table={table} row={row} />
      <Cell cell={"cell8"} table={table} row={row} />
    </div>
  );
}

function HeadCell(props) {
  const { table, row, cell } = props;

  // Do not display any cell if all cells in this column are blank
  if (table.rows.every((thisRow) => thisRow[cell] === null)) {
    return null;
  } else {
    return (
      <div className="ctv-cell-head">
        <b>{row[cell]}</b>
      </div>
    );
  }
}

function Cell(props) {
  const { table, row, cell } = props;

  // Do not display any cell if all cells in this column are blank
  if (table.rows.every((thisRow) => thisRow[cell] === null)) {
    return null;
  } else {
    return <div className="ctv-cell">{row[cell]}</div>;
  }
}
