import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import isEmpty from "../../validation/is-empty";
import { extractYearFromISODateString } from "../../helpers/common.functions";

import BenchmarkingAnonymity from "./BenchmarkingAnonymity";
import BenchmarkingDisplayTable from "./BenchmarkingDisplayTable";
import BenchmarkingMetrics from "./BenchmarkingMetrics";
import AverageCostCategories from "./AverageCostCategories";
import SectionTitleControl from "./SectionTitleControl";
import BenchmarkingHeatMap from "./BenchmarkingHeatMap";
import BenchmarkingCostplanComparisons from "./BenchmarkingCostplanComparisons";
import ProjectComparison from "./ProjectComparison";

import {
  escalation,
  toggleEscalation,
} from "../../helpers/escalation.functions";

import "./BenchmarkSection.css";
import { translate, tranStr } from "../../utils/translation";

import CostBenchmarking from "../charts/CostBenchmarking";
import ContractorOnCostComparisonChart from "../charts/ContractorOnCostComparisonChart";
import { useCbs } from "../../api/cbs/CbsAPI";
import { BenchmarkingJustification } from "./BenchmarkingJustification";
import BenchmarkProjectsMap from "./BenchmarkProjectsMap";
import Dashboard from "../dashboard/Dashboard";
import { useConfigureSettings } from "../../api/dashboards/ConfigureAPI";

/// props validation | SQ(javascript:S6774)
BenchmarkingDisplayTableForDashboard.propTypes = {
  childComponents: PropTypes.array,
  className: PropTypes.string,
};
function BenchmarkingDisplayTableForDashboard(props) {
  const { childComponents } = props;
  const { className } = props;
  return <div className={className}>{childComponents}</div>;
}

/// props validation | SQ(javascript:S6774)
BenchmarkSection.propTypes = {
  caller: PropTypes.string,
  thisBenchmark: PropTypes.object,
  benchmarks: PropTypes.array,
  is_locality_factor_active: PropTypes.bool,
  locality_factor: PropTypes.number,
  title: PropTypes.string,
  unitOfMeasure: PropTypes.string,
  currencySymbol: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  baseDate: PropTypes.string,
  TPI: PropTypes.object,
  LFI: PropTypes.array,
  onBenchmarkAnonymityToggle: PropTypes.func,
  settings: PropTypes.array,
  onSettingsToggle: PropTypes.func,
  currencyExchange: PropTypes.array,
  currency: PropTypes.string,
  project: PropTypes.object,
  isLocalRegionFactorEnabled: PropTypes.bool,
  isGlobalBenchmarking: PropTypes.bool,
  showDashboard: PropTypes.bool,
  setShowDashboard: PropTypes.func,
  moduleId: PropTypes.string,
};
///
export default function BenchmarkSection(props) {
  const { caller } = props;

  // for certain callers, render charts without highlighting the first entity
  const skipHighlight = ["MASTER_BENCHMARKING"].includes(caller);

  const { thisBenchmark } = props;
  const { benchmarks } = props;
  const { is_locality_factor_active } = props; // Cost Plan Locality Factor Override Toggle
  const { locality_factor } = props; // Cost Plan Locality Factor Override Value

  const { title, unitOfMeasure, currencySymbol, city, country } = props;
  const { baseDate } = props;
  const { TPI } = props;
  const { LFI } = props;
  const { onBenchmarkAnonymityToggle } = props;
  const { settings } = props;
  const { onSettingsToggle } = props;
  const { currencyExchange, isLocalRegionFactorEnabled } = props;
  const { currency } = props;
  const { cbs } = useCbs(thisBenchmark?.standard);
  const { project } = props;
  const { isGlobalBenchmarking } = props;

  const [futureYear, setFutureYear] = useState(0);
  const [isEscalated, setIsEscalated] = useState(true);
  const [isElementalUnitRate, setIsElementalUnitRate] = useState(false);
  const { showDashboard, setShowDashboard } = props;

  const DISPLAY_UNIT = "m²";
  const { moduleId } = props;
  const configureSettingsData = useConfigureSettings(moduleId, caller);
  // Set the future year to the base date of the estimate or benchmark
  useEffect(() => {
    if (!isEmpty(thisBenchmark)) {
      // if baseDate is available in props, prefer it over thisBenchmark.base_date
      const base_date = baseDate || thisBenchmark.base_date;
      // pick year from date
      const baseYear = extractYearFromISODateString(base_date);
      // set year for use with TPI / Escalation
      setFutureYear(baseYear);

     
    }
    if (showDashboard) setShowDashboard(!showDashboard);
  }, [thisBenchmark, baseDate, showDashboard,setShowDashboard]);

  // Return null if empty data
  if (isEmpty(benchmarks) || isEmpty(thisBenchmark)) {
    return (
      <div className="benchmark-section-empty">
        {translate("No benchmarks selected")}
      </div>
    );
  }

  let benchmarkOrProject = thisBenchmark;

  if (caller === "COST_PLANNING") {
    benchmarkOrProject = {
      lat: project.lat,
      lng: project.lng,
      project_name: project.title,
      id: project.id,
    };
  }

  let displayUnit =
    isLocalRegionFactorEnabled && caller !== "COST_PLANNING"
      ? unitOfMeasure
      : DISPLAY_UNIT;

  // Escalation
  const escalationData = {
    costData: {
      benchmarks: [thisBenchmark, ...benchmarks],
    },
    inflationData: {
      TPI: TPI,
      futureYear: futureYear,
    },
    localityData: {
      LFI: LFI,
      projectCity: city,
      is_locality_factor_active: is_locality_factor_active,
      locality_factor: locality_factor,
      isGlobalBenchmarking: isGlobalBenchmarking,
    },
    currencyData: {
      currencyExchange: currencyExchange,
      currency: isGlobalBenchmarking ? currency : thisBenchmark.currency,
    },
    isLocalRegionFactorEnabled: isLocalRegionFactorEnabled,
  };

  escalation(escalationData);

  // Toggle escalation on and off
  toggleEscalation(isEscalated, [thisBenchmark, ...benchmarks]);

  // Create Dashboard IDs
  const COMPARISION = "COMPARISION";

  const CATEGORIES = "CATEGORIES";
  const COST_BENCHMARKING = "COST_BENCHMARKING";
  const COST_COMPARISION_CHART = "COST_COMPARISION_CHART";
  const BENCHMARK_DISPLAY_TABLE = "BENCHMARK_DISPLAY_TABLE";

  const BENCHMARK_HEATMAP = "BENCHMARK_HEATMAP";
  const BENCHMARK_HEATMAP_1 = "BENCHMARK_HEATMAP_1";
  const BENCHMARK_COSTPLAN_COMPARISIONS = "BENCHMARK_COSTPLAN_COMPARISIONS";
  const BENCHMARK_METRICS = "BENCHMARK_METRICS";
  const BENCHMARK_PROJECTMAP = "BENCHMARK_PROJECTMAP";

  // Default Layout
  const column1Set = [
    COMPARISION,
    COST_COMPARISION_CHART,
    COST_BENCHMARKING,
    CATEGORIES,
    BENCHMARK_COSTPLAN_COMPARISIONS,
    BENCHMARK_DISPLAY_TABLE,
    BENCHMARK_HEATMAP,
    BENCHMARK_HEATMAP_1,
    BENCHMARK_METRICS,
    BENCHMARK_PROJECTMAP,
  ];
  const column2Set = [];
  const column3Set = [];
  const column4Set = [
    COMPARISION,
    COST_COMPARISION_CHART,
    COST_BENCHMARKING,
    CATEGORIES,
    BENCHMARK_COSTPLAN_COMPARISIONS,
    BENCHMARK_DISPLAY_TABLE,
    BENCHMARK_HEATMAP,
    BENCHMARK_HEATMAP_1,
    BENCHMARK_METRICS,
    BENCHMARK_PROJECTMAP,
  ];

  if (isEmpty(configureSettingsData.configureSettings.data)) {
    let jsonData = [
      {
        Discriminator: { caller },
        configSettings: {
          layout: {
            column1: { column1Set },
            column2: { column2Set },
            column3: { column3Set },
          },
        },
      },
    ];
    configureSettingsData.configureSettings.data = jsonData;
  }

  const display = [
    <ProjectComparison
      dashboardType="BENCHMARKING_ANALYSIS"
      dashboardID={COMPARISION}
      caller={caller}
      skipHighlight={skipHighlight}
      unitOfMeasure={displayUnit}
      thisBenchmark={thisBenchmark}
      benchmarks={benchmarks}
      isEscalated={isEscalated}
      futureYear={futureYear}
      isLocalRegionFactorEnabled={
        caller === "COST_PLANNING" ? true : isLocalRegionFactorEnabled
      }
      key="BENCHMARKING_ANALYSIS"
    />,
    <AverageCostCategories
      dashboardType="CATEGORIES"
      dashboardID={CATEGORIES}
      caller={caller}
      title={title}
      thisBenchmark={thisBenchmark}
      benchmarks={benchmarks}
      isLocalRegionFactorEnabled={
        caller === "COST_PLANNING" ? true : isLocalRegionFactorEnabled
      }
      key="CATEGORIES"
    />,
    <CostBenchmarking
      caller={caller}
      dashboardID={COST_BENCHMARKING}
      unitOfMeasure={displayUnit}
      title={title}
      thisBenchmark={thisBenchmark}
      benchmarks={benchmarks}
      isLocalRegionFactorEnabled={
        caller === "COST_PLANNING" ? true : isLocalRegionFactorEnabled
      }
      key="COST_BENCHMARKING"
    />,
    <ContractorOnCostComparisonChart
      title={title}
      dashboardID={COST_COMPARISION_CHART}
      thisBenchmark={thisBenchmark}
      benchmarks={benchmarks}
      cbs={cbs}
      key="COST_COMPARISION_CHART"
    />,
    <BenchmarkingDisplayTableForDashboard
      dashboardID={BENCHMARK_DISPLAY_TABLE}
      className="benchmarking-table-row"
      key="BENCHMARK_DISPLAY_TABLE"
      childComponents={[
        <BenchmarkingDisplayTable
          dashboardID={BENCHMARK_DISPLAY_TABLE}
          skipHighlight={skipHighlight}
          thisBenchmark={thisBenchmark}
          benchmarks={benchmarks}
          isEscalated={isEscalated}
          setIsEscalated={setIsEscalated}
          unitOfMeasure={caller === "COST_PLANNING" ? displayUnit : ""}
          country={country}
          currencySymbol={currencySymbol}
          cbs={cbs}
          isLocalRegionFactorEnabled={
            caller === "COST_PLANNING" ? true : isLocalRegionFactorEnabled
          }
          caller={caller}
          isGlobalBenchmarking={isGlobalBenchmarking}
          key="BENCHMARK_DISPLAY_TABLE"
        />,
        <BenchmarkingJustification
          benchmarks={[thisBenchmark, ...benchmarks]}
          isLocalRegionFactorEnabled={
            caller === "COST_PLANNING" ? true : isLocalRegionFactorEnabled
          }
          key="BENCHMARK_DISPLAY_TABLE_JUSTIFICATION"
        />,
        <BenchmarkingAnonymity
          caller={caller}
          thisBenchmark={thisBenchmark}
          benchmarks={benchmarks}
          onBenchmarkAnonymityToggle={onBenchmarkAnonymityToggle}
          key="BENCHMARK_DISPLAY_TABLE_ANONIMITY"
        />,
      ]}
    />,
    <BenchmarkingDisplayTableForDashboard
      dashboardID={BENCHMARK_HEATMAP}
      key="BENCHMARK_HEATMAP"
      childComponents={[
        <SectionTitleControl
          title={translate("Dataset Comparison")}
          isElementalUnitRate={isElementalUnitRate}
          setIsElementalUnitRate={setIsElementalUnitRate}
          isLocalRegionFactorEnabled= {isLocalRegionFactorEnabled}
          key="SECTION_TITLE_CONTROL"
        />,
        <BenchmarkingHeatMap
          dashboardID={BENCHMARK_HEATMAP}
          skipHighlight={skipHighlight}
          title={title}
          unitOfMeasure={displayUnit}
          currencySymbol={currencySymbol}
          thisBenchmark={thisBenchmark}
          benchmarks={benchmarks.filter((b) => b.source !== "FORM")}
          isElementalUnitRate={isElementalUnitRate}
          selectedCostType="Construction Cost"
          costTypeLabel={tranStr("Construction")}
          cbs={cbs}
          isLocalRegionFactorEnabled={
            caller === "COST_PLANNING" ? true : isLocalRegionFactorEnabled
          }
          caller={caller}
          key="HEATMAP_TABLE"
        />,
      ]}
    />,
    <BenchmarkingHeatMap
      dashboardID={BENCHMARK_HEATMAP_1}
      skipHighlight={skipHighlight}
      title={title}
      unitOfMeasure={displayUnit}
      currencySymbol={currencySymbol}
      thisBenchmark={thisBenchmark}
      benchmarks={benchmarks.filter((b) => b.source !== "FORM")}
      isElementalUnitRate={isElementalUnitRate}
      selectedCostType="Project Cost"
      costTypeLabel={tranStr("Project")}
      cbs={cbs}
      key="BENCHMARK_HEATMAP_1"
    />,
    <BenchmarkingCostplanComparisons
      dashboardID={BENCHMARK_COSTPLAN_COMPARISIONS}
      skipHighlight={skipHighlight}
      thisBenchmark={thisBenchmark}
      benchmarks={benchmarks.filter((b) => b.source !== "FORM")}
      isElementalUnitRate={isElementalUnitRate}
      selectedCostType="On Costs"
      costType="Project"
      costTypeLabel={tranStr("Project")}
      cbs={cbs}
      key="BENCHMARK_COSTPLAN_COMPARISIONS"
    />,
    <BenchmarkProjectsMap
      dashboardID={BENCHMARK_PROJECTMAP}
      thisBenchmark={benchmarkOrProject}
      benchmarks={benchmarks}
      caller={caller}
      key="BENCHMARK_PROJECTMAP"
    />,
    <BenchmarkingMetrics
      dashboardID={BENCHMARK_METRICS}
      skipHighlight={skipHighlight}
      thisBenchmark={thisBenchmark}
      benchmarks={benchmarks}
      currencySymbol={currencySymbol}
      settings={settings}
      onSettingsToggle={onSettingsToggle}
      key="BENCHMARK_METRICS"
    />,
  ];
  return (
    <Dashboard
      display={display}
      column1Set={column1Set}
      column2Set={column2Set}
      column3Set={column3Set}
      column4Set={column4Set}
      // project={project}
      project={moduleId}
      // Dashboard Settings
      showDashboard={showDashboard}
      setShowDashboard={setShowDashboard}
      dashboardSettings={configureSettingsData.configureSettings}
      dashboardType={caller}
      displayType="row"
    />
  );
}
