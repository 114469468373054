// React
import React, { useState } from "react";
import { translate } from "../../utils/translation";
// API
import { useLocations } from "../../api/admin/RegionManagementAPI";

// Components
import { RegionSelect } from "../locations/LocationSelect";
import CreateProjectSelectCostManager from "../project-create/CreateProjectSelectCostManager";
import {
  DisplayNameUser,
  UploadFile,
  FileTray,
  ExistingFile,
  DisplayError,
  onConsent,
  Upload,
} from "./CompanyCreate.components";

// Functions
import { uploadFile } from "./CompanyCreate.functions";

// Styles
import "./CompanyCreate.css";
import Modal from "../modal/Modal";

export default function CompanyCreate(props) {
  const { show } = props;
  const { setShow } = props;
  const { companies } = props;
  const { locations } = useLocations(show);

  const [file, setFile] = useState(null);
  const [error, setError] = useState({});
  const [companyName, setCompanyName] = useState("");
  const [companyEmailDomain, setCompanyEmailDomain] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [region, setRegion] = useState("");
  const [isGlobal, setIsGlobal] = useState(0);
  const [accountLead, setAccountLead] = useState({});

  const [existingLogo, setExistingLogo] = useState("");

  const data = {
    file: file,
    field: {
      name: companyName,
      email_domain: companyEmailDomain,
      address: companyAddress,
      region: region,
      is_global: isGlobal,
      account_lead: accountLead.id,
    },
    setError: setError,
    setShow: setShow,
    companies: companies,
  };

  return (
    <div className="company-create-container">
      <div className="company-create-inputs">
        <div className="company-create-field">
          <div className="company-create-label">
            {translate("Company Name")}
          </div>
          <input
            id="company-name-input"
            className="company-create-input"
            value={companyName}
            onChange={(e) => {
              setCompanyName(e.target.value);
            }}
          ></input>
        </div>
        <div className="company-create-field">
          <div className="company-create-label">
            {translate("Email Domain(s) (CSVs)")}
          </div>
          <input
            id="company-domain-input"
            className="company-create-input"
            value={companyEmailDomain}
            onChange={(e) => {
              setCompanyEmailDomain(e.target.value);
            }}
          ></input>
        </div>
        <div className="company-create-field">
          <div className="company-create-label">
            {translate("Head Office Address")}
          </div>
          <input
            id="company-address-input"
            className="company-create-input"
            value={companyAddress}
            onChange={(e) => {
              setCompanyAddress(e.target.value);
            }}
          ></input>
        </div>
        <div className="company-create-field">
          <div className="company-create-label">
            {translate("Head Office Region")}
          </div>
          <RegionSelect
            value={region}
            set={setRegion}
            options={locations.data}
            classType={"company-create-input"}
          />
        </div>
        <label className="company-edit-checkbox">
          <div className="company-edit-checkbox-text">
            {translate("Global (2+ Regions)")}
          </div>
          <input
            id="company-global-input"
            name={"consent"}
            type="checkbox"
            checked={isGlobal}
            onChange={(e) => {
              onConsent(e, setIsGlobal);
            }}
          />
          <span className="company-edit-checkbox-checkmark"></span>
        </label>
        <ModalSingle
          Component={CreateProjectSelectCostManager}
          setFunction={setAccountLead}
          label={translate("Arcadis Account Lead")}
          user={accountLead}
        />
      </div>
      <ExistingFile existingLogo={existingLogo} />
      <div className="general-row-container">
        <FileTray file={file} />
      </div>
      <div className="general-row-container">
        <UploadFile setFile={setFile} setExistingLogo={setExistingLogo} />
      </div>
      <div className="general-row-container">
        <DisplayError error={error} setError={setError} />
      </div>
      <div className="general-button-container">
        <Upload data={data} uploadFile={uploadFile} />
      </div>
    </div>
  );
}
export function ModalSingle(props) {
  const { Component, setFunction, label } = props;
  const { user } = props;

  const [modal, setModal] = useState(false);

  return (
    <div className="pciu-col">
      <div className="pciu-row">
        <Modal
          // Modal Props
          title={label}
          Component={Component}
          modal={modal}
          setModal={setModal}
          // Component Props
          show={modal}
          setShow={setModal}
          setFunction={setFunction}
          label={label}
        />
        <button
          id="account-lead-button"
          className="pciu-modal-button"
          onClick={() => {
            setModal(true);
          }}
        >
          {label}
        </button>
        <DisplayNameUser user={user} />
      </div>
    </div>
  );
}
