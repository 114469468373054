import xlsx from "@e965/xlsx";
import isEmpty from "../validation/is-empty";

const BENCHMARKTABS = {
  PROJECT_INFO: 0,
  ELEMENTAL_ANALYSIS: 1,
  BENCHMARKS: 2,
};

const spreadsheets = (name, array) => {
  let worksheet = xlsx.utils.aoa_to_sheet(array);

  let colWidths = [];
  colWidths = getColWidths(array);

  worksheet["!cols"] = colWidths;
  let workbook = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(workbook, worksheet);
  xlsx.writeFile(workbook, `${name}.xlsx`);
};
export default spreadsheets;

export function generateBenchmarkInfoExcel(name, array, sheetNames) {
  let workbook = xlsx.utils.book_new();
  const occurrences = {};
  const maxWorksheetNameLength = 25;
  array.forEach((ele, index) => {
    let worksheet = xlsx.utils.aoa_to_sheet(ele[BENCHMARKTABS.PROJECT_INFO]);
    let worksheetName = sheetNames[index] ?? "worksheet";
    worksheetName = worksheetName.substring(0, maxWorksheetNameLength);

    //FOR FUTURE STORIES COMMENT
    //can add other future stories to add to the existing sheet can use the
    //following method pass arrays object and change the origin where the data should be inserted
    xlsx.utils.sheet_add_aoa(worksheet, ele[BENCHMARKTABS.ELEMENTAL_ANALYSIS], {
      origin: { r: 0, c: 4 },
    });
    xlsx.utils.sheet_add_aoa(worksheet, ele[BENCHMARKTABS.BENCHMARKS], {
      origin: { r: 0, c: 13 },
    });
    styleWorkSheet(worksheet, ele);

    // resolve worksheet name conflict.
    occurrences[worksheetName] = (occurrences[worksheetName] || 0) + 1;
    if (occurrences[worksheetName] > 1) {
      worksheetName = `${worksheetName} (${occurrences[worksheetName]})`;
    }
    xlsx.utils.book_append_sheet(workbook, worksheet, worksheetName);
  });
  xlsx.writeFile(workbook, `${name}.xlsx`);
}

export function styleWorkSheet(worksheet, array) {
  // refer the link for further info... https://github.com/SheetJS/sheetjs/blob/master/tests/write.js
  /* SET: COL props */
  let colWidths = [];
  colWidths = getColWidths(array[BENCHMARKTABS.PROJECT_INFO]);
  colWidths.push({ wch: 10 });
  colWidths = colWidths.concat(
    array[BENCHMARKTABS.ELEMENTAL_ANALYSIS].length > 1
      ? getColWidths(array[BENCHMARKTABS.ELEMENTAL_ANALYSIS])
      : []
  );
  colWidths.push({ wch: 10 });
  colWidths = colWidths.concat(
    array[BENCHMARKTABS.BENCHMARKS].length > 1
      ? getColWidths(array[BENCHMARKTABS.BENCHMARKS])
      : []
  );
  worksheet["!cols"] = colWidths;

  /* SET: ROW props */
  let wsrows = [
    { hpt: 15 }, // "points"
  ];
  worksheet["!rows"] = wsrows;

  /* SET: MERGE props */
  let mergeRanges = [];
  array[BENCHMARKTABS.PROJECT_INFO].forEach((innerarray, index) => {
    if (innerarray.length === 1)
      mergeRanges.push(xlsx.utils.decode_range(`"A${index + 1}:C${index + 1}`));
  });
  array[BENCHMARKTABS.BENCHMARKS].forEach((innerarray, index) => {
    if (innerarray.length === 1)
      mergeRanges.push(xlsx.utils.decode_range(`"N${index + 1}:Q${index + 1}`));
  });
  worksheet["!merges"] = mergeRanges;

  /* SET: FORMAT CELLS=>REG EXPRESSION FOR CURRENCY CELLS ( EX: 1,33,444.00)  */
  if (array[BENCHMARKTABS.ELEMENTAL_ANALYSIS].length > 1) {
    for (let i = 3; i <= array[BENCHMARKTABS.ELEMENTAL_ANALYSIS].length; i++) {
      worksheet["G" + i].z = "#,##0.00_);[Red]\\(#,##0.00\\)";
      worksheet["H" + i].z = "#,##0.00_);[Red]\\(#,##0.00\\)";
      worksheet["I" + i].z = "#,##0.00_);[Red]\\(#,##0.00\\)";
      worksheet["J" + i].z = "#,##0_);[Red]\\(#,##0\\)";
      worksheet["L" + i].z = "#,##0.00_);[Red]\\(#,##0.00\\)";
    }
  }
  if (array[BENCHMARKTABS.BENCHMARKS].length > 1) {
    for (let i = 3; i <= array[BENCHMARKTABS.BENCHMARKS].length; i++) {
      if (!isEmpty(worksheet["P" + i]))
        worksheet["P" + i].z = "#,##0;[Red]\\(#,##0\\)";
    }
  }
  return worksheet;
}

function getColWidths(array) {
  let colWidths = [];
  for (let [i] of array[1].entries()) {
    let width = 0;
    for (let [j] of array.entries()) {
      if (array[j][i]) {
        let char = array[j][i].toString().length;
        if (char > width) {
          width = char;
        }
      }
    }
    colWidths.push({ wch: width });
  }
  return colWidths;
}
