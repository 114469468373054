import React, { useState } from "react";

import Modal from "../modal/Modal";
import { translate } from "../../utils/translation";

import "./PCRALogModalTrigger.css";

export default function PCRALogModalTrigger(props) {
  const { modalComponent } = props;
  const [modal, setModal] = useState(false);

  const title = "QA Log";

  return (
    <div>
      <button
        className="pcra-log-modal-trigger-button"
        onClick={() => {
          setModal(true);
        }}
      >
        {translate(title)}
      </button>
      <Modal
        title={translate(title)}
        Component={modalComponent}
        modal={modal}
        setModal={setModal}
        setShow={setModal}
        {...props}
      />
    </div>
  );
}
