// React
import React, { useState, useEffect } from "react";

// Components
import AdminLocalisationControls from "./AdminLocalisationControls";
import AdminLocalisationTable from "./AdminLocalisationTable";
import AdminLocalisationSelector from "./AdminLocalisationSelector";
import TranslationPagination from "../admin-translation/TranslationsPagination";

// API
import {
  UseLocalisations,
  useNamespaces,
  deleteNamespace,
} from "../../api/admin/LocalisationsAPI";

// Functions
import { hasRoles } from "../../utils/roles";
import { save } from "./AdminLocalisation.functions";

//Translation
import { translate } from "../../utils/translation";

// Style
import "./AdminLocalisation.css";

export default function AdminLocalisation(props) {
  const { user } = props;

  const defaultNamespace = "en-au-building-lump-sum";
  const defaultLanguage = "en";

  // Namespace Data
  const [selectedNamespace, setSelectedNamespace] = useState(defaultNamespace);
  const { namespacesObject } = useNamespaces();

  // Language Data
  const [languageCode, setLanguageCode] = useState(defaultLanguage);
  const [language, setLanguage] = useState([]);
  const [tempLanguage, setTempLanguage] = useState(Object.keys(language));
  const [activePage, setActivePage] = useState(1);
  const { languageObject } = UseLocalisations(selectedNamespace);

  useEffect(() => {
    setLanguage(languageObject.data);
    setTempLanguage(Object.keys(languageObject.data));
  }, [languageObject.data, languageObject.loading, selectedNamespace]);

  // RBAC
  if (!hasRoles(user.roles, ["LanguageAdmin"])) {
    return null;
  }

  return (
    <div className="admin-localisation-container">
      <div className="admin-localisation-table">
        <div className="admin-localisation-table-top">
          {/* Localisation set dropdown */}
          <AdminLocalisationSelector
            namespacesObject={namespacesObject}
            selectedNamespace={selectedNamespace}
            setSelectedNamespace={setSelectedNamespace}
            setActivePage={setActivePage}
          />
          {/* Table and Save changes button */}
          <AdminLocalisationTable
            language={language}
            setLanguage={setLanguage}
            tempLanguage={tempLanguage}
            setTempLanguage={setTempLanguage}
            selectedNamespace={selectedNamespace}
            namespacesObject={namespacesObject}
            setSelectedNamespace={setSelectedNamespace}
            defaultNamespace={defaultNamespace}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        </div>
        <div className="admin-localisation-table-footer">
          <TranslationPagination
            activePage={activePage}
            setActivePage={setActivePage}
            tempLanguage={tempLanguage}
          />
          <div className="admin-localisation-table-footer-right">
            <button
              className="general-upload-button"
              onClick={() => {
                save(language, selectedNamespace);
              }}
            >
              {translate("Save Set")}
            </button>
            <button
              className="general-delete-button"
              onClick={async () => {
                if (
                  !window.confirm(
                    `This will permanently delete the set. Confirm?`
                  )
                ) {
                  return null;
                }

                await deleteNamespace(selectedNamespace);
                namespacesObject.set(await namespacesObject.get());
                setSelectedNamespace(defaultNamespace);
              }}
            >
              {translate("Delete Set")}
            </button>
          </div>
        </div>
      </div>

      {/* Import and cloning components*/}
      <div className="admin-localisation-controls">
        <AdminLocalisationControls
          language={language}
          languageObject={languageObject}
          namespacesObject={namespacesObject}
          selectedNamespace={selectedNamespace}
          setSelectedNamespace={setSelectedNamespace}
          defaultNamespace={defaultNamespace}
          languageCode={languageCode}
          setLanguageCode={setLanguageCode}
        />
      </div>
    </div>
  );
}
