import React from "react";

import isEmpty from "../../validation/is-empty";
import { Donut, Legend } from "britecharts-react";
import { comma } from "../../helpers/common.functions";
import { translate } from "../../utils/translation";

import "../project-dashboard-cost-planning/PDCPDonutChart.css";

export default function BudgetDetailedDonutChart(props) {
  const { data } = props;
  const { width, height } = props;
  const { eRadius, iRadius } = props;
  const { lHeight, lWidth } = props;
  const { margin } = props;
  const { colorSchema } = props;
  const { totalBudget } = props;

  if (isEmpty(data)) {
    return null;
  }

  const maxObject = data.reduce((prev, current) => {
    return prev.quantity > current.quantity ? prev : current;
  });

  let ID = 0;

  if (!isEmpty(maxObject)) {
    ID = maxObject.id;
  }

  return (
    <div className="pdcp-donut-chart-container">
      <BDDCDonutChart
        isAnimated={false}
        colorSchema={colorSchema || null}
        data={data}
        width={width || 425}
        height={height || 200}
        eRadius={eRadius || 90}
        iRadius={iRadius || 70}
        ID={ID}
      />
      <BDDCLegend
        colorSchema={colorSchema || null}
        data={data}
        lHeight={lHeight || 120}
        lWidth={lWidth || 365}
        margin={margin || null}
        totalBudget={totalBudget || 0}
      />
    </div>
  );
}

function BDDCLegend(props) {
  const { data } = props;
  const { lHeight, lWidth } = props;
  const { margin } = props;
  const { colorSchema, ID, totalBudget } = props;

  return (
    <div className="pdcp-donut-chart-legend">
      <Legend
        colorSchema={colorSchema}
        data={data}
        height={lHeight}
        width={lWidth}
        numberFormat={","}
        highlightEntryById={ID}
        margin={margin}
      />
      <div className="pdcp-donut-chart-total">
        <div className="pdcp-donut-chart-total-label">
          <b>{translate("Project Budget")}</b>
        </div>
        <div className="pdcp-donut-chart-total-value">
          <b>{comma(totalBudget * 1)}</b>
        </div>
      </div>
    </div>
  );
}

function BDDCDonutChart(props) {
  const { data } = props;
  const { width, height } = props;
  const { eRadius, iRadius } = props;
  const { colorSchema, ID } = props;

  return (
    <Donut
      isAnimated={false}
      colorSchema={colorSchema}
      data={data}
      width={width}
      height={height}
      externalRadius={eRadius}
      internalRadius={iRadius}
      highlightSliceById={ID}
      hasFixedHighlightedSlice={true}
    />
  );
}
