import isEmpty from "../../validation/is-empty";
import { tranStr } from "../../utils/translation";

const AVERAGE_COLUMN = 2;
// Add each Project's name as a column heading
export function columnsFromProjects(headings, allBenchmarks) {
  allBenchmarks.forEach((b) => {
    let name = "";

    if (isEmpty(b.project_name_short)) {
      if (!isEmpty(b.project_name)) {
        if (b.project_name.length > 12) {
          name = b.project_name.slice(0, 9) + "...";
        } else {
          name = b.project_name;
        }
      }
    } else {
      name = b.project_name_short;
    }

    headings.push({ id: b.id, column_name: name });
  });
  headings.splice(AVERAGE_COLUMN, 0, {
    id: 0,
    column_name: tranStr("Average"),
  });
  return headings;
}

export function generateRows(
  elements,
  allBenchmarks,
  isElementalUnitRate,
  unitOfMeasure
) {
  const table = [];

  // Each ELEMENT forms a row in the table
  elements.forEach((element) => {
    const ele = element;

    // Set RATE to match BASIS
    let unit = unitOfMeasure;
    if (isElementalUnitRate) {
      unit = ele.unit;
    }

    const tableRow = {
      code: ele.code, // Each row has a central code
      unit: unit,
      cells: [
        { field: ele.code + " - " + ele.description }, // First Column - Description
        { field: unit }, // Second Column - Unit (UNIT RATES basis, else AREA basis)
      ],
    };

    // Each ROW will have a COLUMN for each PROJECT
    allBenchmarks.forEach((b, i) => {
      const benchmark = b;
      let codeExists = false; // Some codes may be missing so we try to prove otherwise
      benchmark.rows.forEach((thisRow) => {
        const bRow = thisRow;
        // CODE matched
        if (ele.code === bRow.code && i === tableRow.cells.length - 2) {
          codeExists = true; // Codes matched to true as it is not missing

          // Set RATE to match BASIS
          let rate = bRow.displayed_local_region_rate;
          if (isElementalUnitRate) {
            rate = bRow.displayed_rate;
          }

          // Compose the CELL
          const cell = {
            id: benchmark.id,
            field: rate,
            colour: "white",
          };

          // Push to the TABLE ROW
          tableRow.cells.push(cell);
        }
      });
      // No code match exists for this benchmark so add an N/A
      if (!codeExists) {
        const cell = { id: benchmark.id, field: 0, colour: "white" };
        tableRow.cells.push(cell);
      }
    });

    caleculateMeanAndSD(tableRow);
    // Push the completed TABLE ROW to the TABLE
    table.push(tableRow);
  });

  //
  return table;
}

export function addTotals(rows, allBenchmarks, costType, unitOfMeasure) {
  let totalRow = {
    cells: [],
    code: "99.99",
  };

  totalRow.cells.push({
    field:
      tranStr("Total") +
      " " +
      tranStr(costType) +
      " " +
      tranStr("Cost") +
      " / " +
      unitOfMeasure,
  });
  totalRow.cells.push({ field: unitOfMeasure });

  if (costType === "Project") {
    let arrayProjectTotals = [];
    rows.forEach((b) => {
      let i = 0;
      b.cells.forEach((c) => {
        if (c.id !== undefined) {
          if (arrayProjectTotals[i] === undefined) arrayProjectTotals[i] = 0;
          arrayProjectTotals[i] = Math.round(c.field + arrayProjectTotals[i]);
        }
        i++;
      });
    });
    let j = 0;
    arrayProjectTotals.forEach((r, k) => {
      if (k !== AVERAGE_COLUMN && k !== arrayProjectTotals.length - 1) {
        totalRow.cells.push({
          id: "",
          field: Math.round(
            r + allBenchmarks[j].displayed_construction_cost_rate
          ),
        });
        j++;
      }
    });
  } else {
    allBenchmarks.forEach((b) => {
      totalRow.cells.push({
        id: b.id,
        field: Math.round(b.displayed_construction_cost_rate),
      });
    });
  }
  caleculateMeanAndSD(totalRow);

  rows.push(totalRow);

  return rows;
}

export function colourCells(rows) {
  const darkGreen = "13, 166, 66";
  const collaboration3 = "196, 194, 196";

  rows.forEach((row) => {
    const isNotEnoughDataRow =
      row.cells.filter((cell) => cell.field > 0).length < 4;

    row.cells.forEach((cell, index) => {
      if (index === AVERAGE_COLUMN) return;

      if (isNotEnoughDataRow) {
        if (cell.field > 0) {
          cell.colour = `rgba(${darkGreen})`;
        } else if (index > 1) {
          cell.colour = `rgba(${collaboration3})`;
        }
      } else {
        if (cell.field === 0) {
          cell.colour = `rgba(${collaboration3})`;
        } else {
          colourSdCell(
            cell,
            row.cells[AVERAGE_COLUMN].field,
            row.cells[row.cells.length - 1].field
          );
        }
      }
    });
  });

  return rows;
}

function colourSdCell(cell, avg, sd) {
  const oneplus = 1.645;
  const one = 1;
  const pointfive = 0.5;

  const darkblue = "50, 98, 149";
  const lightblue = "101, 137, 176";
  const lightgreen = "113, 202, 142";
  const darkgreen = "13, 166, 66";
  const lightred = "255, 129, 129";
  const red = "228, 31, 19";

  if (cell.field < avg - oneplus * sd) {
    cell.colour = `rgba(${darkblue})`;
  } else if (cell.field > avg - oneplus * sd && cell.field < avg - one * sd) {
    cell.colour = `rgba(${lightblue})`;
  } else if (cell.field > avg - one * sd && cell.field < avg - pointfive * sd) {
    cell.colour = `rgba(${lightgreen})`;
  } else if (
    cell.field > avg - pointfive * sd &&
    cell.field < avg + pointfive * sd
  ) {
    cell.colour = `rgba(${darkgreen})`;
  } else if (cell.field > avg + pointfive * sd && cell.field < avg + one * sd) {
    cell.colour = `rgba(${lightgreen})`;
  } else if (cell.field > avg + one * sd && cell.field < avg + oneplus * sd) {
    cell.colour = `rgba(${lightred})`;
  } else if (cell.field > avg + oneplus * sd) {
    cell.colour = `rgba(${red})`;
  }
}

// Find the largest number in an array
export function maxNumber(array) {
  let tempTotal = 0;
  tempTotal = Math.max.apply(
    Math,
    array.map((o) => {
      return o.field;
    })
  );

  return tempTotal;
}

// Find the smallest number in an array
export function minNumber(array) {
  let tempTotal = 0;
  tempTotal = Math.min.apply(
    Math,
    array.map((o) => {
      return o.field;
    })
  );

  return tempTotal;
}

export function removeDuplicateObjects(array) {
  return array.filter(
    (e, index, self) => index === self.findIndex((t) => t.code === e.code)
  );
}

function caleculateMeanAndSD(tableRow) {
  const tableRowCellsNotZero = tableRow.cells
    .slice(3)
    .filter((ele) => ele.field > 0 || ele.field < 0);

  const mean =
    tableRowCellsNotZero.reduce((total, currentValue) => {
      return total + currentValue.field;
    }, 0) / tableRowCellsNotZero.length;

  const meanCell = {
    field: Math.round(mean),
    colour: "white",
  };

  tableRow.cells.splice(AVERAGE_COLUMN, 0, meanCell);
  caleculateStandardDeviation(tableRow, mean);
}

function caleculateStandardDeviation(tableRow, mean) {
  const deviationArray = [];
  tableRow.cells.forEach((ele) => {
    deviationArray.push(Math.pow(ele.field - mean, 2));
  });
  const squaresNotZeros = deviationArray.filter(
    (ele) => ele > 0 && deviationArray.indexOf(ele) > AVERAGE_COLUMN
  );

  const totalSquares = squaresNotZeros.reduce((total, currentValue) => {
    return total + currentValue;
  }, 0);

  let variance = totalSquares / (squaresNotZeros.length - 1);

  const cell1 = {
    field: Math.sqrt(variance),
    colour: "white",
  };

  tableRow.cells.push(cell1);
}
