import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import BenchmarkingMetricsChart from "../charts/BenchmarkingMetricsChart";
import ChartsToggle from "../charts-toggle/ChartsToggle";

import isEmpty from "../../validation/is-empty";

import { isMetricHidden } from "./BenchmarkingMetrics.functions";
import { hasRoles } from "../../utils/roles";

/// props validation | SQ(javascript:S6774)
BenchmarkMetricsContainer.propTypes = {
  skipHighlight: PropTypes.bool,
  m: PropTypes.object,
  user: PropTypes.object,
  currencySymbol: PropTypes.string,
  settings: PropTypes.array,
  onSettingsToggle: PropTypes.func,
};
///
export default function BenchmarkMetricsContainer(props) {
  const { skipHighlight } = props;

  const { m, user } = props;
  const { currencySymbol } = props;
  const { settings } = props;
  const { onSettingsToggle } = props;

  const setting = "hide_" + m.metric.replace(/\s+/g, "_").toLowerCase();

  const [off, setOff] = useState(false);

  useEffect(() => {
    if (!isEmpty(settings)) {
      if (!isEmpty(settings.find((s) => s.setting === setting))) {
        setOff(settings.find((s) => s.setting === setting).state);
      }
    }
  }, [settings, setting]);

  // Some metrics are to be hard coded off
  if (isMetricHidden(m.code)) {
    return null;
  }

  let box;
  if (!off) {
    box = "benchmarking-chart-box";
  } else if (off && !hasRoles(user.roles, ["CostManager"])) {
    box = "benchmarking-chart-box-disabled";
  } else if (off && hasRoles(user.roles, ["CostManager"])) {
    box = "benchmarking-chart-box-hidden";
  }

  return (
    <div className={box}>
      <div className="benchmarking-chart-heading">
        <h1
          className="display-4"
          style={{
            fontSize: "16px",
          }}
        >
          {m.metric.length > 50 ? m.metric.slice(0, 50) + "..." : m.metric}
        </h1>
        <ChartsToggle
          user={user}
          off={off}
          setOff={setOff}
          setting={setting}
          onSettingsToggle={onSettingsToggle}
        />
      </div>
      <div className="benchmarking-chart">
        <BenchmarkingMetricsChart
          skipHighlight={skipHighlight}
          currencySymbol={currencySymbol}
          data={m.benchmarks}
          title={m.metric}
          code={m.code}
          metric={m}
        />
      </div>
    </div>
  );
}
