import validateFilter from "../../validation/filtering";
import isEmpty from "../../validation/is-empty";

export function projectFilter(array, filter) {
  if (isEmpty(array)) {
    return [];
  }

  return array.filter((a) => {
    const title = validateFilter(a.title);
    const jobNumber = validateFilter(a.job_number);
    const clientCompany = validateFilter(a.company_name);
    const sectorSubSector = validateFilter(a.sector + a.sub_sector);
    const regionCountryCity =
      validateFilter(a.city) +
      validateFilter(a.country) +
      validateFilter(a.region);
    const searchTags = validateFilter(a.search_tags);

    // The Search Filter
    if (
      title.includes(filter.title.toLowerCase()) &&
      jobNumber.includes(filter.jobNumber.toLowerCase()) &&
      clientCompany.includes(filter.clientCompany.toLowerCase()) &&
      sectorSubSector.includes(filter.sectorSubSector.toLowerCase()) &&
      regionCountryCity.includes(filter.regionCountryCity.toLowerCase()) &&
      searchTags.includes(filter.searchTags.toLowerCase())
    ) {
      return a;
    } else {
      return null;
    }
  });
}
