import React from "react";
import isEmpty from "../../validation/is-empty";
import { displayUserImage } from "../../validation/image-clean";
import "./Profile.css";
import { ImageDisplay } from "../images/ImageDisplay";

export default function Profile(props) {
  const { user, projectName } = props;

  return (
    <div className="profile-modal">
      <div className="profile-container">
        <ProfileImage user={user} />
        <div className="profile-details">
          <ProfileName user={user} fontSize={"50px"} />
          <ProfileJobTitle user={user} fontSize={"20px"} />
          <ProfileCompany user={user} fontSize={"40px"} />
          <ProfileLocation user={user} fontSize={"16px"} />
          <ProfileContacts
            user={user}
            fontSize={"16px"}
            projectName={projectName}
          />
        </div>
      </div>
    </div>
  );
}

const ProfileContacts = (props) => {
  const { user, fontSize, projectName } = props;
  return (
    <div className="profile-contacts">
      <ProfileContactItem
        icon={"fab fa-linkedin fa-2x"}
        text={user.linkedin}
        fontSize={fontSize}
      />
      <ProfileEmailItem
        icon={"fas fa-at fa-2x"}
        text={user.email}
        fontSize={fontSize}
        projectName={projectName}
      />
      <ProfileContactItem
        icon={"fas fa-mobile-alt fa-2x"}
        text={user.mobile_number}
        fontSize={fontSize}
      />
      <ProfileContactItem
        icon={"fas fa-phone fa-2x"}
        text={user.phone_number}
        fontSize={fontSize}
      />
    </div>
  );
};

const ProfileEmailItem = (props) => {
  const { icon, text, fontSize, projectName } = props;

  if (isEmpty(text)) {
    return null;
  }

  return (
    <div className="profile-contact-item">
      <div className="profile-contact-icon">
        <i className={icon}></i>
      </div>
      <div className="profile-contact-text">
        <h1 className="display-4" style={{ fontSize: fontSize }}>
          <a
            className="email-link"
            href={"mailto:" + text + "?subject=" + projectName || ""}
          >
            {text}
          </a>
        </h1>
      </div>
    </div>
  );
};

const ProfileContactItem = (props) => {
  const { icon, text, fontSize } = props;

  if (isEmpty(text)) {
    return null;
  }

  return (
    <div className="profile-contact-item">
      <div className="profile-contact-icon">
        <i className={icon}></i>
      </div>
      <div className="profile-contact-text">
        <h1 className="display-4" style={{ fontSize: fontSize }}>
          {text}
        </h1>
      </div>
    </div>
  );
};

const ProfileName = (props) => {
  const { user, fontSize } = props;
  return (
    <div className="profile-name">
      <h1 className="display-4" style={{ fontSize: fontSize }}>
        {user.name + " " + user.surname}
      </h1>
    </div>
  );
};

const ProfileCompany = (props) => {
  const { user, fontSize } = props;
  return (
    <div className="profile-company">
      <h1 className="display-4" style={{ fontSize: fontSize }}>
        {user.company_name}
      </h1>
    </div>
  );
};

const ProfileLocation = (props) => {
  const { user, fontSize } = props;
  return (
    <div className="profile-location">
      <h1 className="display-4" style={{ fontSize: fontSize }}>
        {user.city}
      </h1>
    </div>
  );
};

const ProfileJobTitle = (props) => {
  const { user, fontSize } = props;
  return (
    <div className="profile-job-title">
      <h1 className="display-4" style={{ fontSize: fontSize }}>
        {user.job_title}
      </h1>
    </div>
  );
};

function ProfileImage(props) {
  const { user } = props;
  return (
    <div>
      <ImageDisplay
        image={displayUserImage(user)}
        imageWidth={"150px"}
        imageHeight={"150px"}
        borderRadius={"75px"}
      />
    </div>
  );
}
