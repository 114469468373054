import React from "react";
import { translate } from "../../utils/translation";

import isEmpty from "../../validation/is-empty";
import { comma } from "../../helpers/common.functions";

import "./AreaSchedulesTable.css";

export default function AreaSchedulesTable(props) {
  const { CP } = props;

  if (isEmpty(CP)) {
    return (
      <div className="project-budget">
        {translate(
          "Area Schedules are available after a Cost Plan has been uploaded."
        )}
      </div>
    );
  }

  if (isEmpty(CP.areaschedules)) {
    return (
      <div className="project-budget">
        {translate("Area Schedules have not been uploaded yet.")}
      </div>
    );
  }

  return (
    <div className="area-schedule-table">
      <TableHead />
      <Table data={CP.areaschedules} />
    </div>
  );
}
function TableHead() {
  return (
    <div className="area-schedule-table-heading-row">
      <div className="area-schedule-table-description-cell">
        {translate("Description")}
      </div>
      <div className="area-schedule-table-quantity-cell">
        {translate("Quantity")}
      </div>
      <div className="area-schedule-table-unit-cell">{translate("Unit")}</div>
      <div className="area-schedule-table-allocation-cell">
        {translate("Cost Allocated to Area")}
      </div>
      <div className="area-schedule-table-allocation-cell">
        {translate("Cost per Functional Area")}
      </div>
    </div>
  );
}
function Table(props) {
  const { data } = props;

  return (
    <div>
      {data.map((row, i) => {
        // Subtotal styling for other rows
        let rowType = "area-schedule-table-row";
        switch (row.code) {
          case "Head1":
            rowType = "area-schedule-table-head-row";
            break;
          case "Head2":
            rowType = "area-schedule-table-head2-row";
            break;
          case "T":
            rowType = "area-schedule-table-total-row";
            break;
          case "ST":
            rowType = "area-schedule-table-sub-total-row";
            break;
          case "GT":
            rowType = "area-schedule-table-grandtotal-row";
            break;
          default:
            rowType = "area-schedule-table-row";
            break;
        }

        if (row.description === "") {
          return null;
        }
        // Estimate Description Type
        let descriptionType = "area-schedule-table-description-cell";
        if (row.code === "Head1" || row.code === "Head2") {
          return (
            <div key={row.id} className={rowType}>
              <div className={"area-schedule-table-description-cell-heading"}>
                {row.description}
              </div>
            </div>
          );
        }

        return (
          <SummaryRowNoLink
            key={i}
            row={row}
            descriptionType={descriptionType}
            rowType={rowType}
          />
        );
      })}
    </div>
  );
}

function SummaryRowNoLink(props) {
  const { row } = props;
  const { descriptionType } = props;
  const { rowType } = props;

  //Get costs allocated to this area schedule
  let allocated = 0;

  if (!isEmpty(row.CostAllocations)) {
    row.CostAllocations.forEach((x) => {
      allocated += x.cost;
    });
  }
  let cost_per_area = 0;

  if (row.quantity > 0) {
    cost_per_area = (allocated / row.quantity).toFixed(1);
  }

  if (row.code === "GT" || row.code === "T" || row.code === "ST") {
    allocated = "";
    cost_per_area = "";
  }

  let quantity = row.quantity;

  if (row.code === "GT" || row.code === "T") {
    quantity = row.total;
  }
  if (row.code === "ST") {
    quantity = row.subtotal;
  }

  return (
    <div key={row.id} className={rowType}>
      <div className={descriptionType}>{row.description}</div>
      <div className="area-schedule-table-quantity-cell ">
        {comma(quantity)}
      </div>
      <div className="area-schedule-table-unit-cell">{row.unit_of_measure}</div>
      <div className="area-schedule-table-allocation-cell ">
        {comma(allocated)}
      </div>
      <div className="area-schedule-table-allocation-cell ">
        {comma(cost_per_area)}
      </div>
    </div>
  );
}
