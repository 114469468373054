import React, { useEffect, useState } from "react";
import PCRALogAPI from "../pcra/PCRALogAPI";
import PCRAReadInput from "../pcra/PCRAReadInput.class";
import PropTypes from "prop-types";

import PCRALogRoot from "../pcra/PCRALogRoot";

import { PROCESS_TYPE } from "../pcra/PCRAGlossary.constants";

PostContractPCRALogModal.propTypes = {
  user: PropTypes.object,
  postContract: PropTypes.object,
  selectedReport: PropTypes.object,
  isLocked: PropTypes.bool,
  lockedReason: PropTypes.string,
};

export default function PostContractPCRALogModal(props) {
  const { user } = props;
  const { postContract } = props;
  const { selectedReport } = props;
  const { isLocked, lockedReason } = props;

  const [pcraData, setPcraData] = useState({ loading: true });

  useEffect(() => {
    const readInput = new PCRAReadInput({
      ids: [selectedReport.Id],
      user,
      processType: PROCESS_TYPE.PCA,
      isLocked,
    });
    (async () => {
      await PCRALogAPI.ReadAuditLog({ readInput, setPcraData });
    })();
  }, [postContract, selectedReport, user, isLocked]);

  return (
    <PCRALogRoot
      user={user}
      data={pcraData}
      setData={setPcraData}
      isLocked={isLocked}
      lockedReason={lockedReason}
    />
  );
}
