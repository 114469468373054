import { authHeader } from "../../_helpers/auth-header";
import { upAPIs } from "../api-endpoints/UserProfileEndpoints";
import { addCompanyIDs, addCompanyData } from "../admin/CompanyAPI";
import { useState, useEffect } from "react";
import { setCurrentUser, createUserObject } from "../../actions/authActions";
import { addSingleEmployee } from "../../api/admin/CompanyAPI";
import { userAdaptor, usersAdaptor } from "../api-adaptors/UserAdaptor";
import { getDmsToken } from "../../active-directory/getDmsToken";

import store from "../../store";

import isEmpty from "../../validation/is-empty";

export async function getOrCreateUserProfile(idToken) {
  const url = upAPIs().GetOrCreateUserProfile;

  const dmsToken = await getDmsToken();
  let dmsBearer = "";
  if (!isEmpty(dmsToken)) {
    dmsBearer = dmsToken.accessToken;
  }

  const config = {
    method: "GET",
    headers: {
      authorization: `Bearer ${idToken}`,
      access: null,
      "Content-type": "application/json",
      dmsBearer: `Bearer ${dmsBearer}`,
    },
  };

  let response = {};
  try {
    response = await fetch(url, config);
  } catch (e) {
    throw new Error("Network Error");
  }

  if (response.ok) {
    return userAdaptor(response);
  } else {
    response = await response.text();
    throw new Error(response);
  }
}

// Get a list of users
export async function getUsersByIDs(userIDs) {
  // COST PLAN API
  let url = upAPIs().GetUserList;

  if (isEmpty(userIDs)) {
    return [];
  }

  const dmsToken = await getDmsToken();
  let dmsBearer = "";
  if (!isEmpty(dmsToken)) {
    dmsBearer = dmsToken.accessToken;
  }

  const config = {
    method: "POST",
    body: JSON.stringify(userIDs),
    headers: {
      ...authHeader({ authJson: true, authForm: false, guestJson: false }),
      dmsBearer: `Bearer ${dmsBearer}`,
    },
  };

  let response = await fetch(url, config);
  if (response.ok) {
    const users = await usersAdaptor(response);
    const usersCompanyIDs = await addCompanyIDs(users);
    const usersCompanyData = await addCompanyData(usersCompanyIDs);
    response = usersCompanyData;
  } else {
    response = [];
    console.log("Network Error");
  }
  return response;
}

export async function getAllUserProfiles() {
  const dmsToken = await getDmsToken();
  let dmsBearer = "";
  if (!isEmpty(dmsToken)) {
    dmsBearer = dmsToken.accessToken;
  }

  const url = upAPIs().Get;

  const config = {
    method: "GET",
    headers: {
      ...authHeader({ authJson: true, authForm: false, guestJson: false }),
      dmsBearer: `Bearer ${dmsBearer}`,
    },
  };

  let erMessage = "Get Accounts error";

  let response = await fetch(url, config);
  if (response.ok) {
    const users = await usersAdaptor(response);
    response = users;
  } else {
    response = [];
    console.log(erMessage);
  }

  return response;
}

export async function getAllEmployees(show) {
  if (!show) {
    return [];
  }

  const dmsToken = await getDmsToken();
  let dmsBearer = "";
  if (!isEmpty(dmsToken)) {
    dmsBearer = dmsToken.accessToken;
  }

  const url = upAPIs().Get;
  const config = {
    method: "GET",
    headers: {
      ...authHeader({ authJson: true, authForm: false, guestJson: false }),
      dmsBearer: `Bearer ${dmsBearer}`,
    },
  };

  let erMessage = "Get All User Profiles Error";

  let response = await fetch(url, config);

  if (response.ok) {
    response = await usersAdaptor(response);
  } else {
    response = [];
    console.log(erMessage);
  }

  return response;
}

export async function getUsersByGroup(show, groupType) {
  if (!show) {
    return [];
  }

  const dmsToken = await getDmsToken();
  let dmsBearer = "";
  if (!isEmpty(dmsToken)) {
    dmsBearer = dmsToken.accessToken;
  }

  // B2C API
  const url = upAPIs().Get;
  const config = {
    method: "GET",
    headers: {
      ...authHeader({ authJson: true, authForm: false, guestJson: false }),
      dmsBearer: `Bearer ${dmsBearer}`,
    },
  };

  let erMessage = "Get Cost Managers Error";
  let response = await fetch(url, config);

  if (response.ok) {
    const userIDs = await usersAdaptor(response);
    const IDs = userIDs.map((x) => x.id);
    const users = await getUsersByIDs(IDs);
    const filteredUsers = groupUsers(users, groupType);
    response = filteredUsers;
  } else {
    response = [];
    console.log(erMessage);
  }

  return response;
}

export async function sendCreateRequest(d) {
  const data = d;

  const url = upAPIs().Put + "?id=" + data.payload.id; // activeDirectoryId
  const { payload } = data;

  const config = {
    method: "PUT",
    body: JSON.stringify(payload),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);

  if (response.ok) {
    // Profile created or updated successfully, now set the company
    const companySuccess = await addSingleEmployee(
      data.payload.companyID,
      data.payload.id
    );
    if (companySuccess) {
      const imageSuccess = await uploadUserImage(data);
      if (imageSuccess) {
        try {
          const idToken = localStorage.getItem("idToken");
          const profile = await getOrCreateUserProfile(idToken);
          const user = await createUserObject(profile, idToken);
          store.dispatch(setCurrentUser(user));
        } catch (e) {
          // User won't see change until relog
          console.log("Get Updated Profile Failed");
        }
      } else {
        console.log("Error Uploading Image");
      }
    } else {
      // User Image Didn't Upload
      console.log("Error Saving Company");
    }
  } else {
    // User Details Didn't Upload
    response = await response.json();
    data.setErrors(response.errors);
    console.log("Error Saving User");
  }
}

export async function sendEditRequest(d) {
  const data = d;

  const url = upAPIs().Put + "?id=" + data.payload.id; // activeDirectoryId
  const { payload } = data;

  const config = {
    method: "PUT",
    body: JSON.stringify(payload),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);

  if (response.ok) {
    const imageSuccess = await uploadUserImage(data);
    if (imageSuccess) {
      data.setErrors({});
      const idToken = localStorage.getItem("idToken");
      try {
        const profile = await getOrCreateUserProfile(idToken);
        const user = await createUserObject(profile, idToken);
        store.dispatch(setCurrentUser(user));
        data.setShow(false);
      } catch (e) {
        // User won't see change until relog
        console.log("Get Updated Profile Failed");
      }
    } else {
      // User Image Didn't Upload
      console.log("Error Uploading Image");
    }
  } else {
    // User Details Didn't Upload
    response = await response.json();
    data.setErrors(response.errors);
  }
}

export async function sendAdminRequest(d) {
  const data = d;

  const url = upAPIs().Put + "?id=" + data.payload.id; // activeDirectoryId
  const { payload } = data;

  const config = {
    method: "PUT",
    body: JSON.stringify(payload),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);
  if (response.ok) {
    const imageSuccess = await uploadUserImage(data);
    if (imageSuccess) {
      data.setErrors({});
      data.setShow(false);
      // Force reload so that user data is pulled
      window.location.reload();
    } else {
      // User Image Didn't Upload
      console.log("Error Uploading Image");
    }
  } else {
    // User Details Didn't Upload
    response = await response.json();
    data.setErrors(response.errors);
    console.log("Network Error");
  }
}

export async function deleteAccount(data) {
  let result = window.confirm(
    `This will permanently delete the account. Confirm?`
  );

  if (!result) {
    return null;
  }

  const url = upAPIs().Delete + "/" + data.accountID + "?isSoftDelete=false";

  const config = {
    method: "DELETE",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);
  if (response.ok) {
    data.accounts.set(await data.accounts.get());
    data.setShow(false);
  } else {
    console.log("Admin Account Delete Error");
  }
}

// GET ACCOUNTS
export function useAccounts() {
  const [loading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState([]);
  useEffect(() => {
    setLoading(true);
    getAllUserProfiles()
      .then((accountsRes) => {
        setAccounts(accountsRes);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  return {
    accounts: {
      data: accounts,
      loading: loading,
      get: getAllUserProfiles,
      set: setAccounts,
    },
  };
}

export function useUsersIds(userIDs, show) {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  useEffect(() => {
    if (show) {
      setLoading(true);
      getUsersByIDs(userIDs)
        .then((data) => {
          setUsers(data);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e.message);
          setLoading(false);
        });
    }
  }, [userIDs, show]);

  return {
    users: {
      data: users,
      loading: loading,
      get: getUsersByGroup,
      set: setUsers,
    },
  };
}

export function useUsersByGroup(show, userGroup) {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  useEffect(() => {
    if (show) {
      setLoading(true);
      getUsersByGroup(show, userGroup)
        .then((data) => {
          setUsers(data);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e.message);
          setLoading(false);
        });
    }
  }, [show, userGroup]);

  return {
    users: {
      data: users,
      loading: loading,
      get: getUsersByGroup,
      set: setUsers,
    },
  };
}

export function groupUsers(users, groupType) {
  const filteredUsers = [];
  if (groupType === "all") {
    return users;
  }

  if (groupType === "internal") {
    users.forEach((u) => {
      if (validateField(u.email).includes("@arcadis")) {
        filteredUsers.push(u);
      }
    });
  }
  if (groupType === "external") {
    users.forEach((u) => {
      if (!validateField(u.email).includes("@arcadis")) {
        filteredUsers.push(u);
      }
    });
  }

  return filteredUsers;
}

function validateField(value) {
  return value ? value.toLowerCase() : "";
}

export async function uploadUserImage(d) {
  const data = d;
  const url = upAPIs().UploadImage + "?id=" + data.payload.id; // activeDirectoryId
  const formData = new FormData();
  try {
    // A default or existing image from a URL
    if (typeof data.file === "string") {
      let fileURL = data.file;
      let file = await fetch(fileURL)
        .then((r) => r.blob())
        .then(
          (blobFile) => new File([blobFile], "image.png", { type: "image/png" })
        );

      formData.append("userImage", file, file.name);
      // An image from an upload
    } else {
      formData.append("userImage", data.file, data.file.name);
    }
  } catch (e) {
    // Image broken or no image
    data.setErrors({
      Image: e.message,
    });
    return false;
  }
  formData.append("field", JSON.stringify(data.payload));

  const dmsToken = await getDmsToken();
  let dmsBearer = "";
  if (!isEmpty(dmsToken)) {
    dmsBearer = dmsToken.accessToken;
  }

  const config = {
    method: "POST",
    body: formData,
    headers: {
      ...authHeader({ authJson: false, authForm: true, guestJson: false }),
      dmsBearer: `Bearer ${dmsBearer}`,
    },
  };

  let response = await fetch(url, config);
  if (response.ok) {
    return true;
  } else {
    try {
      response = await response.json();
      data.setErrors({
        Image: response.message,
      });
      return false;
    } catch (e) {
      data.setErrors({
        Image: "Image Upload Error",
      });
      return false;
    }
  }
}
