import { useState, useEffect } from "react";
import { authHeader } from "../../_helpers/auth-header";
import { feedbackAPIs } from "../api-endpoints/FormDataEndpoints";
// Get a list of locations
export function getFeedback() {
  const url = feedbackAPIs().get_feedback;
  let erMessage = "Get Feedback error";
  return fetch(url, {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  })
    .then(function (response) {
      if (response.ok) {
        return response.json();
      }
      throw new Error(erMessage);
    })
    .then((result) => {
      return result;
    })
    .catch(function (error) {
      console.log(
        "There has been a problem with your fetch operation: ",
        error.message
      );
    });
}

export function useFeedback() {
  const [feedbackLoading, setLoading] = useState(true);
  const [feedback, setFeedback] = useState([]);
  useEffect(() => {
    setLoading(true);
    getFeedback()
      .then((locations) => {
        setFeedback(locations);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  return {
    feedbackLoading,
    feedback,
    setFeedback,
    error: null,
  };
}

export async function onClickFeedback(method, url, selectedItems, setFeedback) {
  let feedbackIDs = selectedItems.map((f) => f.id);
  const payload = { feedbackIDs };
  const config = {
    method: method,
    body: JSON.stringify(payload),
    headers: authHeader({
      authJson: true,
      authForm: false,
      guestJson: false,
    }),
  };

  let erMessage = "On click feedback Error";
  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
  } else {
    setFeedback(await getFeedback());
    return console.log(erMessage);
  }
  setFeedback(await getFeedback());
}

export async function onClickNote(url, selectedItems, note, setFeedback) {
  let feedbackIDs = selectedItems.map((f) => f.id);
  const payload = { feedbackIDs, note };
  const config = {
    method: "POST",
    body: JSON.stringify(payload),
    headers: authHeader({
      authJson: true,
      authForm: false,
      guestJson: false,
    }),
  };

  let erMessage = "Admin Save Note Error";
  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
  } else {
    setFeedback(await getFeedback());
    return console.log(erMessage);
  }

  setFeedback(await getFeedback());
}
