// React
import React, { useState, useEffect, useRef } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// Redux
import { connect } from "react-redux";

// API
import { useDashboardSettings } from "../../api/portfolio/PortfolioDashboardSettingsAPI";
import {
  usePortfolios,
  usePortfolioUsers,
} from "../../api/portfolio/PortfolioAPI";

import {
  usePortfolioProjects,
  usePortfolioCostPlanning,
  usePortfolioPostContract,
} from "../../api/portfolio/PortfolioProjectsAPI";
import { useTPI } from "../../api/tender-price-index/TenderPriceIndexingAPI";

// Components
import PortfolioControl from "../portfolio-control/PortfolioControl";
import PortfolioProjects from "../portfolio-projects/Projects";
import PortfolioDashboard from "../portfolio-dashboard/PortfolioDashboard";
import PortfolioMap from "../portfolio-map/PortfolioMap";
import PortfolioProjectsCreateButton from "../portfolio-projects/PortfolioProjectsCreateButton";
import PortfolioNavigation from "../portfolio-navigation/PortfolioNavigation";
import PortfolioList from "../portfolio-list/PortfolioList";
import PortfolioFilter from "../portfolio-projects/PortfolioFilter";
import PortfolioDashboardButton from "../portfolio-dashboard/PortfolioDashboardButton";

// Functions
import { translate } from "../../utils/translation";
import { filterProjects } from "../portfolio-projects/PortfolioFilter.functions";
import { generateProjects, combineProjects } from "./Portfolio.functions";
// Style
import "./Portfolio.css";

function Portfolio(props) {
  // Props
  const { portfolioId } = props.match.params;
  const { user } = props.auth;

  const [selectedPortfolio, setSelectedPortfolio] = useState({});

  // Portfolio State
  const [isDropEnabled, setIsDropEnabled] = useState(false);
  const [isDragEnabled, setIsDragEnabled] = useState(false);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [showDashboard, setShowDashboard] = useState(false);

  // Projects State
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [portfolioFilters, setPortfolioFilters] = useState({
    title: "",
    clientCompany: "",
    sector: "",
    subsector: "",
    projectType: "",
    projectQuality: "",
    procurementMethod: "",
    location: "",
    searchTags: "",
  });
  const [showFilters, setShowFilters] = useState(false);

  // The set of all projects in their default collection
  const { projects, setProjects, projectsLoading } =
    usePortfolioProjects(portfolioId);

  // The set of projects that have been filtered (with no filter this is every project)
  const [filteredProjects, setFilteredProjects] = useState([]);
  useEffect(() => {
    setFilteredProjects(filterProjects(projects, portfolioFilters));
  }, [projects, portfolioFilters]);

  // The set of projects that will have additional data loaded
  const [detailedProjects, setDetailedProjects] = useState([]);
  useEffect(() => {
    // In a Portfolio this is all of them
    if (portfolioId !== "all-projects") {
      setDetailedProjects(filteredProjects);
      // In a Main view this is only the current visible
    } else {
      setDetailedProjects(
        generateProjects(filteredProjects, pageSize, activePage)
      );
    }
  }, [filteredProjects, pageSize, activePage, portfolioId]);

  // Load additional data from services for the projects async
  const { projectsCostPlanning, projectsCostPlanningLoading } =
    usePortfolioCostPlanning(detailedProjects, selectedPortfolio);
  const { projectsPostContract, projectsPostContractLoading } =
    usePortfolioPostContract(detailedProjects);

  // The combined project data
  const [combinedProjects, setCombinedProjects] = useState([]);
  useEffect(() => {
    setCombinedProjects(
      combineProjects(
        filteredProjects,
        projectsCostPlanning,
        projectsPostContract
      )
    );
  }, [filteredProjects, projectsCostPlanning, projectsPostContract]);

  // The set of projects that are visible
  const [visibleProjects, setVisibleProjects] = useState([]);
  useEffect(() => {
    setVisibleProjects(
      generateProjects(combinedProjects, pageSize, activePage)
    );
  }, [combinedProjects, pageSize, activePage]);

  // Load supporting data async
  const { users } = usePortfolioUsers(portfolioId);
  const { portfolios } = usePortfolios();
  const { TPI } = useTPI(true, user.region, user.country, true);
  const { dashboardSettings } = useDashboardSettings(selectedPortfolio);

  // Update selected portfolio when portfolios change
  useEffect(() => {
    if (!portfolios.loading) {
      if (portfolioId !== "all-projects") {
        // Set the previous portfolio again (which is updated)
        const p = portfolios.data.find((x) => x.id === portfolioId);
        if (p) {
          setSelectedPortfolio(p);
        } else {
          props.history.push("/portfolio/all-projects/projects/");
        }
      }
    }
  }, [
    portfolios,
    selectedPortfolio,
    setSelectedPortfolio,
    portfolioId,
    props.history,
  ]);

  // The ProjectID being dragged
  const dragID = useRef(null);

  return (
    <div className="portfolio-background">
      <div className="portfolio-control-column-backer">
        <div className="portfolio-control-column">
          <div className="portfolio-control-content">
            <div className="portfolio-title">
              <h1 className="display-4">{translate("Portfolio")}</h1>
            </div>
            <PortfolioNavigation portfolioId={portfolioId} />
            <div className="portfolio-control">
              <div className="portfolio-title-small">
                <h1 className="display-4">{translate("Portfolio Control")}</h1>
              </div>
              <PortfolioList
                portfolioId={portfolioId}
                portfolios={portfolios}
                setIsDropEnabled={setIsDropEnabled}
                setIsEditEnabled={setIsEditEnabled}
              />
              <PortfolioControl
                portfolioId={portfolioId}
                portfolios={portfolios}
                dragID={dragID}
                selectedPortfolio={selectedPortfolio}
                isDropEnabled={isDropEnabled}
                setIsDropEnabled={setIsDropEnabled}
                isDragEnabled={isDragEnabled}
                isEditEnabled={isEditEnabled}
                setIsEditEnabled={setIsEditEnabled}
                setProjects={setProjects}
                users={users}
                user={user}
              />
            </div>
          </div>
          <div className="post-contract-control-panel">
            <PortfolioDashboardButton
              selectedPortfolio={selectedPortfolio}
              showDashboard={showDashboard}
              setShowDashboard={setShowDashboard}
            />
            <PortfolioFilter
              pageSize={pageSize}
              setPageSize={setPageSize}
              setPortfolioFilters={setPortfolioFilters}
              showFilters={showFilters}
              setShowFilters={setShowFilters}
            />
            <PortfolioProjectsCreateButton
              portfolioId={portfolioId}
              user={user}
              setProjects={setProjects}
            />
          </div>
        </div>
      </div>
      <Switch>
        {/* Default Route */}
        <Route
          exact
          path={"/portfolio/:portfolioId/"}
          render={() => <Redirect replace to={`/portfolio/projects`} />}
        />
        {/* Routes */}
        <Route
          path={"/portfolio/:portfolioId/projects"}
          render={() => (
            <PortfolioProjects
              projects={projects}
              projectsLoading={projectsLoading}
              projectsCostPlanningLoading={projectsCostPlanningLoading}
              projectsPostContractLoading={projectsPostContractLoading}
              pageSize={pageSize}
              dragID={dragID}
              setIsDragEnabled={setIsDragEnabled}
              selectedProjects={visibleProjects}
              filteredProjects={filteredProjects}
              activePage={activePage}
              setActivePage={setActivePage}
              // Search Filters
              portfolioFilters={portfolioFilters}
              setPortfolioFilters={setPortfolioFilters}
            />
          )}
        />
        <Route
          path={"/portfolio/:portfolioId/dashboard"}
          render={() => (
            <PortfolioDashboard
              filteredProjects={combinedProjects}
              projectsLoading={projectsLoading}
              projectsCostPlanningLoading={projectsCostPlanningLoading}
              projectsPostContractLoading={projectsPostContractLoading}
              selectedPortfolio={selectedPortfolio}
              showDashboard={showDashboard}
              setShowDashboard={setShowDashboard}
              dashboardSettings={dashboardSettings}
              TPI={TPI}
              portfolioId={portfolioId}
              // Search Filters
              portfolioFilters={portfolioFilters}
              setPortfolioFilters={setPortfolioFilters}
            />
          )}
        />
        <Route
          path={"/portfolio/:portfolioId/map"}
          render={() => (
            <PortfolioMap
              filteredProjects={combinedProjects}
              // Search Filters
              portfolioFilters={portfolioFilters}
              setPortfolioFilters={setPortfolioFilters}
            />
          )}
        />
      </Switch>
    </div>
  );
}

export default connect((state) => ({
  auth: state.auth,
}))(Portfolio);
