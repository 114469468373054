import isEmpty from "../validation/is-empty";

// OLD REGEX const thousandsSeparator = /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g;

export function calculateTotal(estimates) {
  let sum = 0;
  estimates.forEach((item) => {
    sum += item.value;
  });
  return Math.round(sum);
}

export function commaToFixed(x) {
  if (isEmpty(x)) {
    return;
  }

  if (isNaN(x)) {
    return;
  }

  // The value may be a string so we return it to a number
  x = x * 1;

  const resolvedOptions = new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "AUD",
  }).resolvedOptions();
  const currencyOptions = {
    minimumFractionDigits: resolvedOptions.minimumFractionDigits,
    maximumFractionDigits: resolvedOptions.maximumFractionDigits,
  };
  return x.toLocaleString("en-AU", currencyOptions);
}

export function commaBrackets(x) {
  if (isEmpty(x)) {
    return;
  }

  if (isNaN(x)) {
    return;
  }

  return generateBrackets(new Intl.NumberFormat().format(x));
}

export function comma(x) {
  if (isEmpty(x)) {
    return;
  }
  if (isNaN(x)) {
    return x;
  }

  return new Intl.NumberFormat().format(x).toString();
}

export function commaWithNaN(x) {
  if (isEmpty(x)) {
    return "";
  }
  if (isNaN(x)) {
    return "";
  }
  return new Intl.NumberFormat().format(x).toString();
}

export function OrderByDescending(prop) {
  return function (a, b) {
    if (a[prop] < b[prop]) {
      return 1;
    } else if (a[prop] > b[prop]) {
      return -1;
    }
    return 0;
  };
}

export function OrderByAscending(prop) {
  return function (a, b) {
    if (a[prop] > b[prop]) {
      return 1;
    } else if (a[prop] < b[prop]) {
      return -1;
    }
    return 0;
  };
}

export function randomNumberGenerator(min, max) {
  // Create byte array and fill with 1 random number
  let byteArray = new Uint8Array(1);
  window.crypto.getRandomValues(byteArray);

  let range = max - min + 1;
  let max_range = 256;
  if (byteArray[0] >= Math.floor(max_range / range) * range)
    return randomNumberGenerator(min, max);
  return min + (byteArray[0] % range);
}

function generateBrackets(string) {
  string = string.toString();
  if (string.includes("-")) {
    return "(" + string.split("-")[1] + ")";
  } else {
    return "+" + string;
  }
}

export function FormatDate(value) {
  if (isEmpty(value)) {
    return "";
  }

  const formattedDate = new Date(value).toLocaleString("default", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  return formattedDate;
}

export function FormatDateTime(value) {
  if (isEmpty(value)) {
    return "";
  }

  const formattedDate = new Date(value).toLocaleString("default", {
    dateStyle: "full",
    timeStyle: "medium",
  });

  return formattedDate;
}

// input date must be a valid ISO date string, e.g. 2024-07-19T00:00:00
export function extractYearFromISODateString(date) {
  return parseInt(
    new Date(date).toLocaleString("default", {
      year: "numeric",
    })
  );
}

export function deepClone(object) {
  return JSON.parse(JSON.stringify(object));
}

export function dateDDMMYYYYToDateObject(dateDDMMYYYY, delimiter = "/") {
  if (!dateDDMMYYYY?.includes(delimiter)) {
    return null;
  }

  // input date must be in DD/MM/YYYY format
  // if DD-MM-YYYY, specify delimiter="-"
  const [DD, MM, YYYY] = dateDDMMYYYY.split(delimiter);

  // consider date as UTC
  // month is 0-based, hence subtract 1
  const date = new Date(Date.UTC(+YYYY, +MM - 1, +DD));

  return date;
}

export function dateDDMMYYYYToISODateString(dateDDMMYYYY, delimiter = "/") {
  return dateDDMMYYYYToDateObject(dateDDMMYYYY, delimiter)?.toISOString();
}

export function buildDateFromYear({ year }) {
  return year && dateDDMMYYYYToISODateString(`01/01/${year}`);
}

// input date must be in ISO date format
// eg. "2027-01-01T00:00:00.000Z"
export function extractYearFromDate({ date }) {
  return (date || "").substring(0, 4);
}

/*
Usage: nameof({ myLongVariable }) // returns "myLongVariable"
*/
export function nameof(variable) {
  return Object.keys(variable)[0];
}

/*
Usage: toSnakeCase("Project Construction Cost") ---> project_construction_cost
Usage: toSnakeCase("Project   Construction   Cost") ---> project_construction_cost
*/
export function toSnakeCase(string) {
  return string.toLowerCase().replace(/\s+/g, "_");
}

/*
Usage: toKebabCase("Current Selection") ---> current-selection
Usage: toKebabCase("Previously   Saved") ---> previously-saved
*/
export function toKebabCase(string) {
  return string.toLowerCase().replace(/\s+/g, "-");
}

/*
Usage: toPascalCase("unitOfMeasure") ---> UnitOfMeasure
*/
export function toPascalCase(string) {
  return `${string.slice(0, 1).toUpperCase()}${string.slice(1)}`;
}

export function BooleanToON_OFF(bool) {
  return bool ? "On" : "Off";
}

/*
Usage: areArraysOfObjectsEqual([{region: "UK", city: "London"}], [{region: "UK", city: "Nottingham"}]) ---> false
Usage: areArraysOfObjectsEqual([{region: "UK", city: "London"}], [{region: "UK", city: "London"}]) ---> true
*/
export function areArraysOfObjectsEqual(a, b) {
  return JSON.stringify(a) === JSON.stringify(b);
}

/*
Usage: areArraysEqual(['a', 'b'], ['a', 'c']) ---> false
Usage: areArraysEqual(['a', 'b'], ['a', 'b']) ---> true
*/
export function areArraysEqual(a, b) {
  return a.every((v, i) => v === b[i]);
}
