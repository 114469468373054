import React from "react";
import PropTypes from "prop-types";

import { comma } from "../../helpers/common.functions";

import MetricsChart from "./MetricsChart";

/// props validation | SQ(javascript:S6774)
MetricsHorizontalBarChart.propTypes = {
  skipHighlight: PropTypes.bool,
  benchmarkControl: PropTypes.bool,
  data: PropTypes.array,
  avgs: PropTypes.array,
};
///
export default function MetricsHorizontalBarChart(props) {
  const { skipHighlight } = props;
  const { benchmarkControl } = props;
  const { data, avgs } = props;

  return (
    <MetricsChart
      // props to pick chart
      chartType={"HorizontalBar"}
      labelTooltipCallback={(tooltipItem) => comma(tooltipItem.xLabel)}
      yAxisTicksCallback={(tick, index, values) => comma(tick)}
      // props to fill chart
      skipHighlight={skipHighlight}
      benchmarkControl={benchmarkControl}
      data={data}
      avgs={avgs}
    />
  );
}
