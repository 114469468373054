import isEmpty from "./is-empty";

// Takes in Project and CP and determines the budget
const calculateBudget = (CP, isApprovedOnly) => {
  let budget = 0;

  if (isEmpty(CP)) {
    return budget;
  }

  if (isEmpty(CP.budgets)) {
    return budget;
  }

  CP.budgets.forEach((budgetItem) => {
    if (isApprovedOnly) {
      if (budgetItem.is_approved) {
        budget += parseInt(budgetItem.budget);
      }
    } else {
      budget += parseInt(budgetItem.budget);
    }
  });

  return Math.round(budget);
};

// With react we are using ES6 module, not common JS,
// so we use export default
export default calculateBudget;
