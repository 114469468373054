import React from "react";
import EstimatesBenchmarkDonutChart from "./EstimatesBenchmarkDonutChart";
import { translate } from "../../utils/translation";
import { benchmarkCategories } from "./EstimatesBenchmarkDonutComponent.functions";
import { AVERAGE } from "../../actions/types";

export default function EstimatesBenchmarkDonutComponent(props) {
  const { thisBenchmark } = props;
  const { project } = props;
  const { benchmarks } = props;

  const estimateCategories = benchmarkCategories(
    [thisBenchmark],
    "Element Categories",
    AVERAGE
  );

  const comparisonCategories = benchmarkCategories(
    thisBenchmark?.linked_benchmarks,
    "Element Categories",
    AVERAGE
  );

  return (
    <div className={"estimate-benchmark-chart-box-dashboard"}>
      <div className="benchmarking-donut-chart-heading">
        <h1 className="display-4" style={{ fontSize: "20px" }}>
          {translate("Construction Cost Summary")}
        </h1>
      </div>
      <div className="benchmarking-chart">
        <EstimatesBenchmarkDonutChart
          projectName={project.title}
          data={estimateCategories}
          data2={comparisonCategories}
          benchmarks={benchmarks}
          thisBenchmark={thisBenchmark}
        />
      </div>
    </div>
  );
}
