import React from "react";
import spinner from "../../img/spinner.gif";

import "./Spinner.css";

const Spinner = (props) => {
  return (
    <div>
      <img
        className="loading-spinner"
        src={spinner}
        style={{
          width: props.width || "200px",
          marginTop: props.marginTop || "0px",
          marginBottom: props.marginBottom || "0px",
          marginLeft: props.marginLeft || "auto",
        }}
        alt="Loading..."
      />
    </div>
  );
};

export default Spinner;
