// Import React
import React, { useRef, useState, useEffect } from "react";

// Import React-Router
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
// Import Redux
import { Provider } from "react-redux";
import store from "./store";
// Import Routes
import PrivateRoute from "./components/routes/PrivateRoute";
// Components
import ModalComponent from "../src/components/browser/ModalComponent";
import TokenModalComponent from "./components/browser/TokenModalComponent";
import Modal from "../src/components/modal/Modal";
import ReLoginModal from "./components/modal/ReLoginModal";
import IdleTimer from "react-idle-timer";
// Layout
import NavbarTop from "./components/layout/NavbarTop";
import Footer from "./components/layout/Footer";
import Landing from "./components/auth/Landing";
import BrowserSupport from "./components/browser/BrowserSupport";
// Welcome Page
import Welcome from "./components/welcome/Welcome";
// Home Page
import ServiceSelectionHome from "./components/service-selection/ServiceSelectionHome";
// Admin
import Admin from "./components/admin/Admin";
// Accounts
import Information from "./components/auth/Information";
import Privacy from "./components/auth/Privacy";
import About from "./components/auth/About";
// Profiles
import Contact from "./components/contact/Contact";
import GuestContact from "./components/contact/GuestContact";
// Projects
import Portfolio from "./components/portfolio/Portfolio";
import Project from "./components/project/Project";
// Benchmark
import Benchmark from "./components/benchmarking/Benchmark";
import BenchmarkCreate from "./components/benchmarking/BenchmarkCreate";
// Tender Price Index
import TenderPriceIndex from "./components/tender-price-indexing/TenderPriceIndex";
// Other
import NotFound from "./components/not-found/NotFound";
//Login logout functions
import { checkToken } from "./components/browser/ManageTokens.functions";
import { randomNumberGenerator } from "./helpers/common.functions";

import Sandbox from "./components/sandbox/Sandbox";

// Backgrounds
import Background1 from "./img/backgrounds/background1.jpg";
import Background2 from "./img/backgrounds/background2.jpg";
import Background3 from "./img/backgrounds/background3.jpg";
import Background4 from "./img/backgrounds/background4.jpg";
import Background5 from "./img/backgrounds/background5.jpg";
import Background6 from "./img/backgrounds/background6.jpg";

// MSAL
import userSession from "./active-directory/userSession";
// Styles
import "./App.css";
import isEmpty from "./validation/is-empty";

// Handle user session
userSession({ privateRoute: false });

// Generate Background
const backgroundRandomizer = () => {
  let RNG = randomNumberGenerator(1, 6);

  switch (RNG) {
    case 1:
      return Background1;
    case 2:
      return Background2;
    case 3:
      return Background3;
    case 4:
      return Background4;
    case 5:
      return Background5;
    case 6:
      return Background6;
    default:
      return Background1;
  }
};

// Set Background
const background = backgroundRandomizer();

// Wrap Account components with background
function LandingRoute({ component: Component, auth, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <div
          className="accounts-background"
          style={{
            backgroundImage: `url(${background})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundColor: "rgba(248, 247, 216, 0.7)",
          }}
        >
          <div
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            }}
          >
            <Component {...props} {...rest} />
          </div>
        </div>
      )}
    />
  );
}

// App
function App() {
  // Set Portfolio
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [tokenModalIsOpen, setTokenModalIsOpen] = useState(false);

  const idleTimeRef = useRef(null);
  const logoutAfter = 30000; //30 seconds in milliseconds
  // 300 = 5 mins in seconds
  // 1200 = 20 minutes in seconds
  const idleTimeOut = 1200;

  //check
  // Check the token expiry every 10 seconds
  let interval = useRef(null);
  useEffect(() => {
    if (!isEmpty(localStorage.user)) {
      interval.current = setInterval(() => {
        // Function to check if refresh token is expiring
        let isExpiring = checkToken();
        if (isExpiring) {
          // Prompt the user to re login if refresh token is expiring
          setTokenModalIsOpen(true);
        }
      }, 10000);
      return () => clearInterval(interval.current);
    }
  }, []);

  // Function to call when user is idle
  const OnIdle = () => {
    // Call the next steps only if the user is logged in
    if (!isEmpty(localStorage.user)) {
      setModalIsOpen(true);
    }
  };

  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          {/* Modal will appear when user is idle for more than 5 minutes */}
          <Modal
            title={"Inactive Warning"}
            Component={ModalComponent}
            modal={modalIsOpen}
            setModal={setModalIsOpen}
            logoutAfter={logoutAfter}
            // Component Props
            setShow={setModalIsOpen}
          />
          {/* Modal appears prompting the user to relogin after 12 hours of login */}
          <ReLoginModal
            title={"Re-Login"}
            Component={TokenModalComponent}
            modal={tokenModalIsOpen}
            setModal={setTokenModalIsOpen}
            // Component Props
            logoutAfter={logoutAfter}
            setShow={setTokenModalIsOpen}
            label={"Re-Login"}
          />
          {/* When user is idle for 5 minutes, call the function to display a modal */}
          <IdleTimer
            crossTab={{
              emitOnAllTabs: true,
            }}
            ref={idleTimeRef}
            timeout={idleTimeOut * 1000}
            onIdle={OnIdle}
          ></IdleTimer>
          <NavbarTop />
          <Switch>
            <Route exact path="/browser" component={BrowserSupport} />
            <Route
              exact
              path={"/portfolio/"}
              render={() => <Redirect replace to={`/portfolio/:portfolioId`} />}
            />
            <LandingRoute exact path="/" component={Landing} />
            <LandingRoute exact path="/about" component={About} />
            <LandingRoute exact path="/information" component={Information} />
            <LandingRoute exact path="/privacy" component={Privacy} />
            <LandingRoute exact path="/contact-us" component={GuestContact} />
            <PrivateRoute exact path="/sandbox" component={Sandbox} />
            <PrivateRoute exact path="/welcome" component={Welcome} />
            <PrivateRoute exact path="/contact" component={Contact} />
            <PrivateRoute exact path="/benchmarking" component={Benchmark} />
            <PrivateRoute
              exact
              path="/benchmarking/create"
              component={BenchmarkCreate}
            />
            <PrivateRoute exact path="/not-found" component={NotFound} />
            {/* Must use "path" instead of "exact path" for routes with subroutes */}
            <PrivateRoute path="/home" component={ServiceSelectionHome} />
            <PrivateRoute
              path="/portfolio/:portfolioId"
              component={Portfolio}
            />
            <PrivateRoute path="/admin" component={Admin} />
            <PrivateRoute path="/project/:id" component={Project} />
            <PrivateRoute path="/insights" component={TenderPriceIndex} />
            <Route component={NotFound} />
          </Switch>
          <Footer />
        </div>
      </Router>
    </Provider>
  );
}

export default App;
