import React, { useState } from "react";
import { LocationFactorsModal } from "./LocationFactorsModal";
import Modal from "../../modal/Modal";
import { hasRoles } from "../../../utils/roles";

export default function LocationFactorsUpdate(props) {
  const { locations, user } = props;
  const { region, country } = props;
  const { locationFactors } = props;

  const [modal, setModal] = useState(false);

  if (!window.location.href.includes("location-factors")) {
    return null;
  }

  if (!hasRoles(user.roles, ["Admin"])) {
    return null;
  }
  return (
    <div>
      <Modal
        // Modal Props
        title={"Assign Location Factors"}
        Component={LocationFactorsModal}
        modal={modal}
        setModal={setModal}
        // Component Props
        locationFactors={locationFactors}
        locations={locations}
        region={region}
        country={country}
      />
      <button
        id={"button-location-factor"}
        className="location-factor-set-button"
        value="Assign location factors"
        onClick={() => {
          setModal(true);
        }}
      >
        Update Location Factors
      </button>
    </div>
  );
}
