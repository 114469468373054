import { GET_SETTINGS, SETTINGS_LOADING } from "../actions/types";

import isEmpty from "../validation/is-empty";

const initialState = {
  settings: null,
  loading: false,
};

function settingsReducer(state, action) {
  if (isEmpty(state)) {
    state = initialState;
  }

  switch (action.type) {
    case GET_SETTINGS:
      return {
        ...state,
        settings: action.payload,
        loading: false,
      };
    case SETTINGS_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}

export default settingsReducer;
