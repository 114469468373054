export default function portfolioDashboardModel() {
  return {
    risks: [],
    decisions: [],
    budget: {
      graphData: [],
      budgetValues: [],
      costPlanningBudget: 0,
      costPlanningBudgetAppr: 0,
      postContractBudget: 0,
      portfolioBudget: 0,
      portfolioBudgetAppr: 0,
      portfolioVariance: 0,
      portfolioVarianceAppr: 0,
    },
    totals: {
      portfolioProjects: 0,
      graphLabels: [],
      graphData: [],
      costPlanningCost: 0,
      postContractCost: 0,
      portfolioCost: 0,
    },
    metrics: {
      projectAreas: [],
      projectAreasGraphData: [],
      projectAreasGraphLabels: [],
      servicesMetrics: [],
      servicesGraphData: [],
      servicesGraphLabels: [],
    },
    costCategories: {
      projectTitles: [],
      costCategoryData: [],
      graphData: [],
      masterCategories: [],
    },
    benchmarking: {
      averages: [],
      graphData: [],
      graphLabels: [],
    },
    postContract: {
      progress: [],
      graphData: [],
      graphLabels: [],
    },
  };
}
