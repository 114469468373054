// React
import React, { useState, useEffect } from "react";
// Components
import PortfolioMapPopup from "./PortfolioMapPopup";
import PortfolioMapTooltip from "./PortfolioMapTooltip";
import PortfolioFiltersWarning from "../portfolio-projects/PortfolioFilterWarning";

// Map
import { MapContainer, TileLayer, Marker, Popup, Tooltip } from "react-leaflet";

import "./PortfolioMap.css";
import isEmpty from "../../validation/is-empty";

export default function PortfolioMap(props) {
  const { filteredProjects } = props;
  const { portfolioFilters } = props;
  const { setPortfolioFilters } = props;

  const [centerCoords, setCenterCoords] = useState(null);

  useEffect(() => {
    let project = filteredProjects[0];
    if (!isEmpty(project)) {
      const { lat, lng } = project;
      if (!isEmpty(lat) && !isEmpty(lng) && !isNaN(lat) && !isNaN(lng)) {
        setCenterCoords([lat, lng]);
      } else {
        setCenterCoords([0, 0]);
      }
    }
  }, [filteredProjects]);

  if (centerCoords === null) {
    return null;
  }

  return (
    <div className="portfolio-projects-column">
      <PortfolioFiltersWarning
        portfolioFilters={portfolioFilters}
        setPortfolioFilters={setPortfolioFilters}
      />
      <MapContainer
        className="portfolio-map"
        center={centerCoords}
        zoom={12}
        scrollWheelZoom={true}
        attributionControl={false}
      >
        <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
        {filteredProjects.map((p) => {
          if (!isEmpty(p.lat) && !isEmpty(p.lng)) {
            return (
              <Marker key={p.id} position={[p.lat, p.lng]}>
                <Popup className={"pm-popup"}>
                  <PortfolioMapPopup p={p} />
                </Popup>
                <Tooltip className={"pm-tooltip"} permanent interactive={true}>
                  <PortfolioMapTooltip p={p} />
                </Tooltip>
              </Marker>
            );
          } else {
            return null;
          }
        })}
      </MapContainer>
    </div>
  );
}
