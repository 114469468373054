import React from "react";
import { PCRALogBoxPastOrPending } from "./PCRALogBoxPastOrPending";

export function PCRALogBoxPast(props) {
  const { comments, username, email, timestamp } = props;

  return (
    <PCRALogBoxPastOrPending
      comments={comments}
      username={username}
      email={email}
      timestamp={timestamp}
    />
  );
}
