import React, { useState, useEffect } from "react";
import { translate, tranStr } from "../../utils/translation";

import isEmpty from "../../validation/is-empty";
import OptionsAnalysisImpactRating from "./OptionsAnalysisImpactRating";
import { IMPACT_RATING_SCALE } from "./OptionsAnalysisAPI.constants";
import { Link } from "react-router-dom";

import "./OptionsAnalysisTable.css";
import Modal from "../modal/Modal";
import OptionsAnalysisBreakDownUploader from "./options-breakdown/OptionsAnalysisBreakdownUploader";
import {
  deleteOptionsSummaryBreakdownItem,
  uploadOptionsSummaryBreakdownItems,
} from "./OptionsAnalysis.functions";
import OptionsSummaryBreakDownItems from "./options-breakdown/OptionsAnalysisBreakdownItems";
import Spinner from "../common/Spinner";
import { comma } from "../../helpers/common.functions";
import { hasRoles } from "../../utils/roles";

export default function OptionsAnalysisTable(props) {
  const { activeStage } = props;
  const { setModal } = props;
  const { project } = props;
  const { user } = props;
  const { options, getOptions, setOptions } = props;

  const optionsAnalysisItems = activeStage?.optionsAnalysisItems;

  useEffect(() => {}, [activeStage]);

  return (
    <div className="options-analysis-table-container">
      {activeStage && (
        <div className="options-analysis-table">
          <TableHead />
          <Table
            data={optionsAnalysisItems}
            project={project}
            user={user}
            options={options}
            getOptions={getOptions}
            setOptions={setOptions}
          />
          <TableLegend />
        </div>
      )}
      <TableOptions setModal={setModal} user={user} />
    </div>
  );
}

function TableLegend(props) {
  return (
    <div className="card legend">
      <div className="legend-title">
        <b>Impact Rating Scale</b>
      </div>
      {Object.values(IMPACT_RATING_SCALE).map(({ value }, index) => {
        const key = `${index}|${value}`;
        return <OptionsAnalysisImpactRating key={key} value={value} />;
      })}
    </div>
  );
}

function TableOptions(props) {
  const { setModal } = props;
  const { user } = props;

  if (isEmpty(user)) {
    return null;
  }

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  return (
    <button
      title={tranStr("Add Options Analysis Items")}
      className="options-analysis-table-options-button"
      onClick={() => setModal(true)}
    >
      <i className="fas fa-sliders-h"></i>
    </button>
  );
}

function TableHead() {
  return (
    <div className="options-analysis-table-heading-row">
      <div className="options-analysis-table-cell description-cell">
        {translate("Summary")}
      </div>
      <div className="options-analysis-table-cell quantity-cell">
        {translate("Cost Impact")}
      </div>
      <div className="options-analysis-table-cell quantity-cell">
        {translate("Programme Impact (Days)")}
      </div>
      <div className="options-analysis-table-cell quantity-cell">
        {translate("Environmental Impact")}
      </div>
      <div className="options-analysis-table-cell quantity-cell">
        {translate("Operational Cost Impact")}
      </div>
      <div className="options-analysis-table-cell quantity-cell">
        {translate("Other Considerations")}
      </div>
    </div>
  );
}

function Table(props) {
  const { data } = props;
  const { project } = props;
  const { user } = props;

  const [error, setError] = useState({});
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState();
  const [dataItems, setDataItems] = useState(data);
  const [loading, setLoading] = useState(false);
  const { options, getOptions, setOptions } = props;

  let [summaryKeys] = useState({});

  useEffect(() => {
    setDataItems(data);
    if (!isEmpty(options) && !isEmpty(data)) {
      setDataItems(
        options.optionsAnalysisStages.find(
          (x) => x.id === data[0].optionsAnalysisStageId
        ).optionsAnalysisItems
      );
    }
  }, [options, data, summaryKeys, selected]);

  if (isEmpty(data) || isEmpty(dataItems)) {
    return (
      <div className="project-budget">
        {translate(
          "There are no items added to the selected stage. Please use the Add Options Analysis Items button on the table."
        )}
      </div>
    );
  }

  return dataItems.map((item) => (
    <div key={item.id}>
      <div className="options-analysis-table-row-container">
        <div className="options-analysis-table-row">
          <Link
            to="#"
            key={item.id}
            className={"estimate-summary-table-row-link"}
            onClick={() => {
              summaryKeys[item.id] = !summaryKeys[item.id];
            }}
          >
            <div className="options-analysis-table-cell description-cell">
              {item.summary}
            </div>
            <div className="options-analysis-table-cell quantity-cell">
              <div className="sub-quantity-cell">{comma(item.costImpact)}</div>
              <div className="sub-quantity-cell divider"></div>
              <OptionsAnalysisImpactRating value={item.costImpactRating} />
            </div>
            <div className="options-analysis-table-cell quantity-cell">
              <div className="sub-quantity-cell">
                {comma(item.programmeImpact)}
              </div>
              <div className="sub-quantity-cell divider"></div>
              <OptionsAnalysisImpactRating value={item.programmeImpactRating} />
            </div>
            <div className="options-analysis-table-cell quantity-cell">
              <OptionsAnalysisImpactRating value={item.environmentalImpact} />
            </div>
            <div className="options-analysis-table-cell quantity-cell">
              <div className="sub-quantity-cell">
                {comma(item.operationalCostImpact)}
              </div>
              <div className="sub-quantity-cell divider"></div>
              <OptionsAnalysisImpactRating
                value={item.operationalCostImpactRating}
              />
            </div>
            <div className="options-analysis-table-cell quantity-cell">
              {item.otherConsiderations}
            </div>
          </Link>
          <OptionsAnalysisTableControls
            item={item}
            modal={modal}
            setModal={setModal}
            project={project}
            selected={selected}
            loading={loading}
            setLoading={setLoading}
            setSelected={setSelected}
            error={error}
            setError={setError}
            user={user}
            getOptions={getOptions}
            setOptions={setOptions}
          />
        </div>
      </div>
      {summaryKeys[item.id] && (
        <div className="options-analysis-table-row" style={{ height: "auto" }}>
          <OptionsSummaryBreakDownItems
            data={item.optionsAnalysisBreakdownItems}
          />
        </div>
      )}
    </div>
  ));
}

function OptionsAnalysisTableControls(props) {
  const { item } = props;
  const { modal, setModal } = props;
  const { project } = props;
  const { selected } = props;
  const { loading, setLoading } = props;
  const { setSelected } = props;
  const { error, setError } = props;
  const { user } = props;
  const { getOptions, setOptions } = props;

  if (isEmpty(user)) {
    return null;
  }

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  return (
    <div className="options-analysis-table-button-container">
      <button
        title={tranStr("Upload Breakdown Items")}
        className="options-analysis-table-options-button options-summary"
        onClick={() => {
          setModal(true);
          setSelected(item);
        }}
      >
        <i className="fas fa-upload"></i>
      </button>
      <Modal
        title={translate("Upload Option Analysis Breakdown Items")}
        Component={OptionsAnalysisBreakDownUploader}
        setModal={setModal}
        getOptions={getOptions}
        setOptions={setOptions}
        project={project}
        modal={modal}
        data={{
          projectId: project.id,
          stageId: item.optionsAnalysisStageId,
          selected: { selected },
          setModal: setModal,
        }}
        uploadFunction={uploadOptionsSummaryBreakdownItems}
      />
      {!isEmpty(item.optionsAnalysisBreakdownItems) &&
        item.optionsAnalysisBreakdownItems.length > 0 && (
          <DeleteBreakdown
            item={item}
            project={project}
            loading={loading}
            setLoading={setLoading}
            selected={selected}
            setSelected={setSelected}
            error={error}
            setError={setError}
            getOptions={getOptions}
            setOptions={setOptions}
          />
        )}
    </div>
  );
}

function DeleteBreakdown(props) {
  const {
    item,
    project,
    loading,
    setLoading,
    selected,
    setSelected,
    error,
    setError,
  } = props;
  const { getOptions, setOptions } = props;
  return (
    <>
      <button
        name="Delete"
        title={tranStr("Delete Breakdown Items")}
        className="options-analysis-table-options-button options-summary"
        onClick={() => {
          if (
            window.confirm(
              `Do you wish to delete Breadkdown Items for ${item.summary}`
            )
          ) {
            setLoading(true);
            setSelected(item);

            let data = {
              project,
              projectId: project.id,
              stageId: item.optionsAnalysisStageId,
              selected: { item },
              setError,
              error,
              setLoading,
              getOptions,
              setOptions,
            };

            deleteOptionsSummaryBreakdownItem(data);
          }
        }}
      >
        <i className="fas fa-trash"></i>
      </button>
      {loading && item.id === selected.id && <Spinner width="40px"></Spinner>}
    </>
  );
}
