import React, { useState, useEffect } from "react";

import { translate, tranStr } from "../../utils/translation";

import "../estimates/EstimatesEdit.css";
import { comma } from "../../helpers/common.functions";
import isEmpty from "../../validation/is-empty";
import { allocateCost } from "../../api/cost-planning/AreaSchedulesAPI";

export function CostAllocation(props) {
  const { data } = props;
  const { estimate } = props;
  const { setModal } = props;

  let total = Math.round(estimate.calculated_total);
  const { allocationItems, allocationType } = props;
  let difference = total;

  const [error, setError] = useState(false);
  const [fields, setFields] = useState([
    {
      id: "",
      description: "",
      cost: "",
    },
  ]);

  //Find the cost left to allocate

  fields.forEach((x) => {
    if (!isEmpty(x.cost)) {
      difference = difference - parseFloat(x.cost);
    }
  });

  data.setError = setError;
  data.setModal = setModal;

  useEffect(() => {
    if (!isEmpty(allocationItems)) {
      //Get only relevant items
      let filtered = allocationItems.map((d) => {
        //Find the allocated cost in the  item for the given estimate
        let cost = 0;
        if (!isEmpty(d.CostAllocations)) {
          let allocatedobj = d.CostAllocations.find(
            (o) => o.estimate_id === estimate.id
          );
          cost = !isEmpty(allocatedobj) ? allocatedobj.cost : 0;
        }

        return {
          id: d.id,
          description: d.description,
          cost: cost,
        };
      });

      if (filtered.length > 0) {
        setFields(filtered);
      }
    }
  }, [allocationItems, estimate.id]);

  return (
    <div className="edit-estimate">
      <div className="edit-budget-container">
        <div className="allocate-cost-label">
          <b>{tranStr("Estimate Name")}:</b>
        </div>
        <div className="budget-estimate">{estimate.name}</div>
      </div>
      <div className="edit-estimate-container">
        <div className="allocate-cost-label">
          <b>{tranStr(`Allocate Cost Estimate to ${allocationType}`)}:</b>
        </div>
        <div className="budget-estimate">{comma(total)}</div>
      </div>
      <div className="advice-field-container">
        <div className="edit-estimate-label">
          <b>{allocationType}</b>
        </div>

        <div className="budget-allocated-heading">
          <b>Cost Allocated</b>
        </div>
      </div>
      <div className="advice-decisions-field-container">
        {DecisionFields(fields, setFields)}
      </div>
      <div className="advice-field-container">
        <div className="edit-estimate-label">
          <b>To Allocate</b>
        </div>

        <div className="budget-to-allocate">
          <b>{difference}</b>
        </div>
      </div>
      <div className="general-row-container">
        <DisplayError error={error} setError={setError} />
      </div>

      <div className="general-button-container">
        <SaveButton
          data={data}
          fields={fields}
          setError={setError}
          difference={difference}
        />
      </div>
    </div>
  );
}

function SaveButton(props) {
  const { data, fields } = props;
  const { difference } = props;
  return (
    <button
      id={"button-area-schedule-submit"}
      className="general-upload-button"
      onClick={() => {
        allocateCost(fields, data, difference);
      }}
    >
      {translate("Submit")}
    </button>
  );
}

function DecisionFields(fields, setFields) {
  return fields.map((f, i) => {
    return (
      <div key={f.id} className="advice-field-container">
        <div className="edit-estimate-label">{f.description}</div>

        <input
          id="button-area-schedule-allocation-textbox"
          className="budget-allocated"
          type="number"
          value={f.cost}
          maxLength={90}
          onChange={(e) => {
            updateField("cost", e.target.value, i, fields, setFields);
          }}
        />
      </div>
    );
  });
}
function updateField(field, value, i, array, setFields) {
  let temp = [...array];
  temp[i][field] = value;

  setFields(temp);
}

function DisplayError(props) {
  const { error } = props;
  return (
    <div className="pdu-error-container">
      <div className={error.type}>
        <b>{error.text}</b>
      </div>
    </div>
  );
}
