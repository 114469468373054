import React from "react";
import { PCRA_GLOSSARY } from "./PCRAGlossary.constants";
const { AUDIT_STAGE } = PCRA_GLOSSARY;

export function PCRALogHeader(props) {
  const { processType } = props;

  const stages = Object.values(AUDIT_STAGE(processType));

  return (
    <div className="pcra-log-header">
      {stages.map((stage, index) => {
        const key = `${stage}_${index}`;
        return (
          <div key={key} className={`pcra-stage-label ${stage}`}>
            {stage}
          </div>
        );
      })}
    </div>
  );
}
