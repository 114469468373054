import { authHeader } from "../../_helpers/auth-header";
import { sharedAPIs } from "../../api/api-endpoints/SharedServiceEndpoints";
export async function uploadFile(data) {
  if (
    data.field.name === "" ||
    data.field.email_domain === "" ||
    data.field.address === "" ||
    data.field.region === "" ||
    data.field.account_lead === ""
  ) {
    return data.setError({
      text: "Fields Incomplete",
      type: "feedback-error",
    });
  }

  if (data.file === null) {
    return data.setError({
      text: "Logo Incomplete",
      type: "feedback-error",
    });
  }

  data.setError({
    text: "Creating...",
    type: "feedback-success",
  });

  const formData = new FormData();
  formData.append("file", data.file);
  formData.append("field", JSON.stringify(data.field));

  const url = sharedAPIs().create_company;
  const config = {
    method: "POST",
    body: formData,
    headers: authHeader({ authJson: false, authForm: true, guestJson: false }),
  };

  let response = [];
  try {
    response = await fetch(url, config);
  } catch (e) {
    return response;
  }

  if (response.ok) {
    await data.companies.set(await data.companies.get());
    data.setShow(false);
  } else {
    return data.setError({
      text: "Connection Error",
      type: "feedback-error",
    });
  }
}
