import { cpAPIs } from "../../api/api-endpoints/CostPlanningEndpoints";
import { authHeader } from "../../_helpers/auth-header";

export async function updateCostPlanCashflow(projectID, costPlanId, basis) {
  // COST PLAN API
  const url = cpAPIs().update_basis;
  const payload = JSON.stringify({
    projectID: projectID,
    costPlanId: costPlanId,
    basis: basis,
  });

  const config = {
    method: "POST",
    body: payload,
    headers: authHeader({
      authJson: true,
      authForm: false,
      guestJson: false,
    }),
  };

  let erMessage = "Update Project Error";
  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
  } else {
    return console.log(erMessage);
  }
}
