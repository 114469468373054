//Translation
import { tranStr } from "../../utils/translation";

export function columns() {
  return [
    {
      heading: tranStr("First Name"),
      key: "name",
      type: "DESCRIPTION",
      width: 200,
      isFiltered: true,
    },
    {
      heading: tranStr("Surname"),
      key: "surname",
      type: "DESCRIPTION",
      width: 200,
      isFiltered: true,
    },
    {
      heading: tranStr("Job Title"),
      key: "jobTitle",
      type: "DESCRIPTION",
      width: 220,
      isFiltered: true,
    },
    {
      heading: tranStr("Email"),
      key: "email",
      type: "DESCRIPTION",
      width: 260,
      isFiltered: true,
    },
  ];
}
