// React
import React, { useState } from "react";

import PortfolioEditSelectProjects from "../portfolio-control/PortfolioEditSelectProjects";
import { ModalMultiple } from "../portfolio-edit/PortfolioEdit.components";

// API
import { savePortfolioProjects } from "../../api/portfolio/PortfolioAPI";
import { getPortfolioProjects } from "../../api/projects/ProjectAPI";
import isEmpty from "../../validation/is-empty";

import "./PortfolioControlDropZone.css";

export default function PortfolioControlDropZone(props) {
  const { portfolios } = props;
  const { isDropEnabled } = props;
  const { selectedPortfolio } = props;
  const { dragID } = props;
  const { isDragEnabled } = props;
  const { setProjects } = props;

  const [dragMinus, setDragMinus] = useState(false);

  if (!isDropEnabled) {
    return null;
  }

  let classType = "portfolio-control-drop-zone";

  let arrowType = "portfolio-control-drop-zone-arrow";
  if (isDragEnabled) {
    classType = "portfolio-control-drop-zone-highlight";
    arrowType = "portfolio-control-drop-zone-arrow-highlight";
  }
  return (
    <div>
      <div className="general-row-container">
        <ModalMultiple
          Component={PortfolioEditSelectProjects}
          selectedData={selectedPortfolio.projectIds.map((primitiveID) => {
            return { id: primitiveID };
          })}
          label={<i className="fas fa-city"></i>}
          selectedPortfolio={selectedPortfolio}
          portfolios={portfolios}
          setProjects={setProjects}
        />
      </div>
      <button
        id={"id-portfolio-control-drop-zone"}
        className={classType}
        onDrop={async (e) => {
          e.preventDefault();

          const confirm = window.confirm(
            `Confirm removal of project with ID ${dragID.current} from portfolio?`
          );

          if (confirm) {
            if (isEmpty(dragID.current)) {
              return;
            }
            let temp = [...selectedPortfolio.projectIds];

            const index = temp.indexOf(dragID.current.toString());
            temp.splice(index, 1);

            await savePortfolioProjects(selectedPortfolio, temp);
            portfolios.set(await portfolios.get());
            setProjects(await getPortfolioProjects(selectedPortfolio.id));
          }

          setDragMinus(false);
        }}
        onDragOver={(e) => {
          e.preventDefault();
          setDragMinus(true);
        }}
        onDragLeave={(e) => {
          e.preventDefault();
          setDragMinus(false);
        }}
      >
        <div className={arrowType}>
          {!dragMinus && <i className="fas fa-trash-alt"></i>}
        </div>
      </button>
    </div>
  );
}
