import React from "react";

import isEmpty from "../../validation/is-empty";

import "./ProjectBudgetTable.css";
import { comma } from "../../helpers/common.functions";
import { translate } from "../../utils/translation";
import "../cost-planning/CostPlanningDashboard.css";

export default function EstimateBudgetTable(props) {
  const { data } = props;

  if (isEmpty(data)) {
    return null;
  }

  let tableClass = "ebt";
  let containerTypeTotal = "estimates-budget-container-total";
  let descriptionType = "budget-description-cell";
  let total = 0;
  let approvedTotal = 0;
  let totalAllocatedCost = 0;

  data.forEach((row) => {
    total += parseFloat(row.budget);
    if (!isEmpty(row.CostAllocations))
      row.CostAllocations.forEach((alloc) => {
        if (!isEmpty(alloc)) {
          totalAllocatedCost += alloc.cost;
        }
      });

    if (row.is_approved) {
      approvedTotal += parseFloat(row.budget);
    }
  });

  return (
    <div style={{ display: "flex", alignSelf: "flex-start" }}>
      <div className={tableClass}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <EstimateBudgetTableHead />
            <EstimateBudgetTableRows data={data} />
            <div>
              <div className="estimates-TPI-row">
                <div className={containerTypeTotal}>
                  <div className={descriptionType}>
                    <b>{translate("Total")}</b>
                  </div>
                  <div className="budget-total-value">{comma(total)}</div>
                  <div className="budget-total-approval-cell">
                    {comma(approvedTotal)}
                  </div>
                  <div className="budget-total-value">
                    {comma(totalAllocatedCost)}
                  </div>
                  <div className="budget-total-value">
                    {comma(total - totalAllocatedCost)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function EstimateBudgetTableRows(props) {
  const { data } = props;
  const display = [];
  let rowType = "budget-row";
  data.forEach((row) => {
    display.push(
      <div key={row.id}>
        <EstimateBudgetRow row={row} rowType={rowType} />
      </div>
    );
  });
  return <div>{display}</div>;
}

function EstimateBudgetTableHead() {
  let row = {
    description: translate("Description"),
    budget: translate("Budget"),
    approved: translate("Approved"),
    cost_allocated: translate("Allocated Cost"),
    variance: translate("Variance to Budget"),
  };
  let descriptionType = "budget-description-cell";
  let headRowType = "budget-description-head-row";
  return (
    <div className={headRowType}>
      <div className={descriptionType}>
        <b>{row.description}</b>
      </div>

      <div className="budget-cell-head">
        <b>{row.budget}</b>
      </div>
      <div className="budget-approval-cell">
        <b>{row.approved}</b>
      </div>
      <div className="cost-cell-head">
        <b>{row.cost_allocated}</b>
      </div>
      <div className="cost-cell-head">
        <b>{row.variance}</b>
      </div>
    </div>
  );
}

function EstimateBudgetRow(props) {
  const { row } = props;
  let allocated = 0;
  let variance = 0;
  row.CostAllocations.forEach((x) => {
    allocated += x.cost;
  });
  variance = row.budget - allocated;
  let rowType = "budget-row";
  let descriptionType = "budget-description-cell";
  return (
    <div className="estimate-breakdown-row-wrapper">
      <div>
        <div className={rowType}>
          <div className={descriptionType}>{row.description}</div>
          <div className="budget-cell">
            {row.budget ? comma(Math.round(row.budget)) : null}
          </div>
          <div className="budget-approval-cell">
            {row.is_approved ? <i className="fas fa-check"></i> : null}
          </div>
          <div className="cost-cell">
            {allocated ? comma(Math.round(allocated)) : null}
          </div>

          <div className="cost-cell">
            {variance ? comma(Math.round(variance)) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
