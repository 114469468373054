import isEmpty from "./is-empty";

export default function validateFilter(string) {
  if (isEmpty(string)) {
    return "";
  }

  if (typeof string !== "string") {
    return "";
  }

  if (typeof string === "string") {
    return string.toLowerCase();
  }
}
