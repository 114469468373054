import React from "react";
//Translations
import { tranStr } from "../../utils/translation";

import { ImageDisplay } from "../images/ImageDisplay";

export function columns() {
  return [
    {
      type: "CHECK",
      key: "id",
      width: 50,
      isModal: false,
    },
    {
      heading: tranStr(""),
      key: "image",
      type: "IMAGE",
      width: 42,
      customComponent: (value) => {
        return (
          <ImageDisplay
            image={value}
            imageWidth={"30px"}
            imageHeight={"20px"}
            isBezierDisabled={true}
          />
        );
      },
    },
    {
      heading: tranStr("Title"),
      key: "title",
      type: "DESCRIPTION",
      width: 190,
      isFiltered: true,
    },
    {
      heading: tranStr("Client Company"),
      key: "company_name",
      type: "DESCRIPTION",
      width: 160,
      isFiltered: true,
    },
    {
      heading: tranStr("Sector"),
      key: "sector",
      type: "DESCRIPTION",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Subsector"),
      key: "sub_sector",
      type: "DESCRIPTION",
      width: 180,
      isFiltered: true,
    },

    {
      heading: tranStr("Region"),
      key: "region",
      type: "DESCRIPTION",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Country"),
      key: "country",
      type: "DESCRIPTION",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("City"),
      key: "city",
      type: "DESCRIPTION",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Tags"),
      key: "search_tags",
      type: "DESCRIPTION",
      width: 100,
      isFiltered: true,
    },
  ];
}

export function humanize(str) {
  let frags = str.split("_");
  for (let i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(" ");
}

export function filterCaseInsensitive(filter, row) {
  const id = filter.pivotId || filter.id;
  return row[id] !== undefined
    ? String(row[id].toString().toLowerCase()).includes(
        filter.value.toString().toLowerCase()
      )
    : true;
}
