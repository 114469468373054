// React
import React, { useState } from "react";
import PropTypes from "prop-types";

// Components
import BenchmarkingTable from "./BenchmarkingTable";
import BenchmarkSelectionFilter from "./BenchmarkSelectionFilter";
import CreateBenchmarkQuickModal from "../cost-planning-benchmark/CreateBenchmarkQuickModal";
import { translate } from "../../utils/translation";

// API
import { saveBenchmarksSelection } from "../../api/estimates/EstimateBenchmarkAPI";

// Style
import "./ModalBenchmarkingSelection.css";

/// props validation | SQ(javascript:S6774)
BenchmarkingSelection.propTypes = {
  user: PropTypes.object,
  projectID: PropTypes.string,
  project: PropTypes.object,
  estimateID: PropTypes.string,
  estimate: PropTypes.object,
  CP: PropTypes.object,
  CPs: PropTypes.object,
  setModal: PropTypes.func,
  filteredBenchmarks: PropTypes.array,
  benchmarkingForBenchmark: PropTypes.array,
  benchmarkSelectionFilters: PropTypes.object,
  setBenchmarkSelectionFilters: PropTypes.func,
  setBenchmarking: PropTypes.func,
};
///
export default function BenchmarkingSelection(props) {
  const { user } = props;
  const { projectID } = props;
  const { estimateID } = props;
  const { estimate } = props;
  const { CP } = props;
  const { CPs } = props;
  const { setModal } = props;
  const { filteredBenchmarks } = props;
  const { benchmarkingForBenchmark } = props;
  const { benchmarkSelectionFilters, setBenchmarkSelectionFilters } = props;
  const { setBenchmarking } = props;
  const { project } = props;

  const [selection, setSelection] = useState(benchmarkingForBenchmark);
  const [error, setError] = useState({});

  return (
    <div className="benchmark-selection-content-container">
      <div className="benchmark-selection-table-filter-container">
        <h1 className="display-4">{translate("Benchmark Filters")}</h1>
        <BenchmarkSelectionFilter setFilters={setBenchmarkSelectionFilters} />
      </div>
      <div className="benchmark-selection-table-container">
        <h1 className="display-4">{translate("Benchmark Selection Table")}</h1>
        <BenchmarkingTable
          // All Benchmarking
          data={filteredBenchmarks}
          setBenchmarking={setBenchmarking}
          // Selection
          selection={selection}
          setSelection={setSelection}
        />

        <div className="general-row-container">
          <DisplayError error={error} />
        </div>
        <div className="benchmark-button-container">
          <SaveSelection
            selection={selection}
            projectID={projectID}
            estimateID={estimateID}
            CPs={CPs}
            setModal={setModal}
            setError={setError}
          />
          <CreateBenchmarkQuickModal
            user={user}
            project={project}
            CP={CP}
            CPs={CPs}
            estimate={estimate}
            setBenchmarking={setBenchmarking}
            benchmarkSelectionFilters={benchmarkSelectionFilters}
          />
        </div>
      </div>
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
SaveSelection.propTypes = {
  selection: PropTypes.array,
  projectID: PropTypes.string,
  estimateID: PropTypes.string,
  CPs: PropTypes.object,
  setModal: PropTypes.func,
  setError: PropTypes.func,
};
///
function SaveSelection(props) {
  const { selection } = props;
  const { projectID, estimateID } = props;
  const { CPs } = props;
  const { setModal } = props;
  const { setError } = props;

  return (
    <button
      className="benchmarking-save-button"
      onClick={() => {
        saveBenchmarksSelection({
          selection,
          projectID,
          estimateID,
          CPs,
          setModal,
          setError,
        });
      }}
    >
      {translate("Save Selection")}
    </button>
  );
}

function DisplayError(props) {
  const { error } = props;
  return (
    <div className="feedback-error-container">
      <div className={error.type}>
        <b>{error.text}</b>
      </div>
    </div>
  );
}
