import React, { useState } from "react";
import { authHeader } from "../../_helpers/auth-header";
import { translate, tranStr } from "../../utils/translation";
import { cpAPIs } from "../../api/api-endpoints/CostPlanningEndpoints";
import "./UploadChanges.css";

export default function UploadChanges(props) {
  const { setShow } = props;

  const { projectID } = props;
  const { project } = props;
  const { estimateID } = props;
  const { CPs } = props;

  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [error, setError] = useState({});

  const data = {
    projectID,
    estimateID,
    file,
    setError,
    setShow,
    setFilePreview,
    CPs,
    project,
  };

  return (
    <div className="reconciliation-upload-scope-changes">
      <div className="general-row-container">
        <FileTray file={filePreview} />
      </div>

      <div className="general-row-container">
        <Upload setFile={setFile} setFilePreview={setFilePreview} />
      </div>

      <div className="general-row-container">
        <DisplayError error={error} setError={setError} />
      </div>
      <div className="general-button-container">
        <button
          className="general-upload-button"
          onClick={() => {
            onSubmit(data);
          }}
        >
          {translate("Upload")}
        </button>
      </div>
    </div>
  );
}

function DisplayError(props) {
  const { error } = props;
  return (
    <div className="feedback-error-container">
      <div className={error.type}>
        <b>{error.text}</b>
      </div>
    </div>
  );
}

function Upload(props) {
  const { setFile, setFilePreview } = props;

  return (
    <label className="general-modal-button">
      <input
        type="file"
        className="tpi-upload-button"
        onChange={(e) => {
          if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0]);
            setFilePreview(e.target.files[0]);
          }
        }}
      />
      {translate("Attach File")}
    </label>
  );
}

async function onSubmit(data) {
  const {
    projectID,
    estimateID,
    file,
    setError,
    setShow,
    setFilePreview,
    CPs,
    project,
  } = data;

  if (file === null) {
    return setError({
      text: tranStr("Please attach a file"),
      type: "feedback-error",
    });
  }

  setError({
    text: tranStr("Creating..."),
    type: "feedback-success",
  });

  const payload = {
    projectID: projectID,
    estimateID: estimateID,
  };
  const formData = new FormData();
  const field = JSON.stringify(payload);
  formData.append("field", field);
  formData.append("file", file);
  // COST PLAN API
  const url = cpAPIs().upload_scope_design_changes;
  const config = {
    method: "POST",
    body: formData,
    headers: authHeader({ authJson: false, authForm: true, guestJson: false }),
  };

  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
  } else {
    setError({
      text: tranStr("Error with file"),
      type: "feedback-error",
    });

    return console.log("Network response was not ok.");
  }

  setError({
    text: tranStr("Created Successfully"),
    type: "feedback-success",
  });

  let CPData = await CPs.get(projectID, project);
  // Set the new data with the getters and setters
  CPs.set(CPData);
  setError({});
  setFilePreview(null);
  setShow(false);
}

function FileTray(props) {
  const { file } = props;
  let fileName = tranStr("No File Attached");

  if (file !== null && file !== undefined) {
    fileName = file.name;
  }

  if (typeof file === "string") {
    fileName = tranStr("Keep Existing File");
  }

  return (
    <div className="category-edit-file-display">
      <b>{tranStr("File")}:</b> {fileName}
    </div>
  );
}
