import { useState, useEffect } from "react";
import { getDmsToken } from "../../active-directory/getDmsToken";
import isEmpty from "../../validation/is-empty";
import { authHeader } from "../../_helpers/auth-header";
import { cpAPIs } from "../api-endpoints/CostPlanningEndpoints";
import {
  addBreakdownUsersCostPlanning,
  addBenchmarksCostPlanning,
} from "./EstimateAPI.functions";

export function useEstimates(projectID, project) {
  const [loading, setLoading] = useState(true);
  const [CPs, setCPs] = useState([]);

  useEffect(() => {
    if (projectID) {
      const controller = new AbortController();
      const abortSignal = controller.signal;

      setLoading(true);
      getEstimates(projectID, project, abortSignal)
        .then(async (cp_results) => {
          if (!abortSignal.aborted) {
            setCPs(cp_results);
            setLoading(false);
          }
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
        });

      return () => {
        controller.abort();
      };
    }
  }, [projectID, project]);

  return {
    CPs: {
      data: CPs,
      loading: loading,
      set: setCPs,
      get: getEstimates,
    },
  };
}

async function getEstimates(projectID, project, abortSignal) {
  const dmsToken = await getDmsToken();
  let dmsBearer = "";
  if (!isEmpty(dmsToken)) {
    dmsBearer = dmsToken.accessToken;
  }

  // COST PLAN API
  const url = cpAPIs().get_estimates + "/" + projectID;
  const config = {
    method: "GET",
    headers: {
      ...authHeader({ authJson: true, authForm: false, guestJson: false }),
      dmsBearer: `Bearer ${dmsBearer}`,
    },
  };

  if (!isEmpty(abortSignal)) {
    config.signal = abortSignal;
  }

  let erMessage = "Get Estimates error";
  let response = await fetch(url, config);

  if (response.ok) {
    const CPs = await response.json();

    if (CPs.success === false) {
      response = [];
      return response;
    }

    // Get Linked Benchmarks for the Cost Plan
    const CPs1 = await addBreakdownUsersCostPlanning(CPs);
    const CPs2 = await addBenchmarksCostPlanning(CPs1);
    return CPs2;
  } else {
    response = [];
    console.log(erMessage);
  }
  return response;
}

export function deleteEstimate(estimate_id, project_id) {
  // COST PLAN API
  const url = cpAPIs().delete_estimate + "/" + project_id + "/" + estimate_id;

  let erMessage = "Delete Estimate Error";
  return fetch(url, {
    method: "DELETE",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  })
    .then(function (response) {
      if (response.ok) {
        return response.json();
      }
      throw new Error(erMessage);
    })
    .then(() => {
      return true;
    })
    .catch(function (error) {
      console.log(
        "There has been a problem with your fetch operation: ",
        error.message
      );
    });
}

export function useStages(projectID) {
  const [loading, setLoading] = useState(true);
  const [stages, setStages] = useState([]);

  useEffect(() => {
    if (projectID) {
      setLoading(true);
      getStages(projectID)
        .then(async (stage_results) => {
          setStages(stage_results);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [projectID]);

  return {
    stages: {
      data: stages,
      loading: loading,
      set: setStages,
      get: getStages,
    },
  };
}

export async function getStages(projectID) {
  // COST PLAN API
  const url = cpAPIs().get_stages + "/" + projectID;

  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };
  let erMessage = "Get Cost Plan Stages error";
  let response = await fetch(url, config);

  if (response.ok) {
    response = await response.json();
  } else {
    response = [];
    console.log(erMessage);
  }

  return response;
}
