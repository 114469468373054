import React from "react";
import { comma } from "../../helpers/common.functions";
import { translate } from "../../utils/translation";
import "./ReconciliationDisplayTotal.css";

// Display the total delta
export default function ReconciliationDisplayTotal(props) {
  const { estimateDeltas } = props;
  const { project } = props;
  const { CP } = props;

  if (estimateDeltas.length < 2) {
    return null;
  }

  const displayTotal = comma(Math.round(CP.calculated_total_delta));

  return (
    <div className="reconciliation-display-total">
      <div className="reconciliation-total-label">
        {translate("Total Variance")}
      </div>
      <div className="reconciliation-total-costs">
        <div className="reconciliation-total-value">
          {generateBrackets(displayTotal, project.currency_symbol)}
        </div>
      </div>
    </div>
  );
}

function generateBrackets(string, currency_symbol) {
  string = string.toString();
  if (string.includes("-")) {
    return currency_symbol + "(" + string.split("-")[1] + ")";
  } else {
    return "+" + currency_symbol + string;
  }
}
