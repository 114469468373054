import React from "react";
import Countdown from "react-countdown";
import { ADLogout, logOutUser } from "./LoginLogout.functions";
import "./ModalComponent.css";

function TokenModalComponent(props) {
  const { setModal, logoutAfter } = props;

  //Logout the user using MSAL
  const logOut = async (e) => {
    e.preventDefault();

    await ADLogout();
    logOutUser();
    setModal(false);
  };

  // Renderer callback with condition
  const renderer = ({ total, completed }) => {
    if (completed) {
      // Render a completed state
      logOut();
      return null;
    } else {
      // Render a countdown
      return <div>You will be logged out in {total / 1000} seconds</div>;
    }
  };

  return (
    <div className="idle-timer-modal">
      <div>
        <h2>
          You have been logged in for a while. Kindly relogin to continue the
          session
        </h2>
        <Countdown
          date={Date.now() + logoutAfter}
          intervalDelay={1000}
          precision={1}
          renderer={renderer}
        />
      </div>
      <div>
        <button className="idle-timer-modal-button-logout" onClick={logOut}>
          OK
        </button>
      </div>
    </div>
  );
}

export default TokenModalComponent;
