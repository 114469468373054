import React from "react";
import { CORRESPONDENCE } from "./Correspondence.strings";
import EstimateBreakdownTable from "../estimate-breakdown-table/EstimateBreakdownTable";
import isEmpty from "../../validation/is-empty";

export default function EstimateBreakdownComments(props) {
  const { user, project, CPs } = props;
  const { notificationsOnly } = props;
  const { estimate } = props;

  let breakdownCount = 0;
  let breakdownCommentCount = 0;
  let breakdownPendingCommentCount = 0;

  if (!isEmpty(estimate.rows)) {
    estimate.rows.forEach((row) => {
      if (!isEmpty(row.breakdown)) {
        breakdownCount += row.breakdown?.length;
        row.breakdown.forEach((breakdown) => {
          breakdownCommentCount += breakdown.comments?.length;
          breakdownPendingCommentCount += breakdown.notify_estimate_comment;
        });
      }
    });
  }

  if (breakdownCount === 0 || breakdownCommentCount === 0) {
    return <p className="message">{CORRESPONDENCE.NO_COMMENTS_FOUND}</p>;
  }

  if (breakdownPendingCommentCount === 0) {
    return <p className="message">{CORRESPONDENCE.ALL_COMMENTS_CLOSED_OUT}</p>;
  }

  return (
    <EstimateBreakdownTable
      user={user}
      data={estimate.rows}
      CPs={CPs}
      project={project}
      estimateID={estimate.id}
      selectedRow={"breakdown"}
      filtered={true}
      notificationsOnly={notificationsOnly}
    />
  );
}
