import React, { useState, useEffect, useRef } from "react";
import isEmpty from "../../validation/is-empty";
import { comma } from "../../helpers/common.functions";

import { VALUE_ENGINEERING } from "./ValueEngineering.strings";

import "./ValueEngineeringsMultipleTotals.css";

export default function ValueEngineeringsMultipleTotals(props) {
  const { data, total } = props;

  const [costImpact, setCostImpact] = useState(null);
  const [adjustedCostImpact, setAdjustedCostImpact] = useState(null);
  const [costImpactReviewItems, setCostImpactReviewItems] = useState(null);
  const [adjustedApprovedReviewed, setAdjustedApprovedReviewed] =
    useState(null);

  const impactRef = useRef(0); //will be used to store and access the value

  useEffect(() => {
    const costImpactValue = getCostImpactOfApprovedItems(data);
    const adjustedCostImpactValue = getAdjustedCostPlanPartTotal(
      costImpactValue,
      total
    );
    setCostImpact(costImpactValue);
    impactRef.current = adjustedCostImpactValue; // Update the value in the ref
    setAdjustedCostImpact(comma(adjustedCostImpactValue));
  }, [data, total]);

  useEffect(() => {
    const costImpactReviewItemsValue = getCostImpactOfReviewItems(data);
    const adjustedApprovedReviewedValue = getAdjustedApprovedReviewed(
      costImpactReviewItemsValue
    );

    setCostImpactReviewItems(costImpactReviewItemsValue);
    setAdjustedApprovedReviewed(adjustedApprovedReviewedValue);
  }, [data, total]);

  // Cost Impact of Approved items
  function getCostImpactOfApprovedItems(data) {
    if (isEmpty(data) || isEmpty(data.value_engineering_items)) return 0;

    return data.value_engineering_items.reduce((totalImpact, item) => {
      if (item.status === VALUE_ENGINEERING.STATUS.APPROVED) {
        return totalImpact + item.value;
      }
      return totalImpact;
    }, 0);
  }

  // Adjusted Cost Plan Part Total (Approved items)
  function getAdjustedCostPlanPartTotal(costImpact, total) {
    const costImpactValue = costImpact + total;
    return costImpactValue;
  }

  // Cost Impact of Review items
  function getCostImpactOfReviewItems(data) {
    if (isEmpty(data) || isEmpty(data.value_engineering_items)) return 0;

    return data.value_engineering_items.reduce((totalImpact, item) => {
      if (item.status === VALUE_ENGINEERING.STATUS.FORREVIEW) {
        return totalImpact + item.value;
      }
      return totalImpact;
    }, 0);
  }

  // Adjusted Cost Plan Part Total (Approved + For Review items)
  function getAdjustedApprovedReviewed(costImpactReviewItems) {
    return comma(impactRef.current + costImpactReviewItems);
  }

  const components = [
    {
      label: "Total Accepted",
      value: comma(costImpact),
      class: "secondary",
    },
    {
      label: "Adjusted Cost Plan Part Total",
      value: comma(adjustedCostImpact),
      class: "primary",
    },
    {
      label: "Total For Review",
      value: comma(costImpactReviewItems),
      class: "secondary",
    },
    {
      label: "Adjusted Cost Plan Part Total (Approved + For Review)",
      value: comma(adjustedApprovedReviewed),
      class: "primary",
    },
  ];

  return (
    <div>
      {components.map((component) => (
        <div
          className={`value-engineering-multiple-totals ${component.class}`}
          key={component.label} // Use a unique key for each component
        >
          <div className="value-engineerings-multiple-totals-label">
            <b>{component.label}</b>
          </div>
          <div className="value-engineerings-multiple-totals-costs">
            <div className="value-engineerings-multiple-totals-total">
              {component.value}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
