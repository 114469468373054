import { useEffect, useState } from "react";
import { authHeader } from "../../_helpers/auth-header";
import { sharedAPIs } from "../api-endpoints/SharedServiceEndpoints";
import isEmpty from "../../validation/is-empty";

export function useConfigureSettings(moduleId,discriminator) {
  const [loading, setLoading] = useState(true);
  const [configureSettings, setConfigureSettings] = useState({});
  useEffect(() => {
    getConfigureSettings(moduleId,discriminator)
      .then((res) => {
        setConfigureSettings(res);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [moduleId,discriminator]);

  return {
   configureSettings: {
      data: configureSettings,
      loading: loading,
      set: setConfigureSettings,
      get: getConfigureSettings,
      save: saveConfigureSettings,
   },
  };
}

export async function getConfigureSettings(moduleId,discriminator) {
  const url = sharedAPIs().get_layout + "?moduleId=" + moduleId +"&discriminator=" + discriminator;
  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);

  if (response.ok) {
    const configureSettings = await response.json();
    return configureSettings;
  } else {
    response = {};
  }
  return response;
}

export async function saveConfigureSettings(
  moduleId,
  dashboardType,
  layout,
  setError
) {
  let url = "";
  let payload = "";
  let config = "";
  
  let layoutObj=isEmpty(layout.table)? JSON.parse(layout): null;
  let tableObj =isEmpty(layout.table) ? null: layout.table;
 const field = JSON.stringify({
    module_id: moduleId,
    Discriminator: dashboardType,
   configSettings:{layout: layoutObj,table:tableObj}
  });

    // COST PLAN API
   url = sharedAPIs().update ;
    payload = field;
    config = {
      method: "POST",
      body: payload,
      headers: authHeader({
        authJson: true,
        authForm: false,
        guestJson: false,
      }),
    };   

  let response = await fetch(url, config);
  if (response.ok) {
    setError({
      text: "Saved",
      type: "dashboard-editor-success",
    });
    response = await response.json();
  } else {
    setError({
      text: "Not Saved",
      type: "dashboard-editor-error",
    });
    response = [];
    console.log("Network Error");
  }

  return response;
}