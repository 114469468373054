import {
  saveLocalisation,
  uploadLocalisation,
  cloneLocalisation,
  getNamespaces,
} from "../../api/admin/LocalisationsAPI";

// Save Terminology set
export async function save(language, name) {
  const keys = Object.keys(language);
  //create an input array for the API processing
  const languageArray = [];
  //update the array items in {key:string, text:string} format
  keys.forEach(function (d) {
    languageArray.push({ key: d, text: language[d] });
  });
  const data = {
    language: languageArray,
    selectedLanguage: name,
  };
  return saveLocalisation(data);
}

// Clone Terminologies
export async function clone(language, name) {
  const keys = Object.keys(language);
  //create an input array for the API processing
  const languageArray = [];
  //update the array items in {key:string, text:string} format
  keys.forEach(function (d) {
    languageArray.push({ key: d, text: language[d] });
  });

  const data = {
    language: languageArray,
    selectedLanguage: name,
  };

  return cloneLocalisation(data);
}

//import localisation file
export async function importLocalisation(fname, file) {
  const data = {
    file: file,
    fileName: fname,
  };

  return uploadLocalisation(data);
}
export async function checkNamespace(newNamespace) {
  let namespaces = await getNamespaces();

  return namespaces.indexOf(newNamespace) > -1;
}
