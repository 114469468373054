import React, { useState, useEffect } from "react";

import OptionsAnalysisControl from "./OptionsAnalysisControl";
import OptionsAnalysisCostBaselineModal from "./OptionsAnalysisCostBaselineModal";

import OptioneeringRadarChart from "./options-analysis-charts/OptioneeringRadarChart";
import OptioneeringBarChart from "./options-analysis-charts/OptioneeringBarChart.components";
import OptioneeringBaselineBarChart from "./options-analysis-charts/OptioneeringBaselineBarChart.components";

import { calculateTotalCost } from "./OptionsAnalysisCostBaselineForm";

import "./OptionsAnalysisCharts.css";

export default function OptionsAnalysisCharts(props) {
  const { CPs } = props;
  const { project, user } = props;
  const { activeStage } = props;
  const { getOptions, setOptions } = props;

  const optionsAnalysisItems = activeStage?.optionsAnalysisItems || [];

  const [costBaselineModal, setCostBaselineModal] = useState(false);
  const [costBaselineValue, setCostBaselineValue] = useState(0);

  // Get the Calculated Cost
  useEffect(() => {
    if (activeStage.baselineCostPlanId !== null) {
      let costplan = null;
      CPs.data.forEach((stage) => {
        stage.versions.forEach((version) => {
          if (version.cost_plan_id === activeStage.baselineCostPlanId) {
            costplan = version;
          }
        });
      });
      const totalCost = calculateTotalCost(costplan);
      setCostBaselineValue(totalCost);
    }
  }, [CPs, activeStage, costBaselineValue]);

  return (
    <div className="charts-container">
      <div className="charts-row">
        <div className="charts-column left">
          <OptioneeringRadarChart optionsAnalysisItems={optionsAnalysisItems} />
        </div>
        <div className="charts-column right">
          <OptioneeringBarChart optionsAnalysisItems={optionsAnalysisItems} />
          <OptioneeringBaselineBarChart
            optionsAnalysisItems={optionsAnalysisItems}
            baseline={costBaselineValue}
            CPs={CPs}
          />
        </div>
        <div className="charts-column control">
          <OptionsAnalysisControl
            project={project}
            user={user}
            setModal={setCostBaselineModal}
          />
          <OptionsAnalysisCostBaselineModal
            modal={costBaselineModal}
            setModal={setCostBaselineModal}
            CPs={CPs}
            project={project}
            setCostBaselineValue={setCostBaselineValue}
            activeStage={activeStage}
            getOptions={getOptions}
            setOptions={setOptions}
          />
        </div>
      </div>
    </div>
  );
}
