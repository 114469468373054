import React from "react";
import { Link } from "react-router-dom";

import "../path/Path.css";

export default function InsightCategoryArticlePath(props) {
  const { category } = props;
  const { article } = props;
  const { articleID } = props;
  const { categoryID } = props;

  return (
    <div className="insight-path">
      <Link to="/portfolio" className="path-link">
        {"Portfolio"}
      </Link>
      <div className="path-divider">{" > "}</div>
      <Link to={"/insights"} className="path-link">
        {"Insights"}
      </Link>
      <div className="path-divider">{" > "}</div>
      <Link to={`/insights/categories`} className="path-link">
        {"Categories"}
      </Link>
      <div className="path-divider">{" > "}</div>
      <Link
        to={`/insights/category/${categoryID}/articles`}
        className="path-link"
      >
        {category}
      </Link>
      <div className="path-divider">{" > "}</div>
      <Link
        to={`/insights/category/${categoryID}/article/${articleID}`}
        className="path-link-current"
      >
        {article}
      </Link>
    </div>
  );
}
