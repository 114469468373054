import React from "react";
import PropTypes from "prop-types";

import {
  validateCurrency,
  validatePercent,
  validateDate,
} from "./PostContractTableModal.functions";

import {
  // Description,
  ExpandableRow,
  OneValueRow,
  DocumentLink,
} from "./PostContractTableModal.components";

import "./PostContractTableModal.css";

PostContractTableModal.propTypes = {
  selectedRow: PropTypes.object,
  columns: PropTypes.array,
};

export function PostContractTableModal(props) {
  const { columns } = props;
  const { selectedRow } = props;

  const displayData = [];

  let isRow = false;

  columns.forEach((col) => {
    // Multiple Row Types
    if (
      col.type === "CURRENCY" ||
      col.type === "PERCENT" ||
      col.type === "TEXT" ||
      col.type === "STATUS" ||
      col.type === "DATE"
    ) {
      const numberObject = {
        type: col.type,
        heading: col.heading,
        value: selectedRow[col.key],
      };

      validateCellType(col, numberObject);

      if (!isRow) {
        // Create Array and add this number as the first item
        displayData.push({ type: "ROW", data: [] });
      }

      if (isRow) {
        // Start a new row if the row is already 3 items long
        if (displayData[displayData.length - 1].data.length >= 3) {
          displayData.push({ type: "ROW", data: [] });
        }
      }

      displayData[displayData.length - 1].data.push(numberObject);

      isRow = true;
    }
    // Single Row Types are handled as an else so we catch anything that falls through
    else {
      displayData.push({
        type: col.type,
        heading1: col.heading,
        value1: selectedRow[col.key],
      });
      isRow = false;
    }
  });

  const display = [];

  displayData.forEach((col) => {
    // Multiple Row Types
    if (col.type === "ROW") {
      display.push(
        <ExpandableRow
          headings={col.data.map((x) => x.heading)}
          values={col.data.map((x) => x.value)}
        />
      );
    } else if (col.type === "LINK") {
      display.push(<DocumentLink document_link={col.value1} />);
    }
    // Single Row Types are handled as an else so we catch anything that falls through
    else {
      display.push(<OneValueRow heading1={col.heading1} value1={col.value1} />);
    }
  });

  return <div className="pctm">{display}</div>;
}

function validateCellType(col, numberObject) {
  if (col.type === "CURRENCY") {
    numberObject.value = validateCurrency(numberObject.value);
  }

  if (col.type === "PERCENT") {
    numberObject.value = validatePercent(numberObject.value);
  }

  if (col.type === "DATE") {
    numberObject.value = validateDate(numberObject.value);
  }
}
