import React from "react";
import Pagination from "react-js-pagination";

import "./TablePagination.css";

const PostContractTablePagination = (props) => {
  const { activePage } = props;
  const { setActivePage } = props;
  const { totalItems } = props;
  const { pageSize, setPageSize } = props;

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    setPageSize(pageSize);
  }
  function handleChange(e) {
    if (e.target.value < 100) {
      setPageSize(e.target.value);
      setActivePage(activePage);
    }
  }

  return (
    <div className="pagination-container">
      <div className="pagination-input-container">
        <input
          id="pagination-page-size"
          className="pagination-input"
          value={pageSize}
          onChange={handleChange}
        />
      </div>
      <div className="pagination-page-control">
        <Pagination
          activePage={activePage}
          itemsCountPerPage={pageSize * 1}
          totalItemsCount={totalItems}
          pageRangeDisplayed={3}
          onChange={handlePageChange}
          firstPageText={<i className="fas fa-angle-double-left"></i>}
          prevPageText={<i className="fas fa-chevron-left"></i>}
          nextPageText={<i className="fas fa-chevron-right"></i>}
          lastPageText={<i className="fas fa-angle-double-right"></i>}
          itemClassFirst="tab-arrow-left"
          itemClassPrev="tab-arrow-left"
          itemClassNext="tab-arrow-right"
          itemClassLast="tab-arrow-right"
          itemClass="tab"
          activeClass="tab-selected"
          disabledClass="tab-arrow-inactive"
        />
      </div>
      <div className="pagination-input-container"></div>
    </div>
  );
};

PostContractTablePagination.propTypes = {};

export default PostContractTablePagination;
