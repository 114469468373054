import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { translate } from "../../utils/translation";

import "./PDFViewer.css";

// https://stackoverflow.com/questions/75104923/react-pdf-displaying-text-found-inside-the-pdf-instead-of-the-pdf-itself
import "react-pdf/dist/esm/Page/TextLayer.css";

export default function PDFViewer(props) {
  const { filePath } = props;
  const { deleteButton } = props;

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  // https://github.com/wojtekmaj/react-pdf/issues/991
  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  });

  return (
    <div className="pdf-viewer">
      <div className="pdf-viewer-buttons">
        <p>
          Page {pageNumber} of {numPages}
        </p>
        <button
          className="pdf-next-button"
          disabled={pageNumber >= numPages}
          onClick={() => {
            setPageNumber(pageNumber + 1);
          }}
        >
          Next Page
        </button>
        <button
          className="pdf-previous-button"
          disabled={pageNumber <= 1}
          onClick={() => {
            setPageNumber(pageNumber - 1);
          }}
        >
          Previous Page
        </button>
        <a
          className="pdf-download-button"
          href={filePath}
          target="_blank"
          download
          rel="noopener noreferrer"
        >
          {translate("Download")}
        </a>
        {deleteButton}
      </div>
      <Document file={filePath} onLoadSuccess={onDocumentLoadSuccess}>
        <div className="pdf-viewer-container">
          <Page width={900} pageNumber={pageNumber} />
        </div>
      </Document>
    </div>
  );
}
