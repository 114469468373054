import { authHeader } from "../../_helpers/auth-header";
import { cpAPIs } from "../api-endpoints/CostPlanningEndpoints";
import isEmpty from "../../validation/is-empty";

// Get Project Cost Plan Data
export async function getProjectCostPlan(projectIDs, abortSignal) {
  const url = cpAPIs().get_cp_configs;

  if (isEmpty(projectIDs)) {
    return [];
  }

  const payload = JSON.stringify({
    ProjectIDs: projectIDs,
  });

  const config = {
    method: "POST",
    body: payload,
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
    signal: abortSignal,
  };

  let response = {};
  try {
    response = await fetch(url, config);
  } catch (err) {
    if (err.name !== "AbortError") {
      console.log("getProjectCostPlan Error - ", err.name);
    }
  }

  if (response.ok) {
    response = await response.json();
  } else {
    response = [];
  }
  return response;
}

export async function getProjectsCP(projectIDs, abortSignal) {
  const url = cpAPIs().get_projects_cost_planning;

  const payload = JSON.stringify({
    projectIDs: projectIDs,
  });
  const config = {
    method: "POST",
    body: payload,
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
    signal: abortSignal,
  };

  let response = {};
  try {
    response = await fetch(url, config);
  } catch (err) {
    if (err.name !== "AbortError") {
      console.log("getBenchmarksWithRows Error - ", err.name);
    }
  }

  if (response.ok) {
    response = await response.json();
  } else {
    response = [];
  }

  return response;
}

export async function getPortfolioCP(projectIDs, abortSignal) {
  const url = cpAPIs().get_portfolio_cost_planning;

  const payload = JSON.stringify({
    projectIDs: projectIDs,
  });

  const config = {
    method: "POST",
    body: payload,
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
    signal: abortSignal,
  };

  let response = {};
  try {
    response = await fetch(url, config);
  } catch (err) {
    if (err.name !== "AbortError") {
      console.log("getPortfolioCP Error - ", err.name);
    }
  }

  if (response.ok) {
    response = await response.json();
  } else {
    response = [];
  }

  return response;
}

export async function addProjectCostPlanData(projects, abortSignal) {
  const projectIDs = await projects.map((p) => p.id);
  const newProjectData = await getProjectCostPlan(projectIDs, abortSignal);

  const updatedProjects = [];
  projects.forEach((project) => {
    let match = false;
    let newData = {};

    // Try and find a match for this project
    newProjectData.forEach((data) => {
      if (project.id.toString() === data.project_id.toString()) {
        match = true;
        newData = data;
      }
    });

    // If there is a match combine the project with the data
    if (match) {
      const combinedProject = {
        ...project,
        ...newData,
      };
      updatedProjects.push(combinedProject);
    } else {
      // If there is no match simply return the plain project
      updatedProjects.push(project);
    }
  });
  return updatedProjects;
}

export async function addProjectsCostPlanning(projects, abortSignal) {
  const projectIDs = projects.map((p) => p.id);
  const newProjectData = await getProjectsCP(projectIDs, abortSignal);
  const updatedProjects = [];
  projects.forEach((project) => {
    let match = false;
    let newData = {};
    // Try and find a match for this project
    newProjectData.forEach((data) => {
      if (project.id.toString() === data.id.toString()) {
        match = true;
        newData = data;
      }
    });
    // If there is a match combine the project with the data
    if (match) {
      const combinedProject = {
        ...project,
        ...newData,
      };
      updatedProjects.push(combinedProject);
    } else {
      // If there is no match simply return the plain project
      updatedProjects.push(project);
    }
  });
  return updatedProjects;
}

export async function addPortfolioCostPlanning(projects, abortSignal) {
  const projectIDs = projects.map((p) => p.id);
  const newProjectData = await getPortfolioCP(projectIDs, abortSignal);
  const updatedProjects = [];
  projects.forEach((project) => {
    let match = false;
    let newData = {};
    // Try and find a match for this project
    newProjectData.forEach((data) => {
      if (project.id.toString() === data.id.toString()) {
        match = true;
        newData = data;
      }
    });
    // If there is a match combine the project with the data
    if (match) {
      const combinedProject = {
        ...project,
        ...newData,
      };
      updatedProjects.push(combinedProject);
    } else {
      // If there is no match simply return the plain project
      updatedProjects.push(project);
    }
  });
  return updatedProjects;
}
