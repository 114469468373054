import React from "react";
import { Link } from "react-router-dom";
import ProjectItemHighlights from "./ProjectItemHighlights";
import isEmpty from "../../validation/is-empty";
import "./ProjectItem.css";

import { displayProjectImage } from "../../validation/image-clean";
import { ImageDisplay } from "../images/ImageDisplay";

export default function ProjectItem(props) {
  const { project, isPortfolio, isDraggable, isMap, notification } = props;
  const { dragID } = props;
  const { setIsDragEnabled } = props;

  const { projectsCostPlanningLoading, projectsPostContractLoading } = props;

  const projectLink = `/project/${project.id}`;

  let card = "project-card";
  if (notification) {
    card = "project-card-pulse";
  }

  if (isDraggable) {
    if (dragID.current === project.id) {
      card = "project-card-dragged";
    }
  }

  let classType = "project-link-disabled";
  if (isPortfolio) {
    classType = "project-link";
  }

  let target = null;
  if (isMap) {
    target = "_blank";
  }

  return (
    <button
      className="project-item-drag"
      draggable="true"
      onDragStart={() => {
        if (isDraggable) {
          dragID.current = project.id;
          setIsDragEnabled(true);
        }
      }}
      onDragEnd={() => {
        if (isDraggable) {
          dragID.current = null;
          setIsDragEnabled(false);
        }
      }}
    >
      <Link to={projectLink} className={classType} target={target}>
        <div className={card}>
          <ImageDisplay
            image={displayProjectImage(project)}
            imageWidth={"330px"}
            imageHeight={"190px"}
            isBezierDisabled={true}
          />

          <div className="project-details">
            <div className="project-item-title-row">
              <Title title={project.title} />
              <CommentNotification
                notification={project.notify_estimate_comment}
              />
            </div>
            {isPortfolio && (
              <ProjectItemHighlights
                stages={project.stages}
                project={project}
                CPs={project.CPs}
                projectsCostPlanningLoading={projectsCostPlanningLoading}
                projectsPostContractLoading={projectsPostContractLoading}
              />
            )}
          </div>
        </div>
      </Link>
    </button>
  );
}

function Title(props) {
  const { title } = props;
  return (
    <h3 className="project-item-title" style={{ marginBottom: "0px" }}>
      {title}
    </h3>
  );
}

export function CommentNotification(props) {
  const { notification } = props;
  if (notification < 1) {
    return null;
  }

  if (isEmpty(notification)) {
    return null;
  }

  return (
    <div className="project-item-notification">
      <div className="project-item-notification-text">{notification}</div>
      <div className="project-item-notification-icon">
        <i className="fas fa-comment"></i>
      </div>
    </div>
  );
}
