import React from "react";
import { connect } from "react-redux";

import Arcadis from "../../img/ArcadisBrand.png";
import Arcadisb from "../../img/ArcadisBrandb.png";

import privacyStatement from "../../files/ArcadisPrivacyStatement.pdf";
import "./Footer.css";

const Footer = (props) => {
  const { isAuthenticated } = props.auth;

  // Add a random query value to prevent caching
  const randomQueryValue = "?" + Date.now();

  if (isAuthenticated) {
    return (
      <footer className="clarity-footer-container">
        <hr className="footer-hr" />
        <footer className="clarity-footer">
          <a
            href="https://www.arcadis.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Arcadis}
              width="200"
              className="footer-arcadis-brand"
              alt=""
              aria-label="Clarity"
            />
          </a>
          <a
            href={
              "https://stcostclarityprd001.blob.core.windows.net/1-publications/International Construction Costs Report.pdf" +
              randomQueryValue
            }
            target="_blank"
            className="ICCLogo-container"
            rel="noopener noreferrer"
          >
            <img
              src={
                "https://stcostclarityprd001.blob.core.windows.net/1-publications/International Construction Costs Report.png" +
                randomQueryValue
              }
              className="ICCLogo"
              alt=""
              aria-label="Clarity"
            />
          </a>
          <div className="footer-info">
            <a href={privacyStatement} download className="footer-privacy">
              Privacy Policy
            </a>
            <div>&copy; {new Date().getFullYear()} Arcadis</div>
          </div>
        </footer>
      </footer>
    );
  } else {
    return (
      <footer className="clarity-footer-container-guest">
        <a
          href="https://www.arcadis.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={Arcadisb}
            width="180"
            className="footer-arcadis-brand"
            alt=""
            aria-label="Clarity"
          />
        </a>
        <div className="copyright-guest">
          &copy; {new Date().getFullYear()} Arcadis
        </div>
      </footer>
    );
  }
};

export default connect((state) => ({
  auth: state.auth,
}))(Footer);
