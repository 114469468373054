import { useState, useEffect } from "react";
import { authHeader } from "../../_helpers/auth-header";
import { cpAPIs } from "../api-endpoints/CostPlanningEndpoints";

// Get a user by ID
export async function getProjectSettings(projectID) {
  // COST PLAN API
  const url = cpAPIs().get_projects_charts + "/" + projectID;

  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);
  if (response.ok) {
    response = await response.json();
  } else {
    console.log("Get Projects Setting Error");
    response = [];
  }

  return response;
}

export function useProjectSettings(projectID) {
  const [settingsLoading, setLoading] = useState(true);
  const [settings, setSettings] = useState([]);
  useEffect(() => {
    if (projectID) {
      setLoading(true);
      getProjectSettings(projectID)
        .then((result) => {
          setSettings(result.settings);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [projectID]);

  return {
    settingsLoading,
    settings,
  };
}

export async function onSettingsChange({ project, ...props }) {
  const { setting, state } = props;
  return await insertOrUpdateSettings(project.id, setting, state);
}

export async function insertOrUpdateSettings(project_id, setting, state) {
  // COST PLAN API
  const url = cpAPIs().set_projects_charts;
  const payload = JSON.stringify({
    project_id: project_id,
    setting: setting,
    state: state,
  });

  const config = {
    method: "POST",
    body: payload,
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);
  if (response.ok) {
    response = await response.json();
  } else {
    console.log("Set Projects Setting Error");
    response = [];
  }

  return response;
}
