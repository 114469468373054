import React, { useState } from "react";
import PropTypes from "prop-types";

import PostContractSelectorOptions from "./PostContractSelectorOptions";
import PostContractSliderTicks from "./PostContractSliderTicks";

import isEmpty from "../../validation/is-empty";
import Spinner from "../common/Spinner";

PostContractSlider.propTypes = {
  data: PropTypes.object,
  selectedReport: PropTypes.object,
  setSelectedReport: PropTypes.func,
  loading: PropTypes.bool,
};

export default function PostContractSlider(props) {
  const { data } = props;
  const { selectedReport } = props;
  const { setSelectedReport } = props;
  const { loading } = props;

  const [maxReports, setMaxReports] = useState(10);

  if (isEmpty(selectedReport) || isEmpty(data)) {
    return null;
  }

  const { length } = data;
  let minValue = length - maxReports < 0 ? 0 : length - maxReports;
  let maxValue = length - 1;
  let currentValue = data.findIndex(
    (e) => e.PostContractId === selectedReport.PostContractId
  );

  if (loading) {
    return (
      <div className="post-contract-selector-loading">
        <Spinner width={50} />
      </div>
    );
  }

  return (
    <div className="post-contract-register-selector">
      <div>
        <div className="post-contract-stages-label">
          {selectedReport.PostContractType + " " + selectedReport.Description}
        </div>

        <input
          className="post-contract-stages-slider"
          type="range"
          min={minValue}
          max={maxValue}
          value={currentValue}
          onChange={(e) => {
            let index = parseInt(e.target.value);
            setSelectedReport(data[index]);
          }}
        />
        <PostContractSliderTicks
          minValue={minValue}
          maxValue={maxValue}
          currentValue={currentValue}
          pcaLog={data.map((report) => report.pcra_log)}
        />
      </div>
      <PostContractSelectorOptions
        maxReports={maxReports}
        setMaxReports={setMaxReports}
        enableFilter={false}
        height={50}
      />
    </div>
  );
}
