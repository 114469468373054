// React
import React from "react";

import { Link } from "react-router-dom";
import { translate } from "../../utils/translation";

import "./PortfolioNavigation.css";

export default function PortfolioNavigation(props) {
  const { portfolioId } = props;

  let projectsClass = "portfolio-navigation-section";
  let dashboardClass = "portfolio-navigation-section";
  let mapClass = "portfolio-navigation-section";

  if (window.location.href.includes("/projects")) {
    projectsClass = "portfolio-navigation-section-selected";
  }

  if (window.location.href.includes("dashboard")) {
    dashboardClass = "portfolio-navigation-section-selected";
  }

  if (window.location.href.includes("map")) {
    mapClass = "portfolio-navigation-section-selected";
  }

  return (
    <div className="portfolio-navigation-tab-group">
      <Link
        to={`/portfolio/${portfolioId}/projects/`}
        className="portfolio-navigation-tab-link"
      >
        <div className={projectsClass}>
          <div className="portfolio-navigation-label">
            <div className="front-icon">
              <div className="icon-container">
                <i className="fas fa-city" style={{ marginRight: "10px" }}></i>
              </div>
              {translate("Projects View")}
            </div>
          </div>
        </div>
      </Link>

      <Link
        to={`/portfolio/${portfolioId}/dashboard/`}
        className="portfolio-navigation-tab-link"
      >
        <div className={dashboardClass}>
          <div className="portfolio-navigation-label">
            <div className="front-icon">
              <div className="icon-container">
                <i
                  className="far fa-chart-bar"
                  style={{ marginRight: "10px" }}
                ></i>
              </div>
              {translate("Dashboard View")}
              <div className="portfolio-navigation-beta">
                {translate("Beta")}
              </div>
            </div>
          </div>
        </div>
      </Link>

      <Link
        to={`/portfolio/${portfolioId}/map/`}
        className="portfolio-navigation-tab-link"
      >
        <div className={mapClass}>
          <div className="portfolio-navigation-label">
            <div className="front-icon">
              <div className="icon-container">
                <i
                  className="fas fa-map-marked-alt"
                  style={{ marginRight: "10px" }}
                ></i>
              </div>
              {translate("Map View")}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
