import { useState, useEffect } from "react";

import { MASTER_BENCHMARKING } from "./BenchmarkCreate.strings";

import {
  getPreviouslySavedBenchmarkAnalysisItems,
  getFormsForBenchmarkCreateAnalysisConfiguration,
  getFormsForBenchmarkCreateAnalysisProjectSelection,
} from "./BenchmarkCreate.functions";
import { BooleanToON_OFF } from "../../helpers/common.functions";

export async function getSectionedItems(user, currentSelection) {
  const items = [];

  // first item is the currentSelection
  currentSelection.section =
    MASTER_BENCHMARKING.SECTIONS.CURRENT_SELECTION.TYPE;
  currentSelection.id = "0";
  items.push(currentSelection);

  // subsequent items are previouslySaved items
  const previouslySaved = await getPreviouslySavedBenchmarkAnalysisItems(user);
  for (const item of previouslySaved) {
    item.section = MASTER_BENCHMARKING.SECTIONS.PREVIOUSLY_SAVED.TYPE;
    items.push(item);
  }

  return items;
}

export function useSectionedItems(user, currentSelection) {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);

  useEffect(() => {
    setLoading(true);
    getSectionedItems(user, currentSelection)
      .then((items) => {
        setItems(items);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [user, currentSelection]);

  return {
    itemsLoading: loading,
    items,
    setItems,
  };
}

export function getSelectedItem({ items, selectedItemId }) {
  const index = items.findIndex((item) => item.id === selectedItemId);
  const item = items[index];

  return { item, index };
}

export function prepareForms(item) {
  // item here is of type BenchmarkCreateAnalysisPersistence
  const { configuration, selectedBenchmarkIds, benchmarkSelection } = item;

  const configurationForms = getFormsForBenchmarkCreateAnalysisConfiguration({
    configuration,
    configurationSetters: null,
    locations: [],
  });

  const projectSelectionForms =
    getFormsForBenchmarkCreateAnalysisProjectSelection({
      selectedBenchmarkIds,
      benchmarkSelection,
    });

  return { forms: [...configurationForms, ...projectSelectionForms] };
}

export function getDisplayValueForForm({ value, component }) {
  if (value instanceof Array) {
    return `${value.join(" , ")}`;
  }

  if (component === "Toggle") {
    return BooleanToON_OFF(value);
  }

  return value || "-";
}
