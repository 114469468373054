import React, { useCallback } from "react";
import ToggleControlSwitch from "../checkboxes/ToggleControlSwitch";
import { CORRESPONDENCE } from "./Correspondence.strings";
import ProjectsList from "./CorrespondenceProjectList";

export default function CorrespondenceModalLeftPane(props) {
  const { projects } = props;
  const { selectedProjectId, setSelectedProjectId } = props;
  const { notificationsOnly, setNotificationsOnly } = props;
  const { commentsLoading } = props;

  const handleToggleControlSwitchOnChange = useCallback(() => {
    // toggle notificationsOnly
    setNotificationsOnly(!notificationsOnly);

    // if selected project has no notifications, clear selection
    const selectedProject = projects.find((p) => p.id === selectedProjectId);
    if (selectedProject?.notify_estimate_comment === 0) {
      setSelectedProjectId(0);
    }
  }, [
    projects,
    selectedProjectId,
    setSelectedProjectId,
    notificationsOnly,
    setNotificationsOnly,
  ]);

  return (
    <>
      <ProjectsList
        projects={projects}
        selectedProjectId={selectedProjectId}
        setSelectedProjectId={setSelectedProjectId}
        notificationsOnly={notificationsOnly}
      />
      <ToggleControlSwitch
        style={{ margin: "10px 0", padding: "0 15px" }}
        label={CORRESPONDENCE.SHOW_CLOSED_OUT_COMMENTS_TOGGLE_SWITCH_LABEL}
        checked={!notificationsOnly}
        disabled={commentsLoading}
        onChange={handleToggleControlSwitchOnChange}
      />
    </>
  );
}
