// React
import React, { useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// Language
import { translate } from "../../utils/translation";
import { projectLocalize } from "./ProjectLocalization";

// Bring in Redux
import { connect } from "react-redux";

// API for Project
import { useProject } from "../../api/projects/ProjectAPI";
import { useDashboardSettings } from "../../api/projects/ProjectDashboardSettingsAPI";
import { useDashboardType } from "../../api/projects/ProjectDashboardTypeAPI";

// API for Cost Plan
import { useEstimates } from "../../api/estimates/EstimateAPI";
import { useBenchmarking } from "../../api/benchmarking-control/BenchmarkingControlAPI";
import { useTPI } from "../../api/tender-price-index/TenderPriceIndexingAPI";

// API for Post Contract
import { usePackages } from "../../api/post-contract/PostContractPackageAPI";
import { usePostContractReports } from "../../api/post-contract/PostContractReportsAPI";
import { usePostContract } from "../../api/post-contract/PostContractAPI";
import { usePostContractForm } from "../../api/post-contract/PostContractFormAPI";

// API for Options Analysis
import { useGetOptions } from "../options-analysis/OptionsAnalysisAPI";

// Control Panels
import ProjectControl from "../project-control/ProjectControl";
import CostPlanningControlPanel from "../cost-planning-control/CostPlanningControl";
import PostContractButtons from "../post-contract-2.0/PostContractButtons";
import OptioneeringControlPanel from "../options-analysis/OptioneeringControl";

// Components
import ProjectNavigation from "../project-navigation/ProjectNavigation";
import EstimateBreakdownButtons from "../estimate-breakdown/EstimateBreakdownButtons";
import ProjectPath from "../path/ProjectPath";
import ProjectCostPlanSelectors from "./ProjectCostPlanSelectors";
import PostContractSelector from "../post-contract-2.0/PostContractSelector";
import ProjectTeam from "../project-team/ProjectTeam";
import OptionsAnalysisStageSelector from "../options-analysis/OptionsAnalysisStageSelector";

// Pages
import Dashboard from "./Dashboard";
import CostPlanning from "../cost-planning/CostPlanning";
import PostContract from "../post-contract-2.0/PostContract";
import OptionsAnalysis from "../options-analysis/OptionsAnalysis";

// Functions
import isEmpty from "../../validation/is-empty";

// Styles
import "./Project.css";
import Sustainability from "../sustainability/Sustainability";
import { useLocationFactors } from "../../api/admin/RegionManagementAPI";
import PropTypes from "prop-types";

Project.propTypes = {
  user: PropTypes.object,
  auth: PropTypes.object,
  id: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
};

function Project(props) {
  // PROPS
  const { id } = props.match.params;
  const { auth } = props;
  const { user } = props.auth;

  // PROJECT DASHBOARD
  const { project, projectLoading } = useProject(id, user.id); // activeDirectoryId

  // LOCALIZATION
  projectLocalize(project);

  // PROJECT DASHBOARD
  const { dashboardSettings } = useDashboardSettings(project);

  const [showDashboardDrawer, setShowDashboardDrawer] = useState(false);
  const { dashboardType, setDashboardType } = useDashboardType(project);

  // COST PLANNING
  const { CPs } = useEstimates(id, project);

  // All Benchmarking
  const { benchmarking, setBenchmarking } = useBenchmarking(
    user,
    project.company_id
  );
  const { TPI, TPILoading } = useTPI(
    true,
    project.region,
    project.country,
    false
  );
  const { locationFactors } = useLocationFactors();
  const [CP, setCP] = useState({});
  const [comparedCP, setComparedCP] = useState({}); // Comparison with last Cost Plan STAGE
  const [comparedCPR, setComparedCPR] = useState({}); // Comparison with last Cost Plan RELEASE

  // POST CONTRACT
  const [showCRPanel, setShowCRPanel] = useState(false); // Drawer
  const [showPCPanel, setShowPCPanel] = useState(false); // Drawer

  // Post Contract Packages
  const { packages } = usePackages(id);
  const [selectedPackage, setSelectedPackage] = useState({});

  // Post Contract Sections for navigation bar
  const { postContractForm } = usePostContractForm(
    "navigationFields",
    id,
    selectedPackage.Id
  );

  // Post Contract Reports
  const { postContractReports } = usePostContractReports(
    id,
    selectedPackage.Id
  );
  const [selectedReport, setSelectedReport] = useState({});

  // Post Contract Data
  const { postContract } = usePostContract(
    selectedReport.ProjectId,
    selectedReport.Id
  );

  // OPTIONEERING
  const { options } = useGetOptions(project.id);
  const [activeStage, setActiveStage] = useState(null);

  // No Project Found
  if (!projectLoading && isEmpty(project.id)) {
    props.history.push("/not-found");
  }
  return (
    <div className="project-background">
      <div className="project-control-container">
        <div className="project-control-column-backer">
          <div className="project-control-column">
            <div className="project-control-column-content-container">
              <div className="project-title">
                <h1 className="display-4" style={{ fontSize: "30px" }}>
                  {translate("Project")}
                </h1>
              </div>
              <ProjectNavigation
                projectID={id}
                dashboardType={dashboardType}
                project={project}
                projectLoading={projectLoading}
                CP={CP}
                // Post Contract
                postContractForm={postContractForm}
              />
            </div>
            <ProjectControl
              // Project
              project={project}
              projectLoading={projectLoading}
              projectID={id}
              projectTitle={project.title}
              // Cost Plans
              CPs={CPs}
              CP={CP}
              // User
              auth={auth}
              // Dashboard Type
              dashboardType={dashboardType}
              setDashboardType={setDashboardType}
              // Cost Plan Dashboard Drawer
              showDashboardDrawer={showDashboardDrawer}
              setShowDashboardDrawer={setShowDashboardDrawer}
              // Post Contract
              packages={packages}
              postContract={postContract}
              postContractReports={postContractReports}
              postContractForm={postContractForm}
              selectedPackage={selectedPackage}
              selectedReport={selectedReport}
              setSelectedReport={setSelectedReport}
            />
            <CostPlanningControlPanel
              project={project}
              CPs={CPs}
              CP={CP}
              setCP={setCP}
              projectID={id}
              user={auth.user}
              comparedCP={comparedCP}
              comparedCPR={comparedCPR}
            />
            <PostContractButtons
              projectID={id}
              project={project}
              postContractReports={postContractReports}
              postContractForm={postContractForm}
              postContract={postContract}
              selectedReport={selectedReport}
              packages={packages}
              selectedPackage={selectedPackage}
              showCRPanel={showCRPanel}
              setShowCRPanel={setShowCRPanel}
              showPCPanel={showPCPanel}
              setShowPCPanel={setShowPCPanel}
              user={auth.user}
            />
            <OptioneeringControlPanel
              project={project}
              CPs={CPs}
              CP={CP}
              projectID={id}
              auth={auth}
              user={auth.user}
              options={options.data}
              getOptions={options.get}
              setOptions={options.set}
            />
          </div>
        </div>
        <EstimateBreakdownButtons projectID={id} />
      </div>
      <div className="project-dashboard">
        <div className="project-top-row">
          <ProjectPath
            project_id={project.id}
            project_name={project.title}
            projectLoading={projectLoading}
          />
          <ProjectCostPlanSelectors
            CPs={CPs}
            CP={CP}
            setCP={setCP}
            setComparedCP={setComparedCP}
            setComparedCPR={setComparedCPR}
            dashboardType={dashboardType}
          />
          <PostContractSelector
            project={project}
            dashboardType={dashboardType}
            // Reports
            postContractReports={postContractReports}
            selectedReport={selectedReport}
            setSelectedReport={setSelectedReport}
            // Packages
            packages={packages}
            selectedPackage={selectedPackage}
            setSelectedPackage={setSelectedPackage}
          />
          <OptionsAnalysisStageSelector
            options={options.data}
            activeStage={activeStage}
            setActiveStage={setActiveStage}
          />
        </div>
        <Switch>
          <Route
            exact
            path={"/project/:id/"}
            render={() => <Redirect replace to={`/project/${id}/dashboard`} />}
          />
          <Route
            path={"/project/:id/dashboard"}
            render={() => (
              <Dashboard
                // Project
                project={project}
                projectLoading={projectLoading}
                dashboardType={dashboardType}
                // Cost Planning
                CPs={CPs}
                CP={CP}
                setCP={setCP}
                setComparedCP={setComparedCP}
                TPI={TPI}
                TPILoading={TPILoading}
                // Cost Planning Dashboard Drawer
                showDashboardDrawer={showDashboardDrawer}
                setShowDashboardDrawer={setShowDashboardDrawer}
                // Post Contract
                selectedReport={selectedReport}
                selectedPackage={selectedPackage}
                // Settings
                dashboardSettings={dashboardSettings}
                // Add Route History
                {...props}
              />
            )}
          />
          <Route
            path={"/project/:id/cost-planning"}
            render={() => (
              <CostPlanning
                user={user}
                // Project
                project={project}
                projectLoading={projectLoading}
                // Cost Plan
                CPs={CPs}
                CP={CP}
                setCP={setCP}
                comparedCP={comparedCP}
                comparedCPR={comparedCPR}
                setComparedCP={setComparedCP}
                // TPI
                TPI={TPI}
                TPILoading={TPILoading}
                //LFI
                LFI={locationFactors.data}
                LFILoading={locationFactors.loading}
                // Benchmarking
                benchmarking={benchmarking}
                setBenchmarking={setBenchmarking}
              />
            )}
          />
          <Route
            path={"/project/:id/post-contract/"}
            render={(props) => (
              <PostContract
                // User
                user={auth.user}
                // Project
                project={project}
                // Post Contract Data
                selectedReport={selectedReport}
                selectedPackage={selectedPackage}
                postContract={postContract}
                // Dashboard Settings
                dashboardSettings={dashboardSettings}
                // Cost Report Dashboard
                showCRPanel={showCRPanel}
                setShowCRPanel={setShowCRPanel}
                // Progress Claim Dashboard
                showPCPanel={showPCPanel}
                setShowPCPanel={setShowPCPanel}
                // Render Props
                props={props}
              />
            )}
          />
          <Route
            path={"/project/:id/project-team"}
            render={() => <ProjectTeam project={project} />}
          />
          <Route
            path={"/project/:id/sustainability"}
            render={() => <Sustainability project={project} />}
          />
          <Route
            path={"/project/:id/optioneering"}
            render={() => (
              <OptionsAnalysis
                CPs={CPs}
                CP={CP}
                options={options.data}
                optionsLoading={options.loading}
                getOptions={options.get}
                setOptions={options.set}
                project={project}
                user={auth.user}
                activeStage={activeStage}
                setActiveStage={setActiveStage}
              />
            )}
          />
        </Switch>
      </div>
    </div>
  );
}

export default connect((state) => ({
  auth: state.auth,
}))(Project);
