import isEmpty from "../../validation/is-empty";

import "./CbsDropDown.css";

export function CbsDropDown(props) {
  const { data } = props;
  const { get, set } = props;
  const { placeholder } = props;
  const { id } = props;

  return (
    <select
      id={id}
      className="cbs-dropdown"
      value={get}
      onChange={(e) => {
        set(e.target.value);
      }}
    >
      {selectOptions(data, placeholder)}
    </select>
  );
}

function selectOptions(data, placeholder) {
  if (isEmpty(data)) {
    return null;
  }

  let list = [];

  list.push(
    <option key={"Select CBS"} value={"Select CBS"}>
      {placeholder}
    </option>
  );

  data.forEach((option) => {
    list.push(
      <option key={option} value={option}>
        {option}
      </option>
    );
  });

  return list;
}
