// React
import React, { useState, useEffect } from "react";
import { translate } from "../../utils/translation";
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// API
import { useCompaniesByUser } from "../../api/accounts/ProfileAPI";
import { useAccountLocations } from "../../api/admin/RegionManagementAPI";

import isEmpty from "../../validation/is-empty";

// Components
import {
  RegionSelect,
  CountrySelect,
  CitySelect,
} from "../locations/LocationSelect";

import Spinner from "../common/Spinner";
import { ImageUpload } from "../images/ImageUpload";
import { ImageDisplay } from "../images/ImageDisplay";

// Styles
import "./EditProfile.css";
import "./CreateProfile.css";
import { displayUserImage } from "../../validation/image-clean";

const defaultAvatar =
  "https://stcostclarityprd001.blob.core.windows.net/1-publications/default_avatar.png";

function EditProfile(props) {
  const { user } = props;
  const { setShow } = props;
  const { sendRequest } = props;
  const { isAdmin } = props;
  const { isCreateProfile } = props;

  // Name
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");

  // Location
  const [region, setRegion] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");

  // Employment
  const [companyID, setCompany] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [linkedin, setLinkedin] = useState("");

  // Image
  const [file, setFile] = useState("");
  const [imgPreview, setImgPreview] = useState("");
  const [errors, setErrors] = useState({});

  // Consent
  const [hasPrivacyConsent, setHasPrivacyConsent] = useState(false);

  // User Interface
  const [loading, setLoading] = useState(false);

  // API Calls
  const { locations, locationsLoading } = useAccountLocations();
  const { companies, companiesLoading, companiesError } =
    useCompaniesByUser(user);

  // Set User
  useEffect(() => {
    if (!isEmpty(user)) {
      // Name
      setName(user.name);
      setSurname(user.surname);

      // Location
      setRegion(user.region);
      setCountry(user.country);
      setCity(user.city);

      // Employment
      setJobTitle(user.jobTitle);
      setPhoneNumber(user.phoneNumber);
      setLinkedin(user.linkedin);

      // Image
      if (!user.hasProfile) {
        setImgPreview(defaultAvatar);
        setFile(defaultAvatar);
      } else {
        setImgPreview(displayUserImage(user));
        setFile(displayUserImage(user));
      }

      if (isAdmin) {
        setHasPrivacyConsent(user.hasPrivacyConsent);
      }
    }
  }, [user, isAdmin]);

  // Set Company
  useEffect(() => {
    if (!isEmpty(companies)) {
      setCompany(companies[0].id);
    }
  }, [companies]);

  let data = {};

  if (!isEmpty(user)) {
    data = {
      setShow: setShow,
      setErrors: setErrors,
      loading: loading,
      setLoading: setLoading,
      file: file,
      payload: {
        // IDs
        id: user.id, // activeDirectoryId
        companyID: companyID,

        // Name
        name: name,
        surname: surname,

        // Location
        region: region,
        country: country,
        city: city,

        // Employment
        jobTitle: jobTitle,
        phoneNumber: phoneNumber,
        linkedin: linkedin,

        // Consent
        hasPrivacyConsent: hasPrivacyConsent,
      },
    };
  }

  if (locationsLoading) {
    return <Spinner />;
  }

  if (companiesLoading) {
    return <Spinner />;
  }

  return (
    <div className="edit-profile-container">
      <div>Fields marked * are required. </div>
      <div className="edit-profile-form-container">
        <div className="edit-profile-form">
          <div className="edit-profile-titles-container"></div>

          <div className="edit-profile-inputs-container">
            <div className="edit-profile-inputs-row">
              <div className="edit-profile-icon">
                <i className="fas fa-pen-fancy fa-2x"></i>
              </div>
              <Title title={translate("Name *")} />
              <div className="edit-profile-inputs-row-container">
                <Input value={name} set={setName} />
                <DisplayError error={errors.Name} />
              </div>
            </div>
            <div className="edit-profile-inputs-row">
              <div className="edit-profile-icon">
                <i className="fas fa-pen-fancy fa-2x"></i>
              </div>
              <Title title={"Surname *"} />
              <div className="edit-profile-inputs-row-container">
                <Input value={surname} set={setSurname} />
                <DisplayError error={errors.Surname} />
              </div>
            </div>
            <div className="edit-profile-inputs-row">
              <div className="edit-profile-icon">
                <i className="fas fa-user-graduate fa-2x"></i>
              </div>
              <Title title={"Job Title *"} />
              <div className="edit-profile-inputs-row-container">
                <Input value={jobTitle} set={setJobTitle} />
                <DisplayError error={errors.JobTitle} />
              </div>
            </div>
            {isCreateProfile && (
              <div className="edit-profile-inputs-row">
                <div className="edit-profile-icon">
                  <i className="far fa-building fa-2x"></i>
                </div>
                <Title title={"Company *"} />
                <div className="edit-profile-inputs-row-container">
                  <Select
                    value={companyID}
                    set={setCompany}
                    options={companies}
                  />
                  <DisplayError error={companiesError} />
                </div>
              </div>
            )}

            <div className="edit-profile-inputs-row">
              <div className="edit-profile-icon">
                <i className="fas fa-globe-asia fa-2x"></i>
              </div>
              <Title title={"Region *"} />
              <div className="edit-profile-inputs-row-container">
                <RegionSelect
                  value={region}
                  set={setRegion}
                  options={locations}
                  classType={"edit-profile-select"}
                  setCountry={setCountry}
                  setCity={setCity}
                />
                <DisplayError error={errors.Region} />
              </div>
            </div>
            <div className="edit-profile-inputs-row">
              <div className="edit-profile-icon">
                <i className="far fa-flag fa-2x"></i>
              </div>
              <Title title={"Country *"} />
              <div className="edit-profile-inputs-row-container">
                <CountrySelect
                  value={country}
                  set={setCountry}
                  options={locations}
                  region={region}
                  classType={"edit-profile-select"}
                  setCity={setCity}
                />
                <DisplayError error={errors.Country} />
              </div>
            </div>
            <div className="edit-profile-inputs-row">
              <div className="edit-profile-icon">
                <i className="fas fa-map-marker-alt fa-2x"></i>
              </div>
              <Title title={"City *"} />
              <div className="edit-profile-inputs-row-container">
                <CitySelect
                  value={city}
                  set={setCity}
                  options={locations}
                  country={country}
                  classType={"edit-profile-select"}
                />
                <DisplayError error={errors.City} />
              </div>
            </div>
            <div className="edit-profile-inputs-row">
              <div className="edit-profile-icon">
                <i className="fas fa-phone fa-2x"></i>
              </div>
              <Title title={"Phone Number"} />
              <div className="edit-profile-inputs-row-container">
                <Input value={phoneNumber} set={setPhoneNumber} />
                <DisplayError error={errors.PhoneNumber} />
              </div>
            </div>
            <div className="edit-profile-inputs-row">
              <div className="edit-profile-icon">
                <i className="fab fa-linkedin fa-2x"></i>
              </div>
              <Title title={"LinkedIn"} />
              <div className="edit-profile-inputs-row-container">
                <Input value={linkedin} set={setLinkedin} />
                <DisplayError error={errors.Linkedin} />
              </div>
            </div>
          </div>
          <div className="edit-profile-image-preview-block">
            <ImageDisplay
              image={imgPreview || defaultAvatar}
              imageWidth={"100px"}
              imageHeight={"100px"}
            />
            <DisplayError error={errors.Image} />
          </div>
        </div>
        <div className="general-row-container">
          <ImageUpload
            imagePreview={imgPreview}
            setImage={setFile}
            setImagePreview={setImgPreview}
            imageWidth={"150px"}
            imageHeight={"150px"}
          />
        </div>

        <div className="edit-profile-privacy-row">
          The Arcadis Global Privacy Policy and Cost Clarity Privacy Statement
          can be found at the links below.
        </div>
        <div className="edit-profile-privacy-row-link">
          <div className="edit-profile-titles-container">
            <GlobalPrivacyStatement />
          </div>
        </div>
        <div className="edit-profile-privacy-row-link">
          <div className="edit-profile-titles-container">
            <PrivacyStatement />
          </div>
        </div>
        <div className="edit-profile-privacy-row">
          <label className="checkboxes-vertical-container">
            I confirm my consent to Arcadis storing my personal data in line
            with the Privacy Statement and the Global Privacy Policy.
            <input
              className="edit-profile-checkbox"
              id="edit-profile-checkbox-1"
              name={"consent"}
              type="checkbox"
              checked={hasPrivacyConsent}
              onChange={(e) => {
                if (!isAdmin) {
                  const value = e.target.checked;
                  setHasPrivacyConsent(value);
                }
              }}
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <div className="edit-profile-privacy-row">
          <DisplayError error={errors.hasPrivacyConsent} />
        </div>
        <Submit sendRequest={sendRequest} data={data} />
      </div>
      <RequestDelete isCreateProfile={isCreateProfile} user={user} />
    </div>
  );
}

function Submit(props) {
  const { sendRequest } = props;
  const { data } = props;

  return (
    <div className="edit-profile-submit-container">
      {!data.loading && (
        <button
          id={"edit-profile-submit-1"}
          className="edit-profile-submit"
          onClick={async () => {
            data.setLoading(true);
            await sendRequest(data);
            data.setLoading(false);
          }}
        >
          Submit
        </button>
      )}

      {data.loading && <Spinner width={"30px"} />}
    </div>
  );
}

function Title(props) {
  const { title } = props;
  return (
    <label className="edit-profile-title">
      <h1 className="display-4" style={{ fontSize: "17px" }}>
        {title}
      </h1>
    </label>
  );
}

function Input(props) {
  const { errors } = props;
  let input;

  if (errors) {
    input = "edit-profile-input-error";
  } else {
    input = "edit-profile-input";
  }

  return (
    <input
      className={input}
      value={props.value}
      onChange={(e) => {
        props.set(e.target.value);
      }}
    ></input>
  );
}

function DisplayError(props) {
  const { error } = props;
  return <div className="edit-profile-error">{error}</div>;
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

function GlobalPrivacyStatement() {
  return (
    <a
      href="https://www.arcadis.com/en/privacy"
      target="_blank"
      rel="noopener noreferrer"
    >
      Arcadis Global Privacy Policy
    </a>
  );
}

function PrivacyStatement() {
  return (
    <a
      href="https://stcostclarityprd001.blob.core.windows.net/1-publications/Cost Clarity Privacy Statement.pdf"
      target="_blank"
      rel="noopener noreferrer"
    >
      Cost Clarity Privacy Statement
    </a>
  );
}

export default connect(mapStateToProps)(withRouter(EditProfile));

function RequestDelete(props) {
  const { isCreateProfile } = props;
  const { user } = props;

  if (isCreateProfile) {
    return null;
  }
  return (
    <div>
      Please click{" "}
      <a
        href={
          "mailto:" +
          "itservicedesk@arcadis.com" +
          "?subject=" +
          `Cost Clarity - Erasure of Personal Details` +
          "&body=" +
          `Please erase all of the personal data you hold on me within Cost Clarity. My user ID in the Arcadis B2C solution is ${user.id}.`
        }
      >
        here
      </a>{" "}
      if you wish to request to have your account deleted.
    </div>
  );
}

function Select(props) {
  const { value, set, options } = props;
  return (
    <select
      className="edit-profile-select"
      value={value}
      onChange={(e) => {
        set(e.target.value);
      }}
    >
      <SelectList options={options} />
    </select>
  );
}

function SelectList(props) {
  const { options } = props;
  if (isEmpty(options)) {
    return <option></option>;
  }

  let keyCounter = 0;
  const displayedOptions = [];
  options.forEach((item) => {
    displayedOptions.push(
      <option key={keyCounter} value={item.id} className="option">
        {item.name}
      </option>
    );
    keyCounter++;
  });

  return displayedOptions;
}
