// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Components
import CreateProjectSelectCostManager from "../project-create/CreateProjectSelectCostManager";
import CreateProjectSelectProjectCostManagers from "../project-create/CreateProjectSelectProjectCostManagers";
import {
  ModalMultiple,
  Submit,
  DisplayErrors,
  ModalSingle,
} from "./Permissions.components";

// Functions
import isEmpty from "../../validation/is-empty";
import { translate } from "../../utils/translation";

// Styles
import "./ProjectPermissions.css";
import Spinner from "../common/Spinner";

ProjectPermissions.propTypes = {
  project: PropTypes.object.isRequired,
  updateState: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
};

export default function ProjectPermissions(props) {
  // Props
  const { project } = props;
  const { updateState } = props;
  const { setModal } = props;

  // Selection Details
  const [projectLeader, setProjectLeader] = useState({});
  const [peerReviewer, setPeerReviewer] = useState({});
  const [costManagers, setCostManagers] = useState([]);
  const [clientsSelected, setClientsSelected] = useState([]);
  const [error, setError] = useState({});

  useEffect(() => {
    if (!isEmpty(project)) {
      setProjectLeader(project.project_leader_user || {});
      setPeerReviewer(project.peer_reviewer_user || {});
      setCostManagers(project.cost_managers || []);
      setClientsSelected(project.clients || []);
    }
  }, [project]);

  if (isEmpty(project)) {
    return (
      <div className="project-management-content">
        <Spinner  />
      </div>
    );
  }

  const usersSelected = [];
  costManagers.forEach((c) => {
    usersSelected.push(c.id);
  });
  clientsSelected.forEach((c) => {
    usersSelected.push(c.id);
  });

  const existingUsers = [];
  project.cost_managers?.forEach((c) => {
    existingUsers.push(c);
  });
  project.clients?.forEach((c) => {
    existingUsers.push(c);
  });

  const data = {
    project: project,
    updateState: updateState,
    setError: setError,
    setModal: setModal,
    project_leader: projectLeader.id ? projectLeader.id.toString() : "",
    peer_reviewer: peerReviewer.id ? peerReviewer.id.toString() : "",
    usersSelected: usersSelected,
    existingUsers: existingUsers,
    mappingId: project.id,
    mappingType: "Project",
    companyId: project.company_id,
  };

  return (
    <div className="pciu">
      <div className="pciu-form">
        <div className="pciu-panel-container">
          <div className="pciu-panel">
            <ModalSingle
              Component={CreateProjectSelectCostManager}
              setFunction={setProjectLeader}
              label={translate("Project Leader")}
              user={projectLeader}
              error={error.project_leader}
            />
            <ModalSingle
              Component={CreateProjectSelectCostManager}
              setFunction={setPeerReviewer}
              label={translate("Lead Cost Manager")}
              user={peerReviewer}
              error={error.peer_reviewer}
            />
            <ModalMultiple
              Component={CreateProjectSelectProjectCostManagers}
              setFunction={setCostManagers}
              label={translate("Cost Managers")}
              selectedData={costManagers}
              userGroup={"internal"}
            />
            <ModalMultiple
              Component={CreateProjectSelectProjectCostManagers}
              setFunction={setClientsSelected}
              label={translate("Clients")}
              selectedData={clientsSelected}
              userGroup={"external"}
            />
          </div>
        </div>
        <div className="general-row-container">
          <DisplayErrors error={error} />
        </div>
        <Submit data={data} />
      </div>
    </div>
  );
}
