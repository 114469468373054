import React from "react";

import { translate } from "../../utils/translation";

export default function BenchmarkingLimitationsData() {
  return (
    <>
      <h5>{translate("Limitations on Benchmark Data")}</h5>
      <p>
        {translate(
          "Benchmark comparison projects have been adjusted to account for escalation to the period stated.  The following potential cost drivers may affect costs beyond those presented"
        )}
      </p>
      <ul>
        <li>{translate("Price changes associated with project locations")} </li>
        <li>{translate("Local planning requirements")}</li>
        <li>
          {translate(
            "Changes to regulatory standards within the escalation period"
          )}
        </li>
        <li>
          {translate(
            "Market demand shifts e.g. exposed services vs traditional, health and wellness features"
          )}
        </li>
        <li>{translate("Environmental assessment ratings")} </li>
        <li>
          {translate(
            "Regional cost spikes e.g. cost increases resulting from large projects"
          )}
        </li>
        <li>{translate("Accessibility of the site")} </li>
        <li>
          {translate(
            "A typical sequencing of works, such as undertaking work in phases"
          )}
        </li>
        <li>
          {translate("The extent to which low carbon is being invested in")}
        </li>
        <li>
          {translate(
            "The impact of OPEX considerations on increasing or decreasing CAPEX investment"
          )}
        </li>
        <li>
          {translate(
            "Differential Employer and/or Contractor risk profiles with the comparison projects"
          )}
        </li>
      </ul>
      <p>
        {translate(
          "Comparison projects used for benchmarking frequently exclude demolition, decontamination, external works and incoming utilities, as these items are highly specific to the site bring developed.  Care should be taken when using these elements of the benchmarking."
        )}
      </p>
    </>
  );
}
