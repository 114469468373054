import { useEffect, useState } from "react";

export function useDashboardType(project) {
  const [dashboardType, setDashboardType] = useState("COST_PLANNING");

  useEffect(() => {
    setDashboardType(project.project_phase);
  }, [project]);

  return {
    dashboardType,
    setDashboardType,
  };
}
