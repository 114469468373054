import React from "react";
import isEmpty from "../../validation/is-empty";
import PropTypes from "prop-types";

import "./ProjectItemHighlights.css";
import {
  displayDate,
  displayReportDate,
} from "./ProjectItemHighlights.functions";
import { comma } from "../../helpers/common.functions";
import Spinner from "../common/Spinner";

export default function ProjectItemHighlights(props) {
  const { CPs } = props;
  const { project } = props;
  const { projectsCostPlanningLoading, projectsPostContractLoading } = props;

  if (project.project_phase === "COST_PLANNING") {
    return (
      <CostPlanningHighlights
        project={project}
        CPs={CPs}
        projectsCostPlanningLoading={projectsCostPlanningLoading}
      />
    );
  }

  if (project.project_phase === "POST_CONTRACT") {
    return (
      <PostContractHighlights
        project={project}
        projectsPostContractLoading={projectsPostContractLoading}
      />
    );
  }
}

function CostPlanningHighlights(props) {
  const { CPs } = props;
  const { project } = props;
  const { projectsCostPlanningLoading } = props;

  let total = 0;
  let date = null;

  const display = [];

  if (!isEmpty(CPs)) {
    // Get the latest Cost Plan
    const CP = project.latest_cost_plan;
    if (!isEmpty(CP)) {
      // Calculate Totals
      total = CP.calculated_total;
      date = displayDate(CP);
    }

    // // Identify stages that are active
    CPs.data.forEach((CPStage) => {
      display.push(<Box key={CPStage.stage} CPStage={CPStage} CP={CP} />);
    });
  }

  if (projectsCostPlanningLoading) {
    return (
      <div className="project-item-highlights">
        <Spinner width={50} />
      </div>
    );
  }
  return (
    <div className="project-item-highlights">
      <TextLine label={"Total Cost (Excl. Tax):"} text={comma(total)} />
      <TextLine label={"Estimate Date:"} text={date} />
      <TextLine label={"Currency:"} text={project.currency} />
      <div className="project-item-row">
        <Text text={"Stage:"} />
        {display}
      </div>
    </div>
  );
}

PostContractHighlights.propTypes = {
  project: PropTypes.object,
  projectsPostContractLoading: PropTypes.bool,
};

function PostContractHighlights(props) {
  const { project } = props;
  const { projectsPostContractLoading } = props;

  if (projectsPostContractLoading) {
    return (
      <div className="project-item-highlights">
        <Spinner width={50} />
      </div>
    );
  }

  const { postContractPortfolioTotals } = project;

  let forecasttotal = isEmpty(postContractPortfolioTotals?.PostContractCost)
    ? ""
    : comma(Math.round(postContractPortfolioTotals?.PostContractCost));
  let costreportno = postContractPortfolioTotals?.LatestPostContractNumber;
  let reportdate = displayReportDate(
    postContractPortfolioTotals?.WorkCompletedTo
  );

  return (
    <div className="project-item-highlights">
      <TextLine
        label={"Forecast Total Cost (Excl. Tax):"}
        text={forecasttotal}
      />
      <TextLine label={"Report Date:"} text={reportdate} />
      <TextLine label={"Currency:"} text={project.currency} />
      <TextLine label={"Post Contract Report No:"} text={costreportno} />
    </div>
  );
}

function Box(props) {
  const { CPStage } = props;
  const { CP } = props;
  if (isEmpty(CPStage.versions) || CPStage.versions.length === 0) {
    return (
      <div className="projectitem-future-stage-box">
        <div>{CPStage.stage_code}</div>
      </div>
    );
  }
  if (!isEmpty(CPStage.versions) && CPStage.versions.length > 0) {
    let boxType = "projectitem-stage-box";
    if (CPStage.stage === CP.stage) {
      boxType = "projectitem-current-stage-box";
    }
    return (
      <div className={boxType}>
        <div>{CPStage.stage_code}</div>
      </div>
    );
  }
}

function TextLine(props) {
  const { label, text } = props;
  return (
    <div className="project-item-row">
      <Text text={label} />
      <Text text={text} />
    </div>
  );
}

function Text(props) {
  const { text } = props;
  const { b } = props;
  let value = <h1 className="display-4">{text}</h1>;
  if (b) {
    value = (
      <h1 className="display-4">
        <b>{text}</b>
      </h1>
    );
  }

  return <div className="project-item-text">{value}</div>;
}
