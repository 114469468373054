import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import MasterPage from "../master/MasterPage";
import { useBenchmarkCreateConfigurationControls } from "./BenchmarkCreate.functions";

import BenchmarkCreateAnalysisWelcome from "./BenchmarkCreateAnalysisWelcome";

import "./BenchmarkCreate.css";

/// props validation | SQ(javascript:S6774)
BenchmarkCreate.propTypes = {
  auth: PropTypes.object,
};
///
export function BenchmarkCreate(props) {
  const { user } = props.auth;

  const controls = useBenchmarkCreateConfigurationControls({ user });

  return (
    <MasterPage title="Benchmarking" controls={controls}>
      <Page {...props} />
    </MasterPage>
  );
}

function Page(props) {
  return <BenchmarkCreateAnalysisWelcome {...props} />;
}

export default connect((state) => ({
  auth: state.auth,
}))(BenchmarkCreate);
