import { getPCRARequestingUser } from "./PCRA.functions";

export default class PCRAUpdateInput {
  true; // bypass SonarLint bug 'Unexpected class with only a constuctor'

  constructor({
    id,
    user,
    stage,
    status,
    comments = null,
    clientId = "",
    registrationId = "",
  } = {}) {
    this.id = id;
    this.clientId = clientId || "client_1";
    this.registrationId = registrationId || "registration_1";

    this.user = getPCRARequestingUser({ user });

    this.stage = stage;
    this.status = status;
    this.comments = comments;
  }
}
