import isEmpty from "../../validation/is-empty";

export function generateCostCategorySubTotalData(estimates) {
  let fields = [];
  let data = [];
  let combinedEstimateSubTotal = 0;
  // No Estimates
  if (isEmpty(estimates)) {
    return { data, combinedEstimateSubTotal };
  }

  let estimatesSubtotal = [];
  estimates.forEach((estimate) => {
    estimatesSubtotal = estimate.rows.filter(
      (row) =>
        row.code === "st" && (row.is_selected || isEmpty(row.is_selected))
    );
  });
  estimatesSubtotal.forEach((element) => {
    combinedEstimateSubTotal += element.subtotal;
    let fieldstring = element.description.replace("subtotal:", "") || "";
    if (fieldstring.length > 40) {
      fieldstring = fieldstring.substr(0, 40) + "...";
    }
    fields.push({
      field: fieldstring,
      value: element.subtotal,
    });
  });

  fields.forEach((o, i) => {
    let field = o.field;
    if (o !== undefined) {
      if (o.value > 0) {
        data.push({
          quantity: o.value.toFixed(0), // display value
          value: o.value, // value used for total calculation
          percentage: ((o.value / combinedEstimateSubTotal) * 100).toFixed(1),
          name: field,
          id: i + 1,
        });
      }
    }
  });
  return { data, combinedEstimateSubTotal };
}

export function calculateTotal(estimates) {
  let sum = 0;
  estimates.forEach((item) => {
    sum += item.value;
  });
  return Math.round(sum);
}

//function to check for unselected sub total elements
export function SubTotalsExists(props) {
  const { rows } = props;
  if (!isEmpty(rows)) {
    let st = rows.filter((x) => x.code === "st");
    return st.length > 0;
  }
  return false;
}
