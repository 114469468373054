// React
import React from "react";
import PropTypes from "prop-types";

// Page
import Dashboard from "../dashboard/Dashboard";

// Styles
import "./PostContractProgressClaim.css";
import PostContractComponent from "./PostContractComponent";
import PostContractCommentary from "./PostContractCommentary";
import DecisionsAdvice from "../dashboard-components/DecisionsAdvice";

ProgressClaim.propTypes = {
  project: PropTypes.object,
  postContract: PropTypes.object,
  selectedReport: PropTypes.object,
  dashboardSettings: PropTypes.object,
  showPCPanel: PropTypes.bool,
  setShowPCPanel: PropTypes.func,
};

export default function ProgressClaim(props) {
  const { project } = props;
  const { postContract } = props;
  const { selectedReport } = props;

  const { showPCPanel, setShowPCPanel } = props;
  const { dashboardSettings } = props;

  // Create Dashboard IDs
  const RECOMMENDATION = "RECOMMENDATION";
  const CONTRACT = "CONTRACT";
  const TRACKER = "TRACKER";
  const DETAILS = "DETAILS";
  const DETAILSGRID = "DETAILSGRID";
  const TIMEBAR = "TIMEBAR";
  const OVERVIEW = "OVERVIEW";
  const RECONCILIATION = "RECONCILIATION";
  const PCCOMMENTARY = "PCCOMMENTARY";
  const GLOSSARY = "GLOSSARY";
  const RETENTIONANALYSIS = "RETENTIONANALYSIS";
  const PAYMENTOVERVIEW = "PAYMENTOVERVIEW";

  let display = [
    <PostContractComponent
      key={RECOMMENDATION}
      dashboardID={RECOMMENDATION}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"TableComponents"}
      sectionType={"Progress Claim"}
      componentName={"Progress Payment Recommendation Table"}
      title={"Progress Payment Recommendation"}
    />,
    <PostContractComponent
      key={RETENTIONANALYSIS}
      dashboardID={RETENTIONANALYSIS}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"TableComponents"}
      sectionType={"Progress Claim"}
      componentName={"Retention Analysis Table"}
      title={"Retention Analysis"}
    />,
    <PostContractComponent
      key={PAYMENTOVERVIEW}
      dashboardID={PAYMENTOVERVIEW}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"DonutCharts"}
      sectionType={"Progress Claim"}
      componentName={"Payment Overview Chart"}
      title={"Payment Overview"}
    />,
    <PostContractComponent
      key={CONTRACT}
      dashboardID={CONTRACT}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"TableComponents"}
      sectionType={"Progress Claim"}
      componentName={"Contract Statement Table"}
      title={"Contract Statement"}
    />,
    <PostContractComponent
      key={TRACKER}
      dashboardID={TRACKER}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"HorizontalBarCharts"}
      sectionType={"Progress Claim"}
      componentName={"Contract Tracker Chart"}
      title={"Contract Tracker"}
    />,
    <PostContractComponent
      key={DETAILS}
      dashboardID={DETAILS}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"TableComponents"}
      sectionType={"Progress Claim"}
      componentName={"Payment Details Table"}
      title={"Payment Details"}
    />,
    <PostContractComponent
      key={DETAILSGRID}
      dashboardID={DETAILSGRID}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"ReportDetailsCharts"}
      sectionType={"Progress Claim"}
      componentName={"Payment Details Chart"}
      title={"Payment Details"}
    />,
    <PostContractComponent
      key={OVERVIEW}
      dashboardID={OVERVIEW}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"BarCharts"}
      sectionType={"Progress Claim"}
      componentName={"Payment Overview Chart"}
      title={"Payment Overview"}
    />,
    <PostContractComponent
      key={RECONCILIATION}
      dashboardID={RECONCILIATION}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"TableComponents"}
      sectionType={"Progress Claim"}
      componentName={"Progress Claim Reconciliation Table"}
      title={"Progress Claim Reconciliation"}
    />,
    <PostContractComponent
      key={TIMEBAR}
      dashboardID={TIMEBAR}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"TimeBarComponents"}
      sectionType={"Progress Claim"}
      componentName={"Time Elapsed Bar"}
      title={"Time Elapsed"}
    />,
    <PostContractCommentary
      key={PCCOMMENTARY}
      dashboardID={PCCOMMENTARY}
      commentary={postContract.data[0]?.Advice.ExclusionsClarificationsProgress}
      type={"progress_claim"}
      title={"Progress Commentary"}
    />,
    <DecisionsAdvice
      title={"Contractual Glossary"}
      key={GLOSSARY}
      dashboardID={GLOSSARY}
      decisions={postContract.data[0]?.Advice.DecisionsGlossary}
      type={"glossary"}
      column1={"decisions-advice-glossary-term"}
      column2={"decisions-advice-glossary-definition"}
      isGlossary
    />,
  ];

  const column1Set = [RECOMMENDATION, CONTRACT, PCCOMMENTARY, DETAILS];
  const column2Set = [
    DETAILSGRID,
    RETENTIONANALYSIS,
    PAYMENTOVERVIEW,

    GLOSSARY,
  ];
  const column3Set = [OVERVIEW, TRACKER, RECONCILIATION, TIMEBAR];
  const column4Set = column1Set.concat(column2Set.concat(column3Set));

  return (
    <div className="progress-claim">
      <Dashboard
        display={display}
        column1Set={column1Set}
        column2Set={column2Set}
        column3Set={column3Set}
        column4Set={column4Set}
        project={project}
        // Dashboard Settings
        showDashboard={showPCPanel}
        setShowDashboard={setShowPCPanel}
        dashboardSettings={dashboardSettings}
        dashboardType={"PROGRESS_CLAIM"}
      />
    </div>
  );
}
