import { BA_ROLE } from "../../utils/roles";
import { tranStr } from "../../utils/translation";
import isEmpty from "../../validation/is-empty";

export function columns(selectedColumns) {
  const columns = [
    {
      type: "CHECK",
      key: "id",
      width: 50,
      isModal: false,
    },
    {
      heading: tranStr("Project Number"),
      key: "project_number",
      type: "STATUS",
      width: 200,
      isFiltered: true,
      roles: BA_ROLE,
    },
    {
      heading: tranStr("Name"),
      key: "project_name",
      type: "DESCRIPTION",
      width: 200,
      isFiltered: true,
    },
    {
      heading: tranStr("Information Sensitivity"),
      key: "informationSensitivityLevelDesc",
      type: "STATUS",
      width: 200,
      isFiltered: true,
      roles: BA_ROLE,
    },
    {
      heading: tranStr("Asset Class Level 1"),
      key: "sector",
      type: "STATUS",
      width: 180,
      isFiltered: true,
      roles: BA_ROLE,
    },
    {
      heading: tranStr("Asset Class Level 2"),
      key: "sub_sector",
      type: "STATUS",
      width: 180,
      isFiltered: true,
      roles: BA_ROLE,
      visible: true,
    },
    {
      heading: tranStr("CBS"),
      key: "standard",
      type: "STATUS",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Client"),
      key: "client",
      type: "STATUS",
      width: 200,
      isFiltered: true,
    },
    {
      heading: tranStr("Base Date"),
      key: "base_date",
      type: "DATE",
      width: 100,
    },
    {
      heading: tranStr("City"),
      key: "city",
      type: "STATUS",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("TPI Location"),
      key: "tpi_city",
      type: "STATUS",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Local Region Area"),
      key: "local_region_area",
      type: "QUANTITY",
      width: 120,
    },
    {
      heading: tranStr("Construction Cost"),
      key: "construction_cost",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Construction Cost / Local Region Area"),
      key: "construction_cost_rate",
      type: "CURRENCY",
      width: 100,
    },
    {
      heading: tranStr("Project Quality"),
      key: "quality",
      type: "STATUS",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Procurement Method"),
      key: "procurement_method",
      type: "TEXT",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("File Name"),
      key: "file_name",
      type: "DESCRIPTION",
      width: 100,
    },
    {
      heading: tranStr("Source"),
      key: "analysis_basis",
      type: "DESCRIPTION",
      width: 100,
    },
    {
      heading: tranStr("General Notes"),
      key: "general_notes",
      type: "DESCRIPTION",
      width: 100,
    },
    {
      heading: tranStr("Site Specific Abnormals"),
      key: "site_specifics",
      type: "DESCRIPTION",
      width: 100,
    },
    {
      heading: tranStr("Architectural Notes"),
      key: "architectural_notes",
      type: "DESCRIPTION",
      width: 100,
    },
    {
      heading: tranStr("Structural Notes"),
      key: "structural_notes",
      type: "DESCRIPTION",
      width: 100,
    },
    {
      heading: tranStr("Service Notes"),
      key: "services_notes",
      type: "DESCRIPTION",
      width: 100,
    },
  ];
  if (isEmpty(selectedColumns)) {
    return columns;
  }
  let columnsToBeReturned = columns.filter(function (el) {
    return selectedColumns.indexOf(el.key) >= 0;
  });

  return columnsToBeReturned;
}
