// React
import React, { useState } from "react";

// Functions
import { onSubmit } from "./ProjectControlImage.functions";
import { translate } from "../../utils/translation";

import { ImageUpload } from "../images/ImageUpload";
import { displayProjectImage } from "../../validation/image-clean";

// Styling
import "./ProjectControlImage.css";

export default function ProjectControlImage(props) {
  const { project } = props;
  const { setModal } = props;

  // Image
  const [image, setImage] = useState("");
  const [imagePreview, setImagePreview] = useState(
    displayProjectImage(project)
  );
  const [error, setError] = useState("");

  return (
    <div className="project-control-image">
      <div className="project-control-image-container">
        <ImageUpload
          imagePreview={imagePreview}
          setImage={setImage}
          setImagePreview={setImagePreview}
          imageWidth={"330px"}
          imageHeight={"190px"}
        />
        <div></div>
      </div>
      <div className="general-button-container">
        <DisplayError error={error} />
      </div>
      <div className="general-button-container">
        <button
          className="general-upload-button"
          onClick={async () => {
            await onSubmit(image, project.id, project, setError, setModal);
          }}
        >
          {translate("Save")}
        </button>
      </div>
    </div>
  );
}

function DisplayError(props) {
  const { error } = props;
  return (
    <div className="feedback-error-container">
      <div className={error.type}>
        <b>{error.text}</b>
      </div>
    </div>
  );
}
