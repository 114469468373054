import React, { useState, useEffect } from "react";
import TextEditor from "../text-editor/TextEditor";
import { translate } from "../../utils/translation";
import { DisplayError } from "./EstimateCommentary";

import {
  commentary,
  documentation,
  program,
  procurement,
  contingency,
  assumptions,
  postCommentary,
  costreportcommentary,
} from "./EstimateCommentary.functions";
import "./EstimateCommentaryModal.css";

export default function EstimateCommentaryText(props) {
  const { CP } = props;
  const { CPs } = props;
  const { ectBasis } = props;
  const { project_id, commentNumber } = props;
  const { project } = props;
  const { setShow } = props;
  const { URLs } = props;
  const { CostId } = props;
  const { Module } = props;

  const [title, setTitle] = useState(
    "Documentation Used to Prepare This Cost Plan"
  );

  const [showTitle, setShowTitle] = useState(false);
  const [customTitle, setCustomTitle] = useState("");
  const [text, setText] = useState("");
  const [error, setError] = useState({});

  useEffect(() => {
    if (Module === "PostContract") setTitle("Custom Title");
  }, [setTitle, Module]);

  useEffect(() => {
    if (title === "Custom Title") {
      setShowTitle(true);
    } else {
      setShowTitle(false);
    }
  }, [title]);

  useEffect(() => {
    const commentaryValues = [
      documentation,
      program,
      procurement,
      contingency,
      assumptions,
      "",
    ];

    setText(commentaryValues[commentary.indexOf(title)]);
  }, [title]);

  if (ectBasis !== "TEXT") {
    return null;
  }

  const showHideTitleInput = showTitle
    ? "commentary-title-flexbox"
    : "commentary-title-none";

  return (
    <div>
      <div className="commentary-title-flexbox">
        <label className="label-title-selectbox">
          {translate("Select Title")}:
        </label>
        <select
          className="commentary-select-box"
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        >
          {selectTitle(
            Module === "PostContract" ? costreportcommentary : commentary
          )}
        </select>
      </div>
      <div className={showHideTitleInput}>
        <label className="label-title-input">{translate("Enter Title")}:</label>
        <input
          className="input-custom-title"
          value={customTitle}
          onChange={(e) => {
            setCustomTitle(e.target.value);
          }}
        />
      </div>
      <TextEditor text={text} setText={setText} />
      <div className="general-row-container">
        <DisplayError error={error} />
      </div>
      <div className="general-button-container">
        <button
          className="general-upload-button"
          onClick={(e) => {
            const data = {
              title: title,
              customTitle: customTitle,
              project_id: project_id,
              project: project,
              stage: CP.stage,
              revision: CP.version,
              text: text,
              part: commentNumber,
              showTitle: showTitle,
              CPs: CPs,
              setShow: setShow,
              setError: setError,
              CP: CP,
              CostId: CostId,
              Module: Module,
            };
            postCommentary(data, URLs);
          }}
        >
          {translate("Save")}
        </button>
      </div>
    </div>
  );

  function selectTitle(titles) {
    return titles.map(function (thisTitle, i) {
      return (
        <option key={i} value={thisTitle}>
          {thisTitle}
        </option>
      );
    });
  }
}
