import React from "react";
import isEmpty from "../../validation/is-empty";
import Config from "./PostContractConfig.functions";
import PropTypes from "prop-types";

import "./PostContractTable.css";
import PostContractTable from "./PostContractTable";
import PostContractChart from "./PostContractChart";
import PostContractHorizontalBarChart from "./PostContractHorizontalBarChart";

import PostContractLineChart from "./PostContractLineChart";
import CostVsBudget from "../charts/CostVsBudget";
import PostContractDonutChart from "./PostContractDonutChart";
import PostContractTimeBar from "./PostContractTimeBar";
import PostContractPaymentDetails from "./PostContractPaymentDetails";

PostContractComponent.propTypes = {
  postContract: PropTypes.object,
  sectionType: PropTypes.object,
  componentName: PropTypes.string,
  componentType: PropTypes.object,
  selectedReport: PropTypes.object,
};

export default function PostContractComponent(props) {
  const { postContract } = props;
  const { sectionType } = props;
  const { componentName } = props;
  const { componentType } = props;
  const { selectedReport } = props;

  const report = postContract?.data?.find(
    (p) => p.PostContractId === selectedReport.PostContractId
  );
  const config = Config().find((c) => c.ComponentName === componentName);

  if (isEmpty(report)) {
    return null;
  }

  const selectedSection = report?.Sections.find(
    (d) => d?.SectionType === sectionType
  );

  const component = selectedSection?.[componentType]?.find(
    (t) => t?.ComponentName === componentName
  );

  if (component == null) {
    return null;
  }
  if (componentType === "TableComponents") {
    return (
      <PostContractTable component={component} config={config} {...props} />
    );
  }

  if (componentType === "BarCharts") {
    return (
      <PostContractChart component={component} config={config} {...props} />
    );
  }
  if (componentType === "HorizontalBarCharts") {
    return (
      <PostContractHorizontalBarChart
        component={component}
        config={config}
        {...props}
      />
    );
  }
  if (componentType === "LineCharts") {
    return (
      <PostContractLineChart component={component} config={config} {...props} />
    );
  }
  if (componentType === "CostBudgetCharts") {
    return (
      <CostVsBudget
        title={component?.Heading}
        firstValue={component?.FirstValue}
        secondValue={component?.SecondValue}
        secondText={component?.SecondText}
        firstText={component?.FirstText}
        differenceText={component?.DifferenceText}
        differenceValue={component?.DifferenceValue}
        isApproved={true}
      />
    );
  }

  if (componentType === "DonutCharts") {
    return (
      <PostContractDonutChart
        data={component?.Data}
        width={200}
        height={200}
        eRadius={90}
        iRadius={70}
        lWidth={270}
        lHeight={150}
      />
    );
  }

  if (componentType === "TimeBarComponents") {
    return (
      <PostContractTimeBar
        currentMonth={component?.CurrentMonth}
        totalMonths={component?.TotalMonths}
      />
    );
  }

  if (componentType === "ReportDetailsCharts") {
    return <PostContractPaymentDetails Details={component?.Details} />;
  }

  return <div>No Component</div>;
}
