import React from "react";
import { hasRoles } from "../../utils/roles";
import isEmpty from "../../validation/is-empty";
import PropTypes from "prop-types";

import { PCRA_MARKUP } from "../pcra/PCRAGlossary.constants";

import PCRALogModalTrigger from "../pcra/PCRALogModalTrigger";
import PostContractPCRALogModal from "./PostContractPCRALogModal";

PostContractPCRAModalTrigger.propTypes = {
  project: PropTypes.object,
  postContract: PropTypes.object,
  selectedReport: PropTypes.object,
  user: PropTypes.object,
};

export default function PostContractPCRAModalTrigger(props) {
  const { project } = props;
  const { postContract } = props;
  const { selectedReport } = props;
  const { user } = props;

  const isUserCostManager = hasRoles(user.roles, ["CostManager"]);
  const isUserClient = hasRoles(user.roles, ["Client"]);

  const isActionable =
    project.project_phase === "POST_CONTRACT" && isUserCostManager;

  const isLocked = !isActionable;
  const lockedReason = determineLockedReason({
    isLocked,
    isUserClient,
    project,
  });

  return (
    <>
      {!isEmpty(selectedReport) && (
        <PCRALogModalTrigger
          modalComponent={PostContractPCRALogModal}
          projectID={project.id}
          project={project}
          postContract={postContract}
          selectedReport={selectedReport}
          user={user}
          isLocked={isLocked}
          lockedReason={lockedReason}
        />
      )}
    </>
  );
}

function determineLockedReason({ isLocked, isUserClient, project }) {
  if (isUserClient) {
    return PCRA_MARKUP.LOCKED_REASONS.QA_LOG_LOCKED_FOR_CLIENT;
  }

  if (project.project_phase === "POST_CONTRACT") {
    return PCRA_MARKUP.LOCKED_REASONS
      .PREV_POST_CONTRACT_REPORT_LOCKED_SINCE_NEWER_REPORT_UPLOADED;
  } else {
    return PCRA_MARKUP.LOCKED_REASONS
      .POST_CONTRACT_QA_LOG_LOCKED_WHEN_PROJECT_PHASE_NOT_IN_POST_CONTRACT;
  }
}
