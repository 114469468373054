import isEmpty from "../../validation/is-empty";
import { commaToFixed } from "../../helpers/common.functions";

export function validateDate(value) {
  let date = {
    day: new Date(value).toLocaleString("default", {
      day: "numeric",
    }),
    month: new Date(value).toLocaleString("default", {
      month: "short",
    }),
    year: new Date(value).toLocaleString("default", {
      year: "numeric",
    }),
  };

  if (value === 0 || isEmpty(value)) {
    return "";
  }

  //If the date returned is of date type
  if (value.toString().indexOf("-") > -1) {
    return new Date(value).toString().substring(4, 15);
  }
  return date.day + " " + date.month + " " + date.year;
}

export function validatePercent(value) {
  if (isEmpty(value) || isNaN(value)) {
    return "";
  }

  return commaToFixed(value) + "%";
}

export function validateCurrency(value) {
  if (isEmpty(value) || isNaN(value)) {
    return "";
  }

  return commaToFixed(value);
}
