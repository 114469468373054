import validateFilter from "../../validation/filtering";
import isEmpty from "../../validation/is-empty";

export function accountFilter(array, filter) {
  if (isEmpty(array)) {
    return [];
  }

  return array.filter((a) => {
    const name = validateFilter(a.name);
    const surname = validateFilter(a.surname);
    const jobTitle = validateFilter(a.job_title);
    const regionCountryCity =
      validateFilter(a.city) +
      validateFilter(a.country) +
      validateFilter(a.region);

    // The Search Filter
    if (
      name.includes(filter.name.toLowerCase()) &&
      surname.includes(filter.surname.toLowerCase()) &&
      jobTitle.includes(filter.jobTitle.toLowerCase()) &&
      regionCountryCity.includes(filter.regionCountryCity.toLowerCase())
    ) {
      return a;
    } else {
      return null;
    }
  });
}
