import React from "react";

import { comma } from "../../helpers/common.functions";
import { Bar } from "react-chartjs-2";
import { tranStr } from "../../utils/translation";

export default function CostPlanComparisonChart(props) {
  const { project } = props;
  const { estimateBreakups } = props;
  const { height } = props;
  const { field } = props;
  const { budget } = props;

  let dataSetArray = [];

  let heritage = "rgba(228, 97, 15, 1)";
  let aqua = "rgba(50, 98, 149, 1)";
  let collaboration1 = "rgba(85, 87, 90, 1)";
  let aqua2 = "rgba(50, 98, 149, 0.7)";
  let collaboration12 = "rgba(85, 87, 90, 0.7)";

  let colors = [heritage, collaboration1, aqua, collaboration12, aqua2];

  estimateBreakups.forEach((element, i) => {
    if (element.value.every((e) => e >-1)) {
      dataSetArray.push({
        label: element.field,
        data: element.value,
        barThickness: 15,
        backgroundColor: colors[i % colors.length],
      });
    }
  });

  dataSetArray.push({
    label: tranStr("Budget (Line)"),
    data: budget,

    backgroundColor: "gray",
    borderColor: "gray",
    borderWidth: 3,
    type: "line",
    fill: false,
  });

  dataSetArray.reverse();

  // Create the graph object
  let graph = {
    labels: field,
    datasets: dataSetArray,
    text: field,
  };

  // Create the options object
  let options = {
    animation: {
      duration: 3000,
    },
    plugins: { datalabels: { display: false } },
    tooltips: {
      enabled: true,
      callbacks: {
        label: function (tooltipItem, chart) {
          return (
            project.currency_symbol +
            comma(Math.round(tooltipItem.yLabel)) +
            " (" +
            chart.datasets[tooltipItem.datasetIndex].label +
            ")"
          );
        },
      },
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 28.8,
        bottom: 5,
      },
    },
    scales: {
      xAxes: [
        {
          stacked: true,
        },
      ],
      yAxes: [
        {
          position: "left",
          stacked: true,
          ticks: {
            callback: function (value, index, values) {
              return project.currency_symbol + comma(Math.round(value));
            },
          },
        },
      ],
    },

    legend: {
      display: true,
      position: "right",
      fullWidth: true,
      reverse: true,
    },
  };

  return <Bar data={graph} options={options} height={height} />;
}
