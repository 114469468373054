import React, { Component } from "react";
import browserDetector from "../browser/BrowserDetector";
import brand2 from "../../img/ArcadisClarity02.png";
import "./Information.css";

// Add redux:
// PropTypes - we are mapping our state to props ( meaning
// we are going to have a property) and if you have properties
// in your components you should map them to PropTypes
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

class Landing extends Component {
  // Lifecycle Methods
  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/home/");
    }

    if (browserDetector().unsupported) {
      this.props.history.push("/browser");
    }
  }

  render() {
    return (
      <div className="information-background">
        <div className="information-content">
          <div className="information-container">
            <div className="information-box3">
              <img className="information-brand" src={brand2} alt="" />
            </div>
            <Blurb />
          </div>
          <div className="information-container">
            <EmbeddedVideo />
            <EmbeddedVideo2 />
          </div>
        </div>
      </div>
    );
  }
}
function EmbeddedVideo() {
  return (
    <div className="information-box2">
      <div className="information-video-container">
        <iframe
          src="https://www.youtube.com/embed/UkuznZkPnGc"
          frameBorder="0"
          width="600px"
          height="338px"
          allow="autoplay; encrypted-media"
          allowFullScreen
          title="video"
        />
      </div>
    </div>
  );
}

function EmbeddedVideo2() {
  return (
    <div className="information-box2">
      <div className="information-video-container">
        <iframe
          src="https://www.youtube.com/embed/_qOGOQbmsa4"
          frameBorder="0"
          width="600px"
          height="338px"
          allow="autoplay; encrypted-media"
          allowFullScreen
          title="video"
        />
      </div>
    </div>
  );
}

function Blurb() {
  return (
    <div className="information-box">
      <div className="blurb-text-container">
        <div className="left-quote">
          <i className="fas fa-quote-left" />
        </div>
        <div className="quote-text">
          <h1 className="display-4 text-left" style={{ fontSize: "25px" }}>
            <p>
              <b>Cost Clarity</b> represents a quantum shift in the delivery of
              cost management services.{" "}
            </p>

            <p>
              With client experience built into its core, <b>Cost Clarity</b>{" "}
              will give you instantaneous access to your project cost data. It
              will challenge and improve project performance by generating
              advanced analytics and insights providing you with an elevated
              understanding of the cost impact of your decisions.
            </p>
            <div className="right-quote">
              <i className="fas fa-quote-right" />
            </div>
          </h1>
        </div>
      </div>
    </div>
  );
}

Landing.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Landing);
