import React, { useState } from "react";
import cloud from "../../img/baseline-cloud_upload-24px.svg";
import { randomNumberGenerator } from "../../helpers/common.functions";
import { onSubmit } from "./BenchmarkUpload.functions";
import { useCbsCodes } from "../../api/cbs/CbsAPI";
import { ModalCompany } from "../project-create/CreateProject.components";
import CreateProjectSelectClientCompany from "../project-create/CreateProjectSelectClientCompany";

import "./BenchmarkUpload.css";
import "../dropzone/Dropzone.css";
import { useSensitivityLevelCodes } from "../../api/benchmarking/BenchmarkingAPI";
import { useCompanies } from "../../api/projects/ProjectCreateAPI";
import { ProjectLocation } from "./Benchmark.components";

export default function BenchmarkUpload(props) {
  const { setShow } = props;
  const { setBenchmarking } = props;

  const isNotPublished = false;

  const { cbsCodes } = useCbsCodes(isNotPublished);
  const { companies } = useCompanies(true);

  const [file, setFile] = useState([]);
  const [filePreview, setFilePreview] = useState([]);
  const [messages, setMessages] = useState([]);
  const [highlight, setHighlight] = useState(false);
  const [CBS, setCBS] = useState("");
  const [sourceType, setSourceType] = useState("");
  const [measurementSystem, setMeasurementSystem] = useState("");
  const [infoSensLevel, setInfoSensLevel] = useState("");
  const [coordinates, setCoordinates] = useState({});

  const { sensitivityLevelCodes } = useSensitivityLevelCodes();

  const [companyId, setCompanyId] = useState("");
  const [company, setCompany] = useState({});

  const [error, setError] = useState({});

  // To reset File Input
  const [inputKey, setInputKey] = useState(0);

  let data = {
    file: file,
    CBS: CBS,
    sourceType: sourceType,
    measurementSystem: measurementSystem,
    setModal: setShow,
    setBenchmarking: setBenchmarking,
    setError: setError,
    setFile: setFile,
    setMessages: setMessages,
    setFilePreview: setFilePreview,
    informationSensitivityLevel: infoSensLevel,
    companyId: companyId === "" ? null : companyId,
    coordinates: coordinates,
  };

  const cbsOptions = [{ label: "Select CBS", value: "" }];
  cbsCodes?.data?.forEach((c) => {
    cbsOptions.push({ value: c, label: c });
  });

  const secLevelOptions = [{ label: "Select Sensitivity Level", value: "" }];
  sensitivityLevelCodes?.data?.forEach((c) => {
    secLevelOptions.push({ value: c.value, label: c.label });
  });

  const clientToBeMappedOptions = [{ label: "Select Client", value: "" }];
  if (companies.data.length > 0)
    companies?.data?.forEach((c) => {
      clientToBeMappedOptions.push({ value: c.id, label: c.name });
    });

  // Add a random query value to prevent caching
  const randomQueryValue = "?" + Date.now();

  return (
    <div className="benchmark-upload">
      <div className="benchmark-upload-control-container">
        <Upload
          inputKey={inputKey}
          setInputKey={setInputKey}
          setFile={setFile}
          setFilePreview={setFilePreview}
          setHighlight={setHighlight}
          highlight={highlight}
          setError={setError}
          setMessages={setMessages}
        />
        <div className="benchmark-upload-control">
          <div className="benchmark-upload-control-row">
            <div className="benchmark-upload-control-label">
              Benchmarking Guide:
            </div>
            <a
              className="benchmark-upload-document-button"
              href={
                "https://stcostclarityprd001.blob.core.windows.net/1-guides/Benchmarking Guide.pdf" +
                randomQueryValue
              }
              target="_blank"
              rel="noopener noreferrer"
              download
            >
              Download
            </a>
          </div>
          <div className="benchmark-upload-control-row">
            <div className="benchmark-upload-control-label">
              Benchmarking Template:
            </div>
            <a
              className="benchmark-upload-document-button"
              href={
                "https://stcostclarityprd001.blob.core.windows.net/1-guides/Global CCM Data Capture Sheet.xlsm" +
                randomQueryValue
              }
              target="_blank"
              rel="noopener noreferrer"
              download
            >
              Download
            </a>
          </div>
          <div className="benchmark-upload-control-row">
            <div className="benchmark-upload-control-label">
              Cost Breakdown Structure:
            </div>
            <select
              value={CBS}
              className="benchmark-upload-control-input"
              onChange={(e) => {
                setCBS(e.target.value);
              }}
            >
              {selectType(cbsOptions)}
            </select>
          </div>
          <div className="benchmark-upload-control-row">
            <div className="benchmark-upload-control-label">Source Type:</div>
            <select
              value={sourceType}
              className="benchmark-upload-control-input"
              onChange={(e) => {
                setSourceType(e.target.value);
              }}
            >
              {selectType(sourceTypes())}
            </select>
          </div>
          <div className="benchmark-upload-control-row">
            <div className="benchmark-upload-control-label">
              Measurement Standard:
            </div>
            <select
              value={measurementSystem}
              className="benchmark-upload-control-input"
              onChange={(e) => {
                setMeasurementSystem(e.target.value);
              }}
            >
              {selectType(measurementSystems())}
            </select>
          </div>
          <div className="benchmark-upload-control-row">
            <div className="benchmark-upload-control-label">
              Information Sensitivity Level:
            </div>
            <select
              value={infoSensLevel}
              className="benchmark-upload-control-input"
              onChange={(e) => {
                setInfoSensLevel(e.target.value);
              }}
            >
              {selectType(secLevelOptions)}
            </select>
          </div>
          {
            <div className="benchmark-upload-control-row">
              <div className="benchmark-upload-control-label">
                Client / Company:
              </div>

              <ModalCompany
                Component={CreateProjectSelectClientCompany}
                setFunction={(company) => {
                  setCompany(company);
                  setCompanyId(company.id);
                }}
                label={<i className="fas fa-building"></i>}
                company={company}
                error={""}
                title={"Client Company"}
                isProjectCreation={true}
              />
            </div>
          }
          <ProjectLocation setCoordinates={setCoordinates} />
        </div>
      </div>

      <div className="benchmark-upload-button-container">
        <FileTray files={filePreview} messages={messages} />
      </div>
      <div className="general-row-container">
        <DisplayError error={error} setError={setError} />
      </div>
      <div className="general-button-container">
        <button
          className="general-upload-button"
          onClick={() => {
            setMessages([]);
            onSubmit(data);
          }}
        >
          Upload
        </button>
      </div>
    </div>
  );
}

function DisplayError(props) {
  const { error } = props;
  return (
    <div className="benchmark-upload-error-container">
      <div className={error.type}>
        <b>{error.text}</b>
      </div>
    </div>
  );
}

export function Upload(props) {
  const { setFile, setFilePreview } = props;
  const { highlight, setHighlight } = props;
  const { setError, setMessages } = props;
  const { inputKey, setInputKey } = props; // Change to reset the File Input

  let classType = "benchmark-upload-drop";
  if (highlight) {
    classType = "benchmark-upload-drop-highlight";
  }

  return (
    <label
      className={classType}
      onDrop={(e) => {
        e.preventDefault();
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
          let newFiles = e.dataTransfer.files;
          setMessages([]);
          setError({});
          setFile((files) => {
            return handleFiles(files, newFiles);
          });
          setFilePreview((files) => {
            return handleFiles(files, newFiles);
          });
        }
        setHighlight(false);
      }}
      onDragOver={(e) => {
        e.preventDefault();
        setHighlight(true);
      }}
      onDragLeave={(e) => {
        e.preventDefault();
        setHighlight(false);
      }}
    >
      <input
        key={inputKey}
        type="file"
        multiple
        className="benchmark-upload-button"
        onChange={(e) => {
          e.preventDefault();
          setInputKey(randomNumberGenerator(0, 1).toString(36));
          if (e.target.files && e.target.files.length > 0) {
            let newFiles = e.target.files;
            setMessages([]);
            setError({});
            setFile((files) => {
              return handleFiles(files, newFiles);
            });
            setFilePreview((files) => {
              return handleFiles(files, newFiles);
            });
          }
        }}
      />
      <img alt="upload" className="Icon" src={cloud} />
    </label>
  );
}

function handleFiles(files, newFiles) {
  let oldFiles = [...files];
  for (let file of newFiles) {
    oldFiles.push(file);
  }
  return oldFiles;
}

function FileTray(props) {
  const { files } = props;
  const { messages } = props;
  let display = [];
  files.forEach((file, i) => {
    display.push(
      <FileTrayRow key={i} i={i} file={file} message={messages[i]} />
    );
  });

  return <div className="benchmark-upload-files">{display}</div>;
}

function FileTrayRow(props) {
  const { file } = props;
  const { message } = props;
  const { i } = props;
  let messageClass = "benchmark-upload-message";

  if (message) {
    if (message.status === "rejected") {
      messageClass = "benchmark-upload-message-error";
    }
  }

  return (
    <div className="benchmark-upload-files-item">
      <b>
        <div className="benchmark-upload-files-part">
          {`Benchmark ${i + 1}`}
        </div>
      </b>
      <div className="benchmark-upload-files-name">{file.name}</div>
      {message && message.reason && (
        <div className={messageClass}>{message.reason.message}</div>
      )}
    </div>
  );
}

export function selectType(array) {
  return array?.map((item, i) => {
    return (
      <option key={i} value={item.value}>
        {item.label}
      </option>
    );
  });
}

function sourceTypes() {
  return [
    { label: "Select Source", value: "" },

    {
      label: "Elemental Analysis (Complete)",
      value: "Elemental Analysis (Complete)",
    },
    {
      label: "Elemental Analysis (Estimate Only)",
      value: "Elemental Analysis (Estimate Only)",
    },
  ];
}

function measurementSystems() {
  return [
    { label: "Select System", value: "" },

    {
      label: "Metric",
      value: "METRIC",
    },
    {
      label: "Imperial",
      value: "IMPERIAL",
    },
  ];
}
