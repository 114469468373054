import isEmpty from "../../validation/is-empty";
import { getYearFromDate } from "./EstimatesTPI";

export function getIsSynchronizedTPI(CP) {
  // if every estimate has estimate.is_synchronized_tpi = true, then set global is_synchronized_tpi = true (aka isLinkedTPI)
  if (isEmpty(CP) || isEmpty(CP.estimates)) {
    return false;
  }

  for (const estimate of CP.estimates) {
    if (!estimate?.is_synchronized_tpi) {
      return false;
    }
  }
  return true;
}

export function getSynchronizedTPI(estimate) {
  if (
    estimate?.is_synchronized_tpi &&
    !isEmpty(estimate?.escalation_percentage) &&
    !isEmpty(estimate?.escalation_date)
  ) {
    let escalationDate = getYearFromDate(parseInt(estimate?.escalation_date));
    return {
      escalationDate: escalationDate,
      escalationPercentage: estimate?.escalation_percentage,
    };
  }
}
