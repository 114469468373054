import React from "react";
import CreateBenchmarkForm from "./CreateBenchmarkForm";
import isEmpty from "../../validation/is-empty";

export default function CreateBenchmarkQuick(props) {
  const { project } = props;
  const { CP } = props;
  const { estimate } = props;
  const { user } = props;
  const { mappingFunction, exclusiveProjectId } = props;
  const { url } = props;
  const { benchmarkSource } = props;
  const { rowsFormIsEnabled, metricsFormIsEnabled } = props;
  const { setShow } = props;

  if (isEmpty(project) || isEmpty(CP) || isEmpty(estimate)) {
    return null;
  }

  const { benchmark } = estimate;
  let rows = benchmark.rows;
  let metrics = benchmark.metrics;

  let parsedDate = new Date(estimate.date);

  let day = parsedDate.toLocaleString("default", { day: "numeric" });
  let month = parsedDate.toLocaleString("default", { month: "long" });
  let year = parsedDate.toLocaleString("default", { year: "numeric" });

  return (
    <CreateBenchmarkForm
      // Benchmark Fields
      benchmarkName={benchmark.project_name_short}
      benchmarkClient={project.company_name}
      benchmarkDate={day + " " + month + " " + year}
      benchmarkArea={benchmark.local_region_area}
      benchmarkUnit={project.unit_of_measure}
      benchmarkConCost={Math.round(benchmark.construction_cost)}
      benchmarkQuality={benchmark.quality}
      benchmarkSector={benchmark.sector}
      benchmarkSubsector={benchmark.sub_sector}
      benchmarkProcurement={benchmark.procurement_method}
      benchmarkProjectType={benchmark.project_type}
      benchmarkStandard={benchmark.standard}
      benchmarkCurrency={benchmark.currency}
      benchmarkMeasurementSystem={benchmark.measurement_system}
      benchmarkContactEmail={user.email}
      benchmarkSource={benchmarkSource}
      // Location Data
      benchmarkRegion={benchmark.region}
      benchmarkCountry={benchmark.country}
      benchmarkCity={benchmark.city}
      benchmarkTPICity={benchmark.tpi_city}
      // Benchmark Rows
      benchmarkRows={rows}
      // Benchmark Metrics
      benchmarkMetrics={metrics}
      // General Data
      estimateId={estimate.id}
      projectId={project.id}
      costPlanId={CP.cost_plan_id}
      url={url}
      mappingFunction={mappingFunction}
      exclusiveProjectId={exclusiveProjectId}
      // Cost Breakdown Structure for Elemental Analysis ("Rows")
      benchmark={benchmark}
      // Enabled Forms
      detailsFormIsEnabled={true}
      rowsFormIsEnabled={rowsFormIsEnabled}
      metricsFormIsEnabled={metricsFormIsEnabled}
      setShow={setShow}
    />
  );
}
