import { useState, useEffect } from "react";
import { authHeader } from "../../_helpers/auth-header";
import { sharedAPIs } from "../api-endpoints/SharedServiceEndpoints";
import isEmpty from "../../validation/is-empty";

// Get a list of locations
export async function getLocations() {
  const url = sharedAPIs().get_locations;

  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let erMessage = "Get Locations error";

  let response = await fetch(url, config);

  if (response.ok) {
    response = await response.json();
  } else {
    response = [];
    console.log(erMessage);
  }
  return response;
}

export function useAccountLocations() {
  const [locationsLoading, setLoading] = useState(true);
  const [locations, setLocations] = useState([]);
  useEffect(() => {
    setLoading(true);
    getLocations()
      .then((locationsRes) => {
        setLocations(locationsRes);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  return {
    locationsLoading,
    locations,
    setLocations,
    error: null,
  };
}

export async function saveLocations(
  displayLocations,
  setLocations,
  setLoading,
  setSubmitted,
  setError
) {
  const payload = {
    displayLocations: displayLocations,
  };

  const url = sharedAPIs().save_locations;

  const config = {
    method: "POST",
    body: JSON.stringify(payload),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  return await fetch(url, config)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response was not ok.");
    })
    .then((result) => {
      setLoading(false);
      setSubmitted(true);
      setError(false);
      setLocations(result);
    })
    .catch((error) => {
      console.log("Error", error);
      setLoading(false);
      setSubmitted(false);
      setError(true);
    });
}

// GET LOCATIONS
export function useLocations(show) {
  const [loading, setLoading] = useState(true);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    if (show) {
      setLoading(true);
      getLocations()
        .then((locationsRes) => {
          const _locations = getTrimmedLocationData(locationsRes);
          setLocations(_locations);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [show]);

  return {
    locations: {
      data: locations,
      loading: loading,
      get: getLocations,
      set: setLocations,
    },
  };
}

// clean up spaces before and after region / country / city names
function getTrimmedLocationData(locations) {
  return locations.map((location) => {
    let { region, country, city, ...rest } = location;

    region = region?.trim();
    country = country?.trim();
    city = city?.trim();

    return { region, country, city, ...rest };
  });
}

// GET LOCATIONS
export function useLocationFactors() {
  const [loading, setLoading] = useState(true);
  const [locationFactors, setLocationFactors] = useState([]);

  useEffect(() => {
    setLoading(true);
    getLocationFactors()
      .then((locationsRes) => {
        setLocationFactors(locationsRes);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  return {
    locationFactors: {
      data: locationFactors,
      loading: loading,
      get: getLocationFactors,
      set: setLocationFactors,
    },
  };
}

function addFactorsToLocations(locations, locationFactors) {
  locationFactors.forEach((f) => {
    let location = locations.find(
      (x) =>
        x.region.trim() === f.region.trim() &&
        x.country.trim() === f.country.trim() &&
        x.city.trim() === f.city.trim()
    );
    if (!isEmpty(location)) {
      location.factor = f.factor;
      location.author = f.Name;
      location.time = f.Time;
      location.city = f.city.trim();
      location.globalfactor = f.globalfactor;
    }
  });

  return locations;
}

async function getLocationFactors() {
  const locations = await getLocations();
  const url = sharedAPIs().get_location_factors;

  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);
  if (response.ok) {
    response = await response.json();

    //add location factors
    const locationsWithFactors = addFactorsToLocations(locations, response);
    return locationsWithFactors;
  } else {
    return [];
  }
}
