import React from "react";
import { Bar } from "react-chartjs-2";
import { comma } from "../../helpers/common.functions";

export default function TotalValueChart(props) {
  const { graphData, graphLabels } = props;

  const graph = {
    labels: graphLabels,
    datasets: graphData,
  };
  // Create the options object
  let options = {
    animation: {
      duration: 30,
    },
    plugins: { datalabels: { display: false } },
    maintainAspectRatio: false,
    tooltips: {
      enabled: true,
      callbacks: {
        label: function (tooltipItem, chart) {
          return comma(Math.round(tooltipItem.yLabel));
        },
      },
    },
    scales: {
      yAxes: [
        {
          position: "left",
          stacked: true,
          ticks: {
            callback: function (value, index, values) {
              return comma(Math.round(value));
            },
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
        },
      ],
    },

    legend: {
      display: true,
      position: "right",
      fullWidth: true,
      reverse: false,
    },
  };
  return (
    <div>
      <Bar data={graph} options={options} />
    </div>
  );
}
