import isEmpty from "../validation/is-empty";

export const BA_ROLE = ["BenchmarkAdmin"];
export const BA_CBS_ROLE = ["BenchmarkAdmin", "CbsAdmin"];
export const BA_CBS_CM_ROLE = ["BenchmarkAdmin", "CbsAdmin", "CostManager"];

// Returns boolean if user has a role
export function hasRoles(roles, challenge) {
  if (isEmpty(roles)) {
    return false;
  }

  return roles.some((r) => challenge.includes(r));
}

// Returns a label based on the role
export function accountRole(roles) {
  if (isEmpty(roles)) {
    return "";
  }

  if (roles.some((r) => r === "Client")) {
    return "Client";
  }

  if (roles.some((r) => r === "Admin")) {
    return "Admin";
  }

  if (roles.some((r) => r === "CostManager")) {
    return "Cost Manager";
  }
}
