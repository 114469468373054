import React from "react";
import PropTypes from "prop-types";

import { HorizontalBar } from "react-chartjs-2";
import isEmpty from "../../validation/is-empty";
import { generateCostCategoryData } from "./ContractorOnCostComparisonChart.functions";

/// props validation | SQ(javascript:S6774)
ContractorOnCostComparisonChart.propTypes = {
  title: PropTypes.string,
  benchmarks: PropTypes.array,
  thisBenchmark: PropTypes.object,
  cbs: PropTypes.object,
};
///
export default function ContractorOnCostComparisonChart(props) {
  const { title } = props;
  const { benchmarks } = props;
  const { thisBenchmark } = props;
  const { cbs } = props;

  if (isEmpty(cbs.data)) {
    return null;
  }

  const CHART_COLORS = [
    "rgba(228, 97, 15, 1)",
    "rgba(50, 98, 149, 1)",
    "rgba(85, 87, 90, 1)",
    "rgb(13, 166, 66)",
    "rgb(85, 87, 90)",
    "rgb(228, 31, 19)",
    "rgb(167, 199, 231)",
    "rgb(93, 63, 211)",
  ];

  let theBenchmarks = [thisBenchmark];
  const allBenchmarks = theBenchmarks.concat(benchmarks);
  const { data, benchmarksTitles } = generateCostCategoryData(
    allBenchmarks,
    cbs
  );

  const list = data?.map((d, index) => {
    return {
      label: d.label,
      backgroundColor: CHART_COLORS[index],
      data: d.data,
      stack: 4,
      barThickness: 15,
    };
  });

  const chartOptions = {
    indexAxis: "y",

    tooltips: {
      enabled: true,
      xPadding: 10,
      yPadding: 10,
      callbacks: {
        label: function (tooltipItem) {
          return tooltipItem.xLabel + "%";
        },
      },
    },

    scales: {
      xAxes: [
        {
          ticks: {
            callback: function (tick, index, values) {
              return tick + "%";
            },
            stepSize: 2.5,
            beginAtZero: true,
          },
          stacked: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
        },
      ],
    },

    legend: {
      display: true,
      position: "bottom",
    },
  };

  return (
    <div className="benchmarking-chart-box-dashboard">
      <Title title={"Contractor On-Costs Comparison - " + title} />
      <div className="benchmarking-chart">
        <HorizontalBar
          data={{
            labels: benchmarksTitles,
            datasets: list,
          }}
          options={chartOptions}
        />
      </div>
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
Title.propTypes = {
  title: PropTypes.any,
};
///
function Title(props) {
  const { title } = props;
  return (
    <div className="benchmarking-on-cost-comparison-title">
      <h1
        className="display-4"
        style={{
          fontSize: "16px",
          marginBottom: "20px",
          marginTop: "10px",
          marginLeft: "10px",
        }}
      >
        {title}
      </h1>
    </div>
  );
}
