export function enabledSubTotals(subTotalSelections, options) {
  const subtotals = [];
  options.forEach((a) => {
    if (subTotalSelections.some((option) => option.value === a.value)) {
      subtotals.push({ rowID: parseFloat(a.value), isSelected: true });
    } else {
      subtotals.push({ rowID: parseFloat(a.value), isSelected: false });
    }
  });

  return subtotals;
}
