import {
  PORTFOLIO_TYPES,
  addPortfolio,
  getPortfolios,
  deletePortfolio,
} from "./PortfolioAPI";

export async function addPortfolioForBenchmarkAnalysis(
  creatorID,
  benchmarkAnalysisItem
) {
  // benchmarkAnalysisItem is an object of type BenchmarkCreateAnalysisPersistence
  const {
    title: portfolioName,
    selectedBenchmarkIds,
    configuration: details,
  } = benchmarkAnalysisItem;

  const projectIds = selectedBenchmarkIds.map((b) => b.benchmark_id);

  return await addPortfolio(
    portfolioName,
    creatorID,
    projectIds,
    details,
    PORTFOLIO_TYPES.BENCHMARK_ANALYSIS
  );
}

export async function getPortfoliosOfBenchmarkAnalysis() {
  return await getPortfolios(PORTFOLIO_TYPES.BENCHMARK_ANALYSIS);
}

export async function deletePortfolioOfBenchmarkAnalysis(
  benchmarkAnalysisItemId
) {
  return await deletePortfolio(benchmarkAnalysisItemId);
}
