import { authHeader } from "../../_helpers/auth-header";
import { contactUsApis } from "../../api/api-endpoints/FormDataEndpoints";
export async function deleteDemos(data) {
  let result = window.confirm(
    `This will permanently delete the ${data.demoIDs.length} selected. Confirm?`
  );

  if (!result) {
    return null;
  }

 const url=contactUsApis().delete_demo_request;
  const payload = {
    DemoRequestIds: data.demoIDs,
  };
  const config = {
    method: "DELETE",
    body: JSON.stringify(payload),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let erMessage = "Delete demos Error";
  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
  } else {
    console.log(erMessage);
  }

  data.demos.set(await data.demos.get());
}

export async function contactedDemos(data) {
 const url=contactUsApis().update_demo_contacted;
  const payload = {
    DemoRequestIds: data.demoIDs,
  };

  const config = {
    method: "POST",
    body: JSON.stringify(payload),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let erMessage = "Contacted demos Error";
  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
  } else {
    console.log(erMessage);
  }

  data.demos.set(await data.demos.get());
}

export async function completedDemos(data) {
  const url = contactUsApis().update_demo_completed;
  const payload = {
    DemoRequestIds: data.demoIDs,
  };

  const config = {
    method: "POST",
    body: JSON.stringify(payload),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let erMessage = "Update complete demos Error";
  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
  } else {
    console.log(erMessage);
  }

  data.demos.set(await data.demos.get());
}
