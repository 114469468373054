// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Components
import { TableHeadings } from "./TableHeadings";
import { TableBody } from "./TableBody";
import { TableTotal, TableFilteredTotal } from "./TableTotal";
import TablePagination from "./TablePagination";
import TableFilters from "./TableFilters";

// Functions
import { filterData } from "./Table.functions";

// Style
import "./Table.css";
import isEmpty from "../../validation/is-empty";

Table.propTypes = {
  tableArray: PropTypes.array,
  columns: PropTypes.array,
  title: PropTypes.string,
  isTitleDisabled: PropTypes.bool,
  isTotalDisabled: PropTypes.bool,
  isFilterDisabled: PropTypes.bool,
  isPaginationDisabled: PropTypes.bool,
  tableSize: PropTypes.number,
  // Selected Rows
  selectedRow: PropTypes.object,
  setSelectedRow: PropTypes.func,
  isClickToClose: PropTypes.bool,
  // Modals
  setModal: PropTypes.func,
  // Checkboxes
  checked: PropTypes.array,
  setChecked: PropTypes.func,
  setTableMenuModal: PropTypes.func,
};

export default function Table(props) {
  const { tableArray } = props;
  const { columns } = props;
  const { title } = props;
  const { tableSize } = props;

  const { isTitleDisabled } = props;
  const { isTotalDisabled } = props;
  const { isFilterDisabled } = props;
  const { isPaginationDisabled } = props;

  // Selected Rows
  const { selectedRow, setSelectedRow } = props;
  const { isClickToClose } = props;
  // Modals
  const { setModal } = props;
  const { setTableMenuModal } = props;
  // Checkboxes
  const { checked, setChecked } = props;

  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(tableSize || 10);
  const [filters, setFilters] = useState([]);

  let filterColumns = columns.filter((p) => p.isFiltered);
  let filteredData = filterData(tableArray, filters, filterColumns);

  // If the table data or filters change reset back to page 1
  useEffect(() => {
    setActivePage(1);
  }, [filters]);

  // Calculate start and end item indexes for the page
  let startIndex = (activePage - 1) * pageSize;
  // Multiplying by 1 to convert string to number
  let endIndex = Math.min(
    startIndex + pageSize * 1 - 1,
    filteredData.length - 1
  );

  let pageData = filteredData.slice(startIndex, endIndex + 1);

  return (
    <div className="pct-container">
      <div className="pct">
        {!isTitleDisabled && (
          <div className="pct-title">
            <div className="display-4">
              <b>{title}</b>
            </div>
          </div>
        )}
        <div className="pct-headings-container">
          <TableMenu setTableMenuModal={setTableMenuModal} />
          <TableHeadings columns={columns} />
          <TableFilters
            columns={columns}
            setFilters={setFilters}
            filters={filters}
            tableArray={tableArray}
            isFilterDisabled={isFilterDisabled}
          />
        </div>
        <TableBody
          pageSize={pageSize}
          columns={columns}
          tableArray={pageData}
          setModal={setModal}
          // Select Rows
          setSelectedRow={setSelectedRow}
          selectedRow={selectedRow}
          isClickToClose={isClickToClose}
          // Check Boxes
          checked={checked}
          setChecked={setChecked}
        />
        <TableFilteredTotal
          columns={columns}
          tableArray={filteredData}
          filters={filters}
          isTotalDisabled={isTotalDisabled}
        />
        <TableTotal
          columns={columns}
          tableArray={tableArray}
          isTotalDisabled={isTotalDisabled}
        />
      </div>
      {!isPaginationDisabled && (
        <div className="pct-pagination">
          <TablePagination
            activePage={activePage}
            setActivePage={setActivePage}
            totalItems={filteredData.length}
            pageSize={pageSize}
            setPageSize={setPageSize}
            pageData={pageData.length}
          />
          <div className="pct-button-spacer" />
        </div>
      )}
    </div>
  );
}
TableMenu.propTypes = {
  setTableMenuModal: PropTypes.func,
};
export function TableMenu(props) {
  const { setTableMenuModal } = props;

  if (isEmpty(setTableMenuModal)) return null;
  return (
    <button
      value="Menu"
      className="table-options-cog-menu"
      onClick={() => setTableMenuModal(true)}
    >
      <i className="fas fa-th"></i>
    </button>
  );
}


