// React
import React from "react";

// Components
import PortfolioButtons from "../portfolio-buttons/PortfolioButtons";
import PortfolioControlDropZone from "./PortfolioControlDropZone";
import PortfolioEdit from "../portfolio-edit/PortfolioEdit";

// Functions
import { hasRoles } from "../../utils/roles";

// Style
import "./PortfolioControl.css";

export default function PortfolioControl(props) {
  const { portfolios } = props;
  const { portfolioId } = props;
  const { dragID } = props;
  const { selectedPortfolio } = props;
  const { isDropEnabled, setIsDropEnabled } = props;
  const { isDragEnabled } = props;
  const { isEditEnabled, setIsEditEnabled } = props;
  const { user } = props;
  const { users } = props;
  const { setProjects } = props;

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  return (
    <div className="portfolio-control">
      <PortfolioButtons
        portfolioId={portfolioId}
        portfolios={portfolios}
        isDropEnabled={isDropEnabled}
        setIsDropEnabled={setIsDropEnabled}
        isEditEnabled={isEditEnabled}
        setIsEditEnabled={setIsEditEnabled}
        user={user}
      />
      <PortfolioControlDropZone
        portfolios={portfolios}
        isDropEnabled={isDropEnabled}
        setIsDropEnabled={setIsDropEnabled}
        dragID={dragID}
        selectedPortfolio={selectedPortfolio}
        isDragEnabled={isDragEnabled}
        setProjects={setProjects}
      />
      <PortfolioEdit
        portfolios={portfolios}
        selectedPortfolio={selectedPortfolio}
        isEditEnabled={isEditEnabled}
        setIsEditEnabled={setIsEditEnabled}
        users={users}
      />
    </div>
  );
}
