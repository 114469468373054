import React from "react";
import PropTypes from "prop-types";

import isEmpty from "../../validation/is-empty";

import "./BenchmarkCreateAnalysisDisclaimer.css";

/// props validation | SQ(javascript:S6774)
BenchmarkCreateAnalysisDisclaimer.propTypes = {
  disclaimerDisplay: PropTypes.object,
  show: PropTypes.bool,
};
///
export default function BenchmarkCreateAnalysisDisclaimer(props) {
  const { disclaimerDisplay } = props;
  const { show } = props;

  const { fields, fallbackValue } = disclaimerDisplay || {};

  if (isEmpty(fields) || !show) {
    return <></>;
  }

  return (
    <div className="benchmark-create-analysis-disclaimer">
      {fields.map(([label, value]) => (
        <div
          key={`disclaimerDisplayField_${label}`}
          className="benchmark-create-analysis-disclaimer-item"
        >
          <div className="label">{label}</div>
          <div className="value">{value || fallbackValue}</div>
        </div>
      ))}
    </div>
  );
}
