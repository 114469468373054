import React from "react";
import PropTypes from "prop-types";

import HorizontalLineChart from "../benchmarking-metrics/MetricsHorizontalLineChart";
import VerticalBarChart from "../benchmarking-metrics/MetricsVerticalBarChart";
import HorizontalBarChart from "../benchmarking-metrics/MetricsHorizontalBarChart";
import MetricsBubbleChart from "../benchmarking-metrics/MetricsBubbleChart";

/// props validation | SQ(javascript:S6774)
BenchmarkingMetricsChart.propTypes = {
  skipHighlight: PropTypes.bool,
  data: PropTypes.any,
  title: PropTypes.string,
  metric: PropTypes.object,
  code: PropTypes.string,
  currencySymbol: PropTypes.string,
};
///
export default function BenchmarkingMetricsChart(props) {
  const { skipHighlight } = props;

  const { data, title, metric, code } = props;
  const { currencySymbol } = props;

  if (code !== undefined && code[0] === "D") {
    switch (metric.unit) {
      // show MetricsBubbleChart for the following cases
      case "m2":
      case "m3":
      case "%":
        return (
          <MetricsBubbleChart
            skipHighlight={skipHighlight}
            data={data}
            metric={title}
          />
        );

      // show HorizontalLineChart for the following cases
      case "m":
        return (
          <HorizontalLineChart
            skipHighlight={skipHighlight}
            data={data}
            metric={title}
          />
        );

      // show HorizontalBarChart for the following cases and default
      case "Lvls":
      case "No.":
      default:
        return (
          <HorizontalBarChart
            skipHighlight={skipHighlight}
            data={data}
            metric={title}
          />
        );
    }
  }

  if (code === "C1.4" || code === "C2.3") {
    return (
      <VerticalBarChart
        skipHighlight={skipHighlight}
        data={data}
        metric={title}
        currencySymbol={currencySymbol}
      />
    );
  }

  // others
  return (
    <HorizontalBarChart
      skipHighlight={skipHighlight}
      data={data}
      metric={title}
    />
  );
}
