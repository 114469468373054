// React
import React from "react";
import PropTypes from "prop-types";

// Functions
import isEmpty from "../../validation/is-empty";

// Cost Plan Controls
import CostPlanningPCRALogModalTrigger from "../cost-planning-pcra/CostPlanningPCRALogModalTrigger";
import CostPlanningUploadModal from "../cost-planning/CostPlanningUploadModal";
import CostPlanningSubtotalModal from "../cost-planning/CostPlanningSubtotalModal";
import EstimateCommentaryModal from "../estimate-commentary/EstimateCommentaryModal";
import ModalProjectDrawingUploader from "../project-drawings/ProjectDrawingUploaderModal";
import ProjectBudgetModal from "../project-budget/ProjectBudgetModal";
import AreaScheduleUploadModal from "../cost-planning-area-schedules/AreaScheduleUploadModal";
import ValueEngineeringUploadModal from "../value-engineering/ValueEngineeringUploadModal";
import { cpAPIs } from "../../api/api-endpoints/CostPlanningEndpoints";
import ValueEngineeringDuplicateCP from "../value-engineering/ValueEngineeringDuplicateCP";

CostPlanningControl.propTypes = {
  user: PropTypes.object,
  project: PropTypes.object,
  CPs: PropTypes.object,
  CP: PropTypes.object,
};

export default function CostPlanningControl(props) {
  const { user } = props;
  const { project } = props;
  const { CPs } = props;
  const { CP } = props;
  const comparedCP = props;
  const comparedCPR = props;

  if (!window.location.href.includes("cost-planning")) {
    return null;
  }

  const commentNumber = processComments(CP);
  return (
    <div>
      <CostPlanningUploadModal
        user={user}
        project={project}
        CPs={CPs}
        CP={CP}
      />
      {
        <CostPlanningPCRALogModalTrigger
          user={user}
          project={project}
          CPs={CPs}
          CP={CP}
        />
      }
      <CostPlanningSubtotalModal
        user={user}
        project={project}
        CPs={CPs}
        CP={CP}
      />
      {project.project_phase === "COST_PLANNING" && !isEmpty(CP) && (
        <EstimateCommentaryModal
          user={user}
          project={project}
          CPs={CPs}
          CP={CP}
          commentNumber={commentNumber}
          URLs={{
            endpoint: cpAPIs,
            updateStateFunction: async () => {
              CPs.set(await CPs.get(project.id, project));
            },
          }}
          CostId={CP.cost_plan_id}
        />
      )}
      <ProjectBudgetModal
        user={user}
        project={project}
        CPs={CPs}
        CP={CP}
        comparedCP={comparedCP}
        comparedCPR={comparedCPR}
      />
      <ModalProjectDrawingUploader
        user={user}
        project={project}
        CPs={CPs}
        CP={CP}
      />
      <AreaScheduleUploadModal
        user={user}
        project={project}
        CPs={CPs}
        CP={CP}
      />
      <ValueEngineeringUploadModal
        user={user}
        project={project}
        CPs={CPs}
        CP={CP}
      />
      <ValueEngineeringDuplicateCP
        user={user}
        project={project}
        CPs={CPs}
        CP={CP}
      />
    </div>
  );
}

function processComments(CP) {
  // No Commentary so the Comment is number 1
  if (isEmpty(CP) || isEmpty(CP.commentary)) {
    return 1;
  }

  // The comment is the next in the list
  return CP.commentary.length + 1;
}
