export function generateProjects(array, pageSize, activePage) {
  const selection = [];

  // calculate start and end item indexes
  const startIndex = (activePage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize - 1, array.length - 1);

  for (let i = startIndex; i <= endIndex; i++) {
    selection.push(array[i]);
  }

  return selection;
}

export function combineProjects(
  projects,
  projectsCostPlanning,
  projectsPostContract
) {
  let newProjectArray = [];
  projects.forEach((project) => {
    let pCP = projectsCostPlanning.find(
      (p) => p.id.toString() === project.id.toString()
    );
    let pPC = projectsPostContract.find(
      (p) => p.id.toString() === project.id.toString()
    );
    let newProject = {
      ...project,
      ...pCP,
      ...pPC,
    };
    newProjectArray.push(newProject);
  });

  return newProjectArray;
}
