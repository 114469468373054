import React from "react";
import { HorizontalBar } from "react-chartjs-2";
import { comma } from "../../helpers/common.functions";
import { translate } from "../../utils/translation";

export default function ClusteredBarChart(props) {
  const { deltas } = props;
  const { project } = props;

  let field = [];
  let value = [];
  let delta = [];

  deltas.deltas.forEach((row) => {
    if (row !== undefined) {
      if (
        row.code !== "st" &&
        row.code !== "cc" &&
        row.code !== "pc" &&
        row.code !== "pct"
      ) {
        field.push(row.description);
        value.push(row.subtotal);
        delta.push(row.subtotal - row.delta);
      }
    }
  });

  let datasets = [];
  if (deltas.comparison_stage_name !== "") {
    datasets.push({
      label:
        deltas.comparison_stage_name + ", r. " + deltas.comparison_revision,
      data: delta,
      barThickness: 10,
      backgroundColor: "rgba(85, 87, 90, 0.7)",
    });
  }
  datasets.push({
    label: deltas.stage_name + ", r. " + deltas.revision,
    data: value,
    barThickness: 10,
    backgroundColor: "rgba(228, 97, 15, 1)",
  });
 
  // Create the graph object
  let graph = {
    labels: field,
    datasets: datasets,
    text: field,
  };

  // Create the options object
  let options = {
    animation: {
      duration: 3000,
    },
    plugins: { datalabels: { display: false } },
    tooltips: {
      enabled: true,
      callbacks: {
        label: function (tooltipItem, chart) {
          return project.currency_symbol + comma(tooltipItem.xLabel);
        },
      },
    },
    stacked: true,
    scales: {
      xAxes: [
        {
          position: "left",
          ticks: {
            callback: function (tick, index, values) {
              return project.currency_symbol + comma(tick);
            },
          },
        },
      ],
    },

    legend: {
      display: true,
      position: "top",
      fullWidth: true,
      reverse: false,
    },
  };

  return (
    <div className="recon-cost-comparison-graph">
      <h1 className="display-4" style={{ fontSize: "20px" }}>
        {translate("Cost Comparison")}
      </h1>
      <HorizontalBar
        data={graph}
        options={options}
        width={700}
        height={field.length * 40}
      />
    </div>
  );
}
