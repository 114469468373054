// React
import React from "react";
// Style
import "./AdminTranslation.css";

export function TranslationList(props) {
  // The syntax { value } is "destructuring" which is taking a variable out of an object (props)
  const { language } = props;
  const { setLanguage } = props;
  const keys = Object.keys(language); // This creates an array based on the key names of the object
  const display = []; // We can use an array of JSX elements as our display variable

  // Each key is going to be a "row" in the inputs
  keys.forEach((k, i) => {
    display.push(
      <tr key={i} className="admin-translation-row">
        {/* They key is the row label. We need to find a way to implement "sections" for readability */}
        <td className="admin-translation-label">{k}</td>
        <td>
          <input
            className="admin-translation-input"
            // Value at this key K is the value we will edit
            value={language[k]}
            onChange={(e) => {
              // Override the value in the object at key K with the entered text
              language[k] = e.target.value;
              // Create a copy of the object because the state is const
              // using combination of destructuring '{ }' and spreader '...' which gets all keys
              const updatedLanguage = { ...language };
              // Update the state with the new text
              setLanguage(updatedLanguage);
            }}
          />
        </td>
      </tr>
    );
  });

  //Return the array of JSX elements
  return (
    <div>
      <div className="admin-translation">
        <table id="test">
          <tbody>{display}</tbody>
        </table>
      </div>
    </div>
  );
}
