import React, { useState } from "react";
import { connect } from "react-redux";
import Modal from "../modal/Modal";
import { BA_CBS_ROLE, hasRoles } from "../../utils/roles";

import "./BenchmarkUploadModal.css";
import { FlagBenchmark } from "./Benchmark.components";
import { featureEnumerator } from "./Benchmark.functions";
import PropTypes from "prop-types";

export function ModalBenchmarkInfoFlag(prop) {
  const { selectedBenchmarks, setBenchmarking } = prop;
  const { user } = prop.auth;

  const [modal, setModal] = useState(false);
  const [feature, setFeature] = useState("");

  if (!hasRoles(user.roles, BA_CBS_ROLE)) {
    return null;
  }

  return (
    <div>
      <Modal
        // Modal Props
        title={getModalTitle(feature)}
        Component={FlagBenchmark}
        modal={modal}
        setModal={setModal}
        // Component Props
        setBenchmarking={setBenchmarking}
        selectedBenchmarks={selectedBenchmarks}
        setShow={setModal}
        feature={feature}
      />
      <div className="dropdown bm-vmenu-customized">
        <button className="drpbtn benchmark-control-upload-button">
          <i className="fas fa-ellipsis-v"></i>
        </button>
        <div className="dropdown-content dropdown-ellipse-content">
          <BenchmarkViewConfigButtons
            title="Set Information Flagging"
            buttonClassName="fas fa-flag"
            featureEnumerator={featureEnumerator.SENSITIVITY}
            setFeature={setFeature}
            setModal={setModal}
          />
          <BenchmarkViewConfigButtons
            title="Set Company"
            buttonClassName="fas fa-industry"
            featureEnumerator={featureEnumerator.COMPANY}
            setFeature={setFeature}
            setModal={setModal}
          />
          <BenchmarkViewConfigButtons
            title="Set Project Coordinates"
            buttonClassName="fas fa-map-marker-alt"
            featureEnumerator={featureEnumerator.COORDINATES}
            setFeature={setFeature}
            setModal={setModal}
          />
        </div>
      </div>
    </div>
  );
}
function getModalTitle(feature) {
  switch (feature) {
    case featureEnumerator.SENSITIVITY:
      return "Information Flagging";
    case featureEnumerator.COMPANY:
      return "Set Company";
    case featureEnumerator.COORDINATES:
      return "Set Project Coordinates";
    default:
      return "Modal";
  }
}
/// props validation | SQ(javascript:S6774)
BenchmarkViewConfigButtons.propTypes = {
  title: PropTypes.string,
  buttonClassName: PropTypes.string,
  featureEnumerator: PropTypes.number,
  setModal: PropTypes.func,
  setFeature: PropTypes.func,
};

function BenchmarkViewConfigButtons(props) {
  const { featureEnumerator, title, buttonClassName, setFeature, setModal } =
    props;
  return (
    <div
      aria-hidden="true"
      className="benchmark-control-upload-button"
      onClick={() => {
        setFeature(featureEnumerator);
        setModal(true);
      }}
      title={title}
    >
      <i className={buttonClassName} />
    </div>
  );
}

export default connect((state) => ({
  auth: state.auth,
}))(ModalBenchmarkInfoFlag);
