import React, { useState } from "react";

import isEmpty from "../../../validation/is-empty";
import { translate, tranStr } from "../../../utils/translation";
import "../OptionsAnalysisUploader.css";

export default function OptionsAnalysisBreakDownUploader(props) {
  const { data } = props;
  const { uploadFunction } = props;
  const [file, setFile] = useState(null);
  const [error, setError] = useState({});
  const { getOptions, setOptions, project } = props;

  data.file = file;
  data.setError = setError;
  data.error = error;
  data.getOptions = getOptions;
  data.setOptions = setOptions;
  data.project = project;

  return (
    <div className="pdu-container">
      <div className="general-row-container">
        <OptionsAnalysisSummaryInfo data={data} />
      </div>
      <div className="general-row-container">
        <OptionsAnalysisBreakdownUploaderFileTray file={file} />
      </div>
      <div className="general-row-container">
        <UploadOptionsAnalysisFile setFile={setFile} />
      </div>
      <div className="general-row-container">
        <OptionsAnalysisBreakdownUploadDataError
          error={error}
          setError={setError}
        />
      </div>
      <div className="general-button-container">
        <OptionsAnalysisBreakdownUploadData
          data={data}
          uploadFunction={uploadFunction}
        />
      </div>
    </div>
  );
}

function UploadOptionsAnalysisFile(props) {
  const { setFile } = props;

  return (
    <label className="general-modal-button">
      <input
        type="file"
        onChange={(e) => {
          if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0]);
          }
        }}
      />
      {translate("Attach File")}
    </label>
  );
}

function OptionsAnalysisBreakdownUploaderFileTray(props) {
  const { file } = props;
  let fileName = tranStr("No File Added");

  if (file !== null) {
    fileName = file.name;
  }

  return (
    <div className="pdu-file-display">
      <b>{translate("File")}:</b> {fileName}
    </div>
  );
}

function OptionsAnalysisBreakdownUploadData(props) {
  const { data } = props;
  const { uploadFunction } = props;

  return (
    <button
      className="general-upload-button"
      onClick={async () => {
        uploadFunction(data);
      }}
    >
      {translate("Upload")}
    </button>
  );
}

export function OptionsAnalysisBreakdownUploadDataError(props) {
  const { error } = props;
  return (
    <div className="pdu-error-container">
      <div className={error.type}>
        <b>{error.text}</b>
      </div>
    </div>
  );
}

function OptionsAnalysisSummaryInfo(props) {
  const { data } = props;

  if (isEmpty(data)) {
    return null;
  }
  return (
    <div className="cost-planning-upload-control-row">
      <div className="cost-planning-upload-control-label">
        {translate("Summary")}:
      </div>
      <div className="cost-planning-upload-control-label">
        {translate(data.selected.selected.summary)}
      </div>
    </div>
  );
}
