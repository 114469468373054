import React from "react";
import { comma, commaToFixed } from "../../helpers/common.functions";
import { translate } from "../../utils/translation";
import "./EstimatesTPI.css";

export default function EstimatesTPIAnticipatedCost(props) {
  // Props
  const { estimate, project } = props;
  const { futureYear, inflation } = props;
  const { containerType, descriptionType } = props;
  const { isBreakdown } = props;
  const { isDisabled = false } = props;

  let containerTypeTotal = isBreakdown
    ? "estimates-breakdown-TPI-container-total"
    : "estimates-TPI-container-total";

  let totalCost =
    estimate.project_cost > 0
      ? estimate.project_cost
      : estimate.construction_cost;

  let inflatedCostPerM2 = Math.round(
    (totalCost + inflation) / estimate.local_region_area
  );

  let totalCostPlusInflation = parseInt(Math.round(totalCost + inflation));

  const isRegionAreaZero = estimate.local_region_area === 0;

  return (
    <div className="estimates-TPI-row">
      <div className={isRegionAreaZero ? containerType : containerTypeTotal}>
        <div className={descriptionType}>
          <div
            className={`estimates-TPI-label ${isDisabled ? "disabled" : ""}`}
          >
            <b>{translate("Total Anticipated Cost (Excl. Tax)")}</b>
            {isDisabled ? (
              <></>
            ) : (
              <>
                &nbsp;@ <b>{futureYear}</b>
              </>
            )}
          </div>
        </div>
        <div className="estimates-TPI-value">
          {isRegionAreaZero ? (
            <></>
          ) : (
            <b>{comma(estimate.local_region_area)}</b>
          )}
        </div>
        <div className="estimates-TPI-unit">
          {isRegionAreaZero ? <></> : <b>{project.unit_of_measure}</b>}
        </div>
        <div className="estimates-TPI-construction-cost">
          {isRegionAreaZero ? <></> : <b>{commaToFixed(inflatedCostPerM2)}</b>}
        </div>

        <div className="estimates-TPI-total">
          {isDisabled ? <></> : <b>{comma(totalCostPlusInflation)}</b>}
        </div>
      </div>
    </div>
  );
}
