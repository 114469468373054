import React from "react";

import { msalConfig } from "../../active-directory/authConfig";

import { loginClient } from "../../actions/authActions";
import { B2CLoginRedirect } from "./ActiveDirectory.components";
import * as Msal from "@azure/msal-browser";
import { connect } from "react-redux";

function ActiveDirectory(props) {
  const { setLoading } = props;
  const { setStatus } = props;

  const loginClientConnect = props.loginClient;

  // MSAL.js v2 exposes several account APIs, logic to determine which account to use is the responsibility of the developer
  const myMSALObj = new Msal.PublicClientApplication(msalConfig());

  return (
    <B2CLoginRedirect
      myMSALObj={myMSALObj}
      setLoading={setLoading}
      setStatus={setStatus}
      loginClient={loginClientConnect}
    />
  );
}

export default connect(
  (state) => ({
    auth: state.auth,
  }),
  {
    loginClient,
  }
)(ActiveDirectory);
