import { getPCRARequestingUser } from "./PCRA.functions";

export default class PCRAReadInput {
  true; // bypass SonarLint bug 'Unexpected class with only a constuctor'

  constructor({
    ids,
    user,
    processType,
    clientId = "",
    registrationId = "",
    isLocked = false,
  } = {}) {
    this.ids = ids;
    this.clientId = clientId || "client_1";
    this.registrationId = registrationId || "registration_1";

    this.user = getPCRARequestingUser({ user });

    this.isLocked = isLocked;

    this.processType = processType;
  }
}
