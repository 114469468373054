import React from "react";
import isEmpty from "../../validation/is-empty";
import PropTypes from "prop-types";

import { Bar } from "react-chartjs-2";
import { postContractChartAdaptor } from "./PostContractAdaptor.functions";
import { translate } from "../../utils/translation";

PostContractChart.propTypes = {
  component: PropTypes.object,
  config: PropTypes.object,
};

export default function PostContractChart(props) {
  const { component, config } = props;

  if (isEmpty(component)) {
    return null;
  }

  const { data } = postContractChartAdaptor(component);

  return (
    <div>
      <Bar data={data} options={config?.OPTIONS()} height={200} />
      <Text component={component} />
    </div>
  );
}

Text.propTypes = {
  component: PropTypes.object,
};

function Text(props) {
  const { component } = props;
  if (!isEmpty(component.ChartData)) {
    return (
      <div className="pcct-percent-container">
        <div className="pcct-percent">
          <b>{component.ChartData.Value}</b>
        </div>
        <div className="pcct-text">{translate(component.ChartData.Text)}</div>
      </div>
    );
  } else return null;
}
