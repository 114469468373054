import { authHeader } from "../../_helpers/auth-header";
import { projectUpdate } from "../../api/projects/ProjectAPI";
import { projectAPIs } from "../../api/api-endpoints/ProjectEndpoints";
import { tranStr } from "../../utils/translation";
import { AutoCloseModal } from "../modal/Modal";
import { getDmsToken } from "../../active-directory/getDmsToken";
import isEmpty from "../../validation/is-empty";

export async function onSubmit(file, projectID, project, setError, setModal) {
  setError({
    text: tranStr("Saving..."),
    type: "feedback-success",
  });

  const payload = {
    project_id: projectID,
  };
  const formData = new FormData();

  try {
    formData.append("projectImage", file, file.name);
  } catch (e) {
    console.log(e.message);
    setError({
      text: "The project image was not changed.",
      type: "feedback-success",
    });
    projectUpdate(project);

    return AutoCloseModal(setModal);
  }
  formData.append("field", JSON.stringify(payload));

  const dmsToken = await getDmsToken();
  let dmsBearer = "";
  if (!isEmpty(dmsToken)) {
    dmsBearer = dmsToken.accessToken;
  }

  const url = projectAPIs().upload_project_image + "?projectId=" + projectID;
  const config = {
    method: "POST",
    body: formData,
    headers: {
      ...authHeader({ authJson: false, authForm: true, guestJson: false }),
      dmsBearer: `Bearer ${dmsBearer}`,
    },
  };

  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
    response = await response.json();
    setError({
      text: response.message,
      type: "feedback-success",
    });
    projectUpdate(project);

    AutoCloseModal(setModal);
  } else {
    try {
      response = await response.json();
      return setError({
        text: response.message,
        type: "feedback-success",
      });
    } catch (e) {
      return setError({
        text: "Connection Error",
        type: "feedback-error",
      });
    }
  }
}
