import { useState, useEffect } from "react";
import { authHeader } from "../../_helpers/auth-header";
import { projectAPIs } from "../api-endpoints/ProjectEndpoints";
import { getDmsToken } from "../../active-directory/getDmsToken";
import isEmpty from "../../validation/is-empty";

export function useImageUri(imageId, type) {
  const [loading, setLoading] = useState(true);
  const [imageUri, setImageUri] = useState({});

  useEffect(() => {
    if (imageId) {
      setLoading(true);
      setImageUri("");
      getImageUri(imageId, type)
        .then((res) => {
          setImageUri(res);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [imageId, type]);

  return {
    imageUri: {
      data: imageUri,
      loading: loading,
      get: getImageUri,
      set: setImageUri,
    },
  };
}

// Get all Projects available
export async function getImageUri(imageId, type) {
  const dmsToken = await getDmsToken();
  let dmsBearer = "";
  if (!isEmpty(dmsToken)) {
    dmsBearer = dmsToken.accessToken;
  }

  let url = "";
  if (type === "PROJECT") {
    url = projectAPIs().get_project_image + "?imageId=" + imageId;
  }

  const config = {
    method: "GET",
    headers: {
      ...authHeader({ authJson: true, authForm: false, guestJson: false }),
      dmsBearer: `Bearer ${dmsBearer}`,
    },
  };

  let response = await fetch(url, config);
  if (response.ok) {
    return response.json();
  } else {
    return "";
  }
}
