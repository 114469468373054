import React from "react";
import { HorizontalBar } from "react-chartjs-2";
import { comma } from "../../helpers/common.functions";
import { translate } from "../../utils/translation";

export default function DesignContingencyExpenditureChange(props) {
  const { estimate, estimates } = props;
  const { project } = props;

  let codesWithValues;
  let value = [];
  let field = [];
  let changes = [];

  // Now get the design development over time
  estimates.forEach((e) => {
    if (e.part === estimate.part) {
      e.changes.forEach((c) => {
        changes.push(c);
      });
    }
  });

  let codes = uniqueValuesFromObjectsArray(changes);
  codesWithValues = totalEachUniqueValueInObjectsArray(codes, changes);
  codesWithValues.sort((a, b) => a.code.localeCompare(b.code));
  codesWithValues.forEach((e) => {
    field.push(e.code);
    value.push(e.value);
  });

  let heritage = "rgba(228, 97, 15, 1)";
  let fluidity = "rgba(50, 98, 149, 1)";
  let collaboration1 = "rgba(85, 87, 90, 1)";
  let heritage2 = "rgba(228, 97, 15, 0.7)";
  let fluidity2 = "rgba(50, 98, 149, 0.7)";
  let collaboration2 = "rgba(85, 87, 90, 0.7)";

  let colorSchema = [
    fluidity,
    fluidity2,
    collaboration1,
    heritage2,
    collaboration2,
    heritage,
  ];

  // Create the graph object
  let graph = {
    labels: field,
    datasets: [
      {
        data: value,
        barThickness: 10,
        backgroundColor: colorSchema,
      },
    ],
    text: field,
  };

  // Create the options object
  let options = {
    animation: {
      duration: 3000,
    },
    plugins: { datalabels: { display: false } },
    maintainAspectRatio: true,
    tooltips: {
      enabled: true,
      callbacks: {
        label: function (tooltipItem, chart) {
          return project.currency_symbol + comma(tooltipItem.xLabel);
        },
      },
    },
    stacked: true,
    scales: {
      xAxes: [
        {
          position: "left",
          ticks: {
            callback: function (tick, index, values) {
              return project.currency_symbol + comma(tick);
            },
          },
        },
      ],
    },

    legend: {
      display: false,
      position: "right",
      fullWidth: true,
      reverse: false,
    },
  };

  return (
    <div className="recon-estimate-section-graph">
      <h1 className="display-4" style={{ fontSize: "20px" }}>
        {translate("Design Contingency Expenditure - Adjustment")}
      </h1>
      <HorizontalBar data={graph} options={options} />
    </div>
  );
}

function uniqueValuesFromObjectsArray(array) {
  return Array.from(new Set(array.map((s) => s.code))).map((code) => {
    return {
      code: array.find((s) => s.code === code).code,
    };
  });
}

function totalEachUniqueValueInObjectsArray(array, changes) {
  array.forEach((code) => {
    code.value = 0;
    changes.forEach((c) => {
      if (c.code === code.code) {
        code.value += c.value;
      }
    });
  });

  return array;
}
