import React, { useState } from "react";
import PropTypes from "prop-types";

import "./TableMenuOptions.css"
import { translate } from "../../utils/translation";
import isEmpty from "../../validation/is-empty";
TableMenuOptions.propTypes = {
  columns: PropTypes.array,
  selectedColumns: PropTypes.array,
  setSelectedColumns: PropTypes.func,
  configureSettings: PropTypes.object,
  tableDiscriminator: PropTypes.string,
  discriminator: PropTypes.string,
  moduleId: PropTypes.string,
};

export default function TableMenuOptions(props) {  
    const {columns}=props;  
    const {selectedColumns,setSelectedColumns}=props;
    const {configureSettings}=props;
    const {tableDiscriminator}=props;
    const {discriminator} =props;
    const {moduleId}=props;

    const [error, setError] = useState();

    const handleCheck = (event) => {
      let prevColumnsSelected = selectedColumns;
      if (event.target.checked) {
        prevColumnsSelected.push(event.target.value);
      } else {
        prevColumnsSelected.splice(
          prevColumnsSelected.indexOf(event.target.value),
          1
        );
      }
      setSelectedColumns(prevColumnsSelected);
      setError({type:"",text:""})
    };
    return (
      <div className="table-options-menu-container">
        <div className="table-options-menu-button">
          <div className="table-options-menu-body">
            <div className="table-options-menu-list">
              {columns.map(
                (item, index) =>
                  !isEmpty(item.heading) && (
                    <div key={item.key}>
                      <input
                        value={item.key}
                        type="checkbox"
                         onChange={handleCheck}
                        className="table-options-checkbox"
                        checked = {selectedColumns.indexOf(item.key) > -1}
                      />
                      <span className="checked-item">{item.heading}</span>
                    </div>
                  )
              )}
            </div>
            <div className="table-options-menu-footer">
              <button
                id="table_options_menu_save"
                className="table-options-menu-save"
                onClick={async () => {
                    setError({type:"table-menu-options-success",text:"Saving..."});
                    prepareUpdateObject(configureSettings,tableDiscriminator,selectedColumns,discriminator,moduleId,setError);
                  }}
              >
                {translate("Save")}
              </button>
              <DisplayError error={error} />
            </div>
          </div>
        </div>
      </div>
    );
}
DisplayError.propTypes = {
    error:PropTypes.object
}
function DisplayError(props) {
    const { error } = props;
    if(isEmpty(error))
      return null;
    
    return (
      <div className="table-menu-options-container">
        <div className={error.type}>
          <b>{error.text}</b>
        </div>
      </div>
    );
  }
  async function prepareUpdateObject(configureSettings,tableDiscriminator,selectedColumns,discriminator,moduleId,setError) {   
    let settings = configureSettings.configureSettings;
    await settings.save(
      moduleId,
      discriminator,
      {        
            layout:null,
            table: [{
                id: "",
                discriminator: tableDiscriminator,
                columns: selectedColumns,
              }],
      },
      setError
    );
  }

