import { authHeader } from "../../_helpers/auth-header";

export async function savePostContractDecisionsAndGlossary(fields, data, url) {
  const { projectID } = data;
  const { postContract } = data;
  const { postContractID } = data;
  const { type } = data;
  const { setError } = data;

  fields.forEach((f) => {
    f.post_contract_id = postContractID;
    f.type = type;
    delete f.id;
  });

  const payload = {
    projectID: projectID,
    decisions: fields,
    postContractID: postContractID,
    type: type,
  };
  const field = JSON.stringify(payload);
  const formData = new FormData();
  formData.append("field", field);

  const config = {
    method: "POST",
    body: formData,
    headers: authHeader({
      authJson: false,
      authForm: true,
      guestJson: false,
    }),
  };

  setError({
    text: "Saving...",
    type: "feedback-success",
  });

  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
    setError({
      text: "Saved Successfully",
      type: "feedback-success",
    });

    const URL = window.location.href;
    const sheetName = URL.substring(URL.lastIndexOf("/") + 1);
    await postContract.set(
      await postContract.get(projectID, postContractID, sheetName)
    );
  } else {
    // Response Not OK
    console.log("Network Error");
    setError({
      text: "Network Error!",
      type: "feedback-error",
    });
  }
}
