import React from "react";

import { Switch, Route, Redirect } from "react-router-dom";
import isEmpty from "../../validation/is-empty";
import WholelifeCost from "../sustainability-whole-life-cost/WholelifeCost";
import CarbonCost from "../sustainability-carbon-cost/CarbonCost";
import EnvironmentalCost from "../sustainability-environmental-cost/EnvironmentalCost";
export default function Sustainability(props) {
  const { project } = props;

  if (isEmpty(project)) {
    return null;
  }

  return (
    <div className="project-background">
      <Switch>
        <Route
          exact
          path={"/project/:id/sustainability/"}
          render={() => (
            <Redirect
              replace
              to={`/project/${project.id}/sustainability/whole-life-cost`}
            />
          )}
        />
        <Route
          path={"/project/:id/sustainability/whole-life-cost"}
          render={() => <WholelifeCost />}
        />
        <Route
          path={"/project/:id/sustainability/carbon-cost"}
          render={() => <CarbonCost project={project} />}
        />
        <Route
          path={"/project/:id/sustainability/environmental-cost"}
          render={() => <EnvironmentalCost />}
        />
      </Switch>
    </div>
  );
}
