import React, { useState } from "react";

import "./PostContractSliderTicks.css";
import { PCRAStatusSummary } from "../pcra/PCRAStatusSummary";

export default function PostContractSliderTicks(props) {
  const { minValue } = props;
  const { maxValue } = props;
  const { pcaLog } = props;

  const [showPCAStatusSummary, setShowPCAStatusSummary] = useState(false);
  const [pcaLogIndex, setPcaLogIndex] = useState(minValue);

  const difference = maxValue - minValue;
  const display = [];

  for (let i = 0; i < difference + 1; i++) {
    const isCurrentIndex = pcaLogIndex === minValue + i;
    display.push(
      <button
        key={`pcst-tick_${i}`}
        className="pcst-tick"
        onMouseOver={() => {
          setShowPCAStatusSummary(true);
          setPcaLogIndex(minValue + i);
        }}
        onMouseOut={() => {
          setShowPCAStatusSummary(false);
        }}
        onFocus={() => {}}
        onBlur={() => {}}
      >
        {pcaLog && showPCAStatusSummary && isCurrentIndex && (
          <PCRAStatusSummary pcraLog={pcaLog[pcaLogIndex]} />
        )}
      </button>
    );
  }

  return (
    <div>
      <div className="post-contract-slider-ticks">{display}</div>
    </div>
  );
}
