import { GET_PROJECT, GET_PROJECTS, PROJECT_LOADING } from "../actions/types";

import isEmpty from "../validation/is-empty";

const initialState = {
  project: null,
  projects: null,
  loading: false,
};

function projectReducer(state, action) {
  if (isEmpty(state)) {
    state = initialState;
  }

  switch (action.type) {
    case GET_PROJECT:
      return {
        ...state,
        project: action.payload,
        loading: false,
      };
    case GET_PROJECTS:
      return {
        ...state,
        projects: action.payload,
        loading: false,
      };
    case PROJECT_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}

export default projectReducer;
