import { useState, useEffect } from "react";
import isEmpty from "../../validation/is-empty";
import { authHeader } from "../../_helpers/auth-header";

// API
import { pc2APIs } from "../api-endpoints/PostContract2.0Endpoints";

export function usePostContract(projectId, pcId) {
  const [loading, setLoading] = useState(false);
  const [postContract, setPostContract] = useState([]);

  const URL = window.location.href;
  const sheetName = URL.substring(URL.lastIndexOf("/") + 1);

  useEffect(() => {
    // Only make the request inside Post Contract or Dashboard
    if (
      window.location.href.includes("post-contract") ||
      window.location.href.includes("dashboard")
    ) {
      if (!isEmpty(pcId) && !isEmpty(sheetName)) {
        setLoading(true);
        getPostContract(projectId, pcId, sheetName)
          .then((data) => {
            setPostContract(data);
            setLoading(false);
          })
          .catch((e) => {
            setLoading(false);
          });
      }
    }
  }, [projectId, pcId, sheetName]);

  return {
    postContract: {
      data: postContract,
      loading: loading,
      get: getPostContract,
      set: setPostContract,
    },
  };
}

export async function getPostContract(projectId, pcId, sheetName) {
  if (sheetName === "dashboard") {
    sheetName = "Cost Report";
  }
  if (sheetName === "Commentary") {
    sheetName = "Cost Report";
  }

  if (sheetName === "Cashflow") {
    sheetName = "Progress Claim";
  }

  // POST CONTRACT API
  const url =
    pc2APIs().read_post_contract +
    "?projectId=" +
    projectId +
    "&postContractId=" +
    pcId +
    "&sheetName=" +
    sheetName;

  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = [];
  try {
    response = await fetch(url, config);
    //Add new get here
  } catch (e) {
    console.log("getPostContract error");
  }

  if (response.ok) {
    response = await response.json();
  } else {
    response = [];
    console.log("getPostContract response error", response);
  }
  return response;
}
