import { tranStr } from "../../utils/translation";

export function columns() {
  return [
    {
      type: "CHECK",
      key: "id",
      width: 50,
      isModal: false,
    },
    {
      heading: tranStr("Full Name"),
      key: "fullName",
      type: "DESCRIPTION",

      width: 200,
    },
    {
      heading: tranStr("Company Name"),
      key: "businessName",
      type: "DESCRIPTION",

      width: 200,
    },
    {
      heading: tranStr("Phone Number"),
      key: "phoneNumber",
      type: "DESCRIPTION",

      width: 200,
    },
    {
      heading: tranStr("Email Address"),
      key: "emailAddress",
      type: "DESCRIPTION",

      width: 300,
      // Cell: (p) => (
      //   <span>
      //     <a
      //       className="table-email-link"
      //       href={
      //         "mailto:" +
      //         p.value +
      //         "?subject=Cost Clarity Demonstration Request"
      //       }
      //     >
      //       {p.value}
      //     </a>
      //   </span>
      // ),
    },
    {
      heading: tranStr("Notes"),
      key: "notes",
      type: "DESCRIPTION",

      width: 400,
    },
    {
      heading: tranStr("Contacted"),
      key: "contacted",
      type: "DESCRIPTION",

      width: 80,

      //Cell: (p) => <Tick p={p} />,
    },
    {
      heading: tranStr("Completed"),
      key: "completed",
      type: "DESCRIPTION",

      width: 80,
      //Cell: (p) => <Tick p={p} />,
    },
  ];
}
