import React from "react";
import isEmpty from "../../validation/is-empty";

import ProjectBudgetReconciliation from "./ProjectBudgetReconciliation";
import EstimateBudgetTable from "./ProjectBudgetTable";
import ProjectBudgetCharts from "./ProjectBudgetCharts";

import "./ProjectBudget.css";

// Functions
import { translate } from "../../utils/translation";

export default function ProjectBudget(props) {
  const { project } = props;
  const { CP } = props;
  const { comparedCP, comparedCPR } = props;

  if (isEmpty(CP)) {
    return (
      <div className="project-budget">
        {translate(
          "Project Budget is available after a Cost Plan has been uploaded."
        )}
      </div>
    );
  }

  if (isEmpty(CP.budgets)) {
    return (
      <div className="project-budget">
        {translate(
          "Project Budget has not been added yet. Please click Configure Budget to start adding budget items."
        )}
      </div>
    );
  }

  return (
    <div className="project-budget">
      <div className="project-budget-column">
        <EstimateBudgetTable data={CP.budgets} CP={CP} />
        <ProjectBudgetReconciliation
          CP={CP}
          project={project}
          comparedCP={comparedCP}
          comparedCPR={comparedCPR}
        />
      </div>
      <div className="project-budget-column">
        <ProjectBudgetCharts data={CP.budgets} />
      </div>
    </div>
  );
}
