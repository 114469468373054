import { opAPIs } from "../../api/api-endpoints/OptioneeringEndpoints";
import { authHeader } from "../../_helpers/auth-header";
import { tranStr } from "../../utils/translation";
import { AutoCloseModal } from "../modal/Modal";
import isEmpty from "../../validation/is-empty";
import { deepClone } from "../../helpers/common.functions";

export async function uploadOptionsAnalysis(data) {
  if (data.file === null) {
    return data.setError({
      text: tranStr("Please attach file"),
      type: "feedback-error",
    });
  }

  if (isEmpty(data.stageId)) {
    return data.setError({
      text: tranStr("Please select a stage"),
      type: "feedback-error",
    });
  }

  data.setError({
    text: tranStr("Creating..."),
    type: "feedback-success",
  });

  let field = JSON.stringify({
    projectId: data.projectID,
    stageId: data.stageId,
  });

  const formData = new FormData();
  formData.append("file", data.file);
  formData.append("field", field);

  // Optioneering API
  const url = opAPIs().upload_options_analysis;
  const config = {
    method: "POST",
    body: formData,
    headers: authHeader({ authJson: false, authForm: true, guestJson: false }),
  };

  let response = await fetch(url, config);

  if (response.ok) {
    // Response OK
    await reloadData(data);

    data.setError({
      text: "Saved Successfully",
      type: "feedback-success",
    });

    AutoCloseModal(data.setModal);
  } else {
    // Response Not OK
    return data.setError({
      text: "Save Failed",
      type: "feedback-error",
    });
  }
}

export async function reloadData(data) {
  const { project } = data;
  const { getOptions, setOptions } = data;

  // refresh options
  await setOptions(await getOptions(project.id));
}

export async function uploadOptionsSummaryBreakdownItems(data) {
  if (data.file === null) {
    return data.setError({
      text: tranStr("Please attach file"),
      type: "feedback-error",
    });
  }

  data.setError({
    text: tranStr("Creating..."),
    type: "feedback-success",
  });

  let field = JSON.stringify({
    projectId: data.projectId,
    stageId: data.stageId,
    summaryId: data.selected.selected.id,
  });

  const formData = new FormData();
  formData.append("file", data.file);
  formData.append("field", field);

  // Optioneering API
  const url = opAPIs().upload_options_summary_breakdown_items;
  const config = {
    method: "POST",
    body: formData,
    headers: authHeader({ authJson: false, authForm: true, guestJson: false }),
  };

  let response = await fetch(url, config);

  if (response.ok) {
    // Response OK
    await reloadData(data);
    await response.json();

    data.setError({
      text: "Saved Successfully",
      type: "feedback-success",
    });
    AutoCloseModal(data.setModal);
  } else {
    // Response Not OK
    return data.setError({
      text: "Save Failed",
      type: "feedback-error",
    });
  }
}

export async function deleteOptionsSummaryBreakdownItem(data) {
  let field = JSON.stringify({
    projectId: data.projectId,
    stageId: data.stageId,
    summaryId: data.selected.item.id,
  });

  data.setLoading(true);

  const url = opAPIs().delete_options_summary_breakdown_item;
  const config = {
    method: "DELETE",
    body: field,
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);

  if (response.ok) {
    // Response OK
    await reloadData(data);

    await response.json();

    data.setLoading(false);
    return data.setError({
      text: "Saved Successfully",
      type: "feedback-success",
    });
  } else {
    // Response Not OK
    data.setLoading(false);
    return data.setError({
      text: "Save Failed",
      type: "feedback-error",
    });
  }
}

export async function saveOptionsAnalysisItems(data) {
  const { project, setModal } = data;
  const { formRows, setError } = data;

  let error = false;
  setError({
    text: "Saving...",
    type: "Success",
  });

  const formData = deepClone(formRows);
  // convert impact rating values to strings
  formData.forEach((row) => {
    row.costImpactRating = `${row.costImpactRating}`;
    row.programmeImpactRating = `${row.programmeImpactRating}`;
    row.environmentalImpact = `${row.environmentalImpact}`;
    row.operationalCostImpactRating = `${row.operationalCostImpactRating}`;
  });

  formData.forEach((row) => {
    if (row.summary === "" || row.costImpact === "") {
      error = true;
      setError({
        text: "Summary and Cost Impact fields are mandatory",
        type: "Error",
      });
    }
  });

  if (!error) {
    const payload = JSON.stringify({
      projectId: project.id,
      stageId: data.stageId,
      items: formData,
    });

    const config = {
      method: "POST",
      body: payload,
      headers: authHeader({
        authJson: true,
        authForm: false,
        guestJson: false,
      }),
    };

    const url = opAPIs().add_items_to_stages;

    let response = await fetch(url, config);

    if (response.ok) {
      // refresh options
      await reloadData(data);

      setError({
        text: "Success",
        type: "Success",
      });

      AutoCloseModal(setModal);
    } else {
      setError({
        text: "Save Error",
        type: "Error",
      });
    }
  }
}
