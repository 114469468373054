// React
import React from "react";
import { ImageDisplay } from "../images/ImageDisplay";
import { displayProjectImage } from "../../validation/image-clean";
// Style
import "./PortfolioMapTooltip.css";

export default function PortfolioMapTooltip(props) {
  const { p } = props;

  return (
    <div className="pm-tooltip-link">
      <ImageDisplay
        image={displayProjectImage(p)}
        imageWidth={"50px"}
        imageHeight={"30px"}
        isBezierDisabled={true}
      />
      <div className="pm-tooltip-text">{p.title}</div>
    </div>
  );
}
