import { tranStr } from "../../utils/translation";

export function getCostPlanningPart() {
  if (test("summary")) {
    return tranStr("Summary");
  }

  if (test("commentary")) {
    return tranStr("Commentary");
  }

  if (test("key-documents")) {
    return tranStr("Key Documents");
  }

  if (test("breakdown")) {
    return tranStr("Breakdown");
  }

  if (test("reconciliation")) {
    return tranStr("Reconciliation");
  }

  if (test("benchmarking")) {
    return tranStr("Benchmarking");
  }

  if (test("cashflow")) {
    return tranStr("Cashflow");
  }
  if (test("project-budget")) {
    return tranStr("Project Budget");
  }

  if (test("area-schedule")) {
    return tranStr("Area Schedule");
  }
  if (test("value-management")) {
    return tranStr("Value Management");
  }
}

export function getCostPlanningPartURL() {
  if (test("summary")) {
    return "summary";
  }

  if (test("commentary")) {
    return "commentary";
  }

  if (test("key-documents")) {
    return "key-documents";
  }

  if (test("breakdown")) {
    return "breakdown";
  }

  if (test("reconciliation")) {
    return "reconciliation";
  }

  if (test("benchmarking")) {
    return "benchmarking";
  }

  if (test("cashflow")) {
    return "cashflow";
  }
  if (test("project-budget")) {
    return "project-budget";
  }

  if (test("area-schedule")) {
    return "area-schedule";
  }
  if (test("value-management")) {
    return "value-management";
  }
}

export function getPostContractPart() {
  if (test("cost-report")) {
    return tranStr("Cost Report");
  }

  if (test("progress-claims")) {
    return tranStr("Progress Claims");
  }

  if (test("trade-costs")) {
    return tranStr("Trade Costs");
  }

  if (test("unfixed-materials")) {
    return tranStr("Unfixed Materials");
  }

  if (test("enabling-works-variations-register")) {
    return tranStr("Enabling Works Variations");
  }

  if (test("consultant-fee-variations-register")) {
    return tranStr("Project Team Consultant Fees Variations");
  }

  if (test("variations")) {
    return tranStr("Variations");
  }

  if (test("provisional-sums")) {
    return tranStr("Provisional Sums");
  }

  if (test("provisional-quantities")) {
    return tranStr("Provisional Quantities");
  }

  if (test("client-directs")) {
    return tranStr("Client Directs");
  }

  if (test("client-direct-variation-register")) {
    return tranStr("Client Direct Variations");
  }

  if (test("enabling-works")) {
    return tranStr("Enabling Works");
  }

  if (test("consultant-fees")) {
    return tranStr("Project Team Consultant Fees");
  }

  if (test("risk-register")) {
    return tranStr("Risk Register");
  }

  if (test("cashflow")) {
    return tranStr("Cashflow");
  }

  if (test("delays")) {
    return tranStr("Delays / EoTs");
  }

  if (test("contingency")) {
    return "Contingency";
  }

  if (test("commentary")) {
    return "Commentary";
  }
}

export function getPostContractPartURL() {
  if (test("cost-report")) {
    return "cost-report";
  }

  if (test("progress-claims")) {
    return "progress-claims";
  }

  if (test("trade-costs")) {
    return "trade-costs";
  }

  if (test("unfixed-materials")) {
    return "unfixed-materials";
  }

  if (test("enabling-works-variations-register")) {
    return "enabling-works-variations-register";
  }

  if (test("consultant-fee-variations-register")) {
    return "consultant-fee-variations-register";
  }

  if (test("variations")) {
    return "variations";
  }

  if (test("provisional-sums")) {
    return "provisional-sums";
  }

  if (test("provisional-quantities")) {
    return "provisional-quantities";
  }

  if (test("client-directs")) {
    return "client-directs";
  }

  if (test("risk-register")) {
    return "risk-register";
  }

  if (test("cashflow")) {
    return "cashflow";
  }

  if (test("delays")) {
    return "delays";
  }

  if (test("consultant-fees")) {
    return "consultant-fees";
  }

  if (test("client-direct-variation-register")) {
    return "client-direct-variation-register";
  }

  if (test("contingency")) {
    return "contingency";
  }
  if (test("enabling-works")) {
    return "enabling-works";
  }
  if (test("commentary")) {
    return "Commentary";
  }
}
export function getSustainabilityPart() {
  if (test("whole-life-cost")) {
    return tranStr("Whole Life Cost");
  }
  if (test("carbon-cost")) {
    return tranStr("Carbon Cost");
  }
  if (test("environmental-cost")) {
    return tranStr("Environmental Cost");
  }
}
export function getSustainabilityPartURL() {
  if (test("whole-life-cost")) {
    return tranStr("whole-life-cost");
  }
  if (test("carbon-cost")) {
    return tranStr("carbon-cost");
  }
  if (test("environmental-cost")) {
    return tranStr("environmental-cost");
  }
}

export function getOptioneeringPart() {
  if (test("options-analysis")) {
    return tranStr("Options Analysis");
  }
}

export function getOptioneeringPartURL() {
  if (test("options-analysis")) {
    return "options-analysis";
  }
}

export function test(value) {
  const URL = window.location.href;

  return URL.includes(value);
}
