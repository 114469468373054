import React from "react";

import "./DashboardDrawer.css";

export default function DashboardDrawer(props) {
  const { Component } = props;
  const { show } = props;

  return (
    <div className="dashboard-drawer">
      {show && (
        <div className="dashboard-drawer-content">
          <Component {...props} />
        </div>
      )}
    </div>
  );
}
