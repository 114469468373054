// React
import React from "react";
import ProjectItem from "../portfolio-projects/ProjectItem";

// Style
import "./PortfolioMapPopup.css";

export default function PortfolioMapPopup(props) {
  const { p } = props;

  return <ProjectItem project={p} isPortfolio isMap />;
}
