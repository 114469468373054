import { GET_RESPONSE, CLEAR_RESPONSE } from "../actions/types";

import isEmpty from "../validation/is-empty";

const initialState = {};

function responseReducer(state, action) {
  if (isEmpty(state)) {
    state = initialState;
  }

  switch (action.type) {
    case GET_RESPONSE:
      return action.payload;
    case CLEAR_RESPONSE:
      return {};
    default:
      return state;
  }
}

export default responseReducer;
