import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Link } from "react-router-dom";
import { connect } from "react-redux";

import MasterPage from "../master/MasterPage";
import { useServiceSelectionHomeControls } from "./ServiceSelectionHome.functions";

import BenchmarkingDisclaimerTrigger from "../benchmarking/BenchmarkingDisclaimerTrigger";

import { HOME_GLOSSARY } from "./ServiceSelectionHome.constants";

import "./ServiceSelectionHome.css";

/// props validation | SQ(javascript:S6774)
ServiceSelectionHome.propTypes = {
  auth: PropTypes.object,
};
///
export function ServiceSelectionHome(props) {
  const { user } = props.auth;

  const controls = useServiceSelectionHomeControls({ user });

  return (
    <MasterPage title="Home" controls={controls}>
      <Page {...props} />
    </MasterPage>
  );
}

function Page(props) {
  return (
    <Switch>
      <Route exact path={"/home/"} render={() => <Home {...props} />} />
      <Route
        exact
        path={"/home/benchmarking/"}
        render={() => <BenchmarkingHome {...props} />}
      />
    </Switch>
  );
}

/// props validation | SQ(javascript:S6774)
Home.propTypes = {
  tiles: PropTypes.array,
};
///
function Home(props) {
  return <PageContent {...props} tiles={HOME_GLOSSARY.TILES.HOME_PAGE} />;
}

/// props validation | SQ(javascript:S6774)
BenchmarkingHome.propTypes = {};
///
function BenchmarkingHome(props) {
  return (
    <>
      <PageContent
        {...props}
        tiles={HOME_GLOSSARY.TILES.BENCHMARKING_HOME_PAGE}
      />
      <BenchmarkingDisclaimerTrigger {...props} />
    </>
  );
}

/// props validation | SQ(javascript:S6774)
PageContent.propTypes = {
  tiles: PropTypes.array,
};
///
function PageContent(props) {
  const { tiles } = props;

  return (
    <div className="page-content-center">
      {tiles.map((tile) => (
        <ServiceSelectorBox key={`${tile.title}`} {...tile} />
      ))}
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
ServiceSelectorBox.propTypes = {
  title: PropTypes.string,
  imagePath: PropTypes.string,
  linkTo: PropTypes.string,
};
///
function ServiceSelectorBox(props) {
  const { title, imagePath, linkTo } = props;

  return (
    <Link to={linkTo} className="service-selector-box-link">
      <div className="service-selector-box">
        <div
          className="image"
          title={title}
          // style={{ backgroundImage: `url(${imagePath});` }}
          style={{ backgroundImage: `url("${imagePath}")` }}
        ></div>
        <div className="text">{title}</div>
      </div>
    </Link>
  );
}

export default connect((state) => ({
  auth: state.auth,
}))(ServiceSelectionHome);
